.mobile-my-center.mobile-my-center.mobile-my-center{
  .my-info-wrap{
    margin: 0.24rem 0;
    padding: 0.24rem;
    background: #fff;
    border-top: 1px solid #E9EAF2;
    border-bottom: 1px solid #E9EAF2;
    .name-img{
      font-size: 0.32rem;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      //background: #8DC8EA;
      background: #999999;
      color: #fff;
      margin-right: 0.32rem;
    }
    .my-info{
      .name{
        font-weight: bold;
        font-size: 0.32rem;
        margin-bottom: 0.08rem;
      }
      .user-no{
        color: #999999;
      }
    }
  }
  .cell-group{
    .cell{
      height: 0.8rem;
      background: #fff;
      border-bottom: 1px solid #E9EAF2;
      justify-content: space-between;
      .cell-icon{
        font-size: 0.48rem;
        margin:0.08rem 0.16rem;
      }
      .to-icon{
        margin-right: 0.16rem;
      }
    }
  }
}