.assigning-menu-log.assigning-menu-log {
  width: 550px;
  height: 60vh;
  display: flex;
  flex-direction: column;

  .content.content.content {
    padding: 0;
    flex: 1;

    .ant-tabs {
      height: 100%;

      .ant-tabs-nav {
        padding: 0 12px;
      }

      .ant-tabs-content {
        height: 100%;

        .ant-tabs-tabpane-active {
          height: 100%;
          overflow: scroll;

          // .ant-tree {
          //   height: 100%;

          //   .ant-tree-list {
          //     height: 100%;

          //     .ant-tree-list-holder {
          //       max-height: 100%;
          //       overflow-y: hidden;
          //       overflow-anchor: none;
          //     }
          //   }
          // }
        }
      }
    }
  }
}