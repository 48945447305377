.project-view{
  .project-view-options{
    .btn.btn.btn.btn {
      width: 75px;
      margin-left: 15px;
      height: 31px;
    }

    .reset-btn.reset-btn.reset-btn {
      color: #3A415C;
      border: 1px solid #7A8199;
    }
  }
}