.menu-mgt-two-columns.menu-mgt-two-columns.menu-mgt-two-columns{
  margin: 6px 12px 12px;
  flex: 1;
  display: none;
  height: 100px;
  &.is-tree{
    display: flex;
  }
  .menu-tree-wrap{
    width: 300px;
    height: 100%;
    padding: 8px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 4px;
    .ant-tabs-tab{
      padding: 4px 10px 10px;
    }
    .ant-tree{
      height: 100px;
      flex: 1;
      overflow-y: auto;
    }
  }
}