.defect-left-panel.defect-left-panel.defect-left-panel{
  background: #fff;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 6px 20px rgba(0,0,0,0.1);
  height: 100%;
  .list-area{
    height: 200px;
    flex: 1;
    overflow: auto;
    &:first-child{
      border-radius: 6px 6px 0 0;
    }
    .list-item{
      box-sizing: border-box;
      //height: 70px;
      padding: 10px;
      border-bottom: 1px solid #e9eaf2;
      &.active{
        background: #F3F7FF;
      }
      &:hover{
        background: #F3F7FF;
        cursor: pointer;
      }
      .list-item-top{
        //justify-content: space-between;
        //.item-severity{
        //  width: 40px;
        //  padding: 1px 0;
        //  border-radius: 4px;
        //  font-size: 12px;
        //  border: 1px solid #5477FF;
        //  color: #5477FF;
        //  margin-right: 8px;
        //}
        .item-defect-id{
          color: #15171f;
        }
        .item-status{
          margin-left: 8px;
          padding: 0 4px;
          border-radius: 4px;
          font-size: 12px;
          border: 1px solid #5477FF;
          color: #5477FF;
        }
      }
      .list-item-bottom{
        margin-top: 4px;
        //margin-left: 50px;
        color: #7A8199;
        white-space: nowrap;/*不允许换行*/
        overflow: hidden;/*超出隐藏*/
        text-overflow: ellipsis;/*文本超出三点代替*/
      }
    }
  }
  .x-pagination{
    margin: 5px;
  }
  .pagination-area{
    padding: 8px;
  }
}