.req-join-apply {
  // >.content {
  //   overflow: hidden;
  // }

  .ant-tabs {
    height: 100%;

    .ant-tabs-content {
      height: 100%;
      display: flex;

      .ant-tabs-tabpane {
        display: flex;
        overflow: hidden;

        &.ant-tabs-tabpane-hidden {
          display: none;
        }

        .untreated-tables {
          flex: 1;
          overflow: hidden;
          display: flex;
          position: relative;

          .select-operation {
            position: absolute;
            top: 0;
            left: 40px;
            z-index: 10;
            width: 100%;
            height: 37px;
            background-color: #f5f5f5;
            align-items: center;
            padding: 0 12px;

            .flag-reject {
              margin-left: 16px;
              cursor: pointer;

              &:hover {
                color: #5477ff;
              }

              >i {
                color: #A10D0D;
                font-size: 14px;
                margin-left: 4px;

              }
            }

            .flag-agree {
              cursor: pointer;

              &:hover {
                color: #5477ff;
              }

              >i {
                color: #25A10D;
                font-size: 14px;
                margin-left: 4px;
              }
            }
          }
        }

        .x-datagrid {
          font-size: 12px;
          flex: 1;

          .flag-reject {
            >i {
              color: #A10D0D;
              font-size: 14px;
              margin-left: 4px;
            }
          }

          .flag-agree {
            >i {
              color: #25A10D;
              font-size: 14px;
              margin-left: 4px;
            }
          }

          .req-operation {
            >div {
              cursor: pointer;
              margin-right: 16px;

              &:hover {
                color: #5477ff;
              }

              >i {
                margin-left: 4px;
              }
            }

            .agree {
              >i {
                color: #25A10D;
                font-size: 14px;
              }
            }

            .reject {
              >i {
                color: #A10D0D;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-tab {
      font-size: 12px;
      padding: 8px 0;
    }
  }
}

.req-select-log {
  .select-log {
    margin-left: 60px;

    >div {
      cursor: pointer;
      padding: 4px 6px;

      &:hover {
        background-color: #E4EAFF;
      }

      >i {
        margin-left: 4px;
      }
    }

    .checked {
      background-color: #E4EAFF;
    }

    .agree {
      margin-right: 16px;

      >i {
        color: #25A10D;
        font-size: 14px;
      }
    }

    .reject {
      >i {
        color: #A10D0D;
        font-size: 14px;
      }
    }
  }
}