.work-time-table.work-time-table.work-time-table {
  display: flex;
  height: 100px;
  width: 100%;
  flex: 1;
  padding-top: 12px;

  .work-time-working-project-type {
    &::before {
      display: inline-block;
      margin-right: 4px;
      font-family: SimSun, sans-serif;
      content: '*';
      color: #F5221B;
    }
  }

  .x-card.x-card {
    margin: 0;

    .column {
      >.top {
        border: none;
      }
    }

    .cell {
      border: none;

      &.fixed-right {
        box-shadow: rgba(5, 5, 5, 0.06) -10px 0px 6px -5px;
      }
    }

    .card-title {
      &:before {
        display: block;
        content: "";
        width: 2px;
        height: 1em;
        background-color: #5477ff;
        box-shadow: 2px 0 3px 0 rgba(24, 144, 255, 0.42);
        margin-right: 12px;
      }

      .header-text {
        font-weight: 700;
      }

      .this-week-total-text {
        margin-left: 16px;
      }

      .this-week-total-num {
        font-size: 18px;
      }
    }

    .normal-row {
      .cell:nth-of-type(3) {
        .x-datagrid-tooltip {
          width: 100%;
          align-items: flex-start;
          padding: 0;

          .description-textarea {
            margin: 8px 12px 0;
            font-size: 12px;
            line-height: 22px !important;
          }

          .description-wrap {
            max-width: 100%;
            line-height: 49px;
            word-wrap: break-word;
            word-break: normal;
            max-height: 200px;
            padding: 0 12px;
          }
        }
      }

      &.refuse-row {
        color: #909090;

        .ant-progress-text {
          color: #909090;
        }
      }

      .ant-input-number {
        font-size: 12px;
      }
    }

    .project-select-wrap {
      height: 30px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 4px 11px;
      cursor: pointer;
      width: 260px;
      background: #fff;

      &.placeholder-text {
        color: #BFBFBF;
      }
    }

    .title-tips-wrap {
      color: #909090;
      font-size: 12px;
      margin-left: 12px;

      .icon {
        font-size: 14px;
        margin-right: 6px;
        color: #5477ff;
      }
    }
  }

  .one-line-row-wrap {
    width: 100%;

    .menu-row {
      height: 100%;
      width: 100%;
      cursor: pointer;
      padding-left: 10px;
      font-weight: bold;
      font-size: 13px;

      .fold-icon {
        margin-right: 12px;
      }

      .menu-total {
        font-weight: normal;
        color: #909090;
      }
    }

    .add-row {
      .textIconBtn {
        color: #5477ff;
        margin-left: 30px;
      }
    }

    &.experience-line-row-wrap {
      border-bottom: 0px solid #e6e6e6;
    }

    .experience-item-box {
      width: 100%;
      border-bottom: 1px solid #e6e6e6;

      &:hover {
        background-color: #f8f8f8;
      }
    }
  }

  .state-wrap {
    border-radius: 4px;
    padding: 1px 8px;
  }

  .weekly-input-percentage {
    width: 100%;

    .ant-progress {
      width: 70px;
    }

    .percentage-text {
      margin-left: 6px;
    }
  }

  .transaction-select {
    .status {
      display: none;
    }
  }
}

.work-time-description-pop-wrap {
  padding-top: 0;
  padding-left: 8px;

  .work-time-description-pop {
    width: 400px;
    padding: 6px;
    max-height: 250px;
    overflow: auto;
    white-space: pre-wrap;
    font-size: 12px;
  }
}

.work-time-table-dynamic {
  .comment-info {
    display: none;
  }
}

.lase-week-hint-log {
  width: 350px;

  .content {
    font-size: 12px;
    color: #909090;
  }
}