.version-plan-condition-filter-pop{
  padding: 4px 6px;
  .rootnet-forminput.rootnet-forminput.rootnet-forminput{
    margin: 4px;
  }
  .btn-group{
    margin: 4px 0;
    justify-content: flex-end;
    .button{
      height: 30px;
    }
  }
}