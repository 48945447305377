.used-product-detail{
  .content.content.content.content{
    width: 700px;
    padding-bottom: 20px;
  }
  .sub-sys-id-options{
    .rootnet-popup{
      .container{
        .option{
          min-width: 300px;
        }
      }
    }
  }
}