.ImportApiDialog.ImportApiDialog.ImportApiDialog.ImportApiDialog.ImportApiDialog {
  width: 850px;
  .content.content.content.content.content {
    height: 65vh;
    display: block;
    overflow: scroll;
    .file-input {
      display: none;
    }

    >div {
      >p {
        margin-left: 18px;
      }

      >p:nth-child(1) {
        margin-left: 0;
      }
    }

    .arrow_drop_down {
      cursor: pointer;
      margin-left: 10px;
      color: #5477ff;

      &:hover {
        color: #7f93e0;
      }

      >i {
        color: #333;
        display: inline-block;
        margin-left: 5px;
        font-size: 14px;
      }
    }

    .Download {
      >span {

        i {
          cursor: pointer;
          font-size: 14px;
        }

        span {
          cursor: pointer;
          color: #5477ff;

          &:hover {
            color: #7f93e0;
          }
        }
      }
    }
  }
}

.importFiltering.importFiltering.importFiltering.importFiltering {
  min-width: 850px;
  max-width: 1100px;

  .content.content.content.content.content {
    display: block;
    padding-top: 0;
    .x-card {

      .card-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .x-datagrid {
          height: 210px;
          border-top: none;
          font-size: 12px;
        }
      }

    }

    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 10px 0;
    }
  }
}