.post-Message {
  .ellipsis-line {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .x-tooltip {
    max-width: 400px;
  }

  .rootnet-forminput {
    width: 100%;

    .autoTextarea {
      display: flex;

      >textarea {
        position: static;
      }
    }

    .form-component {
      width: 90% !important;
      white-space: pre-wrap;
      height: auto;
    }
  }
}