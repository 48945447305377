.defect-right-panel.defect-right-panel.defect-right-panel{
  //margin-left: 12px;
  height: 100%;
  width: 500px;
  flex: 1;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 6px 20px rgba(0,0,0,0.1);
  .defect-change-record{
    padding-left: 16px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .defect-into{
    color: #7A8199;
    height: 48px;
    .defect-into-item{
      margin-right: 20px;
    }
  }
  &.full-screen{
    box-sizing: border-box;
    position: absolute;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    margin: 0 12px;
    .panel-sub-header{
      border-bottom: 1px solid #E9EAF2;
    }
  }
  .panel-header{
    height: 50px;
    padding: 0 14px;
    justify-content: space-between;
    border-bottom: 1px solid #E9EAF2;
    .header-left{
      width: calc(100% - 260px);
    }
    .copy-icon{
      font-weight: normal;
      margin-left: 4px;
      margin-top: 4px;
      font-size: 16px;
      cursor: pointer;
      &:hover{
        color: #3f7dff;
      }
    }
    .defect-title{
      font-weight: bold;
      font-size: 18px;
      max-width: 90%;
      white-space: nowrap;/*不允许换行*/
      overflow: hidden;/*超出隐藏*/
      text-overflow: ellipsis;/*文本超出三点代替*/
      cursor: pointer;
      &:hover{
        background: #F7F7F7;
      }
    }
    .defect-id{
      color: #15171F;
      margin-right: 48px;
    }
    .header-icon-group{
      .header-icon{
        font-size: 16px;
        margin-left: 18px;
        cursor: pointer;
        &:hover{
          color: #3f7dff;
        }
      }
      .previous{
        .icon{
          display: inline-block;
          transform: rotate(-90deg);
          padding-right: 0;
        }
      }
      .next{
        .icon{
          display: inline-block;
          transform: rotate(90deg);
          padding-right: 0;
        }
      }
    }
  }
  .panel-content{
    height: 100px;
    flex: 1;
  }
  .panel-sub-header{
    height: 50px;
    padding: 0 14px;
    justify-content: space-between;
    &.is-fold{
      height: 0;
      overflow: hidden;
    }
  }
  .panel-form{
    padding: 0 14px;
    flex-wrap: wrap;
    .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
      margin: 4px 8px;
    }
    &.is-fold{
      height: 0;
      overflow: hidden;
    }
  }
  .fold-bar-wrap{
    width: 100%;
  }
  .fold-bar{
    height: 30px;
    width: 200px;
    cursor: pointer;
    &:hover{
      background: #F3F7FF;
    }
    .fold-icon{
      font-size: 16px;
      &.is-fold{
        transform:rotate(180deg);
      }
    }
  }
  .full-screen-content{
    height: 100px;
    flex: 1;
    .common-content{
      width: 400px;
      flex: 1;
      .ant-tabs{
        flex: 1;
        height: 100px;
        .ant-tabs-nav{
          padding-left: 20px;
          margin-bottom: 0;
        }
        .ant-tabs-content-holder{
          display: flex;
          flex: 1;
        }
        .ant-tabs-content, .ant-tabs-content{
          flex: 1;
        }
        .tab-content{
          height: 100%;
        }
      }
    }
    .rich-text-tab{
      .rich-text-tab-icon{
        color: #3A415C;
        cursor: pointer;
        &:hover{
          color: #3f7dff;
        }
      }
    }
    .rich-text-area-wrap{
      flex: 1;
      height: 100%;
      width: 100%;
      min-height: 300px;
      overflow: hidden;
    }
    .rich-text-detail-wrap{
      width: 100%;
      height: 200px;
      padding: 8px;
      overflow: auto;
      flex: 1;
      border: 1px solid #fff;
      &:hover{
        border: 1px solid #E9EAF2;
      }
      .no-description{
        padding: 4px 8px;
        color: #738299;
      }
      img{
        max-width: 100%;
      }
    }
    .columns-form{
      //padding: 10px 0;
      border-left: 1px solid #E9EAF2;
      width: 330px;
      min-height: 400px;
      overflow: auto;
    }
  }
  .editable-formInput{
    width: 320px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
      background: #F7F7F7;
      .edit-display-icon{
        visibility: unset;
      }
    }
    .edit-display-icon{
      width: 20px;
      visibility: hidden;
      &:hover{
        color: #3f7dff;
      }
    }
  }
  .save-btn{
    z-index: 4;
    position: absolute;
    bottom: 24px;
    right: 10px;
  }
  .fixed-comment-fixed{
    z-index: 3;
    position: absolute;
    width: 44px;
    height: 44px;
    right: 32px;
    bottom: 102px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    border-radius: 50%;
    background: #ffffff;
    &:hover{
      color: #1890ff;
    }
    .fixed-comment-icon{
      font-size: 26px;
    }
  }
  .display-formInput.display-formInput.display-formInput{
    .form-component{
      line-height: 32px;
      overflow: hidden;  //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical;  //规定为垂直方向排列
    }
  }
  .right-panel-defect-file-area{
    height: 100%;
  }
  .ant-tabs-tabpane{
    .x-card{
      margin: 0;
    }
  }
}