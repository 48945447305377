.add-version-log {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;

  >.content.content.content.content {
    padding: 0;
    display: flex;
    flex: 1;

    .version-form-list {
      flex: 1;
      overflow: auto;
      padding: 0 24px;
      box-sizing: border-box;

    }
  }
}