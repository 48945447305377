.requirement-table-details.requirement-table-details.requirement-table-details {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;

  .view-area-wrap {
    display: none;
  }

  .requirement-table-details-data {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .x-pagination {
    justify-content: end;
  }

  .x-datagrid {
    flex: 1;
    overflow: hidden;
    font-size: 12px;

    .hover-title-line {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}