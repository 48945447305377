.version-making-msg {
  height: 100%;
  overflow: hidden;
  display: flex;

  .x-card {
    margin: 0;

    .card-content {
      .x-datagrid {
        .grid {
          .row {
            .fixed-right {
              z-index: 10;
            }
          }
        }
      }
    }
  }


}