.rightDataGrid {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100px;
  overflow: hidden;
  margin: 0 8px 8px 0px;
  .view-area-wrap{
    margin: 0 0 6px 0;
  }

  .customize-options {
    border-radius: 4px;
    margin-bottom: 4px;
    background-color: white;

    .c-options {
      margin-top: 0;
    }
  }

  .x-card {
    margin: 0;

    .enter-detail-field {
      cursor: pointer;
      color: #5477ff;

      &:hover {
        text-decoration: underline
      }
    }

    .row {
      &>.cell:hover .edit-icon {
        margin-left: 8px;
        display: inline-block;
      }

      .edit-icon {
        display: none;
        cursor: pointer;

        i {
          margin-right: 8px;
          font-size: 14px;
        }

        & i:hover {
          color: #5477ff;
        }
      }

      .expand-icon {
        margin-left: 0;
      }
    }
  }
}