.desc-item{
  color: #15171F;
  min-width: 60px;
  .desc-header{
    color: #7A8199;
    margin-bottom: 8px;
  }
  .desc-text{
    max-width: 265px;
    white-space: nowrap;/*不允许换行*/
    overflow: hidden;/*超出隐藏*/
    text-overflow: ellipsis;/*文本超出三点代替*/
  }
}