.customer-communication-record {
  width: 100%;
  height: 100%;

  .rich-text-area {
    height: 100%;
  }

  .rich-text-detail-wrap {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px;

    img {
      max-width: 100%;
    }
  }
}