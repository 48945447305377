div.html-editor {
    position: relative;
    height: 100%;
    display: flex;
    background-color: white;

    >div {
        flex: 1;
        margin: 8px;
    }
}