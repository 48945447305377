.customer-information-wrap {
  height: 100%;
  width: 100%;

  .x-card {
    margin: 0;
    box-shadow: none;
  }

  .enter-detail-field-wrap {
    cursor: unset;

    .enter-detail-field {
      cursor: pointer;
      color: #5477ff;
    }
  }

}