.isTestPlan.isTestPlan.isTestPlan.isTestPlan {
  display: none;
}

.TestPlan {
  >.x-card {
    .row>.cell:hover .edit-icon {
      margin-left: 8px;
      display: inline-block;
    }

    .edit-icon {
      display: none;

      i {
        cursor: pointer;
        margin-right: 8px;
        font-size: 14px;
      }

      & i:hover {
        color: #5477ff;
      }
    }

    .enter-detail-field {
      color: #5477ff;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .progress-color {
      .x-datagrid-tooltip {
        width: 100%;

        .progressSet {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          .progressColor {
            border-radius: 2px;
            margin-right: 1px;
          }
        }
      }
    }
  }
}

.testPlan-dialog {
  width: 660px;
  height: 70%;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    .tox .tox-sidebar-wrap {
      height: 300px;
    }

    .rich-text-area {
      margin: 8px;
      margin-left: 0;
      display: flex;
      border-radius: 4px;

      >label {
        color: #738299;
        width: 80px;
        text-align: right;
        margin-left: 8px;

        &::after {
          content: ":";
          margin: 0px 8px 0px 2px;
        }
      }

      >div {
        flex: 1;
        border-radius: 4px;
      }
    }

    .merge_row {
      display: flex;

      .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
        flex: 1;
        display: flex;

        >.form-component.form-component.form-component.form-component {
          flex: 1;
        }
      }

      .rootnet-forminput:nth-child(2) {
        >label {
          flex: none;
        }
      }
    }

    .params-item {

      display: flex;

      .hint-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}