.conference-records-detail-dialog{
  height: 90%;
  width: 90%;
  display: flex;
  >.content.content.content.content {
    padding: 0;
    width: 100px;
    height: 100%;
    display: flex;
    flex: 1;
  }
}