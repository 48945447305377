.matchcode-details {
  height: 80vh;

  .content.content.content.content.content {
    padding: 0;
    height: 100%;
    display: flex;
    flex: 1;

    .matchcode-detail-content {
      padding: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;

      .mock-dialog-header {
        display: flex;
        align-items: center;
        color: #15171f;
        padding: 12px 16px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #e9eaf2;
        justify-content: space-between;

        .dialog-title {
          font-weight: 700;
        }

        .mock-right-header {
          .header-edit-icon {
            font-size: 18px;
            cursor: pointer;

            &.edit-active-icon {
              color: #5477ff;
            }

            &:hover {
              color: #5477ff;
            }
          }
        }

        .close-area {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          cursor: pointer;
          margin-left: 20px;

          .close-icon {
            font-size: 14px;
          }

          &:hover {
            background-color: #ECECEC;
          }
        }
      }

      .matchcode-detail-main-panel {
        flex: 1;
        overflow: auto;

        .code-review-use-time {
          display: inline-block;
          white-space: nowrap;
          color: #738299;
          font-size: 14px;
          width: 100px;
          text-align: right;

          &::after {
            content: ':';
            margin-left: 2px;
          }
        }
      }

      .mock-footer {
        height: 44px;
        box-sizing: border-box;
        position: sticky;
        bottom: 0;
        background-color: #fff;
        z-index: 5;
        border-radius: 0 0 4px 4px;
        justify-content: flex-end;
        border-top: 1px solid #e9eaf2;

        .btn-group {
          .button {
            margin-right: 16px;
            height: 32px;
          }
        }
      }
    }
  }

  .ant-tabs {
    .ant-tabs-content.ant-tabs-content.ant-tabs-content {
      .rootnet-forminput.rootnet-forminput.rootnet-forminput {
        width: 100%;
        margin: 0;
        padding: 8px;
        display: flex;

        textarea {
          flex: 1;
          min-height: 350px;
        }

        .form-component {
          width: 90% !important;
          white-space: pre-wrap;
          height: auto;
        }
      }
    }
  }
}