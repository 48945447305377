.acceptance-result-dialog.acceptance-result-dialog {
  width: 720px;
  height: 520px;

  .content.content.content {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .acceptance-result-dialog-content {
      flex: 1;
      overflow: hidden;
    }

    .mock-dialog-header {
      width: 100%;
      display: flex;
      align-items: center;
      color: #15171f;
      padding: 12px 16px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e9eaf2;
      justify-content: space-between;

      .dialog-title {
        width: 100px;
        flex: 1;
        font-weight: 700;

        .title-hint {

          font-size: 12px;
          font-weight: 400;
          display: flex;
          align-items: center;
          padding-left: 16px;
          color: #C4C4C4;
        }
      }

      .mock-right-header {
        .header-edit-icon {
          font-size: 18px;
          cursor: pointer;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }
      }

      .close-area {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 20px;

        .close-icon {
          font-size: 14px;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }

    .content-main-panel {
      // width: 100%;
      height: 100px;
      flex: 1;
      // padding: 0 8px;
      overflow-y: auto;

      .text-area-detail-style {
        width: 97%;

        .form-component {
          flex: 1;
        }
      }

      .text-area-detail-style.text-area-detail-style.text-area-detail-style.text-area-detail-style {
        //详情富文本样式
        // width: 100%;
        align-items: baseline;

        .form-component.form-component.form-component {
          flex: 1;
          word-break: break-all;
          white-space: pre-wrap;
        }

        >textarea {
          min-height: 340px;
        }
      }
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}