$color: #2952FE;

.problemResearch {
  .problemResearch-tabs {
    .ant-tabs-nav-list {
      width: 100%;
      justify-content: space-around;

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
      }

      .ant-tabs-tab:hover {
        color: black;
      }

      .ant-tabs-tab {
        color: #666666;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: black;
      }

      .ant-tabs-tab.ant-tabs-tab-active {
        box-sizing: border-box;
      }

      .ant-tabs-ink-bar {
        background-color: $color;
      }
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active {
      padding: 0 26px 26px;
      box-sizing: border-box;
    }

  }
}