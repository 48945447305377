$color: #2952FE;

.antd-dialog.footer-align {
  .ant-modal-footer {
    text-align: left;
  }
}

.antd-dialog.antd-title {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    color: white;
    right: -30px;
    top: -4px;

    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.75);
    }

    .ant-modal-close-x {
      width: auto;
      height: auto;
      line-height: normal;
      font-size: 20px;
    }
  }
}

.antd-dialog {
  .ant-modal-content {
    border-radius: 6px;

    .ant-modal-header {
      border-radius: 6px 6px 0 0;

      .ant-modal-title {
        >span {
          display: flex;
          align-items: center;

          &::before {
            display: block;
            content: "";
            width: 4px;
            height: 1em;
            background-color: $color;
            margin-right: 8px;
          }
        }
      }
    }

    .ant-modal-footer {
      .ant-btn {
        height: 32px;
      }

      .ant-btn {
        border-radius: 4px;

        &.ant-btn:not(.ant-btn-primary) {

          &:hover,
          &:focus {
            color: #4267fb;
            border-color: #4267fb;
          }
        }

        &.ant-btn-primary {
          background-color: $color;
          border-color: $color;

          &:hover {
            background-color: #4267fb;
            border-color: #4267fb;
          }
        }
      }
    }
  }
}