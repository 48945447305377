.req-forecast{
  overflow: hidden;
  .outer-card{
    margin: 0 0 6px 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  }

  .card-header{
    .right{
      width: 52%;
    }
    .extra-wrap{
      width: 100%;
      justify-content: space-between;
      .expand-text{
        cursor: pointer;
        color: #5477ff;
        .fold-icon{
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }
  .extra-import-btn{
    height: 32px;
  }
  .add-req-wrap{
    height: 200px;
    width: 100%;
    cursor: pointer;
    &:hover{
      background-color: #EEF1FF;
    }
    .add-req-icon{
      font-size: 18px;
      margin-right: 12px;
      color: #5477ff;
    }
    .add-req-text{
      font-size: 16px;
      color: #5477ff;
    }
  }
  .req-forecast-summary{
    width: 100%;
    .req-forecast-summary-left{
      padding: 8px 12px;
      border-right: 1px solid #e1e1e1;
      .req-forecast-summary-item{
        padding: 4px;
        &.time{
          margin-left: 50px;
        }
      }
    }
    .req-forecast-summary-right{
      flex: 1;
      width: 200px;
      font-size: 12px;
      padding: 4px 12px;
      .ant-radio-group{
        margin-bottom: 4px;
      }
      .x-datagrid{
        max-height: 250px;
      }
    }
  }
}