.import-results.import-results.import-results.import-results.import-results {
  min-width: 850px;
  max-width: 1100px;

  .content.content.content.content.content {
    display: block;
    padding-top: 0;

    .x-card {

      .card-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .x-datagrid {
          height: 210px;
          // border-top: none;
          font-size: 12px;
        }
      }

    }

    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 10px 0;
    }
  }
}