.requirement-info-wrap {
  overflow: scroll;
  height: 100%;

  .text-area-detail-style.text-area-detail-style.text-area-detail-style {
    align-items: baseline;

    .form-component.form-component.form-component {
      height: auto;
      flex: 1;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }

  .area-wrap {
    .area-header {
      margin: 12px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .base-info-show-more {
    margin: 8px 40px;

    .base-info-show-more-text {
      cursor: pointer;
      color: #5477ff;
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 4px 8px;
  }

  .desc-wrap {
    padding-bottom: 10px;
    padding-right: 10px;

    .rich-text-area {
      margin-left: 40px;
    }
  }

  .rich-text-detail-wrap {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px;
    white-space: pre-wrap;

    img {
      max-width: 100%;
    }
  }

  .sales-project-id {
    overflow: hidden; //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical; //规定为垂直方向排列
  }

  .test-suggest-textarea.test-suggest-textarea.test-suggest-textarea.test-suggest-textarea {
    width: 100%;
  }

  .jira-id {
    color: #5477ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}