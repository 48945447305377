.dev-project-scope-all-info.dev-project-scope-all-info.dev-project-scope-all-info.dev-project-scope-all-info {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .enter-detail-field {
    cursor: pointer;
    color: #5477ff;

    &:hover {
      text-decoration: underline;
    }
  }
}