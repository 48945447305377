.testPlanDetail {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  >.header {
    position: absolute;
    right: 28px;
    top: 22px;
  }

  >.content {
    flex: 1;
    margin: 12px 12px 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .plan-record {
      padding: 0 8px;
    }

    >.x-tab {
      flex: 1;
      background-color: white;
      border-radius: 4px;

      >.tab-header {
        border-radius: 4px 4px 0 0;
        box-shadow: none;
        flex-direction: row-reverse;
        .header-list{
          padding-left: calc(20%);
        }
      }
    }

  }
}