.global-search-drawer {
  .ant-drawer-content-wrapper {
    width: 85% !important;

    .ant-drawer-header {
      padding: 8.5px 24px;

      .ant-drawer-close {
        padding: 12px;
      }
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      padding: 8px;
      box-sizing: border-box;

      .hover-tracer-id {
        cursor: pointer;
        color: #5477ff;

        &:hover {
          text-decoration: underline;
        }
      }

      .ant-input-group-wrapper {
        margin-bottom: 8px;
      }

      .ant-list {
        flex: 1;
        display: flex;
        overflow: hidden;
        flex-direction: column;

        .ant-spin-nested-loading {
          flex: 1;
          overflow: auto;
        }
      }
    }

    .ant-list-footer {
      .x-pagination {
        justify-content: end;
      }
    }
  }
}