
div.x.radiogroup {
    $primary-color:#5477FF; 
    display: flex;
    >label {
        display: flex;
        padding: 9px 45px 9px 0;
        align-items: center;
    }
    input[type="radio"] {
        display: flex;
        appearance: none;
        outline: none;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #969696;
        margin-right:9px;
        cursor: pointer;
        &:after,
        &:checked {
            display: inline-flex;
            content: "";
            border-radius: 50%;
        }
        &:checked,
        &:disabled {
            border: 1px solid $primary-color; // margin: 0;
            margin-right: 9px;
            &:after {
                border: none;
                background-color: $primary-color;
                width: 6px;
                height: 6px;
                transition: background-color .3s ease-out;
            }
        }
        &:disabled {
            background-color: #e8e8e8;
            border: solid 1px #979797;
            &:after {
                
                background-color: #969696;
            }
        }
    }
}