.date-selector{
  border-radius: 4px;
  &.error.error.error.error{
    border: 1px solid #F5221B;
    &:hover{
      box-shadow: 0 0 4px 0 #F5221B;
    }
  }
  &:hover{
    border-color: #5477FF;
  }
  &.ant-picker-focused{
    border-color: #5477FF;
  }
}