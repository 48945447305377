.associated-tracking-log {
  width: 1050px;

  .customize-options {
    .c-options {
      margin-top: 0;
      padding: 0;

      .rootnet-forminput {
        margin-left: 0;

        .switch {
          margin-bottom: 4px;
        }
      }
    }

    .search-btn-group {
      margin-bottom: 8px;
    }
  }

  .content.content.content.content {
    display: flex;
    height: 500px;
    padding-top: 0;

    .query-condition {
      width: 120px;
      border-right: 1px solid #e6e6e6;
      margin-right: 8px;
      box-sizing: border-box;

      >div {
        margin-top: 8px;

        .active {
          color: #5477ff;
        }

        >span {
          cursor: pointer;

          &:hover {
            color: #5477ff;
          }
        }
      }
    }

    .x-card {
      margin: 0;
      flex: 1;
      box-shadow: none;
      overflow: hidden;
    }

    .searchIconInput {

      .prefix {
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: unset;

        i {
          font-size: 14px;
        }
      }

      input {
        text-indent: unset;
      }
    }

    .flex {
      flex: 1;
      display: flex;
      overflow: hidden;

      // 左侧需求或研发任务
      .query-list {
        // padding: 12px;
        box-sizing: border-box;
        // border: 1px solid #ccc;
        border-radius: 4px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      //右侧选中数据
      .selected-list {
        margin-left: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        >div {
          width: 240px;
          font-size: 16px;
          padding: 4px 12px;
          border-bottom: 1px solid #ccc;
          box-sizing: border-box;
        }

        ul,
        li {
          padding: 0;
          margin: 0;
          list-style: none
        }

        ul {
          flex: 1;
          overflow: auto;
        }

        li {
          padding: 4px 12px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          >span:nth-child(1) {
            width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          >span:nth-child(2) {
            display: none;
            cursor: pointer;

            i {
              font-size: 14px;
              color: #ccc;
            }
          }

          &:hover>span:nth-child(2) {
            display: inline-block;
          }

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
}