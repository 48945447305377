.defect-data-grid{
  .quick-add-area{
    height: 34px;
    .quick-add-form{
      flex: 1;
      margin-left: 20px;
      //.ant-btn-primary{
      //  background-color: #5477ff;
      //  border: none;
      //  &:hover{
      //    background-color: #7692FF;
      //  }
      //}
    }
    .ant-select{
      .ant-select-selector{
        height: 34px;
        overflow: auto;
      }
    }
    .quick-add-area-text{
      color: rgb(191,191,191);
      margin-left: 20px;
      width: 100%;
    }
    &.inactivated{
      cursor: pointer;
      &:hover{
        background: #efefef;
      }
    }
  }
}