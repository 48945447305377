.req-stage-update-dialog{
  height: 80vh;
  .content.content.content.content{
    padding: 0;
    width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-header, .ant-modal-footer, .ant-modal-close{
    display: none;
  }
  .ant-modal-body{
    padding: 0;
  }
  .content-wrap{
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .mock-dialog-header{
    width: 100%;
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;
    .dialog-title{
      width: 100px;
      flex: 1;
      font-weight: 700;
    }
    .mock-right-header{
      .header-edit-icon{
        font-size: 18px;
        cursor: pointer;
        &.edit-active-icon{
          color: #5477ff;
        }
        &:hover{
          color: #5477ff;
        }
      }
    }
    .close-area{
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 20px;
      .close-icon{
        font-size: 14px;
      }
      &:hover{
        background-color: #ECECEC;
      }
    }
  }

  .content-main-panel{
    width: 100%;
    height: 100px;
    flex: 1;
    padding: 6px 0;
    overflow: scroll;
  }

  .mock-footer{
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    border-top: 1px solid #e9eaf2;

    .footer-switch {
      .footer-switch-item {
        cursor: pointer;
        padding: 4px 12px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        margin-right: 12px;
        color: #fff;
        background-color: #5477ff;
        transition: background-color 0.4s;

        &:hover {
          background-color: #214eff;
        }

        &.disabled {
          color: #6A6A6A;
          background-color: #E8E8E8;
          cursor: not-allowed;

          &:hover {
            background-color: #E8E8E8;
          }
        }

        >i {
          font-size: 14px;
        }
      }

      .list-num {
        font-weight: bold;
      }
      .comment-wrap{
        width: 30px;
        height: 30px;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #6A6A6A;
        &:hover{
          color: #1890ff;
          border: 1px solid #1890ff;
        }
        .comment-icon{
          font-size: 18px;
        }
      }
    }
    .btn-group{
      .button{
        margin-right: 16px;
        height: 32px;
      }
    }
  }
  .relate-document-icon-wrap{
    color: #5477ff;
    cursor: pointer;
  }
  .detail-left-panel{
    width: 650px;
  }

  .ant-tabs{
    height: 100%;
    .ant-tabs-nav{
      padding-left: 10px;
      margin-bottom: 0;
    }
    .ant-tabs-content-holder{
      display: flex;
      flex: 1;
      height: 100px;
      //border-bottom: 1px solid #D0D3E3;
      overflow: hidden;
      padding: 6px;
    }
    .ant-tabs-content, .ant-tabs-content{
      flex: 1;
      overflow: auto;
    }
    .tab-content{
      height: 100%;
    }
  }
  .dynamic-wrap{
    border-left: 1px solid #e9eaf2;
    padding-left: 8px;
    width: 300px;
    flex: 1;
  }
  .conference-tab-wrap{
    height: 100%;
    width: 100%;
  }

  .req-header{
    border-left: 1px solid #15171f;
    margin-left: 10px;
    padding-left: 10px;
    width: 100px;
    flex: 1;
    .req-tag{
      color: #3f7dff;
      border: 1px solid #3f7dff;
      font-size: 10px;
      padding:0 2px;
      border-radius: 4px;
      margin-right: 10px;
      &.issue{
        color: #FF5454;
        border: 1px solid  #FF5454
      }
    }
    .req-title-wrap{
      width: 100px;
      flex: 1;
    }
    .req-title{
      display: inline-block;
      max-width: 100%;
      color: #3f7dff;
      cursor: pointer;
      white-space: nowrap;/*不允许换行*/
      overflow: hidden;/*超出隐藏*/
      text-overflow: ellipsis;/*文本超出三点代替*/
      &:hover{
        text-decoration: underline;
      }
    }
  }
}