.stage-mgt{
  .x-card{
    .grid {
      .cell.cell.cell{
        .enter-detail-field-wrap{
          display: flex;
          width: 100%;
          height: 22px;
          &:hover .concerned-icon{
            display: inline-block !important;
          }
        }
        .enter-detail-field {
          width: 92%;
          line-height: 22px;
          cursor: pointer;
          color: #5477ff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}