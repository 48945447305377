.sales-product-view-mgt{
  .enter-detail-field-wrap{
    width: 100%;
    cursor: unset;
    .enter-detail-field-text{
      max-width: 80%;
    }
    .enter-detail-field-delete-icon {
      visibility: hidden;
      margin-left: 10px;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        color: red;
      }
    }
    &:hover {
      .enter-detail-field-delete-icon {
        visibility: visible;
      }
    }
  }

  .first-col {
    width: 100%;
    height: 100%;

    .check-box {
      display: none;
    }

    .rootnet-checkbox {
      height: unset;
    }

    &:hover,
    &.selected {
      .check-box {
        display: unset;
      }

      .index-num {
        display: none;
      }
    }
  }
}