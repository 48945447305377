.project-manpower-summary-detail-dialog {
  width: 800px;
  height: 500px;
  display: flex;

  >.content.content.content.content {
    padding: 0;
    width: 100px;
    height: 100%;
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}