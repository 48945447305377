.imp-project-info-wrap.imp-project-info-wrap {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .imp-project-handle-header {
    width: 100%;
    justify-content: space-between;
    padding: 8px 0 8px 16px;
    border-bottom: 1px solid #F0F0F0;

    .handle-title {
      flex: 1;
      display: flex;

      .imp-title-wrap {
        width: 100%;

        .imp-title {
          font-size: 16px;
          max-width: 90%;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .copy-icon {
          margin-left: 8px;
          margin-top: 2px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .rootnet-forminput {
        flex: 1;
        display: flex;
        margin: 0;

        .form-component {
          flex: 1;
        }
      }
    }

    .handle-group {
      .ant-btn {
        height: 34px;
        margin: 0 6px;
      }
    }
  }

  .imp-project-info {
    display: flex;
    overflow: hidden;

    &.is-fold {
      height: 192px;
      overflow: hidden;
    }

    .left-project-info {
      flex: 1;
      overflow: auto;
      flex-wrap: wrap;
      min-width: 662px;
    }

    .right-info {
      min-width: 350px;
      max-width: 550px;
      // padding-bottom: 8px;
      overflow: hidden;
      border-left: 1px solid #F0F0F0;

      .project-detail {
        border-bottom: 1px solid #F0F0F0;
        overflow: hidden;
        min-height: 200px;
        max-height: 270px;

        .project-brief-tooltip {
          white-space: pre-wrap;
          word-break: break-all;
          width: 560px;
        }

        .project-detail-top {
          .project-manager {
            .form-component {
              height: auto;
            }
          }

          .rootnet-forminput {
            margin: 0;
          }
        }

        .project-brief {
          display: flex;

          .rootnet-forminput {
            flex: 1;
            margin: 8px 16px;
            margin-top: 0;

            label {
              font-size: 14px;
            }

            .rootnet-textarea.form-component {
              height: 100px;
            }

            .form-component {
              width: 100%;
              height: auto;
              white-space: pre-wrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              word-break: break-all;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .dynamic-wrap.dynamic-wrap.dynamic-wrap {
        flex: 1;
        overflow: hidden;
        padding-bottom: 0;
      }
    }

    .area-wrap-basicInfo,
    .area-wrap-costcInfo {
      .configuration-long-text {
        display: flex;
        width: calc(100% - 24px);

        .long-text,
        .long-text-detail {
          flex: 1;
        }
      }
    }

    .area-wrap-desccInfo {
      .area-content-wrap {
        display: flex;
        flex-direction: column;

        .rootnet-forminput {
          flex: 1;
          display: flex;

          .long-text,
          .long-text-detail {
            flex: 1;
          }
        }
      }
    }

    .area-wrap {
      margin-bottom: 8px;

      .area-header {
        margin: 4px 0;
        font-size: 14px;
        font-weight: 900;

        .fold-icon {
          cursor: pointer;
          margin-left: 10px;
          margin-right: 18px;
        }
      }

      .area-content-wrap {
        overflow: hidden;
        padding-left: 40px;
      }
    }

    .rootnet-forminput {
      align-items: flex-start;

      label {
        height: 32px;
        line-height: 32px;
      }
    }

    .long-text-detail {
      height: unset;
      min-height: 32px;
      line-height: 32px;
      white-space: pre-wrap;
      word-break: break-all;
    }

    &.detail-mode {

      .form-component.long-text {
        height: unset;
        min-height: 32px;
        line-height: 32px;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }

  .fold-bar-wrap {
    width: 100%;
  }

  .fold-bar {
    height: 30px;
    width: 200px;
    cursor: pointer;

    &:hover {
      background: #F3F7FF;
    }

    .fold-icon {
      font-size: 16px;

      &.is-fold {
        transform: rotate(180deg);
      }
    }
  }
}

.imp-project-title-copy-popover {
  padding: 6px 12px;

  .button {
    height: 32px;
    margin: 6px;
  }
}