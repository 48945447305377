.plan-stage-log {
  width: 80%;

  .header-title {
    .hint-btn {
      margin-left: 8px;

      .icon {
        color: #5477ff;
      }
    }
  }

  .content.content.content.content {
    width: 100%;
    height: 70vh;

    .x-card {
      .grid {
        .cell.cell.cell {
          .enter-detail-field {
            cursor: pointer;
            color: #5477ff;
          }
        }
      }
    }
  }
}