
.dev-project-edit-member-dialog.dev-project-edit-member-dialog.dev-project-edit-member-dialog {
  width: 630px;
  height: 70vh;
  display: flex;
  flex-direction: column;

  .content.content.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;

    .mock-footer {
      display: flex;
      align-items: center;
      padding: 8px 0;
      height: 48px;
      box-sizing: border-box;
      border-top: 1px solid #E9EAF2;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: flex-end;

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}