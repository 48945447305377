.work-flow-graph{
  width: 100%;
  height: 100%;
  .x6-graph{
    width: 100%;
    height: 100%;
  }
  .x6-edge-label{
    text{
      font-size: 12px;
    }
  }
}