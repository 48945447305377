.version-create-branch-result-dialog{
  .content.content.content.content{
    height: 500px;
    width: 900px;
    padding: 16px;
    max-height: 70vh;
    .x-card{
      height: 100%;
    }
  }
}