.issue-info{
  height: 100%;
  width: 100%;
  .rich-text-detail-wrap{
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px;
    img{
      max-width: 100%;
    }
  }
  .rich-text-area{
    height: 100%;
    width: 100%;
  }
}