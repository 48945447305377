div.approval.x{
    height: 100%;
    .router-back{
        height: 40px;
        line-height: 40px;
        &.x.button{
            margin: 0 0 0 10px;
            padding: 0 16px;
            i{
                font-size: 14px;
                color: #000;
            }
        }        
    }
    .approval-con{
        flex: 1;
        overflow: auto;
    }
}