.text-plan-upload-area {
  padding: 8px;
  width: 100%;
  height: 100%;

  >span {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .ant-upload-list {
    flex: 1;
    height: 200px;
    overflow: auto;
  }

  .list-render {
    width: 100%;

    &.can-not-remove-item {
      .ant-upload-list-item-card-actions {
        .ant-btn:nth-child(2) {
          display: none;
        }
      }
    }

    .ant-upload-list-item {
      flex: 1;
      width: 100px;
    }

    .rename-btn {
      margin-top: 8px;
      margin-left: 8px;
      height: 54px;
    }
  }

  .ant-btn:focus {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
  }

  .ant-upload-list-item {
    height: 54px;
  }

  .ant-upload-list-item-thumbnail {
    img {
      object-fit: cover;
    }
  }
}