.version-defects.version-defects.version-defects {
  flex-direction: column;
  height: 100%;
  display: flex;

  .custom-search-area {
    box-shadow: none;
  }

  .customize-options {
    .c-options {
      margin: 0;
    }
  }

  .x-card {
    margin: 0;

    &.is-not-table {
      flex: unset;
      height: 40px;
      margin-bottom: 4px;

      .card-content {
        display: none;
      }
    }
  }

  .defect-panel {
    height: 100px;
    flex: 1;

    &.defect-panel-full-screen {
      margin: 0;
    }

    .resize {
      cursor: col-resize;
    }

    .left-panel-remove-wrap {
      .line {
        height: 100%;
        width: 6px;
        background: #e9eaf2;
        cursor: col-resize;
      }
    }
  }

  .containerParent {
    transition: 350ms ease height;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .quick-add-area{
    height: 34px;
    .quick-add-form{
      flex: 1;
      margin-left: 20px;
      //.ant-btn-primary{
      //  background-color: #5477ff;
      //  border: none;
      //  &:hover{
      //    background-color: #7692FF;
      //  }
      //}
    }
    .ant-select{
      .ant-select-selector{
        height: 34px;
        overflow: auto;
      }
    }
    .quick-add-area-text{
      color: rgb(191,191,191);
      margin-left: 20px;
      width: 100%;
    }
    &.inactivated{
      cursor: pointer;
      &:hover{
        background: #efefef;
      }
    }
  }

  .full-screen.full-screen.full-screen {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  .grid {
    .cell {
      padding: 0;

      .person-select {
        width: unset !important;

        .ant-select-selection-overflow {
          flex-wrap: unset;
        }
      }

      .rootnet-forminput {
        margin-left: 2px;
      }

      .common-display-field {
        padding: 0 12px;
      }

      .common-edit-field {
        padding: 0 12px;
        cursor: pointer;
      }

      .enter-detail-field-wrap {
        cursor: unset;

        .enter-detail-field {
          cursor: pointer;
          color: #5477ff;
        }

        .enter-detail-field-edit-icon {
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;

          &:hover {
            color: #5477ff;
          }
        }

        &:hover {
          .enter-detail-field-edit-icon {
            display: block;
          }
        }
      }

      .arrow-drop-down-group {

        .common-display-field,
        .common-edit-field {
          padding-left: 4px;
          padding-right: 12px;
        }
      }
    }
  }
  .work-flow-status-field{
    cursor: pointer;
    margin-left: 8px;
  }
  .fist-col{
    width: 100%;
    height: 100%;
    .check-box{
      display: none;
    }
    .rootnet-checkbox{
      height: unset;
    }
    &:hover, &.selected{
      .check-box{
        display: unset;
      }
      .index-num{
        display: none;
      }
    }
  }
}