.customize-menu-group.customize-menu-group.customize-menu-group{
  .customize-menu-group-left-panel{
    width: 40%;
    flex: 1;
    .x-card{
      .card-content{
        justify-content: center;
        .x-datagrid{
          font-size: 12px;
        }
      }
    }
  }
  .customize-menu-group-right-panel{
    width: 40%;
    flex: 1;
    .x-card{
      margin-left: 0;
      .card-content{
        justify-content: center;
        .ant-tree{
          width: 100%;
          padding: 16px;
          .ant-tree-node-content-wrapper{
            cursor: unset;
          }
        }
        .tree-item{
          .tree-item-text{
            margin-right: 12px;
          }
        }
      }
    }
  }
}