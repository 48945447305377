.arrow-crumbs {
  position: relative;
  margin-left: 16px;

  .breadcrumb,
  .breadcrumb-item {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .breadcrumb {
    display: flex;
    overflow: hidden;
    height: 32px;
    background: #fff;
    padding-right: 16px;

    .breadcrumb-item {
      cursor: pointer;
      padding: 0px 26px;
      line-height: 32px;
      background: #f0f0f0;
      display: inline-block;
      color: black;
      position: relative;
      font-weight: bold;

      &:last-child {
        border-radius: 0px 4px 4px 0px;
        padding-right: 25px;
      }

      &:first-child:before {
        display: none;
      }

      &:hover {
        color: #5477ff;
      }

      &::before {
        cursor: default;
        content: '';
        display: block;
        border-top: 16px solid #f0f0f0;
        border-bottom: 16px solid #f0f0f0;
        border-left: 16px solid #fff;
        position: absolute;
        left: 0px;
        top: 0;
      }

      &::after {
        content: '';
        display: block;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 16px solid #f0f0f0;
        position: absolute;
        right: -14px;
        top: 0;
        z-index: 8;
      }
    }

    .breadcrumb-item.active {
      background: #f0f0f0;
      padding-right: 0;
      max-width: 9999px;

      .rootnet-forminput {
        margin: 0;
        text-align: left;

        .rootnet-select {
          background-color: #f0f0f0;
          border: none;

          .label {
            color: #5477ff;
          }

          &:not(.disabled):focus-within {
            border: none;
            box-shadow: none;
          }

          .rootnet-popup {
            color: black;
            font-weight: 400;

            .option {
              padding: 0 26px;
            }
          }
        }


      }

      &::before {
        border-top-color: #f0f0f0;
        border-bottom-color: #f0f0f0;
      }

      &::after {
        border-color: transparent transparent transparent #f0f0f0;
      }
    }
  }
}