.all-search-input.all-search-input.all-search-input {
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  border: 1px solid #C3C5CC;
  border-radius: 4px;
  background: #fff;

  .text-input-prefix{
    background: #fff;
    border-right:none;
    i.icon.iconfont {
      width: 28px;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
    }
  }
}
