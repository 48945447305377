.person-select.person-select.person-select{
  width: 180px;

  .ant-select-selection-placeholder{
    color: #D7D9E0;
    right: unset;
  }

  &.ant-select-single{
    .ant-select-selector{
      padding:0 8px;
    }
    .ant-select-selection-search{
      left: 8px;
    }
  }

  &.ant-select-multiple{
    .ant-select-selection-placeholder{
      left: 8px;
    }
    .ant-select-selection-search{
      left: -3px;
    }
  }
  .ant-select-selector{
    border-radius: 4px;
    &:hover{
      border-color: #3a61ff;
    }
  }
  &.error>.ant-select-selector{
    border: 1px solid #F5221B;
    &:not(.disabled):hover{
      border: 1px solid #F5221B;
      box-shadow: 0 0 4px 0 #F5221B;
    }
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector.ant-select-selector.ant-select-selector{
    border-color: #3a61ff;
    &:focus-within{
      box-shadow: 0 0 4px 0 #3a61ff;
    }
  }
}