.data-auth-mgt.data-auth-mgt.data-auth-mgt.data-auth-mgt{
  .c-options{
    .button{
      margin-left: 28px;
      margin-right: 0;
    }
  }
  .role-table-cell{
    height: 100%;
    .detail-cell{
      max-width: 80%;
    }
    &:hover{
      .edit-icon{
        display: block;
      }
    }
    .edit-icon{
      display: none;
      cursor: pointer;
      color: #5477FF;
      margin-left: 6px;
    }
  }
  .art-table-row{
    height: 50px;
  }
  .table-header-cell{
    .del-icon{
      margin-left: 8px;
      cursor: pointer;
      &:hover{
        color: red;
      }
    }
  }
  .x-card{
    margin-top: 0;
  }
}