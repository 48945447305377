.test-analysis {

  font-size: 12px;

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    font-size: 12px;

    label {
      font-size: 12px;
    }
  }

  .area-wrap {
    margin-bottom: 8px;

    .area-header {
      margin: 4px 0;
    }

    .area-content-wrap {
      overflow: hidden;

      .rootnet-forminput {
        width: 100%;

        .form-component {
          width: 90% !important;
          white-space: pre-wrap;
          padding-left: 40px;
          box-sizing: border-box;
        }
      }
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 15px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 2px 8px;
  }

  .long-options {
    .rootnet-popup {
      .container {
        .option {
          min-width: 300px;
        }
      }
    }
  }

  .rich-text-detail-wrap {
    min-height: 100px;
    width: 100%;
    overflow: auto;
    padding: 10px 20px 10px 50px;

    img {
      max-width: 100%;
    }
  }

  .rich-text-area {
    height: 350px;
    width: 100%;
    padding: 10px 20px 10px 50px;
  }
}