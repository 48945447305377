.assigning-role-dialog {
  width: 660px;
  height: 70vh;
  display: flex;
  flex-direction: column;

  .content.content.content.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;

    .role-content {
      padding: 16px;
      position: relative;

      .prompt-text {
        font-size: 12px;
        color: red;
      }
      .rootnet-forminput{
        margin-right: 0;
      }
    }

    .role-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #d0d3e3;
      padding: 8px;

      .footer-lef {
        font-size: 12px;
        color: #7A8199;
        display: flex;
        align-items: center;
      }

      .btn-group {
        .button {
          height: 32px;
        }
      }
    }

    .transfer {
      flex: 1;
      overflow: hidden;
    }
  }
}