.template-detail {
  width: 85%;
  height: 90%;
  display: flex;
  flex-direction: column;

  .header {

    .title {
      width: 100%;
    }

    .header-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 400;

      .lef {
        display: flex;

        >div:nth-child(1) {
          font-weight: 900;
          max-width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        >div:nth-child(2) {
          margin-left: 8px;
          font-size: 12px;
          color: white;
          padding: 0 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
        }
      }

      .rig {
        display: flex;
      }
    }
  }

  .content.content.content.content.content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;

    .ant-table-wrapper {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table,
      .ant-table-container {
        height: 100%;
      }

      .ant-table-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .ant-table-body {
        flex: 1;
        overflow: auto;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    padding: 8px 0;
    border-top: 1px solid #E9EAF2;
    justify-content: flex-end;

    .footer-tips{
      flex: 1;
      padding-left: 10px;
    }

    .button {
      height: 32px;
      margin-right: 16px;
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 5;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}