.workflow-batch-close-dialog{
  .content.content.content.content{
    padding-top: 0;
    height: 70vh;
    width: 800px;
    overflow-y: auto;
    .x-datagrid{
      font-size: 12px;
    }
    .ant-tabs{
      height: 100%;
      .ant-tabs-content{
        height: 100%;
        .ant-tabs-tabpane{
          height: 100%;
          .x-datagrid{
            height: 100%;
          }
        }
      }
    }
  }
}