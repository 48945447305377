div.generic-report-test {
    flex: 1;

    .x.button.primary {
        height: 30px;
        width: 120px;
        justify-self: start;
    }

    .rootnet-grid {
        align-items: center;
    }

    >.card-content {
        display: flex;
        flex-direction: column;
    }

    textarea.report-info {
        position: relative;
        flex: 1;
        margin: 8px;
        resize: none;
        font-size: 14px;
        border: 1px solid lightgray;
        outline: none;
    }
}