$item-width: 260px;

.dev-project-member {
  display: flex;
  height: 100%;

  .area-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .area-header {
      padding-right: 16px;
      justify-content: space-between;

      .textIconBtn {
        color: #5477ff;
      }
    }
  }

  .area-wrap {
    .area-header {
      margin: 12px 0;
    }

    .area-content-wrap {
      flex: 1;
      overflow: hidden;
      display: flex;
    }
  }

  .fold-icon {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .project-member-table {
    padding-left: 60px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .project-member-table-header {
      background-color: #f5f5f5;
      font-weight: bold;

      .header-item {
        padding: 8px;
        width: $item-width;
        text-align: center;
      }
    }

    .project-member-table-content {
      flex: 1;
      overflow: auto;

      .list-item {
        .item-field {
          width: $item-width;
          display: flex;
          justify-content: center;
          align-content: center;

          &.item-field-icon {
            width: 40px;
            flex-direction: column;

            .icon {
              cursor: pointer;
              font-size: 14px;

              &:hover {
                color: red;
              }
            }
          }

          &.item-field-required {
            flex-direction: column;
          }
        }

        .item-role-required {
          padding-left: 50px;

          &::before {
            display: inline-block;
            margin-right: 4px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
            color: #F5221B;
          }
        }
      }
    }
  }
}