.customer-voice-detail-info{
  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
    margin: 4px 8px;
  }
  .area-wrap{
    .area-header{
      margin: 12px 0;
    }
    .area-content-wrap{
      overflow: hidden;
    }
  }
  .fold-icon{
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;
    &:hover{
      color: #5477ff;
    }
  }
  .ellipsis-line.ellipsis-line {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .doc-area{
    margin-left: 40px;
  }
  .rootnet-forminput.rootnet-forminput.rootnet-forminput{
    .form-component, >span{
      margin-left: 6px;
    }
  }
  .add-relation-icon{
    margin: 4px 44px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    color: #738299;
    &:hover{
      color: #5477ff;
    }
  }
  .relation-items-wrap{
    line-height: 30px;
    flex-wrap: wrap;
    .relation-text{
      font-size: 14px;
      margin-left: 48px;
      color: #738299;
      width: 100px;
    }
    .tag-text{
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
    .handle-icon{
      cursor: pointer;
      font-size: 16px;
      margin-right: 10px;
      &.edit-icon{
        &:hover{
          color: #5477ff;
        }
      }
      &.del-icon{
        &:hover{
          color: red;
        }
      }
    }
  }

  .desc-wrap{
    padding-bottom: 10px;
    padding-right: 10px;
    .rich-text-area{
      margin-left: 40px;
      height: 400px;
    }
  }
  .rich-text-detail-wrap{
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px 30px;
    white-space: pre-wrap;
    line-height: normal;
    img{
      max-width: 100%;
    }
  }
}