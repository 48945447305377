.Examine {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  >section {
    flex: 1;
    overflow-y: auto;
    padding: 0.2rem;
    padding-top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    >h1 {
      font-size: 0.28rem;
      text-indent: 0.2rem;
    }

    >div {
      flex: 1;
      background: white;
      border-radius: 0.15rem;
      padding: 0 0.3rem;
      box-sizing: border-box;

      >div:first-child {
        display: flex;

        dl {
          flex: 1;
          padding: 0.3rem 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 0.24rem;

          dt {
            img{
              width: 0.75rem;
              height: 0.75rem;
            }
            >i {
              font-size: 0.8rem;
            }
          }

          dd {
            margin: 0;
          }
        }
      }

      >div:nth-child(2) {
        display: flex;

        .error {
          border: 1px solid #f5221b;
        }

        >div {
          flex: 1;
          height: 2rem;


          >textarea {
            font-size: 0.23rem;
          }

          >span {
            font-size: 0.2rem;
          }
        }
      }
    }
  }

  >footer {
    background: white;
    padding: 0.2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .primary {
      width: 90%;
      border-radius: 0.5rem;
      padding: 0.22rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      letter-spacing: 0.1rem
    }
  }
}