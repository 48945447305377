.dev-project-stage-update-header{
  margin: 0 12px;
  padding-top: 4px;
  border-bottom: 1px solid #e9eaf2;
  .header-left {
    flex: 1;
    width: 200px;
    align-items: center;
    display: flex;

    .title-wrap {
      width: 100%;
      margin-top: 4px;
      margin-left: 4px;
      font-size: 16px;

      .title {
        max-width: 90%;

        >span {
          overflow: hidden; //隐藏多余行数
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
          -webkit-box-orient: vertical; //规定为垂直方向排列
        }
      }
    }

    .copy-icon {
      margin-left: 8px;
      margin-top: 2px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: #3f7dff;
      }
    }

    .rootnet-forminput.rootnet-forminput.rootnet-forminput {
      width: 100%;
      margin: 0;
      padding-top: 6px;

      label {
        flex: none;
      }

      .rootnet-input {
        flex: 1;
      }
    }
  }
  .header-right {
    margin-right: 10px;

    .rootnet-forminput.rootnet-forminput.rootnet-forminput {

      .form-component,
      >span {
        margin-left: 0;
      }
    }

    .header-right-item {
      height: 55px;
      margin-left: 16px;

      .header-right-icon {
        //height: 20px;
        //width: 20px;
        font-size: 30px;
        margin-right: 4px;
      }

      .header-right-info {
        margin-left: 4px;

        .rootnet-forminput {
          margin: 0;
        }

        .header-right-value {
          height: 24px;

          .current-user {
            color: #868686;
          }

          .priority-tag-text,
          .status-tag {
            font-size: 12px;
          }
        }

        .header-right-field {
          color: #C4C4C4;
        }
      }
    }
  }
}