.login-statistics-wrap.login-statistics-wrap.login-statistics-wrap{
  .form-text{
    color: #738299;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .search-btn-group{
    .button.button.button{
      margin: 0 16px 0;
    }

    .reset-btn {
      border: 1px solid #7A8199;
    }
  }
}