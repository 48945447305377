.trace-component-add-dialog{
  .content.content.content.content{
    font-size: 14px;
    width: 785px;
    padding: 0;
    display: flex;
    flex-direction: column;
    .mock-dialog-header{
      padding: 4px 4px 4px 12px;
      border-bottom: 1px solid #e1e1e1;
      justify-content: space-between;
      .mock-dialog-header-text{
        font-weight: bold;
        font-size: 16px;
      }
      .mock-dialog-header-right{
        .mock-dialog-close-icon{
          height: 28px;
          width: 28px;
          border-radius: 50%;
          cursor: pointer;
          margin-left: 8px;
          .icon{
            font-size: 16px;
          }
          &:hover{
            background: rgba(160,160,160,0.2);
          }
        }
      }
      .header-icon{
        cursor: pointer;
        margin: 0 6px;
        .icon{
          font-size: 16px;
        }
        &:hover{
          color: #3a61ff;
        }
      }
    }
    .mock-dialog-content{
      flex: 1;
      overflow-y: auto;
      padding: 16px;
    }
    .mock-dialog-footer{
      padding: 8px 0;
      border-top: 1px solid #E9EAF2;
      justify-content: space-between;
      .mock-dialog-footer-right{
        .button{
          height: 32px;
          margin: 0 16px 0 0;
        }
      }
    }
  }
}