div.v-project-list {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    >.list-option {
        flex: none;
        margin: 12px 12px 0px 12px;
        display: flex;
        align-items: flex-end;

        >.x-forminput {
            margin-right: 12px;
        }

        >.x.button {
            margin-left: 12px;
            width: 100px;
        }

        .x.text-input{
            background: #fff
        }
    }

    >.list-panel {
        flex: 1;
        display: flex;

        >.x-card {
            flex: 1;
        }

        >.detail-chart {
            flex: none;
            width: 600px;
            display: flex;
            flex-direction: column;

            >.x-card {
                margin-left: 0px;
                margin-top: 0px;
                flex: 1;

                &.milestones {
                    flex: none;
                    margin-top: 12px;
                    .card-content{
                        padding:40px 16px;
                    }
                }
            }
        }
    }

    .x-datagrid {
        position: absolute;
        height: 100%;
        width: 100%;

        .cell>.operations {
            display: flex;
            align-items: center;

            >span {
                position: relative;
                color: #5477FF;
                cursor: pointer;
                width: 45px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:last-child)::after {
                    content: "";
                    display: block;
                    height: 60%;
                    width: 1px;
                    background-color: #d8d8d8;
                    position: absolute;
                    right: 0px;
                }

                &:hover {
                    color: blue;
                }
            }
        }
    }
}


.modal.create-project-dialog {
    >.content {
        display: grid;
        grid-template-columns: 340px 340px;
        grid-template-rows: repeat(6, auto);
        row-gap: 12px;
        justify-items: start;

        >.x-forminput {
            &.projectSort{
                grid-column: span 2;
            }

            &:first-child {
                grid-column: span 2;

                .x.text-input {
                    width: 520px;
                }
            }

            &:last-child {
                grid-column: span 2;
                align-items: flex-start;

                .x.text-area {
                    width: 520px;
                    height: 80px;
                }
            }

            >.input-label {
                width: 120px;
                text-align: right;
            }
        }

    }
}