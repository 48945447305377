.qa-check-dialog {
  .add-follow-btn {
    text-align: right;

    .hover-add-follow {
      cursor: pointer;
      color: #5477ff;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}