.person-select-pop.person-select-pop.person-select-pop{
  width: 570px;
  background-color: #fff;
  padding: 8px 14px;
  .person-search{
    width: 100%;
    border: 1px solid #E6E8EB;
    .suffix{
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #E6E8EB;
      .person-search-icon{
        font-size: 16px;
      }
    }
  }
  .person-select-main-panel{
    margin-top: 8px;
    width: 100%;
    height: 300px;
    .person-select-left, .person-select-right{
      width: 50%;
      border-bottom: 1px solid #F2F4F5;
    }
    .person-select-left{
      .ant-tabs{
        height: 100%;
        line-height: 16px;
        .ant-tabs-tabpane{
          height: 100%;
        }
        .ant-tabs-content-holder{
          height: 100%;
        }
        .ant-tabs-content{
          height: 100%;
        }
        .ant-tabs-nav{
          margin-bottom: 0;
        }
        .ant-tabs-tab{
          font-size: 12px;
          padding: 8px 0;
        }
      }
      .all-user, .recent-user, .dept-user-warp{
        overflow-y: scroll;
        height: 100%;
      }
      .all-user, .recent-user{
        .ant-spin{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .recent-user{
        .ant-empty{
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .dept-user-warp{
        .ant-tree, .ant-tree-list, .ant-tree-treenode, .ant-tree-node-content-wrapper{
          width: 100%;
        }
        .ant-tree-title{
          .person-select-content{
            width: 100%;
          }
        }
        .person-select-user-item:hover{
          background-color: unset;
        }
      }
    }
    .person-select-right{
      border-left: 1px solid #F2F4F5;
      padding-left: 6px;
      .person-select-right-header{
        font-size: 12px;
        line-height: 20px;
        justify-content: space-between;
        padding-right: 8px;
        .person-selected-num-wrap{
          color: #36434d;
          padding: 5px 0;
          .person-selected-num{
            margin-left: 8px;
          }
        }
        .clear-all-selected{
          color: #1890ff;
          cursor: pointer;
        }
      }
      .person-select-right-content{
        height: 200px;
        flex: 1;
        overflow-y: scroll;
        .ant-spin{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ant-empty{
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .confirm-footer{
    padding-top: 8px;
    justify-content: space-between;
    .button{
      height: 28px;
    }
    .confirm-footer-left{
      font-size: 12px;
    }
  }
  .dept-name-wrap{
    width: 100%;
    justify-content: space-between;
    .add-dept-icon{
      display: none;
      margin:0 10px;
      font-size: 18px;
      color: #1890ff;
    }
    &:hover{
      .add-dept-icon{
        display: block;
      }
    }
  }
  .dept-item-selected{
    font-size: 12px;
    position: absolute;
    height: 14px;
    width: 14px;
    top: 4px;
    right: -16px;
    border-radius: 50%;
    background-color: #108ee9;
    color: #fff;
  }
}