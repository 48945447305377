.comment-info {
  position: sticky;
  left: 0;
  bottom: 0;
  margin-bottom: 16px;

  // .ant-mentions {
  //   .rc-textarea {
      
  //   }
  // }

  .reply-info {
    background: #f0f3f7;
    width: 100%;
    padding: 4px;
    height: 24px;
    justify-content: space-between;

    .reply-info-content {
      flex: 1;
      font-size: 12px;
      overflow: hidden; //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical; //规定为垂直方向排列
    }

    .reply-info-del-icon {
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }

  .mentions-bottom-btn {
    position: absolute;
    bottom: 4px;
    right: 8px;

    .button {
      height: 24px;

      &.text {
        color: #5477ff;
      }
    }
  }
}