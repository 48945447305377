.dev-project-cost-budgeting {
  width: 100%;
  display: flex;
  flex-direction: column;

  .containerParent {
    transition: 350ms ease height;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      width: 100%;
    }
  }
}