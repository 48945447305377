.menu-detail-dialog{
  .content.content.content.content{
    width: 700px;
    padding: 0;

    .mock-dialog-header {
      display: flex;
      align-items: center;
      color: #15171f;
      padding: 12px 16px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e9eaf2;
      justify-content: space-between;

      .dialog-title {
        font-weight: 700;
      }

      .mock-right-header {
        .header-edit-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }

        .header-handle-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &:hover {
            color: #5477ff;
          }
        }
      }

      .close-area {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 16px;

        .close-icon {
          font-size: 14px;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }
    .main-content-wrap{
      padding: 16px;

      .role-name-wrap{
        .mock-label {
          margin-left: 8px;
          margin-top: 6px;
          width: 120px;
          text-align: right;
          color: #738299;
          &::after {
            content: ":";
            margin: 0 8px 0 2px;
          }
        }
        .role-name{
          flex: 1;
          max-height: 70px;
          overflow-y: auto;
          margin-top: 6px;
        }
      }
    }
  }
}