.func-table-edit{
  position: relative;
  .x-card.x-card.x-card{
    .card-content.card-content.card-content{
      .rootnet-forminput{
        >label{
          font-size: 12px;
        }
      }
      .ant-table{
        font-size: 12px;
        .ant-radio-group{
          .ant-radio-wrapper{
            >span{
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .second-row{
    height: 200px;
    margin-bottom: 12px;
  }
  .third-row{
    min-height: 230px;
    flex: 1;
  }
  .edit-panel{
    flex: 1;
  }
  .handle-bar{
    width: 100%;
    height: 50px;
    background: #fff;
    border-top: 1px solid #ececec;
    flex-direction: row-reverse;
    >button{
      width: 100px;
      margin: 0 8px;
    }
    >button.ant-btn-primary{
      border-color: #7692FF;
      background-color: #5477ff;
      &:hover {
        background-color: #7692FF;
      }
    }
  }
  .full-screen-icon.full-screen-icon{
    font-size: 16px;
    cursor: pointer;
    margin-left: 12px;
    &:hover{
      color: #3f7dff;
    }
  }
}
.func-preview-dialog{
  width: 600px;
  min-height: 300px;
  max-height: 80vh;
  margin-bottom: 16px;
  overflow: auto;
}