.user-menu {
  margin-left: 12px;

  &.account {
    height: calc(100%);
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;

    >.icon {
      font-size: 14px;
    }

    &:hover,
    &.open {
      color: bisque;
      // background-color: #083376;

      >.iconfont {
        color: bisque;
      }
    }

    &.open {
      cursor: default;
    }

    >div.dropdown {
      min-width: 120px;
      background-color: #f8f8f8;
      box-shadow: 0 5px 5px #888888;
      position: absolute;
      top: 100%;
      right: 0px;
      z-index: 10;
      padding: 8px;
      display: flex;
      flex-direction: column;
      border-radius: 0px 0px 4px 4px;

      li {
        display: block;
        white-space: nowrap;
        line-height: 36px;

        >span:nth-child(1) {
          margin: 0 8px 0 12px;
        }

        >span:nth-child(2) {
          margin: 0 0 0 8px;
        }
      }

      .list-item {
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 4px;
        width: 100%;
        white-space: nowrap;

        &:hover {
          background-color: #E5E5E5;
        }
      }

      >div.footer {
        display: flex;
        flex-direction: column;
        margin: 8px 4px;

        .x.button {
          width: 120px;
          padding: 0;
        }
      }
    }
  }
}

.user-feedback {
  display: flex;
  align-items: center;

  .textIconBtn {
    color: white;
    margin: 0;

    &:hover {
      color: bisque;
    }
  }
}

.user-login-out {
  display: flex;
  align-items: center;

  .textIconBtn {
    color: white;

    &:hover {
      color: bisque;
    }
  }
}

.user-menu-popover {
  .user-menu-select {
    padding: 8px;

    >div {
      padding: 4px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: #f8f8f8;
        border-radius: 4px;
      }
    }

    .cut-off-rule {
      border-top: 1px solid #f0f0f0;
    }

    .user-menu-del-style {
      cursor: default;

      &:hover {
        background-color: #fff;
        border-radius: none;
      }
    }

    .appbar_help {
      .list-item {
        width: 100%;
      }
    }
  }
}