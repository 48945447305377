.version-application {
  width: 720px;

  .tracer-id {
    .ellipsis-line {
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .error {
    &:hover {
      >.rootnet-textarea {
        box-shadow: 0 0 4px 0 #F5221B;
      }
    }

    &:focus-within {
      >.rootnet-textarea {
        border: 1px solid #5477FF;
        box-shadow: 0 0 4px 0 #5477FF;
      }
    }

    >.rootnet-textarea {
      border: 1px solid #F5221B;
    }

  }
}