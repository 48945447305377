.sort-card-wrap.sort-card-wrap.sort-card-wrap{
  margin-top: 0;
  .ant-table-wrapper{
    height: 100%;
    width: 100%;
  }
  .delete-text{
    cursor: pointer;
    color: #3f7dff;
    &:hover{
      color: red;
    }
  }
  &.full-screen{
    position: absolute;
    margin: 12px;
    width: calc(100% - 24px);
    height: calc(100vh - 62px);
    z-index: 4;
  }
}