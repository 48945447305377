.dev-project-stage-update-dialog{
  height: 80vh;
  .content.content.content.content{
    padding: 0;
    width: 1030px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.is-add-content{
      width: 660px;
      .ant-tabs-nav{
        display: none;
      }
    }
  }
  .ant-modal-header, .ant-modal-footer, .ant-modal-close{
    display: none;
  }
  .ant-modal-body{
    padding: 0;
  }
  .content-wrap{
    padding: 0;
    height: 100%;
    width: 100%;
  }
  .mock-dialog-header{
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;
    .dialog-title{
      font-weight: 700;
    }
    .mock-right-header{
      .header-edit-icon{
        font-size: 18px;
        cursor: pointer;
        &.edit-active-icon{
          color: #5477ff;
        }
        &:hover{
          color: #5477ff;
        }
      }
    }
    .close-area{
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 20px;
      .close-icon{
        font-size: 14px;
      }
      &:hover{
        background-color: #ECECEC;
      }
    }
  }
  .content-main-panel{
    width: 100%;
    height: 100px;
    padding: 6px 0;
    overflow-y: scroll;
    flex: 1;
    //background-color: #0c64eb;
  }
  .mock-footer{
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    border-top: 1px solid #e9eaf2;
    .btn-group{
      .button{
        margin-right: 16px;
        height: 32px;
      }
    }
  }
  .relate-document-icon-wrap{
    color: #5477ff;
    cursor: pointer;
  }
  .detail-left-panel{
    width: 650px;
  }

  .ant-tabs{
    height: 100%;
    .ant-tabs-nav{
      padding-left: 10px;
      margin-bottom: 0;
    }
    .ant-tabs-content-holder{
      display: flex;
      flex: 1;
      height: 100px;
      //border-bottom: 1px solid #D0D3E3;
      overflow: hidden;
      padding: 6px;
    }
    .ant-tabs-content, .ant-tabs-content{
      flex: 1;
      overflow: auto;
    }
    .tab-content{
      height: 100%;
    }
  }
  .dynamic-wrap{
    border-left: 1px solid #e9eaf2;
    padding-left: 8px;
    width: 300px;
    height: 100%;
    flex: 1;
  }
  .ant-tabs-tab.ant-tabs-tab.ant-tabs-tab.ant-tabs-tab{
    &.ant-tabs-tab-active{
      .ant-badge{
        color: #1890ff;
      }
      .ant-badge-count{
        background-color: #EFF4FF !important;
        .ant-scroll-number-only{
          color: #6698FF;
        }
      }
    }
  }
}