.dynamic-time-wrap{
  position: relative;
  .preview-time{
    width: 126px;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    bottom: -9px;
    color: gray;
    &.first{
      left: 0;
    }
    &.second{
      right: 0;
    }
  }
}