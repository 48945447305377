.mobile-attendance-mgt{
  .pic-cell{
    height: 2.4rem;
    background: #fff;
    justify-content: space-between;
    .cell-title{
      margin-left: 0.32rem;
    }
    .show-images-area{
      margin-right: 0.32rem;
      height: 2rem;
      width: 2rem;
      background: #F5F5F5;
      border: 1px solid #ebebeb;
      .no-img-text{
        color: #999999;
      }
    }
  }
  .common-cell-group{
    margin-top: 0.2rem;
    .common-cell{
      justify-content: space-between;
      height: 0.8rem;
      background: #fff;
      border-bottom: 1px solid #E9EAF2;
      .common-cell-title{
        margin-left: 0.32rem;
      }
      .common-cell-text{
        margin-right: 0.32rem;
        color: #999999;
      }
    }
  }
}
.mobile-attendance-mgt-confirm-dialog.mobile-attendance-mgt-confirm-dialog.mobile-attendance-mgt-confirm-dialog{
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .show-images-area{
    background: #fff;
    height: 6rem;
    width: 4rem;
  }
  >.footer.footer.footer.footer{
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}