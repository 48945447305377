.authority-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .x-card {
    flex: 1;

    .card-content {
      display: flex;
      justify-content: center;

      .x-datagrid {
        flex: 1;
      }
    }
  }
}

.authority-drawer {
  width: 1000px;

  >div.header.header.header {
    height: 39px;
    font-weight: 600;
    padding-left: 24px;
  }

  >div.content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    >.x-card {
      width: 97.5%;
    }
  }
}

.AuthorizationBox {
  width: 600px;

  >div.content {
    display: inline;

    >div:first-child {
      margin: 0;
    }

    >div:nth-child(2) {
      .toolbar {
        display: none;
      }
    }
  }

  .selectPerson {
    width: 526px;
    min-height: 30px;
    border-radius: 4px;
    // box-sizing: border-box;
    border: 1px solid #d9d9d9;

    >span {
      margin: 3px;
      padding: 3px;
      box-sizing: border-box;
      background-color: #f5f5f5;
      border: 1px solid #f0f0f0;
      display: inline-block;
      border-radius: 2px;

      >i {
        cursor: pointer;
        margin: 0 5px;

        &:before:hover {
          color: black;

        }
      }
    }
  }
}

.AuthorizeDialog.AuthorizeDialog.AuthorizeDialog.AuthorizeDialog.AuthorizeDialog {
  min-width: 1170px;

  //max-width: 1170px;
  .AuthorizeDialog-hour {
    width: 100%;
  }
}

.AuthorizationManager {
  width: 100%;
  height: 100%;
  display: flex;

  .x-card {
    flex: 1;

    .workingHours {
      color: #e15722;

      span {
        margin-right: 24px;
      }
    }
  }
}

.AuthorizeDialogDel.AuthorizeDialogDel.AuthorizeDialogDel {
  font-size: 18px;

  .header.header.header.header {
    background-color: rgb(255, 255, 255);
    color: #13151a;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}