.worktime{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #F9FAFE;
  .worktimeOption{
    display: flex;
    justify-content: space-between;
    padding: 4px 12px 0 4px;
    align-items: center;
    font-size: 14px;
    .refresh-icon{
      margin-left: 10px;
      font-size: 18px;
      cursor: pointer;
      &:hover{
        color: #5477FF;
      }
    }
    .optionLeft{
      .button:hover{
        color: #5477FF;
        border-color: #5477FF;
      }
      .button.buttonActive{
        //color: #5477FF;
        //border-color: #5477FF;
      }
    }
    .optionRight{
      height: 48px;
      position: relative;
      flex: 1;
      justify-content: flex-end;
      .options-right-item{
        color: #5477FF;
        margin-left: 40px;
        &.cut-off-date-tips{
          color: red;
          width: 100px;
          flex: 1;
        }
      }
      .x.button{
        width: 84px;
        background: #5477FF;
        border-color: #5477FF;
        color: #fff;
        &:hover{
          background: #5477FF;
        }
      }
      .selectList{
        width: 140px;
        position: absolute;
        right: 3.5px;
        top: 45px;
        z-index: 2;
        border-radius: 4px;
        overflow: hidden;
        background:#fff;
        transition:height 450ms;
        box-shadow: 0 6px 14px 0 rgba(163,171,203,0.40);
        p{
          width: 100%;
          height: 35px;
          line-height: 35px;
          cursor: pointer;
          padding-left: 12px;
          box-sizing: border-box;
          margin: 0;
          &:hover{
            background:#e6eef9;
          }
        }
      }
    }
    .x.button{
      width: 74px;
      height: 32px;
      background:#fbfcfe;
      border: 1px solid #7A8199;
      border-radius: 4px;
      line-height: 32px;
      color: #555;
      &:hover{
        background: #fff;
      }
    }
  }

  .worktimeContent{
    width: 100%;
    flex: 1;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    p{
      margin: 0;
    }
    .content{
      flex:1;
      position: relative;
      .container{
        display: flex;
        overflow-x: scroll;
        .time-header{
          justify-content: space-between;
          margin-bottom: 10px;
        }
        .dateItem{
          margin-left: 5px;
        }
        .add-time{
          color:#3A415C;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
          cursor: pointer;
          background: #EBEDF2;
          &:hover{
            color: #5477FF;
          }
          .add-time-text{
            padding-right: 10px;
          }
        }
        .card-group-wrap{
          //transition: height 0.8s cubic-bezier(.7, 0, 1, 1);
          overflow: hidden;
        }
        .card-group{
          overflow: scroll;
          &.overflow{
            height: calc(100vh - 240px);
          }
          &::-webkit-scrollbar {
            width: 5px;
            //display: none;/*隐藏滚动条*/
          }
          &::-webkit-scrollbar-thumb {
            background: #CACBCDdf;
          }
        }
        .totalTime{
          display: block;
          color: #5477FF;
          font-size: 12px;
          margin: 3px 5px 5px;
          font-family: 'PingFangSC-Medium';
        }
        .card{
          overflow: hidden;
          width: 217px;
          height: max-content;
          flex-shrink: 0;
          background: #EBEDF2;
          margin-left: 8px;
          border-radius: 3px;
          padding: 14px 4px 0 9px;
          box-sizing: border-box;
          user-select: none;
          position: relative;
          &:first-child{
            margin-left: 12px;
          }
          .schedule{
            width: 235px;
            height: 3px;
            background:green;
            border-radius: 3px 3px 0 0;
            position: absolute;
            top: 0;
            left: 0;
          }
          .cardItem{
            background: #fff;
            padding: 8px 0 8px;
            box-sizing: border-box;
            margin-bottom: 8px;
            border-radius: 3px;
            box-shadow: 0 1px 3px 0 rgba(147,151,170,0.4);
            transition: box-shadow .3s;
            &:hover{
              box-shadow: 0 4px 15px 0 rgba(147, 151, 170, 0.4);
              .icon-shanchu{
                display: block;
              }
            }
            .cardTitle{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .status{
                font-size: 11px;
                color: #7A8199;
                .optionStatus{
                  padding: 0 2px;
                  border-radius: 4px;
                  margin: 0 6px 0 9px;
                }
              }
              .delUt{
                display: flex;
                align-items: center;
                .ut{
                  display: inline-block;
                  width: 28px;
                  height: 16px;
                  background: #33CC8A;
                  text-align: center;
                  line-height: 16px;
                  border-radius: 9px 0 0 9px;
                  color: #fff;
                  font-style: normal;
                  font-size: 12px;
                }
              }
            }
            .cardCont{
              width: 100%;
              padding: 0 8px 2px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              font-family: 'PingFangSC-Medium';
              font-size: 14px;
              cursor: pointer;
              p{
                margin:2px 0 8px;
                color: #000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                i{
                  font-size: 14.5px;
                  display: none;
                }
              }
              >span{
                font-size: 12px;
                line-height: 18px;
                color: #7A8199;
                overflow: hidden;  //隐藏多余行数
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; //规定显示几行，其余行数用小数点代替
                -webkit-box-orient: vertical;  //规定为垂直方向排列
                //text-align:justify;
                //word-break:break-all;
              }
            }
          }
        }
      }
    }
  }
}

.worktime-edit{
  width: 810px;
  .content.content.content.content{
    //flex: 1;
    height: 380px;
    max-height: 380px;
    //padding: 0;
    .worktime-cont{
      //flex: 1;
      height: 100%;
      display: flex;
      .edit-libType{
        width: 168px;
        height: 100%;
        //flex-shrink: 0;
        .edit-border{
          height: 100%;
          border-right: 1px solid #ccc;
          position: relative;
          padding-top: 12px;
          padding-right: 5px;
          box-sizing: border-box;
          .job-type-item{
            margin: 0;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            >span{
              display: block;
              padding:5px 4px 5px 12px;
              text-align: right;
            }
            .selectActive{
              color: #5477FF;
              //padding-right: 8px;
            }
            &:hover{
              background:#e6eef9;
            }
          }
          >em{
            display: inline-block;
            width: 1px;
            height: 28px;
            background: #5477FF;
            border: 1px solid #5477FF;
            border-radius: 12px;
            position: absolute;
            right: -1px;
            top: 0;
            //transition: 500ms top;
          }
        }
      }
      .edit-content{
        //flex: 1;
        width: 610px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .edit-form{
          display: flex;
          //flex: 1;
          justify-content: center;
          align-items: center;
          margin-top: 12px;
          .rootnet-forminput.horizontal.remark{
            align-items: flex-start;
            >label{
              margin-top: 2px;
            }
            >div{
              height: 200px;
            }
          }
        }
        .edit-footer{
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: flex-end;
          div{
            margin: 0 12px 0 0;
            &:last-child{
              background: #eee;
              color: #7A8199;
              box-shadow: none;
              &:hover{
                background: #ccc;
              }
            }
          }
        }
      }
    }
    .ut{
      align-self: flex-start;
      margin-top: 4px;
      width: 22px;
      height: 16px;
      background: #33CC8A;
      text-align: center;
      line-height: 16px;
      border-radius: 6px;
      color: #fff;
      font-size: 12px;
      letter-spacing: 1px;
      transform: scale(0.8);
      flex-shrink: 0;
    }
  }
}
.x-popup.x-popup.x-popup{
  box-shadow: 0 6px 14px 0 rgba(163,171,203,0.40);
  border: none;
  .work-time-select-list{
    .work-time-select-list-item{
      width: 100%;
      height: 35px;
      cursor: pointer;
      padding:0 12px;
      box-sizing: border-box;
      margin: 0;
      display: flex;
      &.active{
        background:#e6eef9;
      }
      .job-type-name{
        display: inline-block;
        line-height: 35px;
        margin-right: 2px;
      }
      &.top-border{
        border-top: 1px dashed #D0D3E3;
      }
      &:hover{
        background:#e6eef9;
      }
      .ut{
        margin-top: 4px;
        display: inline-block;
        width: 22px;
        height: 16px;
        background: #33CC8A;
        text-align: center;
        line-height: 16px;
        border-radius: 6px;
        color: #fff;
        font-size: 12px;
        letter-spacing: 1px;
        transform: scale(0.8);
        flex-shrink: 0;
      }
      .show-child-icon{
        flex: 1;
        height: 34px;
        line-height: 34px;
        text-align-last: end;
      }
    }
  }
}