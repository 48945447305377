.time-details-list-drawer.time-details-list-drawer {
  width: 90%;

  .time-details-list-drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .x-datagrid {
      flex: 1;
      font-size: 12px;

      .ellipsis-line {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .x-pagination {
      display: flex;
      flex: none;
      padding-right: 20px;
      justify-content: flex-end;
      border-top: 1px solid #e6e6e6;
    }
  }
}