.version-requirement-mgt {
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    box-sizing: border-box;

    .view-area-wrap {
      width: 100%;
      margin-right: 0;

      .view-area {
        display: flex;
        justify-content: space-between;
      }
    }

    .req-fun {
      align-items: center;
      position: relative;
      left: -10px;

      .textIconBtn {
        margin-left: 12px;
      }

      .type-num {
        color: #8A8888;
        margin-right: 10px;

        >span {
          margin-left: 8px;
        }
      }

      .type-change {
        display: flex;
        align-items: center;

        >span {
          margin-left: 6px;
        }

        .rootnet-checkbox {
          height: 20px;
          margin: 0;
        }
      }
    }
  }

  .req-table {
    flex: 1;
    overflow: auto;

    .ant-table-wrapper {
      height: 100%;
    }

    .ant-table-row-expand-icon.ant-table-row-expand-icon {
      margin-top: 2.5px;
      float: left;
    }

    .hide-display {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .init-style-display {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .memo-linefeed{
      white-space: pre-wrap;
      word-break: break-all;
    }

    .title-hidden {
      flex: 1;
      display: flex;

      .more-operations {
        cursor: pointer;

        >i {
          font-size: 14px;
        }

        &:hover {
          color: #5477ff;
        }
      }

      .type-color {
        border-radius: 4px;
        margin-right: 4px;
        color: white;
        padding: 0 2px;
        box-sizing: border-box;
        display: inline-block;
        transform: scale(0.7);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .text {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #5477ff;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .ant-table-tbody>tr.ant-table-row-selected>td {
      background-color: #EDF1FF;
    }

    .ant-table-thead>tr>th {
      height: 38px;
      white-space: nowrap;
      background: #f5f5f5;
    }

    .ant-table-header {
      height: 38px;
    }

    .ant-table-row td {
      white-space: nowrap;
    }

    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table,
    .ant-table-container {
      height: 100%;
      font-size: 12px;
    }

    .ant-table-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .ant-table-body {
      flex: 1;
      overflow: auto;
    }

  }
}

.item-operation-disabled.item-operation-disabled.item-operation-disabled.item-operation-disabled {
  cursor: no-drop;
}