.defect-update-dialog.defect-update-dialog.defect-update-dialog.defect-update-dialog.defect-update-dialog {
  z-index: 0;
  min-height: 400px;
  height: 90%;
  width: 90%;

  .content {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
  }

  .defect-update-dialog-content {
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: #fff;
  }

  .mock-dialog-header {
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;

    .dialog-title {
      font-weight: 700;
    }

    .mock-right-header {
      .header-edit-icon {
        font-size: 18px;
        cursor: pointer;
        margin: 0 20px;

        &.edit-active-icon {
          color: #5477ff;
        }

        &:hover {
          color: #5477ff;
        }
      }

      .header-handle-icon {
        font-size: 18px;
        cursor: pointer;
        margin: 0 20px;

        &:hover {
          color: #5477ff;
        }
      }
    }

    .close-area {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;

      .close-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: #ECECEC;
      }
    }
  }

  .defect-update-dialog-main-panel {
    flex: 1;
    height: 100px;
    overflow: hidden;
    padding: 8px 12px;
  }

  .left-panel {
    flex: 1;
    width: 100px;
    border-top: 1px solid #d0d3e3;

    .rootnet-forminput {
      margin: 0 0 8px;

      label {
        flex: none;
      }

      .rootnet-input {
        margin-left: 4px;
        flex: 1;
      }
    }

    .defect-title-wrap {
      .defect-title {
        height: 32px;
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 18px;
        max-width: 90%;
        white-space: nowrap;
        /*不允许换行*/
        overflow: hidden;
        /*超出隐藏*/
        text-overflow: ellipsis;
        /*文本超出三点代替*/
      }

      .copy-icon {
        margin-left: 8px;
        margin-top: 2px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: #3f7dff;
        }
      }
    }

    .left-panel-line {
      width: 100%;
      background: #D0D3E3;
      height: 1px;
    }

    .rich-text-area {
      border: none;
    }
  }

  .ant-tabs-tabpane {
    height: 100%;

    .tox-tinymce {
      border: none;
    }
  }

  .rich-text-detail-wrap {
    height: 410px;
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px;

    img {
      max-width: 100%;
    }
  }

  .right-panel {
    width: 350px;
    height: 498px;
    flex: 1;
    overflow: auto;
    border: 1px solid #D0D3E3;
    border-radius: 4px;
    margin-left: 8px;
    padding: 8px 0;

    &.is-detail {
      .form-component {
        line-height: 32px;
        overflow: hidden; //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical; //规定为垂直方向排列
      }
    }

    .search-tracer {
      .prefix {
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: unset;

        i {
          font-size: 14px;
        }
      }

      .suffix {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: unset;

        i {
          display: none;
          cursor: pointer;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.25);

          &:hover {
            color: gray;
          }
        }
      }

      &:hover {
        .suffix>i {
          display: inline-block;
        }
      }

      input {
        text-indent: unset;
      }
    }
  }

  .right-panel-wrap {
    .right-panel-edit-btn {
      margin: 0 0 8px 8px;
      flex-direction: row-reverse;
      height: 32px;
    }
  }

  .rich-text-area {
    flex: 1;
    height: 100%;
  }

  .ant-tabs {
    flex: 1;
    height: 200px;

    .ant-tabs-nav {
      padding-left: 10px;
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      display: flex;
      flex: 1;
      height: 100px;
      border-bottom: 1px solid #D0D3E3;
      overflow: hidden;
      padding: 6px;
    }

    .ant-tabs-content,
    .ant-tabs-content {
      flex: 1;
      overflow: auto;
    }

    .tab-content {
      height: 100%;
    }
  }

  .display-formInput.display-formInput.display-formInput {
    .form-component {
      line-height: 32px;
      overflow: hidden; //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical; //规定为垂直方向排列
    }
  }

  .defect-update-dialog-footer {
    overflow: hidden;
    justify-content: space-between;
    height: 48px;
    box-sizing: border-box;
    padding-bottom: 4px;

    .continue-add-check {
      margin-left: 20px;
    }

    .footer-switch {
      .footer-switch-item {
        cursor: pointer;
        padding: 4px 12px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        margin-right: 12px;
        color: #fff;
        background-color: #5477ff;
        transition: background-color 0.4s;

        &:hover {
          background-color: #214eff;
        }

        &.disabled {
          color: #6A6A6A;
          background-color: #E8E8E8;
          cursor: not-allowed;

          &:hover {
            background-color: #E8E8E8;
          }
        }

        >i {
          font-size: 14px;
        }


      }

      .list-num {
        font-weight: bold;
      }
    }

    .comment-wrap {
      width: 30px;
      height: 30px;
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
      border-radius: 50%;
      background: #ffffff;
      border: 1px solid #6A6A6A;

      &:hover {
        color: #1890ff;
        border: 1px solid #1890ff;
      }

      .comment-icon {
        font-size: 18px;
      }
    }

    .handle-btn {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

  .ant-tabs-tabpane {
    .x-card {
      margin: 0;
    }
  }
}

.defect-title-handle-popover {
  padding: 6px 12px;
}

.recurrence-tooltip {
  font-size: 12px;
  max-width: 530px;
  width: 530px;

  .ant-tooltip-content {
    width: 530px;
  }

  .ant-tooltip-inner {
    padding: 0;
    color: black;
  }
}