.version-making-detail-content {
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #fff;

  .mock-dialog-header {
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;

    .dialog-title {
      font-weight: 700;
    }

    .mock-right-header {
      .header-edit-icon {
        font-size: 18px;
        cursor: pointer;
        margin: 0 14px;

        &.edit-active-icon {
          color: #5477ff;
        }

        &:hover {
          color: #5477ff;
        }
      }

      .header-handle-icon {
        font-size: 18px;
        cursor: pointer;
        margin: 0 20px;

        &:hover {
          color: #5477ff;
        }
      }
    }

    .close-area {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;

      .close-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: #ECECEC;
      }
    }
  }

  .mock-dialog-content {
    flex: 1;
    overflow: hidden;

    .version-making-info {
      height: 100%;
      overflow: auto;

      .text-area-detail.text-area-detail.text-area-detail {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: 0 8px;
        margin-left: 0;
        margin-right: 0;

        >label {
          margin-top: 0;
        }

        .form-component.rootnet-textarea {
          height: 100px;
          margin-right: 8px;
        }

        .form-component {
          padding-right: 8px;
          box-sizing: border-box;
          height: auto;
          white-space: pre-wrap;
        }
      }

      .rootnet-forminput {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: flex-end;
    border-top: 1px solid #e9eaf2;

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

}