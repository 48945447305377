.customer-voice-detail{
  height: 90%;
  width: 90%;
  display: flex;
  >.content.content.content.content {
    padding: 0;
    width: 100px;
    height: 100%;
    display: flex;
    flex: 1;
    .customer-voice-detail-content{
      padding: 0;
      height: 100%;
      width: 100%;
      overflow: auto;
      background-color: #fff;

      .area-header{
        font-size: 14px;
        font-weight: 900;
      }

      .mock-dialog-header {
        display: flex;
        align-items: center;
        color: #15171f;
        padding: 12px 16px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #e9eaf2;
        justify-content: space-between;

        .dialog-title {
          font-weight: 700;
        }

        .mock-right-header {
          .header-edit-icon {
            font-size: 18px;
            cursor: pointer;
            margin: 0 20px;

            &.edit-active-icon {
              color: #5477ff;
            }

            &:hover {
              color: #5477ff;
            }
          }

          .header-handle-icon {
            font-size: 18px;
            cursor: pointer;
            margin: 0 20px;

            &:hover {
              color: #5477ff;
            }
          }
        }

        .close-area {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          cursor: pointer;

          .close-icon {
            font-size: 14px;
          }

          &:hover {
            background-color: #ECECEC;
          }
        }
      }

      .ant-tabs {
        width: 100%;
        height: 100%;

        .ant-tabs-nav {
          padding-left: 10px;
          margin-bottom: 0;
        }

        .ant-tabs-content-holder {
          display: flex;
          flex: 1;
          height: 100px;
          overflow: hidden;
          padding: 6px 10px;
        }

        .ant-tabs-content,
        .ant-tabs-content {
          flex: 1;
          overflow: auto;
        }

        .tab-content {
          height: 100%;
        }
      }

      .mock-footer {
        height: 44px;
        box-sizing: border-box;
        position: sticky;
        bottom: 0;
        background-color: #fff;
        z-index: 5;
        border-radius: 0 0 4px 4px;
        justify-content: space-between;

        .footer-switch {
          .footer-switch-item {
            cursor: pointer;
            padding: 4px 12px;
            border-radius: 18px;
            box-sizing: border-box;
            display: flex;
            margin-right: 12px;
            color: #fff;
            background-color: #5477ff;
            transition: background-color 0.4s;

            &:hover {
              background-color: #214eff;
            }

            &.disabled {
              color: #6A6A6A;
              background-color: #E8E8E8;
              cursor: not-allowed;

              &:hover {
                background-color: #E8E8E8;
              }
            }

            >i {
              font-size: 14px;
            }
          }

          .list-num {
            font-weight: bold;
          }
          .comment-wrap{
            width: 30px;
            height: 30px;
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            border-radius: 50%;
            background: #ffffff;
            border: 1px solid #6A6A6A;
            &:hover{
              color: #1890ff;
              border: 1px solid #1890ff;
            }
            .comment-icon{
              font-size: 18px;
            }
          }
        }

        .btn-group {
          .button {
            margin-right: 16px;
            height: 32px;
          }
        }
      }

      .main-content-wrap{
        width: 100%;
        height: 100px;
        flex: 1;
        border-bottom: 1px solid #D0D3E3;
        .left-content{
          width: 100px;
          flex: 1;
        }
        .dynamic-wrap{
          width: 360px;
          padding-left: 8px;
        }
        .drag-wrap{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: col-resize;
          width: 8px;
          border-left: 2px solid transparent;
          box-sizing: border-box;
          background-color: #F0F3F7;

          &:hover {
            border-left-color: #2684FF;
          }

          &:hover>i {
            color: #5477ff;
          }
        }
      }

      .rootnet-forminput.rootnet-forminput.rootnet-forminput {

        .form-component,
        >span {
          margin-left: 6px;
        }
      }

      .rootnet-forminput.horizontal{
        .cascade-select{
          margin-left: 6px;
        }
      }

      .ant-tabs-tab.ant-tabs-tab.ant-tabs-tab.ant-tabs-tab{
        &.ant-tabs-tab-active{
          .ant-badge{
            color: #1890ff;
          }
          .ant-badge-count{
            background-color: #EFF4FF !important;
            .ant-scroll-number-only{
              color: #6698FF;
            }
          }
        }
      }
    }
  }
}