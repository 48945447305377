.req-shuttle-column-log.req-shuttle-column-log {
  .content.content.content {
    padding-top: 0;
    height: 425px;

    .transfer {
      .container {
        .items.items.items.items {
          height: 300px;
          flex: unset;
        }
      }
    }
  }
}