.version-update-record{
  width: 100%;
  height: 100%;
  .version-update-header{
    justify-content: space-between;
    height: 32px;
    margin-bottom: 4px;
    margin-left: 8px;
    margin-top: 4px;
    .version-update-header-left{
      .version-env{
        margin-right: 20px;
        .version-env-text{
          color: #5477ff;
          cursor: pointer;
        }
      }
    }
    .version-update-header-right{
      padding-right: 20px;
      .filter-btn{
        height: 32px;
      }
      //.ant-switch{
      //  width: 140px;
      //}
    }
  }
  .enter-detail-field-wrap{
    cursor: unset;
    .enter-detail-field{
      cursor: pointer;
      color: #5477ff;
    }
    .del-icon{
      display: none;
      margin-left: 10px;
      cursor: pointer;
      font-size: 15px;
      &:hover{
        color: red;
      }
    }
    &:hover{
      .del-icon{
        display: block;
      }
    }
  }
  .table-wrap{
    flex: 1;
    height: 100px;
    width: 100%;
    font-size: 12px;
  }

  .cell{
    .x-datagrid-tooltip{
      min-width: 100%;
      .enter-detail-field-wrap{
        min-width: 100%;
      }
    }
  }
}