.user-select{
  width: 180px;
  min-height: 32px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #CFD3E3;
  flex-wrap: wrap;
  cursor: pointer;
  box-sizing: border-box;
  &:hover{
    border-color: #3a61ff;
  }
  &.error.error.error.error{
    border: 1px solid #F5221B;
    &:hover{
      box-shadow: 0 0 4px 0 #F5221B;
    }
  }
  &.disabled.disabled.disabled{
    cursor: not-allowed;
    border-color: #d8dae2;
    background-color: #F5F5F5;
    &.error.error:hover{
      box-shadow: none;
    }
    &:hover{
      box-shadow: none;
    }
  }
  .ant-tag{
    height: 24px;
    margin: 2px;
    color: rgba(0,0,0,.85);
    .ant-tag-close-icon{
      color: rgba(0,0,0,.65);
    }
  }
  .user-select-placeholder{
    color: #D7D9E0;
    padding-left: 8px;
  }
}

.user-select-pop{
  padding: 0 10px;
  .ant-popover-arrow{
    display: none;
  }
}