.work-flow-change-pop{
  padding: 8px;
  height: 310px;

  .work-flow-change-left{
    border-right: 1px solid #DFDFDF;
    min-width: 120px;
    overflow: auto;
    justify-content: space-between;
    .status-list-wrap{
      overflow-y: auto;
      padding-right: 4px;
      height: 100px;
      flex: 1;
    }
    .manager-text{
      color: #738299;
      margin-left: 4px;
      font-size: 12px;
      margin-top: 2px;
      margin-bottom: 6px;
    }
    .status-item{
      font-size: 12px;
      min-width: 100px;
      margin: 8px 4px;
      padding: 0 2px;
      border: 1px solid #ADB3CB;
      border-radius: 4px;
      color: #ADB3CB;
      cursor: pointer;
      &:hover{
        color: #5477ff;
        border: 1px solid #5477ff;
      }
      &.selected{
        color: #5477ff;
        border: 1px solid #5477ff;
      }
      .item-selected{
        color: #fff;
        position: absolute;
        height: 14px;
        width: 14px;
        font-size: 10px;
        top: -6px;
        right: -4px;
        border-radius: 50%;
        background-color: #5477ff;
      }
    }
    .work-flow-show-btn{
      height: 28px;
      width: 100px;
      font-size: 12px;
      color: #5477ff;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .work-flow-change-right{
    flex-direction: column-reverse;
    justify-content: space-between;
    min-width: 350px;
    .status-form{
      flex: 1;
      overflow: auto;
      .condition-tips-wrap{
        font-size: 12px;
        .condition-tips-icon{
          margin-top: 2px;
          margin-left: 8px;
          color: red;
        }
        .condition-tips-text{
          margin-left: 8px;
          font-weight: bold;
          color: red;
        }
      }
      .check-condition-group-wrap{
        height: auto;
        .condition-group-wrap{
          margin-top: 4px;
        }
      }
      .next-node-tips{
        color: #738299;
        margin-left: 80px;
        font-size: 12px;
        margin-top: 2px;
        margin-bottom: 6px;
        .next-node-name{
          margin-left: 16px;
          border-radius: 4px;
          padding: 0 8px;
        }
        .node-tips-text{
          margin-left: 16px;
          max-width: 200px;
        }
      }
      .no-match-text{
        border: 1px solid #E8EAEE;
        background-color: #FAFAFA;
        font-size: 12px;
        padding: 10px;
        max-width: 350px;
        white-space: pre-wrap;
      }
    }
    .no-auth-status-wrap{
      width: 300px;
      margin-left: 20px;
      margin-top: 8px;
      font-size: 12px;
      .no-auth-text{
        color: #797979;
        margin-bottom: 12px;
      }
      .auth-tips{
        margin-bottom: 12px;
      }
      .auth-tips-item{
        margin-bottom: 4px;
        .auth-tips-label{
          width: 60px;
          text-align: right;
        }
        .auth-tips-text{
          width: 200px;
          flex: 1;
        }
      }
    }
    .handle-btn-group{
      justify-content: flex-end;
      .handle-btn{
        height: 28px;
        width: 70px;
      }
    }
    .condition-message{
      padding: 12px;
      .tips-icon{
        color: red;
        font-size: 14px;
        margin-right: 4px;
      }
      .condition-message-text{
        white-space: pre-wrap;
      }
    }
  }
  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
    font-size: 12px;
    label{
      font-size: 12px;
      white-space: pre-wrap;
      margin-right: 4px;
    }
  }
}