.project-infoForm {
  width: 1100px;

  >.header {
    height: 48px;

    .title {
      font-weight: 600;
    }
  }

  >.content {
    overflow-y: auto;
    padding-left: 20px;

    .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
      &:not(.required) {
        >label::before {
          content: '';
          display: inline-block;
          width: 7px;
          margin-right: 4px;
        }
      }

      >label {
        text-align: left;



        &::after {
          content: '';
        }
      }

      .tooltip-tips {
        display: flex;
        align-items: center;
        width: 200px;

        .rootnet-checkbox {
          width: 20px !important;
        }
      }
    }

    .lineFeed {
      display: block;

      >label {
        display: inline-block;
      }
    }

    .rootnet-forminput {
      .rootnet-checkbox {
        margin: 0;
      }
    }
  }
}

.important-adjustment {
  padding-top: 16px;
  box-sizing: border-box;
}