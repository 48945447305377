.work-time-deletion-list-option {
  &.c-options {
    flex-wrap: wrap;
    display: flex;
    align-items: flex-end;
    margin: 0;
    padding: 4px 0 4px 4px;
    font-size: 14px;

    .rootnet-forminput {
      .user-select {
        overflow-y: auto;
        height: 32px;
      }
    }

    .rootnet-forminput {
      margin: 8px;
    }

    .search-btn-group {
      .x.button {
        margin: 6px 8px;
      }

      .reset-btn {
        border: 1px solid #7A8199;
      }
    }
  }
}