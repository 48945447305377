.project-list-baseInfo {
  height: 100%;
  display: flex;

  .main-content {
    flex: 1;
    display: grid;
    grid-template: (200px 1fr 1fr) / 20.25% 20.25% 20.25% 20.25% 19%;
    grid-template-areas:
            's1 s1 s1 s1 s6'
            's2 s2 s3 s3 s6'
            's4 s4 s4 s5 s6';
    overflow: hidden;
    >.x-card{
      margin:0 0 12px 12px;
      &.milestone-card{
        grid-area: s1;
        >.card-content{
          display: flex;
          align-items: center;
          padding: 0 120px;
        }
      }
      &:nth-child(2){grid-area: s2;}
      &:nth-child(3){grid-area: s3;}
      &.project-progress{grid-area: s4;}
      &.task-progress{
        grid-area: s5;
        position:relative;
        .task-progress-content{
          position:absolute;
          top:40%;
          left:50%;
          transform: translate(-50%,-50%);
          font-size:18px ;
          font-weight: 600;
        }
        .task-progress-footer{
          position:absolute;
          top:70%;
          left:50%;
          transform: translate(-50%,0%);
          font-size: 12px;
          width:66px;
          text-align: center;
          line-height: 20px;
          >.top{
            color:#738299;
          }
          >.bottom{
            color:#13151A;
            font-weight: 700;
          }
        }
      }
      &.base-info-card{
        grid-area: s6;
        margin-right:12px;
        >.card-content{
          overflow-x: hidden;
        }
        .baseInfo-card-area{
          &:not(:last-child){
            border-bottom: 1px solid #F3F3F3;
          }
          &:not(:first-child){
            .details_item{
              flex-direction: column;
              &_text{
                max-width: 100%;
                width:100%;
              }
              >span{
                text-align: left;
              }
              .tooltip{
                white-space: normal;
              }
            }
          }
          display: flex;
          flex-wrap: wrap;
          padding-left:12px;
          min-height: 100px;
          .details_item{
            font-size: 12px;
            padding: 2px 0;
            line-height: 26px;
            &_tit{
              width:84px;
            }
            &_text{
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}