.release-record-list {
    height: 100%;
    display: flex;

    .x-card {
        flex: 1;

        .card-content.card-content.card-content {
            flex-direction: initial;

            .flex.center {
                width: 100%;
            }
        }

        .x-datagrid.x-datagrid.x-datagrid.x-datagrid {
            flex: initial;
            width: 500px;
            height: auto;
            background-color: white;
        }
    }
}