.post-mail-log {
  width: 750px;
  height: 80%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .rootnet-forminput {
      display: flex;

      &.text-area {
        flex: 1;

        .form-component {
          height: 100%;
        }
      }

      .form-component.form-component.form-component {
        flex: 1;
      }

      >span {
        flex: 1;

        .user-select {
          width: 100%;
        }
      }
    }
  }
}