.version-msg {
  .x-card {
    margin-top: 0;

    .x-datagrid {
      .enter-detail-field {
        cursor: pointer;
        color: #5477ff;

        &:hover {
          text-decoration: underline;
        }
      }

      .enter-detail-field-wrap {
        .enter-detail-field-edit-icon {
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;

          &:hover {
            color: #5477ff;
          }
        }

        &:hover {
          .enter-detail-field-edit-icon {
            display: block;
          }
        }
      }
    }
  }
}