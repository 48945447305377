.version-dashboard-wrap{
  height: 100%;
  width: 100%;
  padding: 6px 12px;
  .version-dashboard-list{
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    border: 1px solid #F0F0F0;
    .version-dashboard-list-item{
      width: 100px;
      height: 32px;
      padding: 6px;
      flex: 1;
      cursor: pointer;
      font-size: 12px;
      .version-dashboard-list-item-text{
        width: 100%;
        overflow: hidden;  //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical;  //规定为垂直方向排列
      }
      &.selected{
        background-color: rgba(84, 119, 255, 0.1);
        color: #5477ff;
      }
      &:hover{
        color: #5477ff;
        background-color: #F5F5F5;
      }
    }
    .fold-bar{
      width: 22px;
      cursor: pointer;
      &:hover{
        background-color: #EAF4FC;
        color: #5477ff;
      }
      .fold-icon{
        font-size: 18px;
      }
    }
  }
  .version-dashboard-content{
    margin-left: 12px;
    width: 100px;
    flex: 1;
  }
}