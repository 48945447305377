div.rims-router.x {
    height: calc(100% - 48px);
    position: relative;
    .router-back{
        position: absolute;
        left: 10px;
        top: 0;
        height: 38px;
        line-height: 38px;
        z-index: 1;
        &.x.button{
            margin: 0;
            padding: 0 16px;
            i{
                font-size: 14px;
                color: #000;
            }
        }        
    }
    .x-tab{
        display: flex;
        flex-direction: column;
        height: 100%;
        .tab-header{
            padding-left: 48px;
            background: #fff;
            border: none;
        }
        .tab-container{
            flex: 1;
            overflow: auto;
            padding-top: 12px;
            .tab-item{
                height: 100%;
                overflow: auto;
            }
        }
    }
}