.sales-product-view-detail-dialog {
  width: 710px;

  .continue-add {
    margin-left: 100px;
  }

  >.content.content.content.content {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
  }

  .sales-product-detail-content {
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;

    .mock-dialog-header {
      display: flex;
      align-items: center;
      color: #15171f;
      padding: 12px 16px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e9eaf2;
      justify-content: space-between;

      .dialog-title {
        font-weight: 700;
      }

      .mock-right-header {
        .header-edit-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }

        .header-handle-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &:hover {
            color: #5477ff;
          }
        }
      }

      .close-area {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;

        .close-icon {
          font-size: 14px;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }

    .sales-product-detail-main-panel {
      height: 70vh;
      min-height: 380px;
      max-height: 454px;
      overflow: auto;

      .sales-product-add-icon {
        cursor: pointer;
        color: #5477ff;
        font-size: 14px;
      }
    }
  }

  .dialog-title {
    font-weight: 700;
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    border-top: 1px solid #e9eaf2;

    .footer-switch {
      .footer-switch-item {
        cursor: pointer;
        padding: 4px 12px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        margin-right: 12px;
        color: #fff;
        background-color: #5477ff;
        transition: background-color 0.4s;

        &:hover {
          background-color: #214eff;
        }

        &.disabled {
          color: #6A6A6A;
          background-color: #E8E8E8;
          cursor: not-allowed;

          &:hover {
            background-color: #E8E8E8;
          }
        }

        >i {
          font-size: 14px;
        }
      }

      .list-num {
        font-weight: bold;
      }
    }

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }


  &.detail-mode {
    .form-component.long-text {
      height: unset;
      min-height: 32px;
      line-height: 32px;
      white-space: pre-wrap;
    }
  }

  &.add-mode {
    .ant-tabs-nav {
      display: none;
    }
  }

  .dynamic-wrap {
    padding: 10px 20px;
  }

  .ant-tabs {
    height: 100%;

    .ant-tabs-nav {
      padding-left: 16px;
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      display: flex;
      flex: 1;
      height: 100px;
      overflow: hidden;
      padding: 6px;
    }

    .ant-tabs-content {
      flex: 1;
      overflow: auto;
      display: flex;

      .ant-tabs-tabpane {
        width: 100px;
        flex: 1
      }
    }

    .tab-content {
      height: 100%;
    }
  }
}