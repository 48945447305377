.resource-plan{
  .content.content.content.content{
    width: 800px;
    height: 70vh;
    overflow-y: auto;
  }
  .type-wrap{
    .ant-radio-group{
      margin-left: 8px;
      margin-right: 10px;
    }
    .rootnet-select{
      width: 100px;
      flex: 1;
    }
    .mock-req-select{
      height: 32px;
      line-height: 32px;
      width: 100px;
      flex: 1;
      box-sizing: border-box;
      border: 1px solid #CFD3E3;
      border-radius: 4px;
      cursor: pointer;
      background-color: white;
      padding:0 8px;
      overflow: hidden;  //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical;  //规定为垂直方向排列
      &.placeholder{
        color: #D9D9E0;
      }
    }
  }
  .time-handle-wrap{
    justify-content: space-between;
  }
  .total-time-wrap{
    height: 32px;
    margin-left: 8px;
    .total-time{
      margin-left: 16px;
    }
  }

  .add-person-btn, .add-person-bottom-btn{
    color: #5477ff;
    .textIconBtn{
      display: inline-block;
      margin-top: 6px;
      margin-left: 8px;
    }
  }
  .plan-main-panel{
    margin-top: 4px;
    .plan-main-panel-header{
      .plan-header-person{
        margin-left: 8px;
      }
      .plan-header-time-range{
        margin-left: 230px;
      }
      .plan-header-include-rest{
        margin-left: 100px;
      }
      .plan-header-plan-time{
        margin-left: 100px;
      }
    }
    .plan-item{
      &.curr-user{
        background: #EEF1FF;
      }
      .del-icon{
        font-size: 16px;
        cursor: pointer;
        &:hover{
          color: red;
        }
      }
    }
  }
  .show-all-btn-wrap{
    margin-top: 8px;
    margin-left: 4px;
    .show-all-btn.show-all-btn.show-all-btn{
      height: 32px;
    }
  }
}