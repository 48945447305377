.color-picker-pop-content{
  .chrome-picker{
    box-shadow: none !important;
  }
  .btn-group{
    justify-content: flex-end;
    margin: 4px 0;
    .button{
      height: 30px;
    }
  }
}