.work-time-query{
  .colorSpan{
    &.reject{
      color: red;
    };
    &.submit{
      color: orange;
    };
    &.approve{
      color: green;
    }
  }
  .work-time-query-options{
    transition: height 0.3s;
    .fold-text{
      display: inline-flex;
      margin-right: 20px;
      height: 30px;
      line-height: 30px;
      color: #5477FF;
      cursor: pointer;
    }
    .form-group{
      margin-left: 4px;
      width: 890px;
    }
    .btn-group{
      margin-top: 30px;
      .work-time-query-button{
        margin-left: 30px;
        height: 31px;
        min-width: 74px;
      }
      .quick-query-text{
        text-align: center;
        height: 30px;
        line-height: 30px;
        font-weight: 400;
        cursor: pointer;
        color: #5477FF;
      }
    }
  }
  .arrow-drop-down-group{
    cursor: pointer;
  }
  .arrow_drop_down{
    margin-right: 5px;
    display: inline-block;
  }
}