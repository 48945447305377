.workflow-time-details-mgt {
  .enter-detail-field {

    .enter-detail-field-text {
      cursor: pointer;
      color: #5477ff;

      &:hover {
        text-decoration: underline
      }
    }
  }
}