.version-test-plan {
  flex-direction: column;
  height: 100%;
  display: flex;

  .x-card {
    margin: 0;
    .row>.cell:hover .edit-icon {
      margin-left: 8px;
      display: inline-block;
    }

    .edit-icon {
      display: none;

      i {
        cursor: pointer;
        margin-right: 8px;
        font-size: 14px;
      }

      & i:hover {
        color: #5477ff;
      }
    }

    .enter-detail-field {
      color: #5477ff;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .progress-color {
      .x-datagrid-tooltip {
        width: 100%;

        .progressSet {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          .progressColor {
            border-radius: 2px;
            margin-right: 1px;
          }
        }
      }
    }
  }
}