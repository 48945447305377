.customer-system-add{
  width: 890px;
  >.content.content.content.content{
    padding: 0;
    .content-wrap{
      flex: 1;
      overflow: scroll;
      padding: 12px;
    }
    .mock-footer{
      display: flex;
      align-items: center;
      padding: 8px 0;
      height: 48px;
      box-sizing: border-box;
      border-top: 1px solid #E9EAF2;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      .continue-add-check{
        margin-left: 20px;
      }
      .btn-group{
        .button{
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}