.children-req-view-tab{
  .view-area-wrap{
    display: none;
  }
  .title-wrap{
    width: 100%;
    .title{
      width: calc(100% - 20px);
      color: #3f7dff;
      cursor: pointer;
      white-space: nowrap;/*不允许换行*/
      overflow: hidden;/*超出隐藏*/
      text-overflow: ellipsis;/*文本超出三点代替*/
    }
  }
  .space-between{
    justify-content: space-between;
  }
  .more-handle-icon.more-handle-icon.more-handle-icon{
    cursor: pointer;
    margin-left: 6px;
    font-size: 14px;
    &:hover{
      color: #3f7dff;
    }
  }
}
.children-requirement-handle-popover{
  .requirement-handle-item{
    padding: 6px 12px;
    min-width: 150px;
    &.disabled{
      color: #d6d8e0;
      &:hover{
        color: #d6d8e0;
        cursor: not-allowed;
      }
    }
    &:hover{
      cursor: pointer;
      background: #efefef;
      color: #3f7dff;
    }
    &.del-item{
      &:hover{
        color: red;
      }
    }
    .handle-icon.handle-icon{
      font-size: 14px;
      margin-right: 6px;
    }
  }
}