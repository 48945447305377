.approval-drawer.approval-drawer.approval-drawer{
  width: 900px;
  >.header{
    background: #111629;
    height: 35px;
    .title{
      color: #FFFFFF;
    }
  }
  .approval-drawer-content{
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    .inner-header{
      padding-left: 20px;
      font-weight: bold;
      line-height: 40px;
      border-bottom: 1px solid #E9EAF2;
      display: flex;
      align-items: center;
      &::before {
        display: block;
        content: "";
        width: 2px;
        height: 1em;
        background-color: #5477ff;
        box-shadow: 2px 0 3px 0 rgba(24,144,255,0.42);
        margin-right: 12px;
      }
    }
    .contrast{
      width: 100%;
      .changed,.before-change{
        flex: 1;
      }
    }
    .operate-info,.approval-area{
      margin-top: 30px;
      width: 100%;
    }
    .operate-info{
      .memo-wrap{
        .display-item{
          margin-top: 4px;
          margin-bottom: 0;
        }
      }
    }
    .approval-select{
      .approval-wrap{
        cursor: pointer;
        margin-left: 50px;
        margin-top: 10px;
      }
    }
    .text-area-with-tooltip{
      padding-left: 60px;
      margin-top: 10px;
      .rootnet-textarea{
        width: 600px;
      }
    }
  }

  .display-item{
    margin:15px 0 15px 60px;
    &.changed{
      .text{
        color: #5477FF;
      }
    }
    .label{
      color: #7A8199;
      margin-right: 5px;
    }
    .text{
      min-width: 60px;
      white-space: nowrap;/*不允许换行*/
      overflow: hidden;/*超出隐藏*/
      text-overflow: ellipsis;/*文本超出三点代替*/
    }
  }
}