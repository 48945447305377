$primaryColor: #5477FF;
$borderColor: rgba(0,0,0,0.1);
$activeBg: #F2F6FA;
.work_Time_list{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    margin: 0;
    &.x-card{
        margin: 0;
    }
    >.card-content{
        display: flex;
        flex-direction: column;
        background: #F0F3F7;
    }
    .list-con{
        flex: 1;
        display: flex;
    }
    .primary{
        color: $primaryColor;
    }
    .x-card-singlegrid{
        overflow: auto;
    }
    .cell{
        .rootnet-forminput{
            margin: 0;
            .form-component{
                width: 80px;
                height: 26px;
            }
        }
    }    
}