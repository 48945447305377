.project-stage-arrow-crumbs {
  position: relative;
  margin-left: 50px;

  .breadcrumb,
  .breadcrumb-item {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .breadcrumb {
    // display: flex;
    // overflow: hidden;
    // height: 26px;
    background: #fff;
    padding-right: 16px;

    .breadcrumb-item {
      // cursor: pointer;
      padding: 0px 26px;
      line-height: 26px;
      background: #f0f0f0;
      display: inline-block;
      color: black;
      position: relative;
      // font-weight: bold;
      font-size: 12px;
      margin-right: 4px;
      margin-top: 4px;
      min-width: 120px;
      position: relative;

      .icon-btn {
        position: absolute;
        right: 8px;
        font-size: 14px;
        cursor: pointer;
      }

      .icon-disable.icon-disable {
        cursor: unset;
      }

      &:last-child {
        border-radius: 0px 4px 4px 0px;
        padding-right: 25px;
      }

      &:first-child:before {
        display: none;
      }

      // &:hover {
      //   color: #5477ff;
      // }

      &::before {
        cursor: default;
        content: '';
        display: block;
        border-top: 13px solid #f0f0f0;
        border-bottom: 13px solid #f0f0f0;
        border-left: 14px solid #fff;
        position: absolute;
        left: 0px;
        top: 0;
      }

      &::after {
        content: '';
        display: block;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-left: 15px solid #f0f0f0;
        position: absolute;
        right: -14px;
        top: 0;
        z-index: 8;
      }
    }

    .breadcrumb-item-disabled.breadcrumb-item-disabled.breadcrumb-item-disabled.breadcrumb-item-disabled {
      background-color: #d3d3d3;
      text-decoration: line-through;
      color: black;

      &::before {
        border-top-color: #d3d3d3;
        border-bottom-color: #d3d3d3;
      }

      &::after {
        border-left-color: #d3d3d3;
      }
    }

    .breadcrumb-item-complete.breadcrumb-item-complete {
      background-color: #1FC695;
      color: white;

      &::before {
        border-top-color: #1FC695;
        border-bottom-color: #1FC695;
      }

      &::after {
        border-left-color: #1FC695;
      }
    }

    .breadcrumb-item-underway {
      background-color: #E8F9F4;
      color: #4EA67C;

      &::before {
        border-top-color: #E8F9F4;
        border-bottom-color: #E8F9F4;
      }

      &::after {
        border-left-color: #E8F9F4;
      }
    }

    .breadcrumb-item-last.breadcrumb-item-last.breadcrumb-item-last {
      &::after {
        display: none;
      }
    }
  }
}

.dev-project-stage-content {
  padding: 8px;

  .quantity-completion {
    font-weight: 700;
    margin-bottom: 8px;
  }

  .stage-content {
    max-height: 340px;
    overflow: auto;

    .stage-item {
      margin-top: 4px;

      .stage-name {
        flex: 1;
      }

      .stage-status {
        width: 50px;
        margin-left: 12px;
      }
    }
  }

}