.department-and-user-mgt {
  .x-card.x-card.x-card {
    height: 40px;
    margin-bottom: 4px;
    flex: unset;
  }

  .two-columns-wrap {
    height: 100px;
    flex: 1;
    padding: 0 12px 12px;

    .expend-depart-n {
      margin-right: 8px;
      background-color: white;
      border-radius: 4px;
      overflow: hidden;

      .textIconBtn {
        margin-right: 8px;

        .icon {
          padding: 0;
        }
      }

    }

    .left-panel {
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
      margin-right: 8px;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #e1e1e1;
      width: 260px;
      height: 100%;

      .resource-dept-search {
        padding: 6px;
        overflow: hidden;
      }


      .x-pagination {
        margin: 5px;
      }

      .pagination-area {
        padding: 8px;
      }
    }

    .right-panel {
      width: 200px;
      height: 100%;
      flex: 1;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #e1e1e1;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);

      .x-datagrid {
        flex: 1;
        height: 100px;
        border-top: none;
        font-size: 12px;
      }

      .x-pagination {
        flex: none;
        padding-right: 20px;
        justify-content: flex-end;
        border-top: 1px solid #e6e6e6;
      }

      .x-datagrid {
        .grid.grid.grid {
          .cell {
            .role-list-overflow {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            .enter-detail-field-wrap {
              cursor: unset;
              width: 100%;

              .enter-detail-field {
                width: 100px;
                flex: 1;

                .enter-detail-field-text {
                  max-width: 100%;
                  cursor: pointer;
                  color: #5477ff;
                  overflow: hidden; //隐藏多余行数
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
                  -webkit-box-orient: vertical; //规定为垂直方向排列

                  &:hover {
                    text-decoration: underline
                  }
                }
              }

              .enter-detail-field-edit-icon {
                display: none;
                margin-left: 10px;
                cursor: pointer;
                font-size: 15px;

                &:hover {
                  color: #5477ff;
                }
              }

              &:hover {
                .enter-detail-field-edit-icon {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

}