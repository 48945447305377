.project-infoForm-detail {
  width: 1100px;

  >.header {
    height: 48px;

    .title {
      font-weight: 600;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .subProject {
        display: flex;
        align-items: center;
      }

      .edit-icon {
        cursor: pointer;
        margin-right: 20px;
        font-weight: 400;
        font-size: 16px;

        &:hover {
          color: #5477ff;
        }
      }
    }
  }

  >.content {
    overflow-y: auto;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
      >label {
        text-align: left;

        &::after {
          content: '';
        }
      }
    }

    .product-ellipsis {
      .benefit-text {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }
}