.refuse-dialog-edit.refuse-dialog-edit.refuse-dialog-edit {
  .text-area-input-edit.text-area-input-edit.text-area-input-edit {
    align-items: flex-start;
  }

  .content.content.content {
    padding: 0;
  }

  .refuse-dialog-edit-content {

    .refuse-dialog-edit-main-panel {
      flex: 1;
    }
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    border-top: 1px solid #d0d3e3;

    .footer-hint {
      font-size: 12px;
      padding-left: 16px;
      color: #D4D4D4;
    }

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

}