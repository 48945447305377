.view-query-dialog {
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;

  .text-hint {
    font-size: 12px;
    color: rgb(191, 191, 191);
    padding-left: 16px;
    font-weight: 400;
  }

  .title-wrap {
    .title-text {
      margin-right: 4px;
    }

    .selected-num-text {
      font-size: 12px;
      color: #BBBBBB;
    }
  }

  .content.content.content.content {
    flex: 1;
    padding: 0;
    width: 100%;
    // height: 70vh;
    display: flex;
    flex-direction: column;

    .check-item {
      position: absolute;
      width: 40px;
      height: 34px;
      cursor: pointer;
    }

    .issue-view-query-content {
      flex: 1;
      overflow: hidden;

      .x-card {
        overflow: hidden;
      }
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}