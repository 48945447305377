.product-mgt{
  .grid{
    .cell{
      //width: 100% !important;
    }
  }
  .enter-detail-field-wrap{
    .expand-icon{
      margin-right: 8px;
      margin-top: 2px;
      cursor: pointer;
      &:hover{
        color: #3f7dff;
      }
    }
    .empty-parent{
      width: 20px;
    }
    .empty-child{
      width: 40px;
    }
  }
}
.product-handle-popover{
  .product-handle-item{
    padding: 6px 12px;
    min-width: 150px;
    &.disabled{
      color: #d6d8e0;
      &:hover{
        color: #d6d8e0;
        cursor: not-allowed;
      }
    }
    &:hover{
      cursor: pointer;
      background: #efefef;
      color: #3f7dff;
    }
    &.del-item{
      &:hover{
        color: red;
      }
    }
    .handle-icon.handle-icon{
      font-size: 14px;
      margin-right: 6px;
    }
  }
}