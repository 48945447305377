.role-mgt {
  .role-mgt-content {
    width: 100%;

    .role-mgt-trag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: col-resize;
      width: 8px;
      border-left: 2px solid transparent;
      box-sizing: border-box;
      background-color: #F0F3F7;

      &:hover {
        border-left-color: #2684FF;
      }

      &:hover>i {
        color: #5477ff;
      }
    }
  }
}