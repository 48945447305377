div.milepost-right.x {
    height: 100%;
    margin: 0 12px 0 0;
    .card-header{
        .right{
            .edit{
                cursor: pointer;
            }
        }
    }
    .card-content{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}