div.milepost-edit.x {
    width: 1000px;
    height: 602px;
    .content{
        display: flex;
        flex-direction: column;
        height: 490px;        
        .tips{
            color: #EF732A;
            font-size: 12px;
            margin-bottom: 10px;
        }
        .tips-con{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .con-tit{
                margin-bottom: 16px;
                font-weight: 800;
            }
        }
    }
}

div.row_item_list.x{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    .left{
        flex: 1;
        display: flex;
        .x-forminput{
            margin-right: 16px;
            height: 32px;
            .text-input{
                width: 300px;
            }
            &:last-child{
                margin: 0;
            }
        }
    }
    .right{
        width: 40px;
        text-align: left;
        padding-top: 6px;
        i{
            cursor: pointer;
        }
    }
    div.x.select>div.select-input-error{
        bottom: -90%;
    }
    div.x.date-picker>div.datepicker-input-error{
        bottom: -90%;
    }
    &.title{
        margin-bottom: 4px;
        .left{
            .x-forminput{
                width: 182px;
                height: 20px;
                &.small{
                    width: 120px;
                }
            }
        }        
    }
}