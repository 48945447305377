.tracking-list-dialog{
  .content.content.content.content{
    display: flex;
    flex-direction: column;
    height: 480px;
    justify-content: flex-start;
    .x-datagrid{
      overflow: hidden;
    }
  }
  .release-id{
    font-size: 12px;
    color: #5477FF;
    border: 1px solid #5477FF;
    border-radius: 4px;
    margin-right: 5px;
    padding: 1px 4px;
  }
  .options{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }
  .data-grid-wrap{
    flex: 1;
    border: 1px solid #e6e6e6;
    .no-data{
      display: none;
    }
  }
  .search-icon.search-icon.search-icon{
    margin: 0 4px;
    font-size: 16px;
    &.pointer{
     &:hover{
       cursor: pointer;
       color: #5477FF;
     }
    }
  }
  .add-button.add-button.add-button{
    border: 1px solid #D0D3E3;
    background: #fff;
    padding: 5px 10px 4px 8px;
    color: #00333F;
    height: 30px;
    font-size: 14px;
    &:hover{
      color: #5477FF;
      border: 1px solid #5477FF;
    }
  }
  .add-icon.add-icon.add-icon{
    margin: 0 4px;
    font-size: 14px;
  }
  .column{
    .top{
      .x-checkbox{
        display: none;
      }
    }
  }
  .footer.footer.footer.footer{
    padding-top: 0;
  }
}