.projectManagement-box.projectManagement-box.projectManagement-box.projectManagement-box {
  .col-hover-blue:hover {
    text-decoration: underline;
    cursor: pointer
  }

  div.x-datagrid>div.header>div.resizer:nth-child(3) {
    z-index: 3;
  }

  .enter-detail-field {
    cursor: pointer;
    color: #5477ff;

    &:hover {
      text-decoration: underline
    }
  }

  .tab-leve {
    z-index: 4 !important;
  }

  .leftArrow {
    cursor: pointer;
  }

  .verticalAlignment {
    display: flex;
    align-items: center;
    width: 100%;

    .enter-detail-field {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .projectLabelY {
    color: #5477FF;
  }

  .priceColor.projectLabelY {
    color: #33CC8A;
  }

  .projectLabelN {
    color: #7A8199;
  }

  .projectLabel {
    // display: inline-block;
    // padding: 2px 4px;
    // box-sizing: border-box;
    // border-radius: 3px;
    // color: white;
    // background-color: #5477FF;
    // font-size: 8px;
    // display: inline-block;
    // font-size: 8px;
    // transform: scale(0.8);
    font-size: 28px;
  }

  .arrow_drop_down {
    margin-right: 5px;
    display: inline-block;
  }

  .project-mgt-card {
    >.grid {
      >div {
        .row {

          //>.cell:nth-child(7){
          >.cell {

            //padding: 0;
            >div {

              //min-width: 100%;
              .budget-cost-wrap {
                //padding-right:12px;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                justify-content: center;

                .budget-cost-value {
                  height: 16px;
                }

                .progress-bg {
                  height: 4px;
                  width: 75px;
                  background: lightgray;
                  border-radius: 4px;

                  .progress {
                    height: 4px;
                    background: #0a365a;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.project-option {
  margin-left: 5px;

  .btn.btn.btn.btn {
    width: 75px;
    margin-left: 10px;
    height: 31px;
  }

  .reset-btn.reset-btn.reset-btn {
    color: #3A415C;
    border: 1px solid #7A8199;
  }



  b {
    width: 30px;
    height: 31px;
    text-align: center;
    line-height: 31px;
    font-weight: 400;
    margin-top: 28px;
    margin-left: 5px;
    cursor: pointer;
    color: #5477FF;
  }

  .option-hide {
    display: none;
  }

  .option-show {
    display: block;
  }
}

.projectDetail-promptBox.projectDetail-promptBox.projectDetail-promptBox.projectDetail-promptBox {
  .content {
    width: 1010px;
    max-height: 600px;
    flex-direction: column;

    >div:first-child {
      padding: 10px 0;
      box-sizing: border-box;
    }

    .projectDetail-promptBox-table-fill {
      height: 400px;
      width: 100%;
      display: flex;
      overflow: hidden;

      .x-card {
        flex: 1;
        overflow: auto;

        .card-content {
          display: flex;

          .x-datagrid {
            flex: 1;
          }
        }
      }
    }
  }
}

//需求分配
.shuttle-Box {
  width: 90%;
  // height: 100%;
  display: flex;

  >.header {
    height: 39px;
  }

  >.content {
    flex: 1;
    padding: 0 15px;
    display: flex;
    font-size: 12px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
  }
}

.shuttleBox-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  // height: 100%;

  .rootnet-select.rootnet-select.rootnet-select.rootnet-select {
    width: 40%;
  }

  .rootnet-input.rootnet-input.rootnet-input {
    width: 41.5%;
  }

  >span {
    font-size: 14px;
    color: #7A8199;
    padding-top: 5px;
  }

  .shuttleBox {
    display: flex;
    flex: 1;
    overflow: hidden;

    .demand {
      margin: 0;
      padding: 10px 5px;
      box-sizing: border-box;
      font-size: 14px;
    }

    .search-box {
      width: 100%;
      display: flex;
      border-top: 1px solid #E9EAF2;

      >span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .inputBox.inputBox.inputBox {
      >div {
        >div:first-child {
          border: none;
          background-color: transparent;
        }
      }

      flex: 1;
      margin: 0;

      >div {
        width: 100%;
        border: none;

        &:hover {
          border: none;
        }

        &:focus-within {
          border: none;
          box-shadow: none
        }

        input {
          width: 100%;
        }
      }
    }

    >div:nth-child(1),
    >div:nth-child(3) {
      width: 48%;
      border: 1px solid #D0D3E3;
      border-radius: 1%;
      display: flex;
      flex-direction: column;

      .x-datagrid {
        display: flex;
        flex-direction: column;

        .grid {
          flex: 1;
          overflow: auto;
        }

      }
    }

    >div:nth-child(2) {
      width: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >.select-arrow.select-arrow {
        background-color: #5477FF;

        >i {
          color: #FFFFFF;
        }
      }

      >.icon-arrow {
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid black;
        background-color: #E3E3E8;
        border-radius: 5px;
        box-sizing: border-box;
        margin: 40px 0;

        >i {
          font-size: 15px;
          color: #D0D1D9;
        }
      }
    }

  }
}

.projectManagement {
  .benefit-text {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-content.card-content.card-content.card-content {
    flex: 1;
    overflow: auto;

    // &::-webkit-scrollbar {
    //   width: 4px;
    // }
  }

  .subProject {
    display: flex;
    font-weight: bold;

    >span {
      width: 750px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &::before {
      display: block;
      content: "";
      width: 2px;
      height: 1em;
      background-color: #5477ff;
      box-shadow: 2px 0 3px 0 rgba(24, 144, 255, 0.42);
      margin-right: 12px;
      margin-top: 2px;
    }
  }
}

.project_tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cost-detail-dialog.cost-detail-dialog.cost-detail-dialog.cost-detail-dialog {

  .leftArrow {
    font-weight: 900;
    cursor: pointer;

    >i {
      display: inline-block;
    }
  }

  .isLeftArrow.isLeftArrow {
    font-weight: 400;
    cursor: auto;
  }

  >.content.content.content {
    padding: 16px 0 0 0;
  }

  .cost-dialog-content {
    width: 1300px;

    .up {
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      padding-bottom: 10px;
    }

    >.x-card {
      height: 450px;
    }
  }

  >.footer {
    display: none;
  }
}

.project-quick-query-pop {
  padding: 0;

  .quick-query-list-wrap {
    overflow: auto;

    .quick-query-item {
      height: 32px;
      padding: 0 8px;
      cursor: pointer;

      &:hover {
        background: #e6eef9;
        color: #5477ff;
      }
    }
  }
}