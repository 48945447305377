.imp-project-issue {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .x-card {
    margin: 0;
    // .row>.cell:hover .x-datagrid-tooltip>.enter-detail-field-wrap>.enter-detail-field-delete-icon {
    //   display: inline-block;
    // }

    .grid {
      .cell.cell.cell {
        padding: 0;

        .status-deal-type {
          display: flex;
          align-items: center;

          >span {
            margin-right: 8px;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }

        .rich-ellipsis-line {
          padding: 0 12px;
          width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .person-select {
          width: unset !important;

          .ant-select-selection-overflow {
            flex-wrap: unset;
          }
        }

        .rootnet-forminput {
          margin-left: 2px;
        }

        .common-display-field {
          padding: 0 12px;
        }

        .common-edit-field {
          padding: 0 12px;
          cursor: pointer;
          height: 34px;

          &:hover {
            background: #EBF0FB;
          }
        }

        .enter-detail-field-wrap {
          cursor: unset;

          .enter-detail-field {
            cursor: pointer;
            color: #5477ff;
          }

          .enter-detail-field-edit-icon,
          .enter-detail-field-delete-icon {
            display: none;
            margin-left: 10px;
            cursor: pointer;
            font-size: 15px;

            &:hover {
              color: #5477ff;
            }
          }

          &:hover {

            .enter-detail-field-edit-icon,
            .enter-detail-field-delete-icon {
              display: block;
            }
          }
        }

        .arrow-drop-down-group {

          .common-display-field,
          .common-edit-field {
            padding-left: 4px;
            padding-right: 12px;
          }
        }
      }
    }
  }

  .work-flow-status-field {
    cursor: pointer;
    margin-left: 8px;
  }
}

.issue-rich-text-val {
  img {
    display: none;
    // width: 100px;
    // height: 80px;
  }
}