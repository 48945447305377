.customer-contact-mgt {
  .grid.grid.grid {
    .cell {
      padding: 0;

      .common-display-field {
        padding: 0 12px;
      }

      .common-edit-field {
        padding: 0 12px;
      }

      .enter-detail-field-wrap {
        cursor: unset;
        display: flex;
        width: 100%;
        height: 22px;

        .icon {
          cursor: pointer;
          display: none;

          &:hover {
            color: red;
          }
        }

        &:hover .icon {
          display: inline-block !important;
        }

        .enter-detail-field {
          width: 92%;
          line-height: 22px;
          cursor: pointer;
          color: #5477ff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          &:hover {
            text-decoration: underline
          }
        }

        .enter-detail-field-edit-icon {
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;

          &:hover {
            color: #5477ff;
          }
        }

        &:hover {
          .enter-detail-field-edit-icon {
            display: block;
          }
        }
      }
    }
  }
}

.customer-contact-del-item {
  .ant-popover-inner-content {
    padding: 8px;
  }
}