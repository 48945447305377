.conference-records-add-dialog {
  .content.content.content.content {
    height: 75vh;
    width: 960px;
    padding: 0;
    font-size: 12px;
    display: flex;

    .conference-records-add {
      flex: 1;
      width: 100%;
      .mock-dialog-header {
        display: flex;
        align-items: center;
        color: #15171f;
        padding: 12px 16px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #e9eaf2;
        justify-content: space-between;

        .dialog-title {
          font-weight: 700;
        }

        .mock-right-header {
          .header-edit-icon {
            font-size: 18px;
            cursor: pointer;

            &.edit-active-icon {
              color: #5477ff;
            }

            &:hover {
              color: #5477ff;
            }
          }
        }

        .close-area {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          cursor: pointer;
          margin-left: 20px;

          .close-icon {
            font-size: 14px;
          }

          &:hover {
            background-color: #ECECEC;
          }
        }
      }

      .mock-detail-main-panel {
        flex: 1;
        padding: 0 16px 8px 16px;
        overflow: auto;
      }
    }

    .rootnet-forminput {
      font-size: 12px;

      >label {
        font-size: 12px;
      }
    }

    .ant-table.ant-table-small {
      font-size: 12px;
    }
  }

  .area-wrap {
    margin-right: 10px;

    .area-header {
      margin: 12px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .desc-wrap {
    .rich-text-area {
      margin-left: 40px;
      //height: 300px;
    }
  }

  .doc-area {
    margin-left: 40px;
  }


  .add-todo-list-text {
    margin: 4px;
    color: #5477ff;
    cursor: pointer;
    display: inline-block;
  }

  .textarea-error.textarea-error.textarea-error.textarea-error {
    border: 1px solid #F5221B;
    box-shadow: none;
  }
}