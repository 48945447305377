.customer-service-info-mgt{
  .grid.grid.grid{
    .cell{
      .enter-detail-field-wrap{
        cursor: unset;
        width: 100%;
        &:hover .concerned-icon{
          display: inline-block !important;
        }
        .enter-detail-field{
          width: 100px;
          flex: 1;
          .enter-detail-field-text{
            width: 92%;
            line-height: 22px;
            cursor: pointer;
            color: #5477ff;
            overflow: hidden;  //隐藏多余行数
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
            -webkit-box-orient: vertical;  //规定为垂直方向排列
            &:hover{
              text-decoration:underline
            }
          }
        }
        .enter-detail-field-edit-icon{
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;
          &:hover{
            color: #5477ff;
          }
        }
        &:hover{
          .enter-detail-field-edit-icon{
            display: block;
          }
        }
      }
    }
  }
  .work-flow-status-field{
    cursor: pointer;
  }
}