.plan-version-detail {
  .plan-version-tabs {
    flex: 1;
    overflow: hidden;
    margin: 8px 12px;
    background-color: white;

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      height: 100%;
      padding: 6px;
      padding-top: 0;
    }

    .ant-tabs-content,
    .ant-tabs-content {
      height: 100%;


      .ant-tabs-tabpane {
        height: 100%;

        &.ant-tabs-tabpane-hidden {
          display: none;
        }
      }
    }

    .tab-content {
      height: 100%;
    }
  }
}