.tooltip{
  position:relative;
  &:hover{
    .tooltip-details{
      display: block;
    }
  }
  &-details{
    display: none;
    position: absolute;
    left: 0;
    top:24px;
    padding: 6px;
    line-height: 24px;
    z-index: 8;
    background: rgba(0, 0, 0, 0.7);
    color: rgba(255, 255, 255, 0.8);
    box-shadow: 3px 3px 7px rgba(124, 124, 124, .7);
    border-radius: 3px;
    max-width:240px;
    white-space: pre-wrap;
  }
}