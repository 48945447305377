.task-list-making {
  height: 100%;
  overflow: hidden;
  background-color: #f0f3f7;

  .right-data {
    margin-left: 8px;
    flex: 1;
    display: flex;
    overflow: hidden;

    .x-card {
      margin: 0;

      .card-header {
        .right {
          flex: 1;

          .extra-operation {
            flex: 1;
            justify-content: space-between;

            .extra-left {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}