.workplace-dynamic{
  .x-card{
    height: 100%;
    margin-left: 0;
  }
  .card-content{
    width: 420px;
    height: 100%;
  }
  .content-wrap{
    width: 100%;
    height: 100%;
  }
  .x-pagination{
    justify-content: end;
    padding-right: 8px;
  }
  .message-wrap{
    width: 100%;
    height: 10px;
    flex: 1;
    padding: 12px;
    overflow-y: auto;
    .ant-timeline{
      margin-left: 100px;
      .time-content-wrap{
        .ant-timeline-item{
          padding-bottom: 30px;
        }
        .time-wrap{
          width: 90px;
          position: absolute;
          left: -122px;
          align-items: flex-end;
          .day{
            background-color: #6F8CFF;
            color: #fff;
            padding: 0 8px;
            height: 24px;
            line-height: 24px;
            border-radius: 12px;
          }
          .time{
            color: #6F8CFF;
            font-weight: bold;
            margin-right: 4px;
          }
        }

        .time-content-wrap{
          font-size: 12px;
          &.no-read{
            font-weight: bold;
          }
          &.no-url{
            font-weight: normal;
          }
          &.has-url:hover{
            cursor: pointer;
            color: #5477ff;
            text-decoration: underline;
          }
          .time-content{
            white-space:pre-wrap;
          }
        }
      }
    }
  }
}