.work-time-review-tabs {
  height: 100%;

  .ant-tabs-nav {
    background: white;
    padding-left: 18px;
    margin: 0;
  }

  .ant-tabs-content {
    height: 100%;
  }
}