.review-minutes-detail-content {

  padding: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-color: #fff;

  .mock-dialog-header {
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;

    .dialog-title {
      font-weight: 700;
    }

    .mock-right-header {
      .header-edit-icon {
        font-size: 18px;
        cursor: pointer;

        &.edit-active-icon {
          color: #5477ff;
        }

        &:hover {
          color: #5477ff;
        }
      }
    }

    .close-area {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 20px;

      .close-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: #ECECEC;
      }
    }
  }

  .file-wrap {
    .reviewLibrary {
      width: calc(100% - 30px);
      display: flex;
      overflow: hidden;

      .form-component {
        flex: 1 !important;
      }
    }
  }

  .ant-tabs {
    height: 100%;

    .ant-tabs-nav {
      padding-left: 10px;
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      display: flex;
      flex: 1;
      height: 100px;
      border-bottom: 1px solid #D0D3E3;
      overflow: hidden;
      padding: 6px;
    }

    .ant-tabs-content {
      flex: 1;
      overflow: auto;
      display: flex;

      .ant-tabs-tabpane {
        width: 100px;
        flex: 1;
      }
    }

    .tab-content {
      height: 100%;
    }
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;

    .footer-switch {
      .footer-switch-item {
        cursor: pointer;
        padding: 4px 12px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        margin-right: 12px;
        color: #fff;
        background-color: #5477ff;
        transition: background-color 0.4s;

        &:hover {
          background-color: #214eff;
        }

        &.disabled {
          color: #6A6A6A;
          background-color: #E8E8E8;
          cursor: not-allowed;

          &:hover {
            background-color: #E8E8E8;
          }
        }

        >i {
          font-size: 14px;
        }


      }

      .list-num {
        font-weight: bold;
      }

      .comment-wrap {
        width: 30px;
        height: 30px;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #6A6A6A;

        &:hover {
          color: #1890ff;
          border: 1px solid #1890ff;
        }

        .comment-icon {
          font-size: 18px;
        }
      }
    }

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

  .mock-detail-main-panel {
    height: 100px;
    padding: 8px 12px;
    flex: 1;
  }

  .mock-detail-left-panel {
    width: 100px;
    flex: 1;
    border-top: 1px solid #d0d3e3;
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput {

    .form-component,
    >span {
      margin-left: 6px;
    }
  }

  .ant-tabs-tab.ant-tabs-tab.ant-tabs-tab.ant-tabs-tab {
    &.ant-tabs-tab-active {
      .ant-badge {
        color: #1890ff;
      }

      .ant-badge-count {
        background-color: #EFF4FF !important;

        .ant-scroll-number-only {
          color: #6698FF;
        }
      }
    }
  }
}