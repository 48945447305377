.resource-select-req{
  .content.content.content.content{
    width: 900px;
    height: 70vh;
    padding-top: 0;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    .c-options{
      .button{
        margin-left: 8px;
      }
    }
    .x-datagrid{
      flex: 1;
      height: 200px;
      font-size: 12px;
    }
    .x-pagination{
      justify-content: end;
    }
  }
}