.req-stage-relate-conference{
  height: 100%;
  width: 100%;
  .x-datagrid{
    flex: 1;
    height: 100px;
    font-size: 12px;
    .disassociate-icon{
      color: #3f7dff;
      cursor: pointer;
    }
  }
  .relate-conference-icon-wrap{
    flex-direction: row-reverse;
    margin: 4px 6px;
    color: #5477ff;
  }
  .name-wrap{
    color: #3f7dff;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}