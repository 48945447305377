$primaryColor: #5477FF;
div.x-card.x-card-singlegrid.work-time-detail-memo{
    margin-left: 0;
    width: 288px;
    flex: initial;
    .card-content{
        .rootnet-textarea{
            width: calc(100% - 20px);
            box-sizing: border-box;
            height: 300px;
        }
    }
}