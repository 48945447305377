.customer-service-base-info.customer-service-base-info.customer-service-base-info {
  height: 100%;
  width: 100%;

  .area-wrap {
    .area-header {
      margin: 8px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .customer-question-number-form {
    .customer-question-number {

      .operation-all {
        margin-left: 16px;

        .feedback-message {
          cursor: pointer;
          font-size: 12px;
          padding: 0 4px;
          border-radius: 4px;
          color: #3AA3FD;
          border: 1px solid #3AA3FD;
          margin-right: 8px;
        }

        .acceptance-result {
          cursor: pointer;
          font-size: 12px;
          padding: 0 4px;
          border-radius: 4px;
          color: #FFB65D;
          border: 1px solid #FFB65D;
        }
      }
    }
  }

  .groups-people-edit {
    .user-select {
      max-height: 60px;
      overflow: auto;
    }
  }

  .groups-people-detail.groups-people-detail.groups-people-detail {
    align-items: flex-start;
    overflow: hidden;

    .form-component {
      min-height: auto;
      max-height: 60px;
      height: auto;
      overflow: auto;
      align-items: stretch;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 4px 8px;
  }

  .add-relation-icon {
    margin-left: 44px;
    margin-bottom: 4px;
    height: 26px;
    display: inline-block;
    color: #738299;

    &:hover {
      color: #5477ff;
    }
  }

  .relation-info {
    height: 26px;
    margin-left: 40px;
    margin-bottom: 4px;

    .type-tag {
      width: 40px;
      border-radius: 4px;
      margin-right: 4px;
      color: white;
      padding: 0 2px;
      box-sizing: border-box;
      display: inline-block;
      transform: scale(0.7);
      text-align: center;

      &.issue {
        background-color: #FF5454;
      }

      &.req {
        background-color: #5477FF;
      }
    }

    .relation-id-wrap {
      min-width: 160px;
    }

    .relation-id {
      display: inline-block;
      color: #5477ff;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .relation-icon {
      .handle-icon {
        cursor: pointer;
        font-size: 16px;
        margin-right: 10px;

        &.edit-icon {
          &:hover {
            color: #5477ff;
          }
        }

        &.del-icon {
          &:hover {
            color: red;
          }
        }
      }
    }

    .relation-status {
      font-size: 12px;
      padding: 0 4px;
      border-radius: 4px;
    }

    .relation-id-wrap,
    .relation-status {
      margin-right: 16px;
    }

    .relation-version {
      max-width: 500px;
      white-space: nowrap;
      /*不允许换行*/
      overflow: hidden;
      /*超出隐藏*/
      text-overflow: ellipsis;
      /*文本超出三点代替*/
    }
  }

  .desc-wrap {
    padding-bottom: 10px;
    padding-right: 10px;

    .rich-text-area {
      margin-left: 40px;
      height: 400px;
    }
  }

  .rich-text-detail-wrap {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px 30px;
    white-space: pre-wrap;
    line-height: normal;

    img {
      max-width: 100%;
    }
  }
}

.relation-version-pop-wrap {
  .relation-version-pop {
    padding: 6px;
    color: #fff;

    .req-info {
      margin: 0 10px;
    }
  }

  .ant-popover-arrow-content::before {
    background: #000000bb;
  }
}