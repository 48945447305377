.dev-project-del-version-item {
  .header-style {
    >span {
      font-size: 12px;
      color: #BBBBBB;
    }
  }

  .text-area-input-edit.text-area-input-edit.text-area-input-edit {
    align-items: flex-start;
  }
}