.system-mgt-detail-common.system-mgt-detail-common.system-mgt-detail-common{
  min-width: 1000px;
  .back-area{
    .all-go-back{
      margin-right: 30px;
    }
    .breadcrumb{
      font-weight: bold;
      .breadcrumb-item-text{
        cursor: pointer;
        &:hover{
          color: #5477FF;
        }
      }
    }
  }
  .desc-card{
    margin-top: 0;
    .card-header>.left::before {
      display: block;
      content: "";
      width: 2px;
      height: 1em;
      background-color: #5477ff;
      box-shadow: 2px 0 3px 0 rgba(24,144,255,0.42);
      margin-right: 12px;
    }
    .desc-left{
      .desc-title{
        font-weight: bold;
      }
      .desc-directions{
        margin-left: 50px;
        color: #7A8199;
        font-size: 12px;
        line-height: 40px;
        width: 300px;
        white-space: nowrap;/*不允许换行*/
        overflow: hidden;/*超出隐藏*/
        text-overflow: ellipsis;/*文本超出三点代替*/
      }
    }
    .desc-extra{
      .all-textIconBtn{
        margin-left: 15px;
        .icon{
          margin-top: 2px;
        }
      }
    }
  }
  .desc-content{
    justify-content: space-around;
    padding: 20px;
  }
  .card-group{
    flex-wrap: wrap;
    padding: 0 6px 8px;
    font-size: 12px;

    .empty-item{
      height: 0;
      margin: 6px;
      flex: 1;
      min-width: 394px;
    }
    .card-item{
      flex: 1;
      justify-content: space-between;
      min-width: 394px;
      height: 214px;
      margin: 0 6px 12px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 4px 20px 0 rgba(188,182,204,0.20);
      &.module-card-item{
        height: 168px;
        .bottom-text{
          justify-content: space-around;
          cursor: default;
          &:hover{
            color: #3E4046;
          }
          .bottom-text-item{
            cursor: pointer;
            &:hover{
              color: #5477FF;
            }
          }
        }
      }
      .card-header{
        height: 36px;
        border-bottom: 1px solid #E9EAF2;
        justify-content: space-between;
        padding: 0 16px;
        margin-bottom: 5px;
        .card-title{
          font-weight: bold;
        }
        .tag{
          color: #5477FF;
          background: rgba(84,119,255,0.12);;
          border: 1px solid #5477FF;
          border-radius: 2px;
          padding:2px 5px;
          margin-left: 8px;
        }
      }
      .h-form-input {
        margin: 0;
        label {
          font-size: 12px;
        }
        .h-form-input-component {
          line-height: 28px;
          height: 28px;
        }
      }
      .bottom-text{
        color: #3E4046;
        font-size: 14px;
        cursor: pointer;
        margin-top: 6px;
        height: 36px;
        border-top: 1px solid #E9EAF2;
        &:hover{
          color: #5477FF;
        }
      }
    }
  }
  //.remark{
  //  height: 64px;
  //  line-height: 28px;
  //  .label{
  //    width: 90px;
  //    color: #738299;
  //    text-align: right;
  //    &::after {
  //      content: ":";
  //      margin: 0px 8px 0px 2px;
  //    }
  //  }
  //  .show-text{
  //    margin-top: 4px;
  //    width: 290px;
  //    line-height: 20px;
  //    overflow: hidden;  //隐藏多余行数
  //    text-overflow: ellipsis;
  //    display: -webkit-box;
  //    -webkit-line-clamp: 2; //规定显示几行，其余行数用小数点代替
  //    -webkit-box-orient: vertical;  //规定为垂直方向排列
  //  }
  //}
}