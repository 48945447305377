.workplace-version.workplace-version.workplace-version{
  .x-card{
    height: 100px;
    margin-left: 0;
  }
  .card-content{
    width: 420px;
    height: 100%;
  }
  .version-wrap{
    height: 100%;
    width: 100%;
    .version-item{
      padding: 12px;
      justify-content: space-between;
      border-bottom: 1px solid #e1e1e1;
      cursor: pointer;
      &:hover{
        background-color: #edf3f9;
      }
      .version-info{
        color: #777777;
        //font-size: 12px;
        .version-info-first-row{
          margin-bottom: 6px;
          .version-id{
            margin-right: 12px;
          }
        }
      }
      .countdown-wrap{
        .countdown-text{
          color: #333333;
          //margin-bottom: 4px;
        }
        .countdown-day{
          font-size: 18px;
          font-weight: bold;
          &.warning{
            color: #FF6900;
          }
          &.danger{
            color: #F31111;
          }
        }
      }
    }
  }
  .handle-icon{
    font-size: 18px;
    margin-left: 24px;
  }
}