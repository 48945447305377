.req-analysis-wrap {
  .total-field {
    color: #13151A;
    font-weight: bold;
  }

  .overdue-num {
    color: red;
  }

  .options-component {
    .user-select {
      height: 32px;
      overflow-y: auto;
    }
  }

  .c-options.c-options .x.button {
    margin-left: 0;
  }

  .reset-btn {
    border: 1px solid #7A8199;
  }
}