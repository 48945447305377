.customize-options{
  .c-options{
    flex-wrap: wrap;
    margin-bottom: 0;

    .single-form-input{
      height: 60px;
      position: relative;
      .close-form-input-icon{
        display: none;
        width: 18px;
        height: 18px;
        color: #fff;
        line-height: 20px;
        text-align: center;
        background: #999999;
        position: absolute;
        right: 8px;
        top: 2px;
        cursor: pointer;
        font-size: 10px;
        border-radius: 50%;
        transform: scale(0.8);
        &:hover{
          background: #F5221B;
        }
      }
      &:hover{
        .close-form-input-icon{
          display: block;
        }
      }
    }
    >.rootnet-forminput{
      margin-bottom: 8px;
    }
    .search-btn-group{
      margin-bottom: 4px;
      .button{
        margin: 6px 8px 0;
      }
      .reset-btn{
        border: 1px solid #7A8199
      }
    }
    .add-options-icon{
      font-size: 20px;
      margin: 0 10px 4px;
      cursor: pointer;
      color: #15171f;
      &:hover{
        color: #3f7dff;
      }
    }
  }
}
.add-condition-pop{
  .ant-popover-title{
    padding: 4px 8px;
  }
  .ant-popover-inner-content{
    padding: 4px 8px;
  }
  .add-condition-list-wrap{
    max-height: 260px;
    overflow: auto;
    .add-condition-list-item{
      height: 32px;
      padding: 0 8px;
      cursor: pointer;
      &:hover{
        background:#e6eef9;
        color: #5477ff;
      }
    }
  }
}