.create-view-list{
  .options.options.options.options{
    overflow: hidden;
    transition: height 0.3s;
    .btn {
      width: 75px;
      margin-left: 15px;
      height: 31px;
    }
    .reset-btn {
      color: #3A415C;
      border: 1px solid #7A8199;
    }
    .expand-icon{
      font-size: 16px;
      margin: 0 30px 6px 10px;
      cursor: pointer;
      transition: transform 0.3s;
    }
  }
  .no-margin-top{
    margin-top: 0;
  }
  .del-operation-group{
    .del-operation-btn{
      display: none;
    }
    &:hover{
      .added-operation-btn{
        display: none;
      }
      .del-operation-btn{
        display: unset;
      }
    }
  }
  .id-col{
    cursor: pointer;
  }
  .arrow_drop_down{
    margin-right: 5px;
    display: inline-block;
  }
}