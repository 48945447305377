// options 样式
div.x.rims_options {
  display: flex;
  padding: 4px 0 0 4px;
  align-items: center;
  font-size: 14px;

  .x.button {
    margin-right: 12px;
    margin-top: 19px;
  }
}

//ylf
//公用表格样式
.x-datagrid.x-datagrid.x-datagrid {
  &.no-hidden {
    .cell {
      overflow: visible;
    }
  }

  &.no-header-bg {
    .header {
      background-color: transparent;
    }
  }

  &.row-fill {
    .row {
      width: 100%;
    }
  }

  &.no-border {
    .header {
      border: none;

      .top {
        border: none;
      }
    }

    .row {
      border: none;
    }

    .cell {
      border: none;
    }
  }

  //lpf
  &.choice {
    .deep {
      background-color: #EDF1FF;
    }
  }
}

//lpf
div.x-card {
  &.x-card-singlegrid {
    flex: 1;
    margin-top: 0px;
    color: #13151A;

    .card-content {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .x-datagrid {
        flex: 1;
        height: 100px;
        border-top: none;
        font-size: 12px;
      }

      .x-pagination {
        flex: none;
        padding-right: 20px;
        justify-content: flex-end;
        border-top: 1px solid #e6e6e6;
      }
    }
  }

}

.table-fill,
.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  .x-card {
    flex: 1;
    overflow: auto;

    .card-content {
      display: flex;

      .x-datagrid {
        flex: 1;
      }
    }
  }
}


.rootnet-forminput.horizontal.reason {
  align-items: flex-start;

  >label {
    margin-top: 7px;
  }

  >div {
    height: 92px;
  }

  &.error {
    &:hover {
      >.rootnet-textarea {
        box-shadow: 0 0 4px 0 #F5221B;
      }
    }

    &:focus-within {
      >.rootnet-textarea {
        border: 1px solid #5477FF;
        box-shadow: 0 0 4px 0 #5477FF;
      }
    }

    >.rootnet-textarea {
      border: 1px solid #F5221B;
    }
  }
}


.linkStyle {
  color: #5477FF;
  text-decoration: none;
  cursor: pointer;
}

//树型表格-杨吕锋
.treeTable {
  .row.row.row.row {
    &-deep {
      background: #B8C7DF;
    }

    &-dark {
      background: #CDD7E9;
    }

    &-gray {
      background: #E7EEFA;
    }

    &-light {
      background: #F8FAFD;
    }
  }

  .sort-header {
    white-space: nowrap;

    .iconfont:before {
      display: inline-block;
      transform: rotate(-90deg);
    }

    &.expand {
      .iconfont:before {
        transform: none;
      }
    }
  }
}

.content-center-dialog {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

p {
  margin-bottom: 8px;
  line-height: normal;
}

.init-style-display {

  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
    color: #000000D9;
    font: inherit;
    vertical-align: baseline;
  }

  img {
    display: none;
  }
}