.associated-tracking-list {

  &.x-card-height.x-card-height.x-card-height {
    flex: 0;
  }

  .card-header {
    .right {
      .ant-input {
        width: 200px;
        border-radius: 4px 0 0 4px;
      }

      .ant-btn {
        border-radius: 0 4px 4px 0;

        .icon {
          font-size: 14px;
        }
      }
    }
  }

  .cancel-relation {
    cursor: pointer;
    color: #5477FF;
  }
}