.role-list {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .view-area-wrap {
    margin-right: 0;
  }

  .x-card {
    margin-right: 0;
  }

  .x-datagrid {
    .grid.grid.grid {
      .cell {
        padding: 0;
      }
    }

    .common-display-field {
      padding: 0 12px;
    }

    .more-handle-icon.more-handle-icon.more-handle-icon {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;

      &:hover {
        color: #3f7dff;
      }
    }

    .left-enter-field-wrap {
      width: 100px;
      flex: 1;
    }

    .enter-detail-field-wrap {
      cursor: unset;
      width: 100%;

      .enter-detail-field {
        width: 100%;
        flex: 1;

        .enter-detail-field-text {
          max-width: 100%;
          cursor: pointer;
          color: #5477ff;
          overflow: hidden; //隐藏多余行数
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
          -webkit-box-orient: vertical; //规定为垂直方向排列

          &:hover {
            text-decoration: underline
          }
        }
      }

      .enter-detail-field-edit-icon {
        display: none;
        margin-left: 10px;
        cursor: pointer;
        font-size: 15px;

        &:hover {
          color: #5477ff;
        }
      }

      &:hover {
        .enter-detail-field-edit-icon {
          display: block;
        }
      }
    }
  }
}

.role-handle-popover {
  .role-handle-item {
    padding: 6px 12px;
    min-width: 150px;


    &:hover {
      cursor: pointer;
      background: #efefef;
      color: #3f7dff;
    }

    .handle-icon.handle-icon {
      font-size: 14px;
      margin-right: 6px;
    }
  }
}