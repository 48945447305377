body {
    overflow: hidden;
    font-size: 14px;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    margin: 0px;
}
html{
    font-size: 14px;
}
p{
    font-size: 14px;
}
@media screen{
    html{
        font-size: 14px;
    }
}
div#root {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    font-size: 14px;
}

div.fill {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

div.flex {
    display: flex;
    flex-direction: row;

    &.center {
        align-items: center;
        justify-content: center;
    }

    &.center-y {
        align-items: center;
    }

    &.center-x {
        justify-content: center;
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    &>div {
        position: relative;
        flex: none;
    }

    &>div.stretch {
        position: relative;
        flex: 1 1 100%;
    }
}

div.flex-y {
    display: flex;
    flex-direction: column;

    &.center {
        align-items: center;
        justify-content: center;
    }

    &.center-x {
        align-items: center;
    }

    &.center-y {
        justify-content: center;
    }

    &.reverse {
        flex-direction: column-reverse;
    }

    &>div {
        position: relative;
        flex: none;
    }

    &>div.stretch {
        position: relative;
        flex: 1 1 100%;
    }
}

div.scroll {
    overflow: auto;
}

div.scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
}

div.scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}

//去掉列表右侧线
.no_vertical_line {
    div.x.virtual-table.grid {

        >div.column-header,
        div.viewport div.data-row {
            div.cell {
                border-right: none;
            }
        }

        >div.column-header .columns,
        div.viewport .data-row {
            >:first-child {
                padding-left: 16px;
            }

            >:last-child {
                padding-right: 16px;
            }
        }
    }
}

.left-menu {
    i.icon.iconfont {
        font-size: 14px;
    }
}

div.headerReference {
    display: flex;
    width: calc(100% - 25px);
    justify-content: space-between;
    align-items: center;

    >span {
        font-weight: 600;
    }
}

// table表格第一列 和 最后一列间距
.no_across_line {
    div.x.virtual-table.grid {

        >div.column-header,
        div.viewport div.data-row {
            div.cell {
                border-right: none;
            }
        }

        >div.column-header .columns,
        div.viewport .data-row {
            >:first-child {
                padding-left: 16px;
            }

            >:last-child {
                padding-right: 16px;
            }
        }
    }
}

// forInput 字体大小  和  small 小输入框 mw
div.x-forminput {
    .input-label {
        font-size: 12px;
    }

    &.small {
        width: 120px;

        .x.date-picker {
            width: 100%;
        }

        .x.select {
            width: 100%;

        }

        .x.text-input {
            width: 100%;
        }
    }
}

/*控制整个滚动条*/
::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px;
    background-clip: padding-box;
}

costrate

/*滚动条两端方向按钮*/
::-webkit-scrollbar-button {
    background-color: transparent;
    width: 0;
    height: 0;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
    background-color: rgba(170, 170, 170, 0.7);
    border-radius: 5px;
}

/*滚动条右下角区域*/
::-webkit-scrollbar-corner {
    background-color: transparent;
    width: 0;
    height: 0;
}

//设置icon公用样式-ylf
.icon {
    &.icon-bg {
        &.warn {
            border-radius: 50%;
            padding: 2px;
            line-height: 16px;

            &.high-risk {
                color: #FF3B30;
                background: rgba(255, 59, 48, 0.4);
            }

            &.middle-risk {
                color: #FF7C54;
                background: rgba(255, 124, 84, 0.4);
            }

            &.low-risk {
                color: #FFB354;
                background: rgba(255, 179, 84, 0.4);
            }

            &.normal {
                color: #5477FF;
                background: rgba(84, 119, 255, 0.4);
            }
        }
    }
}

/* Dialog 左下角提示*/
.dialog-tips {
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    color: #7A8199;
}

/* 解决低版本浏览器表格设置fixedLeft时的错位问题 */
.is-wechat {
    .row>.cell {
        left: 0 !important;
    }
}

.tox-fullscreen.tox-fullscreen.tox-fullscreen {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0;
    bottom: 0;
    margin: auto;

    .tox-toolbar__primary {
        display: flex;

        >div:last-child {
            flex: 1;
            justify-content: flex-end;
        }
    }
}

.select_row.select_row.select_row.select_row.select_row.select_row {
    background-color: #edf3f9;
}

.ant-popover.ant-popover.ant-popover.ant-popover.ant-popover {
    z-index: 6;
}

.dialog.x.x.x.x.x {
    z-index: 5;
}

.x-drawer-container.x-drawer-container.x-drawer-container.x-drawer-container {
    z-index: 4;
}