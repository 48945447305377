.tracking-detail-right-panel {
  width: 350px;
  flex: 1 1;
  overflow: auto;
  border: 1px solid #d0d3e3;
  border-radius: 4px;
  margin-left: 12px;
  padding: 4px 0;
  font-size: 12px;

  .area-wrap {
    .area-header {
      margin: 4px 0;
      font-size: 14px;
      font-weight: 900;
    }

    .area-content-wrap.area-content-wrap.area-content-wrap {
      overflow: hidden;

      label {
        font-size: 12px;
      }
    }
  }

  .base-info-show-more {
    margin: 2px 46px 8px;

    .base-info-show-more-text {
      cursor: pointer;
      color: #5477ff;
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 15px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 2px 8px;
  }

  .long-options {
    .rootnet-popup {
      .container {
        .option {
          min-width: 300px;
        }
      }
    }
  }

  .ellipsis-line {
    width: 180px;
    white-space: nowrap;
    /*不允许换行*/
    overflow: hidden;
    /*超出隐藏*/
    text-overflow: ellipsis;
    /*文本超出三点代替*/
  }
}