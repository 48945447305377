.conference-records-wrap{
  .rich-text-value-in-table{
    img {
      display: none;
    }
  }
  .grid.grid.grid{
    .cell{
      padding: 0;
      .person-select{
        width: unset !important;
        .ant-select-selection-overflow{
          flex-wrap: unset;
        }
      }
      .rootnet-forminput{
        margin-left: 2px;
      }
      .common-display-field{
        padding: 0 12px;
      }
      .common-edit-field{
        padding: 0 12px;
        cursor: pointer;
      }
      .enter-detail-field-wrap{
        cursor: unset;
        .enter-detail-field{
          cursor: pointer;
          color: #5477ff;
          &:hover{
            text-decoration:underline
          }
        }
        .enter-detail-field-edit-icon{
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;
          &:hover{
            color: #5477ff;
          }
        }
        &:hover{
          .enter-detail-field-edit-icon{
            display: block;
          }
        }
      }
      .arrow-drop-down-group{
        .common-display-field, .common-edit-field{
          padding-left: 4px;
          padding-right: 12px;
        }
      }
    }
  }
}

.conference-export-wrap{
  padding: 4px 8px;
  .conference-export-item{
    height: 30px;
    padding: 12px 8px;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.075);
    }
  }
}