.board-task-info-dialog {
  display: flex;
  flex-direction: column;
  width: 750px;
  height: 70%;

  >.content.content.content.content {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .board-content {
      overflow: auto;

      &.detail-mode {
        .rootnet-forminput {
          align-items: flex-start;

          label {
            height: 32px;
            line-height: 32px;
          }
        }

        .form-component {
          height: unset;
          min-height: 32px;
          line-height: 32px;
        }
      }

      .rich-text-form-item {
        margin: 8px;

        .mock-label {
          margin-top: 6px;
          width: 120px;
          text-align: right;
          color: #738299;

          &::after {
            content: ":";
            margin: 0 8px 0 2px;
          }
        }

        .rich-text-area {
          height: 250px;
          width: 536px;
        }
      }

      .rich-text-value-show-wrap {
        margin-top: 6px;
        max-width: 536px;
        line-height: 32px;
      }
    }


    .footer {
      display: flex;
      justify-content: flex-end;
      padding: 8px 0;
      border-top: 1px solid #E9EAF2;

      .button {
        height: 32px;
        margin-right: 16px;
      }
    }
  }
}