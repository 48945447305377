.estimated-total-work-volume {

  .ant-drawer-content-wrapper {
    width: 80% !important;

    .art-table-wrapper {
      font-size: 12px;

      .art-table-header {
        .art-table-header-cell {
          padding: 0px;

          &:nth-child(1) {
            padding-left: 12px;
          }

          .model-icon {
            height: 100%;
          }
        }
      }

      .art-table-cell {
        padding: 0;
      }

      .art-table-cell.lock-left.first:nth-child(1) {
        padding-left: 12px;
      }
    }

    .convert-edit-field-style {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .edit-icon {
        position: absolute;
        right: 16px;
        top: 9px;
        display: none;
        color: #5477FF;
      }

      &:hover .edit-icon {
        display: inline-block;
      }
    }

    .rootnet-input {
      width: 95%;
    }
  }

  .summary-bold {
    font-weight: 900;
  }
}