.customer-service-info-add-dialog {
  width: 1024px;

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 4px 8px;
  }

  >.content.content.content.content {
    padding: 0;

    .content-wrap {
      height: 80vh;
      flex: 1;
      overflow: scroll;
      padding: 8px 12px;
    }

    .groups-people-edit {
      .user-select {
        max-height: 60px;
        overflow: auto;
      }
    }

    .mock-footer {
      display: flex;
      align-items: center;
      padding: 8px 0;
      height: 48px;
      box-sizing: border-box;
      border-top: 1px solid #E9EAF2;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;

      .continue-add-check {
        margin-left: 20px;
      }

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }

  .area-wrap {
    .area-header {
      margin: 12px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .desc-wrap {
    .rich-text-area {
      margin-left: 40px;
      height: 300px;
    }
  }

  .doc-area {
    margin-left: 40px;
  }

  .fold-icon {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .add-contact-icon {
    cursor: pointer;
    color: #5477FF;

    &.disabled {
      cursor: not-allowed;
      color: #CFD3E3;
    }
  }
}