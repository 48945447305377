.create-view.create-view.create-view {

  .type-radio-group{
    height: 56px;
    margin-left: 5px;
  }
  .type-selection {
    margin: 12px;
  }

  .card-group {
    flex: 1;
    .left-card-wrap {
      width: 500px;
      flex-grow: 1;
      .version-card-group{
        height: 100%;
      }
      .left-card {
        height: 200px;
        flex: 1;
        &.narrow-card{
          height:41px;
          flex: none;
        }
        .x-datagrid{
          overflow: hidden;
          .grid{
            //flex: unset;
          }
        }
      }
    }
    .member-info-card{
      min-width: 102px;
      padding-bottom: 12px;
    }

    .right-card {
      height: 100%;
      transition: width 0.5s;
    }

    .right-card-wrap {
      height: 100%;
      justify-content: space-between;

      .form-wrap {
        min-width: 300px;
        margin: 50px 10px 20px;

        .form-save-btn {
          width: 85px;
          letter-spacing: 2px;
          margin-top: 10px;
          margin-left: 95px;
        }
      }

      .fold-area {
        width: 20px;
        margin-right: 20px;

        .fold-icon {
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
  .member-form-item{
    height: 32px;
    margin: 8px;
    line-height: 32px;
    .label{
      width: 90px;
      color: #738299;
      text-align: right;
    }
    .member-form {
      border: 1px solid #D0D3E3;
      border-radius: 4px;
      height: 32px;
      width: 180px;
      padding-left: 8px;
      color: #15171F;
      box-sizing: border-box;
      line-height: 32px;
      cursor: pointer;
    }
  }

}

.selected-member-list {
  margin-top: 15px;

  .label {
    color: #7A8199;
    margin-bottom: 5px;
  }

  .list {
    width: 280px;
    height: 270px;
    overflow: scroll;
    box-sizing: border-box;
    border: 1px solid #D0D3E3;
    border-radius: 4px;
    padding-left: 5px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #CACBCDdf;
    }
    .selected-item{
      justify-content: space-between;
      height: 36px;
      line-height: 36px;
      padding:0 16px;
      border-bottom: 1px solid #CFD3E3;
      &:hover{
        background-color: #F2F6FA;
      }
      .username{
        color: #15171F;
      }
      .del-icon{
        font-size: 16px;
        cursor: pointer;
        &:hover{
          color: #5477ff;
        }
      }
    }
  }
}