.sales-catalogue {
  width: 100%;
  height: 100%;

  .x-card {
    margin: 0;
    box-shadow: none;

    .enter-detail-field-text {
      cursor: pointer;
      color: #5477ff;

      &:hover {
        text-decoration: underline
      }
    }
  }
}