.quick-create-pop{
  .quick-create-item{
    padding: 6px 12px;
    min-width: 150px;
    &.disabled{
      color: #d6d8e0;
      &:hover{
        color: #d6d8e0;
        cursor: not-allowed;
      }
    }
    &:hover{
      cursor: pointer;
      background: #efefef;
      color: #3f7dff;
    }
  }
}

.add-type-pop-group{
  padding: 4px 8px;
  .type-item{
    height: 30px;
    padding: 12px 8px;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.075);
    }
    .type-tag{
      width: 50px;
      background-color: #5477ff;
      color: #fff;
      border-radius: 8px;
      height: 18px;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

.add-type-select-pop{
  padding-left: 0;
  .ant-popover-arrow{
    display: none;
  }
}