.work-time-select-project{
  width: 550px;
  padding: 6px;
  font-size: 12px;
  .work-time-select-project-tips{
    margin-left: 4px;
    color: #AFAFAF;
  }
  .ant-input-affix-wrapper{
    margin-bottom: 6px;
  }
  .project-list-wrap{
    max-height: 250px;
    overflow-y: auto;
    .header-text{
      font-size: 14px;
      font-weight: bold;
      margin: 6px 4px 2px;
      &.all-list-header-text{
        margin-top: 10px;
      }
    }
    .project-list{
      &.recommend-project-list{
        padding-bottom: 6px;
        border-bottom: 4px solid #F6F6F6;
      }
      .project-item{
        padding: 6px 4px;
        cursor: pointer;
        &:hover{
          background:#e6eef9;
        }
        .project-name{
          width: 100px;
          flex: 1;
        }
        .project-state{
          width: 100px;
          font-size: 12px;
          color: #AFAFAF;
        }
        .project-code{
          width: 200px;
          color: gray;
        }
      }
    }
  }
}
.work-time-select-project-pop{
  padding: 0;
  .ant-popover-arrow{
    display: none;
  }
  .ant-popover-inner{
    border-radius: 6px;
  }
}