.resource-plan-base-model.resource-plan-base-model{
  .x-datagrid {
    border-top: none;
    font-size: 12px;
  }
  //.add-module-wrap{
  //  display: inline-block;
  //  .textIconBtn{
  //    margin: 8px 0;
  //    color: #5477ff;
  //  }
  //}
  .add-module-wrap{
    margin: 8px 0;
  }

  .module-col-delete{
    margin-left: 4px;
    font-size: 14px;
    cursor: pointer;
    color: red;
  }
}