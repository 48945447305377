.relevance-todo-list {
  width: 990px;
  height: 600px;
  display: flex;
  flex-direction: column;

  .content.content.content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .x-datagrid {
      height: 200px;
      flex: 1;
    }
  }

  .search-btn-group {
    .button {
      margin-left: 6px;
    }
  }

  .todo-list-data {
    width: 100%;
    flex: 1;
    overflow: hidden;
    margin: 0 0 12px 0;
  }
}