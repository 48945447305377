.global-const-mgt{
  .grid.grid.grid{
    .cell{
      .enter-detail-field-text{
        max-width: 100%;
        cursor: pointer;
        color: #5477ff;
        white-space: nowrap;/*不允许换行*/
        overflow: hidden;/*超出隐藏*/
        text-overflow: ellipsis;/*文本超出三点代替*/
        &:hover{
          text-decoration:underline
        }
      }
    }
  }
}