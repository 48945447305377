.tags-popover.tags-popover{
  padding: 8px 12px;
  width: 204px;
  .ant-divider{
    margin: 8px 0;
  }
  .tag-options-wrap{
    max-height: 200px;
    overflow-y: auto;
    .tag-option{
      padding: 4px 8px;
      &:hover {
        background-color: #f5f5f5;
      }
      .color-block{
        height: 8px;
        width: 8px;
        border-radius: 2px;
        margin-right: 6px;
      }
      .tag-text{
        width: 100px;
        flex: 1;
        overflow: hidden;  //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical;  //规定为垂直方向排列
      }
    }
  }
  .create-option{
    padding: 4px 8px;
    flex: 1;
    overflow: hidden;  //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical;  //规定为垂直方向排列

    &:hover {
      background-color: #f5f5f5;
    }
  }
  .create-btn.create-btn{
    width: 100%;
    height: 28px;
    margin: 12px 0 4px;
    background-color: #7399FF;
  }
}