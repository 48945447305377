.follow-matters-detail {
  max-width: 700px;

  .content {
    height: 600px;
    max-height: 70vh;
    overflow: auto;
  }

  .area-wrap {
    .area-header {
      margin: 12px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .note-wrap {
    padding-bottom: 10px;
    padding-right: 10px;

    .rich-text-area {
      margin-left: 40px;
    }
  }

  .rich-text-detail-wrap {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px;
    white-space: pre-wrap;

    img {
      max-width: 100%;
    }
  }

  .source-text-hover {
    cursor: pointer;
    color: #5477ff;

    &:hover {
      text-decoration: underline;
    }
  }
}