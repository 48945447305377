.customer-associated-project {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .customer-associated-project-tabs {
    flex: 1;
    overflow: hidden;

    .ant-tabs-content {
      display: flex;
      overflow: hidden;
      flex: 1;

      .ant-tabs-tabpane.ant-tabs-tabpane {
        flex: 1;
        padding-left: 0;
        overflow: hidden;
      }
    }
  }
}