.conference-records-detail-right-panel{
  width: 350px;
  flex: 1 1;
  overflow: auto;
  border: 1px solid #d0d3e3;
  border-radius: 4px;
  margin-left: 12px;
  padding: 4px 0;
  .area-wrap{
    .area-header{
      margin: 4px 0;
    }
    .area-content-wrap{
      overflow: hidden;
    }
  }
  .fold-icon{
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 15px;
    margin-right: 18px;
    &:hover{
      color: #5477ff;
    }
  }
  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
    margin: 2px 8px;
  }

  .ellipsis-line{
    width: 180px;
    white-space: nowrap;/*不允许换行*/
    overflow: hidden;/*超出隐藏*/
    text-overflow: ellipsis;/*文本超出三点代替*/
  }


  .memo-textarea.memo-textarea.memo-textarea{
    &:hover, &:focus-within{
      border-color: #CFD3E3;
      box-shadow: unset;
    }
    &.memo-textarea-detail{
      margin-top: 2px;
      border: none;
      height: 72px;
    }
    &.error{
      border: 1px solid #F5221B;
    }
  }

  .textarea-detail-wrap{
    .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
      margin-right: 0;
      height: 32px;
    }
    .textarea-detail-text{
      width: 100px;
      padding: 6px 14px 4px 0;
      line-height: 24px;
      flex: 1;
    }
  }
  .long-text{
    .form-component{
      height: unset;
      min-height: 32px;
    }
  }
}