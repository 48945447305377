.check-condition-group-wrap{
  height: 100%;
  min-width: 900px;
  .condition-group-wrap{
    margin: 12px 12px 12px 0;
    padding: 8px;
    background: #FAFAFA;
    border: 1px solid #E8EAEE;
    .field-type{
      font-size: 16px;
      font-weight: bold;
      color: #182B50;
      margin:0 12px 12px;
    }
    .condition-group{
      position: relative;
      .relation-select{
        margin: 8px 4px;
        background: #FAFAFA;
        cursor: pointer;
        .relation-select-text{
          margin: 6px 4px 6px 0;
          width: 24px;
        }
        .select-icon{
          margin-top: 6px;
        }
      }
      .relation-line{
        position: absolute;
        height: 100%;
        left: 16px;
        width: 20px;
        border: 2px solid #CFD5DD;
        border-right: none;

      }
      .condition-list{
        .condition-item{
          .add-icon{
            font-size: 18px;
            margin-left: 4px;
            margin-right: 12px;
            cursor: pointer;
            &:hover{
              color: #5477ff;
            }
          }
          .sub-icon{
            font-size: 18px;
            margin-right: 20px;
            cursor: pointer;
            &:hover{
              color: red;
            }
          }
          .error-icon{
            font-size: 14px;
            color: red;
          }
        }
      }
    }
    .inner-condition-group{
      margin: 4px 8px;
      padding: 8px;
      border: 2px dashed #E8EAEE;
      .del-group-icon{
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;
        &:hover{
          color: red;
        }
      }
    }
    .add-item-wrap{
      margin-top: 10px;
      margin-left: 32px;
    }
    .del-condition-icon{
      position: absolute;
      right: 6px;
      top: 6px;
      cursor: pointer;
      &:hover{
        color: red;
      }
    }
  }
  .add-condition-item{
    margin: 8px 0;
    padding-bottom: 8px;
    .add-condition-item-btn{
      height: 32px;
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

.condition-relation-select{
  .relation-item{
    height: 30px;
    width: 90px;
    padding: 12px 8px;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.075);
    }
    &.current-relation{
      color: #5477ff;
      background-color: #EAF4FC;
    }
  }
}