.add-field-list-popover{
  min-width: 300px;
  .add-field-list{
    height: 260px;
  }
  .add-field-item{
    padding: 8px 14px;
    cursor: pointer;
    &:hover{
      color: #3f7dff;
      background: #EAF4FC;
    }
  }
}