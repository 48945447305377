.dev-project-add-dialog.dev-project-add-dialog.dev-project-add-dialog {
  width: 1020px;
  height: 70vh;
  display: flex;
  flex-direction: column;

  .content.content.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;

    .content-wrap {
      flex: 1;
      overflow: auto;

      .area-wrap {
        .area-header {
          margin: 12px 0;
        }

        .area-content-wrap {
          overflow: hidden;
        }
      }

      .fold-icon {
        cursor: pointer;
        margin-left: 10px;
        margin-right: 18px;

        &:hover {
          color: #5477ff;
        }
      }

      .form-text-area-style {
        align-items: flex-start;
      }

      .title-prefix {
        padding: 10px;

        &.able:hover {
          cursor: pointer;
          color: #5477ff;
        }

        .arrow-icon {
          margin-top: 4px;
          margin-left: 6px;
        }
      }
    }

    .mock-footer {
      display: flex;
      align-items: center;
      padding: 8px 0;
      height: 48px;
      box-sizing: border-box;
      border-top: 1px solid #E9EAF2;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;

      .footer-hint {
        font-size: 12px;
        padding-left: 16px;
        color: #D4D4D4;
        >span{
          color: red;
        }
      }

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}

.add-dev-project-type-select {
  .type-name {
    height: 30px;
    padding: 12px 8px;
    cursor: pointer;

    &.current-type {
      color: #5477ff;
      background-color: #EAF4FC;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }
  }
}