.resource-info-card.resource-info-card.resource-info-card{
  flex: 1;
  //flex-grow: 1;
  height: 100px;
  .outer-card{
    margin: 0;
  }
  .art-table-wrapper{
    width: 100%;
    .art-table-header{
      border-bottom: 1px solid #dfe3e8;
      .art-table-header-cell{
        font-weight: bold;
      }
    }
    .art-table-cell{
      padding: 0;
    }
  }
  .x-datagrid, .art-table-wrapper{
    border-top: none;
    font-size: 12px;
    .cell.align-center, .art-table-cell{
      padding: 0;
      .saturation-item{
        color: #fff;
        font-size: 16px;
        height: 100%;
        width: 100%;
      }
      .date-item{
        height: 100%;
        width: 100%;
        &.person-row{
          &:hover{
            cursor: pointer;
            background-color: #8DC8EA55;
          }
        }
      }
      .cell-full-item{
        height: 100%;
        width: 100%;
      }
      .rest-day{
        background-color: #F5F5F5;
      }
      .has-task-item{
        background: rgba(255, 125, 0, 0.2);
      }
      .task-item{
        &:hover{
          cursor: pointer;
          background-color: #8DC8EA55;
        }
      }
    }
    >div.grid::-webkit-scrollbar-thumb{

    }
  }
  .extra-plan-btn{
    height: 32px;
    width: 88px;
    margin-left: 30px;
  }
  .info-col-header{
    .all-expand-icon{
      font-weight: normal;
      cursor: pointer;
      margin-right: 4px;
      &:hover{
        color: #5477ff;
      }
    }
  }
  .info-col-item{
    .expand-icon{
      cursor: pointer;
      margin-right: 6px;
      &:hover{
        color: #5477ff;
      }
    }
    .user-name-item{
      cursor: pointer;
      &:hover{
        color: #5477ff;
      }
    }
    .task-name-in-info-col-wrap{
      width: 100%;
      overflow: hidden;  //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical;  //规定为垂直方向排列
      .linked-icon{
        margin-right: 4px;
        color: #70AD47;
      }
      .none-linked-icon{
        margin-right: 16px;
      }
      .task-name-in-info-col{
        overflow: hidden;  //隐藏多余行数
        text-overflow: ellipsis;
        //display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical;  //规定为垂直方向排列
      }
    }
  }
}