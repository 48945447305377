.trace-relate-component-select-dialog{
  .content.content.content.content{
    height: 70vh;
    width: 950px;
    padding: 16px;
    .card-header{
      padding-right: 0;
    }
    .x-card{
      height: 100%;
    }
  }
}