.popover-comment-wrap{
  width: 360px;
  padding: 12px;
  .comment-area-header{
    font-weight: bold;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #e9eaf2;
  }
  .comment-item-wrap{
    flex: 1;
  }
  .comment-wrap{
    border-bottom: 1px solid #e9eaf2;
    padding-bottom: 4px;
    .name-img{
      margin-top: 4px;
      margin-right: 6px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
    }
    .comment-header{
      margin: 4px 0;
      .comment-author{
        color: #00000073;
        padding-right: 8px;
        line-height: 20px;
      }
      .comment-datetime{
        color: #ccc;
        line-height: 20px;
      }
    }
    .comment-content-detail{
      color: #000000d9;
    }
    .comment-actions>span{
      color: #00000073;
      cursor: pointer;
      transition: color .3s;
      display: inline-block;
      margin: 4px 12px 4px 0;
      &:hover{
        color: #595959;
      }
    }
    .sub-comment{
      .comment-wrap{
        border-top: 1px solid #e9eaf2;
        border-bottom: 1px solid #ffffff;
      }
      .comment-wrap:last-child{
        //background: #3f7dff;
        //border-bottom: 1px solid #ffffff;
        padding-bottom: 0;
      }
    }
  }
  .comment-area{
    margin-bottom: 8px;
    max-height: 300px;
    overflow: auto;
  }
  .reply-info{
    background: #f0f3f7;
    width: 100%;
    padding: 4px;
    height: 24px;
    justify-content: space-between;
    .reply-info-content{
      width: 300px;
      font-size: 12px;
      overflow: hidden;  //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical;  //规定为垂直方向排列
    }
    .reply-info-del-icon{
      font-size: 14px;
      cursor: pointer;
      &:hover{
        color: red;
      }
    }
  }
  .btn-group{
    justify-content: flex-end;
  }
  .ant-btn{
    width: 90px;
    margin-top: 6px;
    margin-left: 12px;
  }
  .submit-btn{
    background-color: #5477ff;
    border-color: transparent;
    &:hover{
      background-color: #214eff;
    }
  }
}