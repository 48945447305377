.version-relation-mgt {
  .c-options {
    flex-wrap: wrap;
    margin-bottom: 0;

    .search-btn-group {
      margin-bottom: 4px;

      .button {
        margin: 6px 8px 0;
      }

      .reset-btn {
        border: 1px solid #7A8199
      }
    }
  }
}