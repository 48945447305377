.stage-quality-audit-detail-content {
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;

  .mock-dialog-header {
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;

    .dialog-title {
      font-weight: 700;
    }

    .mock-right-header {
      .header-edit-icon {
        font-size: 18px;
        cursor: pointer;

        &.edit-active-icon {
          color: #5477ff;
        }

        &:hover {
          color: #5477ff;
        }
      }

      .textIconBtn {
        margin-left: 8px;
      }
    }

    .close-area {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 20px;

      .close-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: #ECECEC;
      }
    }
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    border-top: 1px solid #d0d3e3;

    .footer-hint {
      font-size: 12px;
      padding-left: 16px;
      color: #D4D4D4;
    }

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

  .stage-quality-audit-detail-main-panel {
    height: 100px;
    padding: 8px 12px;
    flex: 1;
    overflow: auto;

    .file-upload-area.file-upload-area.file-upload-area {
      padding-top: 0;
      padding-left: 0;
    }

    .text-area-input-edit.text-area-input-edit.text-area-input-edit {
      align-items: flex-start;
    }

    .text-area-input-detail.text-area-input-detail.text-area-input-detail {
      align-items: flex-start;

      .form-component {
        height: unset;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }

}

.deliverable-list-tooltip.deliverable-list-tooltip.deliverable-list-tooltip {
  min-width: 830px;

  .ant-tooltip-inner {
    color: black;
    padding: 0;
    max-height: 350px;
    display: flex;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);

    .x-datagrid {
      flex: 1;
    }
  }
}