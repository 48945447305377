.version-relate-component.version-relate-component.version-relate-component{
  height: 100%;
  .rootnet-checkbox{
    height: unset;
  }
}

.version-relate-component-select-branch-pop{
  padding: 4px 8px;
  .relate-branch-item{
    height: 30px;
    padding: 2px 8px;
    cursor: pointer;
    &:hover {
      color: #fff;
      background: #5477FF;
    }
    .relate-branch-icon{
      font-size: 16px;
      margin-right: 8px;
    }
  }
}