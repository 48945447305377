.contrast-edit-file-log {
  width: 430px;

  .content.content.content.content.content.content {
    padding: 0;

    .ant-checkbox-wrapper {
      padding-left: 20px;
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;

      .ant-checkbox-wrapper {
        padding-left: 20px;
      }
    }

    .label-text {
      white-space: nowrap;
      color: #738299;
      margin: 8px 8px 0 8px;

      &::before {
        display: inline-block;
        margin-right: 4px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
        color: #F5221B;
      }
    }

    .content-panel {
      padding: 12px;
    }

    .rootnet-radiogroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 8px 0 8px;
    }

    .rootnet-forminput {
      &>label {
        flex: none;
      }
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;

      .btn-group {
        .button {
          margin-right: 8px;
          height: 32px;
        }
      }
    }
  }
}