$normalLine: #E5E5E5;
$redLine: red;
$greenLine: #38E097;

.milestone-card-core{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .line{
        height: 4px;
        background: $normalLine;
        width: 70%;
        border-radius: 2px;
        position: relative;
        .item{
            position: absolute;
            text-align: center;
            top: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .name{
                width: 140px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                position: relative;
                right: -70px;
            }
            .time{
                position: relative;
                width: 140px;
                right: -70px;
                &::before{
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    left: 70px;
                    top: -23px;
                    background-color: $normalLine;
                    text-align: center;
                }
            }
            &.red{
                color: $redLine;
            }
            &.green{
                color: $greenLine;
                &::before{
                    background: $greenLine;
                }
                .time{
                    &::before{
                        background-color: $greenLine;
                        text-align: center;
                    }
                }
            }
            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 4px;
                border-radius: 4px;
                left: 0px;
                top: -20px;
                background: $normalLine;
                text-align: center;
            }
            &:nth-last-of-type(2n + 1){
                top: -60px;
                &::before{
                    top: 60px;
                }
                .time{
                    &::before{
                        top: 57px;
                    }
                }
            }
        }
        .item.start{
            left: -180px;
            top: -20px;
            &::before{
                height: 0;
            }
            .time{
                &::before{
                    right: 20px;
                    left: inherit;
                    top: 17px;
                }
            }
        }
        .item.end{
            top: 10px;
            right: -10px;
            &::before{
                width: 100%;
                top: -10px;
            }
            &.green{
                .time{
                    &::before{
                        border-left: 10px solid $greenLine;
                        background: transparent;
                    }
                }
            }
            .time,.name{
                position: relative;
                right: -120px;
                top: -30px;
            }
            .time{
                z-index: 1;
                &::before{
                    content: "";
                    width: 0px;
                    height: 0px;
                    background: inherit;
                    border-radius: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 10px solid $normalLine;
                    position: absolute;
                    top: 12px;
                    left: 15px;
                }
            }
        }
    }
}