.feedback-message-dialog.feedback-message-dialog.feedback-message-dialog {
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;

  .content.content.content {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .feedback-message-dialog-content {
      flex: 1;
      overflow: hidden;
    }

    .feedback-message-comment {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ant-input-textarea {
        position: relative;

        &::after {
          padding: 0;
          position: absolute;
          right: 22px;
          bottom: 3px
        }
      }

      .upload-files {
        position: relative;

        .file-upload-area {
          padding-left: 0;
          padding-right: 0;
        }

        .textIconBtn {
          margin-left: 0;
        }

        .ant-btn-primary {
          position: absolute;
          right: 0;
          top: 7px;
          z-index: 5;
        }
      }

      .comment-section {
        flex: 1;
        overflow: auto;

        .comment-section-item {
          margin-top: 16px;
          width: 100%;
          overflow: hidden;
          padding-right: 2px;

          &:hover .icon-del {
            display: inline-block !important;
          }

          .head-portrait {
            margin-right: 8px;

            .client {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              color: #0153D9;
              background: #E6EEFC;
              font-size: 14px;
              text-align: center;
              line-height: 24px;
            }

            .img {
              width: 24px;
              height: 24px;
              background-image: url("../../../../../assets/images/logo1.png");
              background-size: 18px 12px;
              background-repeat: no-repeat;
              background-position: center center;
              border-radius: 50%;
              background-color: #E6EEFC;
            }
          }

          .detail-content {
            flex: 1;
            font-size: 12px;

            .header {
              height: 24px;
              display: flex;
              justify-content: space-between;
              line-height: 24px;

              .user-name {
                font-weight: 600;
                letter-spacing: 1px;
              }

              .time {
                .icon-del {
                  display: none;
                  cursor: pointer;
                  margin-right: 8px;
                  font-size: 12px;

                  &:hover {
                    color: red;
                  }
                }

                color: #000000a6;
              }
            }

            .message {
              color: #000000a6;
              white-space: pre-wrap;
              box-sizing: border-box;
              word-break: break-all;
              line-break: anywhere;
            }

            .file {
              margin-top: 8px;

              .file-itmes {
                color: #0153D9;
                cursor: pointer;
                justify-content: space-between;

                &:hover {
                  color: #3a77d9;
                }

                .file-text {
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }
    }

    .content-main-panel {
      height: 100px;
      flex: 1;
      padding: 8px;
      display: flex;
    }

    .mock-dialog-header {
      width: 100%;
      display: flex;
      align-items: center;
      color: #15171f;
      padding: 12px 16px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e9eaf2;
      justify-content: space-between;

      .dialog-title {
        width: 100px;
        flex: 1;
        font-weight: 700;

        .title-hint {

          font-size: 12px;
          font-weight: 400;
          display: flex;
          align-items: center;
          padding-left: 16px;
          color: red;
        }
      }

      .mock-right-header {
        .header-edit-icon {
          font-size: 18px;
          cursor: pointer;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }
      }

      .close-area {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 20px;

        .close-icon {
          font-size: 14px;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}