.imp-pay-milestone {
  overflow: auto;

  .imp-pay-list-del-icon {
    cursor: pointer;

    &:hover {
      color: #5477ff;
    }
  }

  .imp-handle-btn {
    text-align: right;
    padding: 8px 0;

    .ant-btn {
      height: 34px;
      margin: 0 6px;
    }
  }

  .add-data-item {
    position: sticky;
    bottom: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    height: 32px;
    border: 1px dashed #f0f0f0;
    text-align: center;
    line-height: 32px;
    cursor: pointer;

    &:hover {
      color: #5477ff;
      border-color: #5477ff;
    }

    .icon {
      font-weight: 900;
    }
  }

  .ant-table {
    .ant-empty.ant-empty-normal {
      display: none;
    }
  }

  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead>tr>th,
  .ant-table.ant-table-small .ant-table-tbody>tr>td,
  .ant-table.ant-table-small tfoot>tr>th,
  .ant-table.ant-table-small tfoot>tr>td {
    padding: 6px;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    min-height: 32px;
  }

  .editable-row {
    .editable-cell-value-wrap {
      cursor: pointer;
    }
  }

  .editable-row .editable-cell-value-wrap:hover {
    background-color: #E0E0E0;
  }
}