.query-field-wrap.query-field-wrap.query-field-wrap.query-field-wrap{
  margin-top: 0;
  margin-right: 0;
  width: 460px;
  height: 100%;
  .ant-table-wrapper{
    height: 100%;
    width: 100%;
  }
  .delete-text{
    cursor: pointer;
    color: #3f7dff;
    &:hover{
      color: red;
    }
  }
  &.full-screen{
    position: absolute;
    margin: 12px;
    width: calc(100% - 24px);
    height: calc(100vh - 62px);
    z-index: 4;
    .relation-card{
      height: 100%;
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}