.version-req-del-log {
  width: 400px;

  .content.content.content {
    justify-content: flex-start;
    align-items: stretch;

    .text-area-input-edit.text-area-input-edit.text-area-input-edit {
      align-items: flex-start;
    }
  }
}