.project-change-history {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  >.content.content.content.content {
    padding-top: 0;
    padding-bottom: 16px;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .project-change-history-content {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .x-datagrid {
      flex: 1;
      overflow: hidden;

      .title-hidden {
        width: 100%;
        display: flex;

        .type-color {
          border-radius: 4px;
          margin-right: 4px;
          color: white;
          padding: 0 2px;
          box-sizing: border-box;
          display: inline-block;
          transform: scale(0.7);
        }

        .text {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #5477ff;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .x-pagination {
      justify-content: flex-end;
    }
  }
}