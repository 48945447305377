.conference-records-detail-header{
  padding: 8px 20px 0 12px;
  height: 44px;
  .header-left{
    flex: 1;
    .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
      width: 100%;
      label{
        flex-grow: 0;
      }
      .rootnet-input{
        flex: 1;
      }
    }
    .mock-title-wrap{
      margin-top: 4px;
      margin-left: 4px;
      font-size: 16px;
      overflow: hidden;  //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical;  //规定为垂直方向排列
    }
    .copy-icon{
      margin-left: 8px;
      margin-top: 2px;
      font-size: 16px;
      cursor: pointer;
      &:hover{
        color: #3f7dff;
      }
    }
  }
}