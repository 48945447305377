.workplace-wrap{
  overflow-y: auto;
  &.has-full-screen{
    .second-row{
      height: unset;
    }
  }
  .second-row{
    height: 540px;
    max-height: 100%;
    &.has-show-card{
      flex-grow: 1;
    }
    .x-card{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .third-row{
    max-height: 860px;
    &.has-show-card{
      flex-grow: 1;
      max-height: unset;
    }
  }
  .hidden-card{
    display: none;
    .x-card{
      margin: 12px;
    }
    &.show-card{
      display: flex;
    }
  }
}