.workplace-calendar.workplace-calendar.workplace-calendar{
  flex: 1;
  width: 100px;
  .ant-picker-calendar-mode-switch{
    display: none;
  }
  .ant-picker-calendar-month-select{
    margin-right: 8px;
  }
  .ant-picker-calendar{
    display: flex;
    flex-direction: column;
    thead{
      tr{
        th{
          padding-left: 8px;
        }
      }
    }
    .ant-picker-calendar-date-value{
      padding-left: 8px;
    }
  }
  .ant-picker-panel{
    overflow-y: auto;
    height: 50px;
    flex: 1;
    text-align: left;
    .ant-picker-body{
      padding-bottom: 0;
      border-bottom: 2px solid #F0F0F0;
    }
  }
  .ant-picker-cell{
    border-right: 2px solid #F0F0F0;
  }
  .ant-picker-cell-in-view{
    cursor: auto;
  }
  .ant-picker-calendar-date{
    margin: 0;
    padding: 0;
    background-color: #fff !important;
  }
  .date-item{
    height: 100%;
    .weekly-report{
      height: 20px;
      width: 100%;
      background-color: #E6F7FF;
      cursor: default;
      .weekly-report-add{
        cursor: pointer;
        &.has-data{
          .icon{
            color: #52c41a;
          }
        }
      }
    }
    .daily-info{
      height: 10px;
      flex: 1;
      overflow-y: auto;
      .daily-report-info{
        padding-left: 8px;
        .daily-report-add{
          &.has-data{
            .icon{
              color: #52c41a;
            }
          }
        }
      }
    }
    .todo-list-wrap{
      margin-left: 9px;

      .todo-item{
        width: 100%;
        .todo-done-icon{
          font-size: 10px;
          color: gray;
          margin-right: 5px;
        }
        .ant-badge{
          margin-left: 3px;
        }
        .todo-title-wrap{
          width: 10px;
          flex: 1;
        }
        .todo-title{
          font-size: 12px;
          max-width: 100%;
          overflow: hidden;  //隐藏多余行数
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
          -webkit-box-orient: vertical;  //规定为垂直方向排列
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .handle-icon{
    font-size: 18px;
    margin-left: 24px;
  }
}