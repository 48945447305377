.details-left-menu {
  &.x-card {
    flex: 1;
    margin: 0 0 12px 0;
    max-width: 206px;
  }

  &-item {
    &:hover {
      background-color: #0000000F;
    }

    &.active {
      background: #5477FF;
      color: #fff;
    }

    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    cursor: pointer;
  }
}