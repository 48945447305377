.customer-detail-wrap.customer-detail-wrap.customer-detail-wrap.customer-detail-wrap{
  .customer-detail{
    height: 100px;
    overflow: auto;
    background: #fff;
    flex: 1;
    margin: 12px;
    border-radius: 4px;
  }
  .ant-tabs{
    flex: 1;
    height: 100px;
    .ant-tabs-nav{
      padding-left: 10px;
      margin-bottom: 0;
    }
    .ant-tabs-content-holder{
      padding: 0;
      display: flex;
      flex: 1;
      height: 100px;
      overflow: hidden;
    }
    .ant-tabs-content, .ant-tabs-content{
      flex: 1;
      overflow: auto;
      .ant-tabs-tabpane{
        height: 100%;
      }
    }
    .tab-content{
      height: 100%;
    }
  }
  .edit-btn{
    height: 32px;
    width: 80px;
    margin-right: 20px;
  }
  .extra-right-wrap{
    width: 210px;
    justify-content: flex-end;
  }
  .dynamic-wrap{
    padding: 12px;
  }
  .ant-tabs-tab.ant-tabs-tab.ant-tabs-tab.ant-tabs-tab{
    &.ant-tabs-tab-active{
      .ant-badge{
        color: #1890ff;
      }
      .ant-badge-count{
        background-color: #EFF4FF !important;
        .ant-scroll-number-only{
          color: #6698FF;
        }
      }
    }
  }
}