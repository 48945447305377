.metadata-mgt{
  .convert-audit-flag{
    .circle{
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .tab-header{
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
  }
  .extra-area{
    .all-textIconBtn{
      margin: 0 8px;
    }
  }
  .options{
    margin: 0 4px;
    justify-content: space-between;
    .rootnet-forminput{
      .suffix{
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .approval-group{
      .approval-select{
        margin: 0 12px;
        .radio-wrap{
          cursor: pointer;
        }
        .radio-desc{
          font-size: 12px;
          color: #7A8199;
          .radio-desc-item{
            margin: 0 5px;
          }
        }
      }
    }
  }
  .field-dict-id-in-cols{
    color: #5477ff;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}

.data-dict-edit-dialog, .constant-defined-edit-dialog, .rootnet-data-type-edit-dialog{
  position: relative;
  .content{
    max-height: 78vh;
    overflow: scroll;
  }
  .content>div{
    display: flex;
    flex-direction: column;
  }
  .dialog-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFFA0;
    z-index: 5;
  }
  .form-error-msg{
    font-size: 10px;
    color: #F5221B;
    margin-left: 118px;
  }
  .desc-textarea{
    label{
      align-self: flex-start;
      margin-top: 6px;
    }
    &.desc-required{
      label::before {
        display: inline-block;
        margin-right: 4px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: '*';
        color: #F5221B;
      }
    }
  }
}

.data-dict-edit-dialog{
  .content{
    width: 460px;
  }
  .to-dict-standard-root-text{
    margin-left: 120px;
    color: #5477ff;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}

.metadata-del-message-dialog.metadata-del-message-dialog{
  .header{
    border-bottom: 1px solid rgba(0,0,0,0.07);
  }
  >.content.content.content{
    padding: 16px;
  }
  .footer{
    .primary{
      background: #ff4500;
      box-shadow: none;
      &:hover {
        background-color: #ff6a33;
      }
    }
  }
  .desc-textarea{
    label{
      align-self: flex-start;
      margin-top: 6px;
    }
    &.desc-required{
      label::before {
        display: inline-block;
        margin-right: 4px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: '*';
        color: #F5221B;
      }
    }
  }
}