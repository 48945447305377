.field-definition-update-dialog{
  height: 80vh;
  width: 1080px;
  .ant-divider-horizontal.ant-divider-with-text{
    margin: 4px 0;
  }
  .content.content.content.content{
    height: 100px;
    overflow: auto;
    flex: 1;
  }
  .red{
    color: red;
  }
  .repeat-tableName-msg{
    margin-left: 160px;
  }
  .custom-field-radio-group{

  }
  .table-relation-item{
    .table-relation-icon{
      display: inline-block;
      font-size: 20px;
      margin-left: 12px;
      cursor: pointer;
      &.add-icon:hover{
        color: #3f7dff;
      }
      &.del-icon:hover{
        color: red;
      }
    }
  }
}