.follow-matters-item {
  font-size: 12px;

  .record-icon {
    width: 35px;

    .head-portrait {
      margin-top: 0px !important;
    }
  }

  .follow-matters-content {
    .name-row {
      .create-time-row {
        color: #929292;
        margin-left: 8px;
      }


    }

    .title-row {
      .operation {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .status {
      letter-spacing: 2px;
      transform: scale(0.8, 0.8);
      border-radius: 4px;
      padding: 2px 4px;
      display: inline-block;
      color: white;
    }

  }
}