.department-select-pop.department-select-pop.department-select-pop {
  width: 570px;
  background-color: #fff;
  padding: 8px 14px;

  .department-search {
    width: 100%;
    border: 1px solid #E6E8EB;

    .suffix {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #E6E8EB;

      .department-search-icon {
        font-size: 16px;
      }
    }
  }

  .department-select-main-panel {
    margin-top: 8px;
    width: 100%;
    height: 300px;

    .department-select-left,
    .department-select-right {
      width: 50%;
      border-bottom: 1px solid #F2F4F5;
    }

    .department-select-left {
      .ant-tabs {
        height: 100%;
        line-height: 16px;

        .ant-tabs-tabpane {
          height: 100%;
        }

        .ant-tabs-content-holder {
          height: 100%;
        }

        .ant-tabs-content {
          height: 100%;
        }

        .ant-tabs-nav {
          margin-bottom: 0;
        }

        .ant-tabs-tab {
          font-size: 12px;
          padding: 8px 0;
        }
      }

      .all-user,
      .recent-user,
      .dept-user-warp {
        width: 100%;
        overflow-y: scroll;
        height: 100%;
      }

      .all-user,
      .recent-user {
        .ant-spin {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .recent-user {
        .ant-empty {
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .dept-user-warp {

        .ant-tree,
        .ant-tree-list,
        .ant-tree-treenode,
        .ant-tree-node-content-wrapper {
          width: 100%;
        }

        .ant-tree-title {
          .department-select-content {
            width: 100%;
          }
        }

        .department-select-user-item:hover {
          background-color: unset;
        }
      }
    }

    .department-select-right {
      border-left: 1px solid #F2F4F5;
      padding-left: 6px;

      .department-select-right-header {
        font-size: 12px;
        line-height: 20px;
        justify-content: space-between;
        padding-right: 8px;

        .department-selected-num-wrap {
          color: #36434d;
          padding: 5px 0;

          .department-selected-num {
            margin-left: 8px;
          }
        }

        .clear-all-selected {
          color: #1890ff;
          cursor: pointer;
        }
      }

      .department-select-right-content {
        height: 200px;
        flex: 1;
        overflow-y: scroll;
        font-size: 12px;

        .select-type-list {
          .select-type-list-head {
            font-size: 12px;
            color: #7A8199;
            padding: 6px 0;
          }

          .select-type-list-content {
            .select-item {
              justify-content: space-between;
              height: 26px;

              &:hover {
                background-color: #E9F5FF;
              }

              &:hover .clear-icon {
                display: block;
              }

              .clear-icon {
                display: none;
                cursor: pointer;
                font-size: 16px;
                color: #B0B4B8;
                padding-right: 4px;
              }
            }

          }
        }

        .ant-spin {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ant-empty {
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .confirm-footer {
    padding-top: 8px;
    justify-content: space-between;

    .button {
      height: 28px;
    }

    .confirm-footer-left {
      font-size: 12px;
    }
  }

  .dept-name-wrap {
    width: 100%;
    justify-content: space-between;

    .add-dept-icon {
      display: none;
      margin: 0 10px;
      font-size: 18px;
      color: #1890ff;
    }

    &:hover {
      .add-dept-icon {
        display: block;
      }
    }
  }
}