.project-manpower-summary-detail-summary.project-manpower-summary-detail-summary.project-manpower-summary-detail-summary {
  overflow: hidden;

  .x-card {
    margin: 0 12px;
    font-size: 12px;

    .card-header {
      .header {
        align-items: center;

        .title {
          display: flex;
          font-weight: bold;

          &::before {
            display: block;
            content: "";
            width: 2px;
            height: 1em;
            background-color: #5477ff;
            box-shadow: 2px 0 3px 0 rgba(24, 144, 255, 0.42);
            margin-right: 12px;
            margin-top: 4px;
          }
        }

        .hint {
          margin-left: 16px;
          color: #D4D4D4;
        }
      }

      .right {
        width: 52%;
      }

      .extra-wrap {
        width: 100%;
        justify-content: space-between;

        .expand-text {
          cursor: pointer;
          color: #5477ff;

          .fold-icon {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }
    }

    .card-content {
      min-height: 100px;
    }
  }
}