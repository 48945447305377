.work-flow-field-setting.work-flow-field-setting{
  height: 100%;
  .table-wrap{
    //flex-grow: 1;
    //flex: 1;
    //height: 100px;
    max-height: calc(100% - 45px);
  }
  //.add-btn-wrap{
  //  flex: 1;
  //}
  .del-icon{
    color: red;
    cursor: pointer;
    font-size: 16px;
    margin-left: 18px;
  }
  .add-btn{
    margin-left: 56px;
    display: inline-block;
  }
  .ant-table-sticky-scroll{
    display: none;
  }
  .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    padding: 6px;
  }

  .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container{
    height: 100%;
  }
  .ant-table-body{
    //overflow: auto !important;
    height: calc(100% - 45px);
    table{
      height: 100%;
    }
  }
  .ant-table-cell{
    height: 45px;
  }

  .ant-checkbox-wrapper{
    margin: -3px !important;
  }

  tr.ant-table-measure-row{
    // display: none;
    visibility: collapse;
  }
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 5;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-cell {
  position: relative;
}
.editable-cell-value-wrap{
  padding: 5px 12px;
  height: 32px;
  white-space: nowrap;/*不允许换行*/
  overflow: hidden;/*超出隐藏*/
  text-overflow: ellipsis;/*文本超出三点代替*/
}

.editable-row{
  .editable-cell-value-wrap {
    cursor: pointer;
  }
}

.editable-row .editable-cell-value-wrap:hover {
  background-color: #E0E0E0;
}