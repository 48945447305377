.tracking-detail-header {
  padding: 8px 12px 0;
  height: 70px;

  .header-left {
    flex: 1;

    .tracking-id-wrap {
      height: 24px;

      .type-tag {
        color: #fff;
        padding: 0 8px;
        border-radius: 8px;
        height: 18px;
        font-size: 14px;
      }

      .tracking-id {
        font-weight: bold;
        margin-left: 4px;
        font-size: 16px;
        overflow: hidden; //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical; //规定为垂直方向排列
      }
    }

    .tracking-title-wrap {
      margin-top: 4px;
      margin-left: 4px;
      font-size: 16px;

      .tracking-title {
        max-width: 90%;
        overflow: hidden; //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical; //规定为垂直方向排列
      }
    }

    .copy-icon {
      margin-left: 8px;
      margin-top: 2px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: #3f7dff;
      }
    }

    .rootnet-forminput.rootnet-forminput.rootnet-forminput {
      width: 100%;
      margin: 0;
      padding-top: 6px;

      label {
        flex: none;
      }

      .rootnet-input {
        flex: 1;
      }
    }
  }

  .header-right {
    .header-right-item {
      height: 55px;
      margin-left: 30px;

      .header-right-icon {
        //height: 20px;
        //width: 20px;
        font-size: 30px;
        margin-right: 4px;
      }

      .header-right-info {
        margin-left: 4px;

        .rootnet-forminput {
          margin: 0;
        }

        .header-right-value {
          height: 24px;

          .current-user {
            color: #868686;
          }

          .priority-tag-text,
          .status-tag {
            font-size: 12px;
          }
        }

        .header-right-field {
          color: #C4C4C4;
        }
      }
    }
  }
}

.tracking-title-copy-popover {
  padding: 6px 12px;

  .button {
    height: 32px;
    margin: 6px;
  }
}