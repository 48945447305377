.view-pop.view-pop.view-pop.view-pop{
  .ant-popover-inner-content{
    padding: 4px 8px;
  }
  .view-group{
    min-width: 140px;
  }
  .view-title{
    color: #b0bac5;
    padding: 4px 8px;
    font-weight: bold;
  }
  .view-item{
    height: 32px;
    padding: 0 8px;
    cursor: pointer;
    justify-content: space-between;
    padding-left: 25px;
    .view-name{
      flex: 1;
      margin-right: 6px;
    }
    &.active{
      background:#e6eef9;
      color: #5477ff;
    }
    &:hover{
      background:#e6eef9;
      color: #5477ff;
      .view-item-edit-icon,.view-item-del-icon{
        visibility: unset;
      }
    }

    .view-item-edit-icon{
      color: rgba(0,0,0,0.85);
      margin-left: 10px;
      visibility: hidden;
      &:hover{
        color: #5477ff;
      }
    }

    .view-item-del-icon{
      margin-left: 10px;
      color: rgba(0,0,0,0.85);
      visibility: hidden;
      &:hover{
        color: red;
      }
    }
    .textIconBtn.textIconBtn{
      margin-left: 0;
    }
  }
  .ant-divider-horizontal{
    margin: 5px 0;
  }
}