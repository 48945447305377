.details {
  font-size: 14px;

  .details_item {
    display: flex;
    justify-content: flex-end;
    padding: 6px 0;
    line-height: 30px;
    white-space: nowrap;

    .details_item_tit,.input-label {
      text-align: right;
      vertical-align: top;
      color: #738299;
    }

    .details_item_text {
      max-width: 240px;
      width:182px;
      color: #13151A;
    }
  }
}