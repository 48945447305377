.projectcostdetail {
  box-sizing: border-box;

  .card-content {
    flex-direction: column;
  }

  .x-pagination {
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .detail-top {
    display: flex;
    justify-content: space-between;

    .detail-top-child {
      width: 50%;
      min-width: 500px;
      background: white;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      >dl:nth-child(2) {
        border-right: 1px solid #e9eaf2;
      }

      >dl:nth-child(3) {
        border-left: 1px solid #e9eaf2;
      }

      >dl {
        width: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        >dt {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 700;
        }

        >dd {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
        }
      }

    }
  }

  .detail-info {
    height: 135px;

    .detail-info-child {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      >div {
        //flex: 1;
        margin-left: 12px;
        color: #7a8199;

        span {
          color: black;
        }
      }
    }
  }
  .maxHeight{
    margin-top: 0;
    height: 650px;
    .card-content {
      display: flex;
      justify-content: none;
    }
  }
  .detail-task {
    margin-top: 0;
    // max-height: 460px;
    min-height: 150px;

    .card-content {
      display: flex;
      justify-content: center;
    }
  }
}