.manpower-detail-list-tabs.manpower-detail-list-tabs.manpower-detail-list-tabs {
  flex: 1;
  margin: 8px 12px;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;

  .ant-tabs-nav {
    margin: 0;

    .ant-tabs-nav-wrap {
      padding-left: 12px;

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .ant-tabs-content,
  .ant-tabs-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .tab-content {
    height: 100%;
  }

  .ant-tabs-tabpane-active {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .art-table-wrapper {
    .art-table-header {
      .art-table-header-cell {
        font-weight: bold;
      }
    }
  }

}