.defect-link-tracer-dialog.defect-link-tracer-dialog.defect-link-tracer-dialog.defect-link-tracer-dialog{
  width: 1110px;
  height: 550px;
  .search-btn-group{
    .button{
      margin-left: 6px;
    }
  }
  .trace-list{
    height: 450px;
    flex: 1;
    overflow: hidden;
  }
}