.resource-import-requirement.resource-import-requirement.resource-import-requirement{
  width: 900px;
  .content.content.content.content{
    height: 70vh;
    overflow-y: auto;
    padding: 6px 16px;
  }
  .step-wrap{
    margin-top: 20px;
    margin-bottom: 4px;
    &.requirement-warp{
      margin-top: 0;
    }
    .step-header{
      margin-right: 12px;
    }
  }
  .download-template{
    margin-left: 21px;
    margin-top: 10px;
    .download-icon{
      cursor: pointer;
      font-size: 14px;
      margin-right: 4px;
    }
    .download-text{
      cursor: pointer;
      color: #5477ff;

      &:hover {
        color: #7f93e0;
      }
    }
  }
  .upload-memo{
    margin: 4px 21px;
  }
  .upload-btn{
    margin-left: 21px;
    margin-top: 10px;
    height: 38px;
  }
  .add-new-next-step-wrap{
    .template-type-radio-group{
      margin-left: 14px;
    }
  }

  .mock-req-select{
    height: 32px;
    line-height: 32px;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    border: 1px solid #CFD3E3;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    padding:0 8px;
    overflow: hidden;  //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical;  //规定为垂直方向排列
    &.placeholder{
      color: #D9D9E0;
    }
  }
}