.dev-project-req-matrix {
  .dev-project-req-matrix-text {
    font-weight: bold;

    &::before {
      display: block;
      content: "";
      width: 2px;
      height: 1em;
      background-color: #5477ff;
      box-shadow: 2px 0 3px 0 rgba(24, 144, 255, 0.42);
      margin-right: 12px;
      margin-top: 4px;
    }
  }

  .x-datagrid {
    .grid.grid.grid {
      .cell {
        padding: 0;
        overflow: initial;
        align-items: unset;


        &.tooltip {
          align-items: center;
          overflow: hidden;

          &:hover {
            overflow: visible;
          }
        }

        .rootnet-forminput {
          z-index: 6;
        }
      }
    }

    .common-display-field {
      padding: 0 12px;
    }

    .text-cverflo-hiding.text-cverflo-hiding {
      flex: 1;
      line-height: 34px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
    }

    .common-edit-field {
      padding: 0 12px;
      cursor: pointer;
      height: 34px;

      &:hover {
        background: #EBF0FB;
      }
    }

    .more-handle-icon.more-handle-icon.more-handle-icon {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;

      &:hover {
        color: #3f7dff;
      }
    }

    .enter-detail-field-wrap {
      cursor: unset;
      // width: 100%;
      display: flex;

      .enter-detail-field {
        // width: 100px;
        // flex: 1;

        .enter-detail-field-text {
          // max-width: 100%;
          cursor: pointer;
          color: #5477ff;

          &:hover {
            text-decoration: underline
          }
        }
      }

      .enter-detail-field-edit-icon {
        display: none;
        margin-left: 10px;
        cursor: pointer;
        font-size: 15px;

        &:hover {
          color: #5477ff;
        }
      }

      &:hover {
        .enter-detail-field-edit-icon {
          display: block;
        }
      }
    }
  }
}

.req-matrix-tooltip {
  .ant-tooltip-inner {
    white-space: pre-wrap;
    word-break: break-all;
    max-height: 300px;
    overflow: auto;
  }
}