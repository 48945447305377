.cost-center-dialog.cost-center-dialog.cost-center-dialog.cost-center-dialog.cost-center-dialog{
  display: flex;
  flex-direction: column;
  align-items: unset;
  justify-content: flex-start;
  .path{
    margin:13px 20px;
    font-size: 14px;
    color: #7A8199;
  }
}