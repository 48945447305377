.isTestCase.isTestCase.isTestCase {
  display: none;
}

.testCase {
  .option {
    margin: 0 4px;

    .rootnet-forminput {
      .suffix {
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .biz-tree-table {
    .enter-detail-field {
      cursor: pointer;
      color: #5477ff;

      &:hover {
        text-decoration: underline
      }
    }

    .grid {
      .row {
        &>.cell:hover .edit-icon {
          margin-left: 8px;
          display: inline-block;
        }

        .edit-icon {
          display: none;
          cursor: pointer;

          i {
            margin-right: 8px;
            font-size: 14px;
          }

          & i:hover {
            color: #5477ff;
          }
        }

        .expand-icon {
          margin-left: 0;
        }
      }
    }
  }
}

.testCase-displayForm.testCase-displayForm.testCase-displayForm.testCase-displayForm {
  min-width: 480px;

  .content {
    min-height: 300px;
    display: flex;
    flex-direction: column;
  }
}

.actual-result-html-tip {
  .ant-tooltip-inner {
    width: 800px;
    max-height: 500px;
    overflow: auto;
  }
}

.actual-result-html {
  width: 95%;
  height: 18.84px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  * {
    box-sizing: border-box;
    color: black !important;
    font-size: 12px !important;
    font-weight: normal !important;
    background-color: white !important;
    text-decoration: none !important;
    font-style: normal !important;
    padding: 0;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  ul,
  ol {
    list-style: none;
  }

  img {
    display: none;
    border: none;
    vertical-align: middle;
  }

  a {
    text-decoration: none;
    color: #232323;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
  }

  input,
  textarea {
    outline: none;
    border: none;
  }

  textarea {
    resize: none;
    overflow: auto;
  }

  .clearfix {
    zoom: 1;
  }

  .clearfix:after {
    content: ".";
    width: 0;
    height: 0;
    visibility: hidden;
    display: block;
    clear: both;
    overflow: hidden;
  }

  .fl {
    float: left
  }

  .fr {
    float: right
  }

  .tl {
    text-align: left;
  }

  .tc {
    text-align: center
  }

  .tr {
    text-align: right;
  }

  .ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .inline {
    display: inline-block;
    *display: inline;
    *zoom: 1;
  }
}