.requirement-add-dialog{
  width: 960px;
  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
    margin: 4px 8px;
  }

  .mock-dialog-header{
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;
    .dialog-title{
      font-weight: 700;
    }
    .mock-right-header{
      .header-doc-icon{
        font-size: 18px;
        cursor: pointer;
        &.edit-active-icon{
          color: #5477ff;
        }
        &:hover{
          color: #5477ff;
        }
      }
    }
    .close-area{
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 20px;
      .close-icon{
        font-size: 14px;
      }
      &:hover{
        background-color: #ECECEC;
      }
    }
  }
  >.content.content.content.content{
    padding: 0;
    .content-wrap{
      height: 80vh;
      flex: 1;
      overflow: scroll;
      padding: 8px 12px;
    }
    .mock-footer{
      display: flex;
      align-items: center;
      padding: 8px 0;
      height: 48px;
      box-sizing: border-box;
      border-top: 1px solid #E9EAF2;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      .continue-add-check{
        margin-left: 20px;
      }
      .btn-group{
        .button{
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
  .base-info-show-more{
    margin: 8px 40px;
    .base-info-show-more-text{
      cursor: pointer;
      color: #5477ff;
    }
  }
  .area-wrap{
    .area-header{
      margin: 12px 0;
    }
    .area-content-wrap{
      overflow: hidden;
    }
  }
  .title-prefix{
    padding: 10px;
    &.able:hover{
      cursor: pointer;
      color: #5477ff;
    }
    .arrow-icon{
      margin-top: 4px;
      margin-left: 6px;
    }
  }
  .fold-icon{
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;
    &:hover{
      color: #5477ff;
    }
  }
  .desc-wrap{
    .rich-text-area{
      margin-left: 40px;
      height: 300px;
    }
  }
  .doc-area{
    margin-left: 40px;
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput{
    .form-component, >span{
      margin-left: 6px;
    }
  }

  .rootnet-forminput.horizontal{
    .cascade-select{
      margin-left: 6px;
    }
  }
  .sales-project-id-options, .sub-sys-id-options{
    .rootnet-popup{
      .container{
        .option{
          min-width: 300px;
        }
      }
    }
  }
  .parent-req-wrap{
    height: 40px;
    justify-content: space-between;
    .parent-req-left-wrap{
      flex: 1;
    }
    .label-text{
      width: 108px;
      text-align: right;
      color: #738299;
      &::after {
        content: ":";
        margin: 0 8px 0 2px;
      }
    }
    .parent-req-text{
      flex: 1;
      margin-left: 6px;
      color: #5477ff;
      overflow: hidden;  //隐藏多余行数
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
      -webkit-box-orient: vertical;  //规定为垂直方向排列
    }
    .parent-req-handle-wrap{
      margin-right: 18px;
      .icon{
        font-size: 16px;
        margin: 0 4px;
        cursor: pointer;
        &:hover{
          color: #5477ff;
        }
        &.del-icon:hover{
          color: red;
        }
      }
    }
  }
  .tips-text{
    height: 20px;
    padding-left: 114px;
    .tips-icon{
      margin-top: 2px;
      margin-right: 6px;
      font-size: 16px;
      color: red;
    }
  }
}
.add-story-type-select{
  .type-name{
    height: 30px;
    width: 100px;
    padding: 12px 8px;
    cursor: pointer;
    &.current-type{
      color: #5477ff;
      background-color: #EAF4FC;
    }
    &:hover{
      background: rgba(0, 0, 0, 0.075);
    }
  }
}