.field-dict-drawer.field-dict-drawer.field-dict-drawer.field-dict-drawer{
  >.header{
    height: 48px;
  }
  >.content{
    width: 620px;
    align-items: flex-start;
    .field-dict-box{
      height: 100%;
    }
    .desc-item{
      height: 50px;
      .label{
        margin-left: 30px;
        color: #7A8199;
      }
      .value{
        color: #15171F;
      }
    }
  }
}