.plan-version-setting {
  height: 100%;
  overflow: auto;

  .header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10
  }

  .footer {
    position: sticky;
    bottom: 0;
    text-align: center;
    background-color: white;
    z-index: 10;
  }

  .mulcust-select-style {
    display: inline-flex;
    align-items: center;
    margin: 8px;
    box-sizing: border-box;

    >label {
      flex: none;
      text-align: right;
      margin-bottom: 0px;
      font-size: 14px;
      white-space: nowrap;
      color: #738299;

      &::after {
        content: ":";
        margin: 0px 8px 0px 2px;
      }
    }

    .ant-select {
      border-radius: 4px;

      .ant-select-selector {
        border-radius: 4px;
        height: 32px;
        overflow: auto;
      }
    }
  }
}