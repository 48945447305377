.review-minutes-detail-content-list {
  .add-btn-wrap {
    flex-direction: row-reverse;
  }

  .add-text {
    margin: 0 4px 4px 8px;
    cursor: pointer;
    color: #5477ff;
  }

  .ant-table-cell.ant-table-cell.ant-table-cell.ant-table-cell {
    height: 45px;
  }
}