.mobile-home-page.mobile-home-page.mobile-home-page{
  .mobile-main-panel{
    flex: 1;
    height: 100px;
  }
  .bottom-tab{
    height: 1rem;
    justify-self: flex-end;
    background: #fff;
    .bottom-tab-item{
      flex: 1;
      &.active-tab{
        background: #3f7dff;
        color: #fff;
      }
    }
  }
}