.dev-project-scope.dev-project-scope.dev-project-scope{
  width: 100px;
  height: 100%;
  flex: 1;
  .dev-project-scope-view-area{
    width: 100px;
    height: 100%;
    flex: 1;
    .dev-project-scope-view-selector-wrap{
      z-index: 6;
      position: absolute;
      margin-top: 6px;
      margin-left: 20px;
      .selector-label{
        color: #738299;
        font-size: 12px;
      }
      .dev-project-scope-view-selector{
        padding: 6px 12px;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 4px;
        transition: .4s cubic-bezier(.175,.885,.32,1);
        &:hover{
          background: rgba(0,0,0,.075);
        }
        .selector-text{
          position: relative;
          font-weight: bold;
          color: #5477ff;
        }
        .more-icon{
          margin-left: 4px;
        }
      }
    }
    .ant-tabs{
      width: 100%;
      height: 100%;
      .ant-tabs-nav{
        padding-left: 30px;
        display: none;
      }
      .view-area-wrap{
        .view-area{
          padding-left: 150px;
        }
      }
    }
  }
}

.dev-project-scope-view-selector-pop{
  padding: 8px;
  .selector-item{
    height: 34px;
    width: 120px;
    padding: 12px 8px;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.075);
    }
    &.active{
      color: #5477ff;
      background: #e6eef9;
    }
  }
}