.menu-mgt.menu-mgt.menu-mgt{
  .enter-detail-field-wrap{
    cursor: unset;
    width: 100%;
    .enter-detail-field{
      width: 100px;
      flex: 1;
      .enter-detail-field-text{
        cursor: pointer;
        color: #5477ff;
        &:hover{
          text-decoration:underline
        }
      }
    }
    .enter-detail-field-edit-icon{
      display: none;
      margin-left: 10px;
      cursor: pointer;
      font-size: 15px;
      &:hover{
        color: #5477ff;
      }
    }
    &:hover{
      .enter-detail-field-edit-icon{
        display: block;
      }
    }
  }

  &.is-tree{
    .view-table{
      flex: none;
      margin-bottom: 0;
      .card-content{
        display: none;
      }
    }
  }
}
.menu-system-add-group{
  padding: 4px 8px;
  .menu-system-item{
    height: 30px;
    padding: 12px 8px;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.075);
    }
    .type-tag{
      width: 50px;
      background-color: #5477ff;
      color: #fff;
      border-radius: 8px;
      height: 18px;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}