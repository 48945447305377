.case-defect-l.case-defect-l.case-defect-l {
  .check-item.check-item.check-item {
    position: absolute;
    width: 40px;
    height: 34px;
    cursor: pointer;
  }

  .x-card {
    .grid {
      .cell.cell.cell {
        .enter-detail-field {
          cursor: pointer;
          color: #5477ff;
        }
      }
    }
  }
}