.subsidy{
  .edit-disable{
    span{
      color: #D0D1D9;
      cursor: not-allowed
    }
  }
}
.subsidyDialog-promptBox{
  .content{
    width: 720px;
    max-height: 400px;
    flex-direction: column;
    >div:first-child{
      padding:10px 0;
      box-sizing: border-box;
    }
    .subsidyDialog-promptBox-table-fill{
      width: 100%;
      display: flex;
        overflow: hidden;
        .x-card{
            flex:1;
            overflow: auto;
            .card-content{
                display: flex;
                .x-datagrid{
                    flex: 1;
                }
            }
        }
    }
  }
  .id-col{
    cursor: pointer;
  }
  .arrow_drop_down{
    margin-right: 5px;
    display: inline-block;
  }
  
}
.rims_options{
  .btn.btn.btn.btn{
    width: 75px;
    margin-left: 15px;
    height: 31px;
  }
  .reset-btn.reset-btn.reset-btn{
    color: #3A415C;
    border: 1px solid #7A8199;
  }
}
