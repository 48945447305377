.slogan-text{
  &.container {
    height: 35px;
    line-height: 35px;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
  }

  .slogan {
    position: relative;
    display: inline-block;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
}