.show-field-wrap.show-field-wrap.show-field-wrap{
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 12px;
  width: 460px;
  .ant-table-wrapper{
    height: 100%;
    width: 100%;
  }
  .delete-text{
    cursor: pointer;
    color: #3f7dff;
    &:hover{
      color: red;
    }
  }
  &.full-screen{
    position: absolute;
    margin: 12px;
    width: calc(100% - 24px);
    height: calc(100vh - 62px);
    z-index: 4;
    .relation-card{
      height: 100%;
    }
  }
}