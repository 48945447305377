.customer-info-wrap {
  padding-top: 10px;
  justify-content: center;
  background: #F0F3F7;
  height: 100%;

  .email-subject-forminput {
    margin: 8px;

    .email-subject-label {
      color: #738299;
      text-align: right;

      &::after {
        content: ":";
        margin: 0px 8px 0px 2px;
      }
    }

    .email-subject-component {
      .add-icon {
        font-size: 14px;
        font-weight: 700;
        color: #5477ff;
        cursor: pointer;
      }

      .email-subject-order-item-all {
        margin-left: 16px;

        &.email-subject-order-item-all-detail {
          &:hover {
            margin-left: 16px;
          }
        }

        &:hover {
          margin-left: 0;
        }

        .add-before,
        .add-after {
          display: none;
          font-size: 14px;
          font-weight: 700;
          color: #5477ff;
          cursor: pointer;
        }

        &:hover .add-before {
          display: block;
          margin-right: 4px;
          // left: -22px;
        }

        &:hover .add-after {
          display: block;
          margin-left: 4px;
          // right: -18px;
        }

        .email-subject-order-item {
          border-radius: 2px;
          padding: 0 7px;
          background-color: rgb(240, 240, 240);
          font-size: 12px;
          align-items: center;

          .icon {
            cursor: pointer;
            margin-left: 8px;

            &:hover {
              color: #5477ff;
            }
          }
        }
      }
    }

  }
}

.customer-info.customer-info.customer-info {
  width: 1232px;
  max-width: 100%;
  background: #fff;
  overflow-y: scroll;

  .rootnet-forminput {
    &.one-line {
      display: flex;
      flex: 1;

      //padding-bottom: 8px;
      .extend-component {
        flex: 1;
      }
    }
  }

  .textarea-detail-wrap {
    min-height: 100px;
    margin: 8px 0;

    .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
      margin: 4px 0 4px 8px;
      height: 22px;
    }

    .textarea-detail-text {
      width: 100px;
      padding: 3px 14px 4px 3px;
      line-height: 22px;
      flex: 1;
      white-space: pre-line;
    }
  }

  .email-form-wrap {
    width: 90%;

    .mail-form {
      margin-right: 0;
    }
  }
}

.email-subject-popover {

  .add-popover-item {
    padding: 6px;
    cursor: pointer;

    &:hover {
      background-color: rgb(242, 242, 242);
    }
  }
}