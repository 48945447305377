.view-area-wrap{
  margin: 6px 12px;
  .view-area{
    .system-view-area{
      .system-view-item{
        padding: 6px 12px;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 4px;
        transition: .4s cubic-bezier(.175,.885,.32,1);
        .system-view-name{
          position: relative;
          font-weight: bold;
          color: #3c495c;
        }
        &:hover{
          background: rgba(0,0,0,.075);
          .system-view-name{
            color: #222;
          }
        }
        &.forbid{
          cursor: default;
          .system-view-name{
            color: #D8DAE2;
          }
          &:hover{
            background: unset;
          }
        }
        &.active-system-view-item{
          .system-view-name{
            color: #5477ff;
            &:after{
              position: absolute;
              bottom: -4px;
              display: block;
              width: 100%;
              content: ' ';
              border-bottom: 2px solid #0c64eb;
            }
          }
        }
        .more-icon{
          margin-left: 4px;
        }
        .total{
          color: #3c4353;
          background-color: #ddd;
          border-radius: 9px;
          display: inline-block;
          padding: 3px 5px;
          font-size: 12px;
          font-weight: 400;
          min-width: 18px;
          margin-left: 4px;
          line-height: 1;
        }
      }
      .search-btn{
        cursor: pointer;
        font-weight: bold;
        color: #3c495c;
        margin-left: 6px;
        &:hover{
          color: #5477ff;
        }
        &.search-area-open{
          color: #5477ff;
          &:before{
            position: absolute;
            bottom: -16px;
            left: 50%;
            width: 0;
            height: 0;
            content: ' ';
            border-color: transparent transparent #fff transparent;
            border-style: solid;
            border-width: 0 10px 10px 10px;
          }
        }
        .search-icon{
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }
  .custom-search-area{
    margin-top: 6px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 6px 10px rgba(0,0,0,0.1);
    .c-options{
      margin-top: 0;
    }
  }
}