.tracker-design-info{
  .design-info-form{
    flex-wrap: wrap;
    width: 100%;
    .rootnet-forminput.rootnet-forminput.rootnet-forminput {
      width: unset;
    }
  }
  .textarea-field-wrap{
    .rootnet-forminput{
      margin-right: 0;
      height: 32px;
    }
    .textarea-show-value{
      line-height: 32px;
      margin-top: 8px;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
  .real-use-time {
    display: inline-block;
    white-space: nowrap;
    color: #738299;
    font-size: 14px;
    width: 120px;
    text-align: right;

    &::after {
      content: ':';
      margin-left: 2px;
    }
  }
}