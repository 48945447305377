.mo-fa-bang-icon {
  color: #636363;
}

.content-template-select-popover {
  .content-template-select-popover-content {
    font-size: 12px;

    .ant-tabs {
      height: 260px;
      padding: 0 8px 0 8px;

      .ant-tabs-nav {
        margin: 0 0 8px;

        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              padding: 6px 0;
            }
          }
        }
      }

      .ant-tabs-content-holder {
        .ant-tabs-content {
          height: 100%;

          .ant-tabs-tabpane {
            height: 100%;

            .content-template-select-list {
              width: 180px;
              height: 100%;
              overflow: auto;

              .template-item {
                cursor: pointer;
                display: flex;
                width: 100%;

                &:hover {
                  color: #5477ff;
                }

                .template-item-icon {
                  margin-right: 8px;

                  .icon {
                    font-size: 14px;
                  }
                }

                .template-item-text {
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }
    }

    .template-mgt-btn {
      border-top: 1px solid #f0f0f0;
      padding: 6px 8px;

      .textIconBtn {
        margin-left: 0;
      }
    }
  }
}