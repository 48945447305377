.x.textIconBtn {
  position: relative;
  font-size: 14px;
  margin-left: 24px;
  cursor: pointer;

  &:hover {
    .textBox {
      display: block;
    }

    color: #5477ff;
  }

  &.tib-right {
    .textBox {
      right: 12px;
    }
  }

  &.tib-left {
    .textBox {
      left: 12px;
    }
  }

  &.disabled.disabled.disabled {
    background-color: white;
    color: #d6d8e0;
    pointer-events: none;
  }

  .icon {
    padding-right: 4px;
    font-size: 14px;
  }
}

.textBox {
  display: none;
  position: absolute;
  top: 24px;
  white-space: nowrap;
  padding: 6px;
  line-height: 1.5em;
  z-index: 8;
  background: rgba(0, 0, 0, 0.7);
  color: rgba(255, 255, 255, 0.8);
  box-shadow: 3px 3px 7px rgba(124, 124, 124, .7);
  border-radius: 3px;
  max-width: 470px;
}