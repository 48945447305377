.contract-info{
  .arrow_drop_down{
    margin-right: 5px;
    display: inline-block;
  }
  .col-hover-blue:hover {
    text-decoration: underline;
    cursor: pointer
  }

  .contract-info-card{
    >.grid{
      > div{
        .row{
          >.cell{
            >div{
              .budget-cost-wrap{
                width: 100%;
                height: 100%;
                align-items: flex-end;
                justify-content: center;
                .budget-cost-value{
                  height: 16px;
                }
                .progress-bg{
                  height: 4px;
                  width: 75px;
                  background: lightgray;
                  border-radius: 4px;
                  .progress{
                    height: 4px;
                    background: #0a365a;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}