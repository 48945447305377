.conference-records-participant-list{
  .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    padding: 6px;
  }

  .editable-cell {
    position: relative;
  }
  .editable-cell-value-wrap{
    padding: 5px 12px;
    height: 32px;
  }

  .editable-row{
    .editable-cell-value-wrap {
      cursor: pointer;
    }
  }

  .editable-row .editable-cell-value-wrap:hover {
    background-color: #E0E0E0;
  }

  .ellipsis-value{
    overflow: hidden;  //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical;  //规定为垂直方向排列
  }
}