.AuthorizeDialog {
  width: 1160px;
  max-height: 550px;
  //padding: 0;
  position: relative;

  .AuthorizeDialog-hour {
    .AuthorizeDialog-hour-top {
      height: 20px;
      margin: 12px 0 12px 12px;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      >div:nth-child(1) {
        color: #e15722;

        >span:nth-child(2) {
          margin-left: 12px;
        }
      }

      >div:nth-child(2) {
        margin-right: 12px;
      }
    }
  }

  .AuthorizeDialog-list {
    margin: 0;
    height: 300px;
    box-shadow: none;

    .x-datagrid {
      height: 100%;
      width: 100%;

      .grid {
        max-height: 300px;
      }

      .cell>.operations {
        display: flex;
        align-items: center;

        >span {
          position: relative;
          color: #5477ff;
          cursor: pointer;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:not(:last-child)::after {
            content: "";
            display: block;
            height: 60%;
            width: 1px;
            background-color: #d8d8d8;
            position: absolute;
            right: 0px;
          }

          &:hover {
            color: blue;
          }
        }
      }
    }
  }
}

div.x.dialog>div.AuthorizeDialog>div.content {
  //padding: 0;
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    margin-top: 12px;

    .left {
      width: 130px;
      color: #15171f;
      font-weight: 800;
      margin: 4px 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .right {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      color: #7a8199;

      >div {
        width: 150px;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

div.x.dialog>div.AuthorizeDialog>div.footer {
  background-color: white;
}

.AuthorizeDialogEdit {
  width: 300px;

  .header.header.header.header {
    background-color: rgb(255, 255, 255);
    color: #13151a;
    font-size: 18px;
  }

  .content {
    flex-direction: column;

    >div:first-child {
      margin-left: 26px;
    }

    >div:nth-child(2) {
      margin-left: 12px;
    }
  }
}

.AuthorizeDialogDel {
  font-size: 18px;

  .header.header.header.header {
    background-color: rgb(255, 255, 255);
    color: #13151a;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.forbidden {
  color: #d0d1d9;
  pointer-events: none;
}