.version-detail-dialog{
  .content.content.content.content{
    width: 940px;
    height: 450px;
    max-height: 70vh;
    overflow: auto;
    .rootnet-radio{
      margin-left: 0;
    }
    .rootnet-forminput{
      &.one-line{
        display: flex;
        flex: 1;
        //padding-bottom: 8px;
        .extend-component{
          flex: 1;
        }
      }

    }
    .textarea-detail-wrap{
      min-height: 100px;
      margin: 8px 0;
      .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
        margin: 4px 0 4px 8px;
        height: 22px;
      }
      .textarea-detail-text{
        width: 100px;
        padding: 3px 14px 4px 3px;
        line-height: 22px;
        flex: 1;
      }
    }
    .area-wrap{
      padding-top: 8px;
      .area-header{
        margin: 4px 0;
      }
      .area-content-wrap{
        overflow: hidden;
      }
    }
    .fold-icon{
      cursor: pointer;
      //transition: transform 0.3s;
      margin-left: 15px;
      margin-right: 18px;
      &:hover{
        color: #5477ff;
      }
    }
    .purpose-textarea{
      width: 838px;
      height: 230px;
      margin-left: 42px;
      margin-top: 8px;
    }
  }
}