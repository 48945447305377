.work-flow-list{
  padding: 6px 12px;
  &.hidden-page{
    display: none;
  }
  .work-flow-mgt-left-panel{
    width: 220px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    border-radius: 4px;

    .app-type-wrap{
      height: 100%;
      width: 100%;
      flex: 1;
      padding: 6px;
      overflow-y: auto;
      border-right: 1px solid #E6E8EB;
      .type-item{
        height: 32px;
        padding: 0 6px;
        cursor: pointer;
        &:hover{
          background-color: #EAF4FC;
          color: #5477ff;
        }
      }
      .ant-tree-treenode{
        width: 100%;
      }
      .ant-tree-node-content-wrapper{
        flex: 1;
      }
    }

    .fold-bar{
      width: 22px;
      cursor: pointer;
      &:hover{
        background-color: #EAF4FC;
        color: #5477ff;
      }
      .fold-icon{
        font-size: 18px;
      }
    }
  }
  .work-flow-mgt-right-panel{
    width: 200px;
    flex: 1;
    margin-left: 8px;
    .search-condition-wrap{
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 6px;
    }
    .x-card{
      margin: 0;
      .card-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .x-datagrid {
          height: 210px;
          font-size: 12px;
        }
      }
    }
  }
  .enter-field-wrap{
    width: 100%;
    justify-content: space-between;
    .enter-detail-field{
      width: 100px;
      flex: 1;
      .enter-detail-field-text{
        max-width: 100%;
        color: #5477ff;
        cursor: pointer;
        overflow: hidden;  //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical;  //规定为垂直方向排列
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .more-handle-icon.more-handle-icon.more-handle-icon{
      cursor: pointer;
      //margin-left: 10px;
      font-size: 14px;
      &:hover{
        color: #3f7dff;
      }
    }
  }
}
.work-flow-handle-popover{
  .handle-item{
    padding: 6px 12px;
    min-width: 150px;
    &.disabled{
      color: #d6d8e0;
      &:hover{
        color: #d6d8e0;
        cursor: not-allowed;
      }
    }
    &:hover{
      cursor: pointer;
      background: #efefef;
      color: #3f7dff;
    }
    &.del-item{
      &:hover{
        color: red;
      }
    }
    .handle-icon.handle-icon{
      font-size: 14px;
      margin-right: 10px;
      &.version-icon{
        transform: rotate(90deg)
      }
    }
  }
}