.version-upgrade-query{
  min-width: 700px;
  .options-area{
    .first-line-options{
      margin-left: 12px;
    }
    .second-line-options{
      margin-left: 12px;
      .rootnet-forminput{
        margin-top: 0;
      }
    }
    .query-btn{
      height: 30px;
      width: 72px;
      margin-left: 12px;
    }
  }
  .middle-area{
    .x-card{
      margin-top: 0;
    }
    .x-card:nth-of-type(1){
      margin-right: 0;
    }
    .card-content-wrap{
      padding: 8px 16px;
      height: 80px;
      overflow: auto;
      justify-content: space-around;
    }
    .issues-collection-count{
      .issue-coverage{
        margin-left: 40px;
      }
    }
    .issues-progress{
      width: 100%;
      .progress-bar{
        border-radius: 4px;
        margin-right: 1px;
        cursor: pointer;
        &:hover{
          opacity: 0.75;
        }
      }
    }
    .upgrade-detail{
      .arrow{
        color: #C0C0C0;
      }
    }
  }
}