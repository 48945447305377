.resource-project-search.resource-project-search.resource-project-search.resource-project-search{
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 6px 0 6px 6px;
  .search-wrap{
    width: 100%;
    margin-bottom: 4px;
    .suffix{
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #E6E8EB;
      .search-icon{
        font-size: 16px;
      }
    }
  }
  .project-list-wrap{
    height: 200px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    border: 1px solid #E6E8EB;
    border-radius: 8px;
    .project-item{
      width: 100%;
      height: 36px;
      padding: 6px;
      cursor: pointer;
      &.selected{
        background-color: rgba(84, 119, 255, 0.1);
        color: #5477ff;
      }
      &:hover{
        color: #5477ff;
        background-color: #F5F5F5;
      }
      .project-name{
        flex: 1;
        font-size: 12px;
        white-space: nowrap;/*不允许换行*/
        overflow: hidden;/*超出隐藏*/
        text-overflow: ellipsis;/*文本超出三点代替*/
      }
      .project-principal{
        margin: 0 6px;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        padding: 0 4px;
      }
      .project-time{
        font-size: 12px;
      }
      .to-detail-icon{
        font-size: 18px;
        cursor: pointer;
        color: #5477ff;
      }
    }
  }
}