.left-department-menu {
  width: 330px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  border-radius: 4px;

  .dept-search-wrap {
    flex: 1;

    .ant-tabs {
      width: 100%;
      height: 100%;

      .ant-tabs-nav {
        padding-left: 20px;
        margin-bottom: 2px;
      }

      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane{
          height: 100%;
        }
      }
    }
  }

  .fold-bar {
    width: 22px;
    cursor: pointer;

    &:hover {
      background-color: #EAF4FC;
      color: #5477ff;
    }

    .fold-icon {
      font-size: 18px;
    }
  }
}