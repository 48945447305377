.tracking-add-dialog{
  height: 90vh;
  width: 1060px;
  display: flex;
  flex-direction: column;
  .content{
    height: 100px;
    flex: 1;
    .content-wrap{
      height: 100px;
      flex: 1;
      .main-content{
        width: 100px;
        flex: 1;
        overflow: auto;
        .area-wrap{
          .area-header{
            margin: 4px 0;
          }
          .area-content-wrap{
            overflow: hidden;
            &.desc-wrap{
              margin-left: 20px;
            }
            &.rich-text-area{
              margin-left: 28px;
              height: auto;
              width: 852px;
            }
          }
        }
        .fold-icon{
          cursor: pointer;
          //transition: transform 0.3s;
          margin-left: 10px;
          margin-right: 18px;
          &:hover{
            color: #5477ff;
          }
        }
        .rootnet-radiogroup{
          width: 180px;
        }
        .tab-header{
          margin-left: 8px;
          width: 850px;
        }
      }
      .anchor-wrap{
        margin-left: 4px;
        padding-left: 4px;
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar{
          width: 0;
        }
        .anchor-text{
          &::before {
            display: inline-block;
            margin-right: 4px;
            font-family: SimSun,sans-serif;
            line-height: 1;
            content: '*';
            color: #F5221B;
          }
        }
      }
    }
  }
}