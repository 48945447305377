.content-template-detail-content {
  padding: 0;
  height: 100%;
  width: 100%;
  // overflow: scroll;
  background-color: #fff;

  .mock-dialog-header {
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;

    .dialog-title {
      font-weight: 700;
    }

    .mock-right-header {
      .header-edit-icon {
        font-size: 18px;
        cursor: pointer;

        &.edit-active-icon {
          color: #5477ff;
        }

        &:hover {
          color: #5477ff;
        }
      }

      .textIconBtn {
        margin-left: 16px;
      }

      .switch {
        transform: scale(0.9, 0.9);
        .ball{
          transition: unset;
        }
      }
    }

    .close-area {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 20px;

      .close-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: #ECECEC;
      }
    }
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    border-top: 1px solid #d0d3e3;

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

  .content-template-detail-main-panel {
    height: 100px;
    padding: 8px 12px;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .form-module {
      .authorization-display {
        align-items: center;
        font-size: 12px;
        margin-left: 40px;

        >div {
          margin-right: 16px;
        }

        .selected-num {
          color: #B3B3B3;
        }

        .click-configuration {
          cursor: pointer;
          color: #21B2F1;
        }

        .click-configuration-detail.click-configuration-detail {
          color: #B3B3B3;
          cursor: no-drop;
        }
      }
    }

    .template-details {
      flex: 1;
      display: flex;
      flex-direction: column;

      .template-details-header {
        color: #738299;
        padding-left: 8px;
        margin: 8px 0;

        &::before {
          display: inline-block;
          margin-right: 4px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
          color: #F5221B;
        }
      }

      .template-details-content {
        flex: 1;
        display: flex;

        .rich-text-area {
          flex: 1;
        }

        .rich-text-detail-wrap {
          flex: 1;
          width: 100%;
          overflow: auto;
          padding: 12px 30px;
          white-space: pre-wrap;
          line-height: 100%;

          img {
            max-width: 100%;
          }
        }
      }
    }

  }

}