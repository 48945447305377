.isTTemplateMgt.isTTemplateMgt.isTTemplateMgt {
  display: none;
}

.template-mgt {
  .x-card {
    .card-content {
      .row>.cell:hover .edit-icon {
        margin-left: 8px;
        display: inline-block;
      }

      .table-edit {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .x.switch {
          transform: scale(0.9, 0.9);
        }

        .rootnet-forminput {
          margin: 0;
          height: 24px;
        }

        .title {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #5477ff;
        }

        .edit-icon {
          display: none;
          cursor: pointer;

          i {
            margin-right: 8px;
            font-size: 14px;
          }

          & i:hover {
            color: #5477ff;
          }
        }

      }
    }
  }

  .button-disabled.button-disabled.button-disabled.button-disabled.button-disabled {
    &.ant-btn {
      background-color: #D8DAE2;
      border-color: #d9d9d9;

      &:hover {
        border-color: #d9d9d9;
        background-color: #D8DAE2;
      }
    }
  }

  .template-button.template-button.template-button {
    margin-left: 24px;
    border-radius: 4px;

    &.ant-btn {
      background: #5477ff;
      border-color: #5477ff;
      color: white;

      &:hover {
        background: #7692FF;
        border-color: #7692FF;
      }
    }
  }

  .template-mgt-card {
    flex: 1;
    overflow: auto;
  }
}