.customer-info-detail-dialog {
  height: 320px;
  width: 585px;
  display: flex;

  >.content.content.content.content {
    padding: 0;
    width: 100px;
    height: 100%;
    display: flex;
    flex: 1;

    .can-click-id {
      color: #5477ff;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}