.dev-project-risk-follow-up {
  .x-datagrid {
    .grid.grid.grid {
      .cell {
        padding: 0;
      }
    }

    .common-display-field {
      padding: 0 12px;
    }

    .common-edit-field {
      padding: 0 12px;
      cursor: pointer;
      height: 34px;

      &:hover {
        background: #EBF0FB;
      }
    }

    .more-handle-icon.more-handle-icon.more-handle-icon {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;

      &:hover {
        color: #3f7dff;
      }
    }

    .left-enter-field-wrap {
      width: 100px;
      flex: 1;
    }

    .enter-detail-field-wrap {
      cursor: unset;
      width: 100%;

      .enter-detail-field {
        width: 100px;
        flex: 1;

        .enter-detail-field-text {
          max-width: 100%;
          cursor: pointer;
          color: #5477ff;
          overflow: hidden; //隐藏多余行数
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
          -webkit-box-orient: vertical; //规定为垂直方向排列

          &:hover {
            text-decoration: underline
          }
        }
      }

      .enter-detail-field-edit-icon {
        display: none;
        margin-left: 10px;
        cursor: pointer;
        font-size: 15px;

        &:hover {
          color: #5477ff;
        }
      }

      &:hover {
        .enter-detail-field-edit-icon {
          display: block;
        }
      }
    }
  }
}