.markdown-editor-wrap.markdown-editor-wrap.markdown-editor-wrap{
  width: 100%;
  height: 100%;
  >div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .milkdown-menu-wrapper{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .milkdown{
      width: 100%;
      height: 100px;
      flex: 1;
      overflow-y: auto;
      box-shadow: none;
      border: 1px solid #e1e1e1;
      .editor{
        padding: 20px;
        *{
          //margin: 10px 0;
        }
      }
    }
  }
}
