.problemResearch-myFeedback {
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;

  .myFeedback-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: #f8f8f8;
    display: flex;
    font-size: 14px;
    padding: 12px 16px;
    box-sizing: border-box;
    font-weight: 700;

    >div:nth-child(1) {
      flex: 1;
    }

    >div:nth-child(2) {
      width: 200px;
    }
  }

  .ant-collapse {
    border: none;
    background-color: white;

    .ant-collapse-content-active {
      min-height: 100px;
      max-height: 200px;
      overflow-y: auto;
    }

    .merge {
      display: flex;

      .display-details {
        flex: 1;
      }
    }

    .display-details {
      font-size: 14px;
      display: flex;
      margin-bottom: 8px;

      >span:nth-child(1) {
        font-weight: 700;
      }

      >span:nth-child(2) {
        color: #333333;
      }

      .myFeedback-text {
        white-space: pre-wrap;
      }

      .solution {
        flex: 1;

        .solution-data {
          background-color: rgba(193, 192, 197, 0.4);
          border-radius: 4px;
          margin-bottom: 8px;
          padding: 8px;
          box-sizing: border-box;

          >div:nth-child(1) {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;

            >span {
              flex: 1;
            }

          }
        }
      }
    }

    .mapStyle {
      display: flex;
      width: 100%;

      >div:nth-child(1) {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      >div:nth-child(2) {
        width: 176px;
      }
    }

    .ant-collapse-content {
      border: none;
      background-color: #F5F8FF;
    }

    // .ant-collapse-content-box {
    //   background-color: #F5F8FF;
    // }
  }
}