.adjust-state-edit-dialog {
  height: 240px;
  width: 400px;
  display: flex;
  flex-direction: column;

  >.content.content.content.content {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;

    .can-click-id {
      color: #5477ff;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .adjust-state-edit-content {
      padding: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;

      .mock-dialog-header {
        display: flex;
        align-items: center;
        color: #15171f;
        padding: 12px 16px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #e9eaf2;
        justify-content: space-between;

        .dialog-title {
          font-weight: 700;
        }

        .mock-right-header {
          .header-edit-icon {
            font-size: 18px;
            cursor: pointer;

            &.edit-active-icon {
              color: #5477ff;
            }

            &:hover {
              color: #5477ff;
            }
          }

          .textIconBtn {
            margin-left: 8px;
          }
        }

        .close-area {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          cursor: pointer;
          margin-left: 20px;

          .close-icon {
            font-size: 14px;
          }

          &:hover {
            background-color: #ECECEC;
          }
        }
      }
    }
  }
}