.template-inForm {
  width: 85%;
  height: 90%;
  display: flex;
  flex-direction: column;

  .multiple-header {
    display: flex;
    padding: 8px 16px;
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    width: calc(100% - 50px);
    right: 0;
    background: #fafafa;

    .header-left {
      flex: 1;
      display: flex;

      .textIconBtn {
        margin-left: 0;
      }

      .pointer {
        cursor: pointer;
      }

      >div {
        margin-right: 16px;
      }
    }

    .header-right {
      >div {
        cursor: pointer;

        &:hover {
          color: #5477ff;
        }
      }
    }
  }

  .header {

    .title {
      width: 100%;
    }

    .header-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 400;

      .lef {
        display: flex;

        >div:nth-child(1) {
          font-weight: 900;
          max-width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        >div:nth-child(2) {
          transform: scale(0.9, 0.9);
          margin-left: 8px;
          font-size: 12px;
          color: white;
          padding: 0 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
        }
      }

      .rig {
        display: flex;

        .operate-icon {
          margin-left: 16px;

          >i {
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
  }

  .content.content.content.content.content {
    padding: 0;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .template-content {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .template-table {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
      }

      .ant-table-wrapper {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .ant-spin-nested-loading,
        .ant-spin-container,
        .ant-table,
        .ant-table-container {
          height: 100%;
        }

        .ant-table-container {
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }

        .ant-table-body {
          flex: 1;
          overflow: auto;
        }
      }
    }

    .ant-tabs {
      .ant-tabs-nav {
        margin: 0;
      }

      .ant-tabs-nav-wrap {
        padding-left: 8px;
      }
    }

    .ant-table-body {
      .editable-row:hover .order {
        display: none;
      }

      .editable-row:hover .template-add-btn {
        display: block;
      }

      .shown-below {

        .template-add-btn {
          display: none;

          i {
            font-size: 14px;
            color: #5477ff;
            cursor: pointer;
            padding: 0;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    border-top: 1px solid #E9EAF2;

    .button {
      height: 32px;
      margin-right: 16px;
    }
  }
}

.template-add-btn {
  display: none;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap-lz {
  width: 100%;
  height: 22px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;

  &:hover {
    background-color: #E0E0E0;
  }
}