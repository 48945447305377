div.x.dialog>div.modal div.dialog-change-password {
    &>div {
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 14px;

        >span:first-child {
            width: 100px;
            text-align: right;
            margin-right: 16px;
        }

        >div.x.text-input {
            width: 200px;
            margin-right: 8px;
        }

        >i.iconfont {
            color: green;
            visibility: hidden;
            font-size: 18px;
        }
    }

    &.confirm {
        &>div {
            >i.iconfont {
                visibility: visible;
            }
        }
    }
}

div.v-app {
    display: flex;
    flex-direction: column;
    background: #f0f3f7;

    .art-table-header.art-table-header.art-table-header.art-table-header,
    .art-table-footer.art-table-footer.art-table-footer.art-table-footer {
        z-index: 9;
    }


    >div.appbar {
        position: relative;
        flex: none;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: #0747A6;
        padding: 0px 0px 0px 8px;
        overflow: hidden;

        >div.title {
            color: white;
            font-size: 20px;
            font-weight: bold;
            display: flex;
            align-items: center;

            img {
                width: 49px;
                height: 45px;
            }
        }

        >div.header-user-operation {
            height: 100%;
            display: flex;
            padding: 0 16px;
        }

        >div.actions {
            display: flex;
            align-items: center;
            height: 100%;
            color: white;

            >div.account {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0px 16px;
                cursor: pointer;

                >.symbol-icon {
                    font-size: 28px;
                }

                &:hover,
                &.open {
                    background-color: rgb(10, 54, 90);

                    >.iconfont {
                        color: white;
                    }
                }

                &.open {
                    cursor: default;
                }

                >div.dropdown {
                    background-color: rgb(10, 54, 90);
                    position: absolute;
                    top: 100%;
                    right: 0px;
                    z-index: 10;
                    padding: 8px;
                    display: flex;
                    flex-direction: column;
                    border-radius: 0px 0px 4px 4px;

                    li {
                        display: block;
                        white-space: nowrap;
                        line-height: 36px;

                        >span {
                            margin: 0px 8px;
                        }
                    }

                    >div.footer {
                        display: flex;
                        margin: 8px 4px;

                        >div.x.button {
                            width: 120px;
                        }
                    }
                }
            }
        }

        .appbar_help {
            height: 100%;

            &:hover {
                background-color: #0a365a;
            }

            >div.x.button {
                color: lightgray;
                background-color: transparent;
            }
        }
    }

    /*    >div.main-panel {
        flex: 1;
        display: flex;
        height:50%;

        >div.left-menu {
            background: #fff;
            flex: none;
            display: flex;
            flex-direction: column;
            border-right: 1px solid rgb(235, 235, 235);

            >div.x.button {
                border-bottom: 1px solid rgb(240, 240, 240);
                border-radius: 0px;
                margin: 0px;
                height: 40px;
                flex: none;
                background-color: transparent;

                >i.iconfont {
                    font-size: 16px;
                }
            }
        }

        >div.stretch {
            flex: 1;
            position: relative;
            width: 100%;
            overflow: auto;
        }
    }
    .main-panel{
        .x-menu-ext.wide{
            width: 220px;
        }
    }*/
}

div.x.messagebox>div.modal>div.footer>div.x.button {
    min-width: 60px;
    width: unset;
}

.card-scroll {
    overflow: auto;

    >.card-content.card-content.card-content {
        overflow: auto;
    }
}

// 引导
.introjs-tooltipReferenceLayer {
    .introjs-tooltip {

        .introjs-button {
            padding: 2px 10px;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.ant-popover-inner-content {
    padding: 0;
    width: 100%;
}

.can-enter-text {
    cursor: pointer;
    color: #5477ff;

    &:hover {
        text-decoration: underline;
    }
}

.can-click-icon{
    cursor: pointer;
    &:hover{
        color: #5477ff;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.space-between{
    justify-content: space-between;
}

br{
    display: block;
    content: "";
}

.one-line-text{
    white-space: nowrap;/*不允许换行*/
    overflow: hidden;/*超出隐藏*/
    text-overflow: ellipsis;/*文本超出三点代替*/
}