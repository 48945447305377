.daily-report-mgt{
  .x-card.x-card.x-card{
    height: 40px;
    margin-bottom: 4px;
    flex: unset;
  }
  .two-columns-wrap{
    height: 100px;
    flex: 1;
    padding: 0 12px 12px;
    .left-panel{
      box-shadow: 0 6px 20px rgba(0,0,0,0.1);
      margin-right: 8px;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #e1e1e1;
      width: 300px;
      height: 100%;
      .resource-dept-search{
        padding: 6px;
      }
      .list-area{
        height: 100px;
        overflow-y: auto;
        flex: 1;
      }
      .list-item{
        height: 46px;
        width: 100%;
        padding: 0 12px;
        cursor: pointer;
        border-bottom: 1px solid #e9eaf2;
        &.active{
          background: #F3F7FF;
        }
        &:hover{
          background: #F3F7FF;
        }
      }
      .x-pagination{
        margin: 5px;
      }
      .pagination-area{
        padding: 8px;
      }
    }
    .right-panel{
      width: 200px;
      height: 100%;
      flex: 1;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #e1e1e1;
      box-shadow: 0 6px 20px rgba(0,0,0,0.1);
      .right-panel-header{
        height: 46px;
        border-bottom: 1px solid #e9eaf2;
        justify-content: space-between;
        padding: 0 16px;
        .title{
          min-width: 350px;
        }
        .right-panel-header-handle{
          .textIconBtn{
            margin-right: 8px;
          }
          .button{
            width: 80px;
            height: 30px;
            margin: 0 8px;
          }
        }
      }
      .markdown-area-wrap{
        height: 100px;
        width: 100%;
        flex: 1;
        background-color: #EEF1F5;
      }
      .x-datagrid {
        flex: 1;
        height: 100px;
        border-top: none;
        font-size: 12px;
      }

      .x-pagination {
        flex: none;
        padding-right: 20px;
        justify-content: flex-end;
        border-top: 1px solid #e6e6e6;
      }

      .x-datagrid{
        .grid.grid.grid{
          .cell{
            .enter-detail-field-wrap{
              cursor: unset;
              width: 100%;
              .enter-detail-field{
                width: 100px;
                flex: 1;
                .enter-detail-field-text{
                  max-width: 100%;
                  cursor: pointer;
                  color: #5477ff;
                  overflow: hidden;  //隐藏多余行数
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
                  -webkit-box-orient: vertical;  //规定为垂直方向排列
                  &:hover{
                    text-decoration:underline
                  }
                }
              }
              .enter-detail-field-edit-icon{
                display: none;
                margin-left: 10px;
                cursor: pointer;
                font-size: 15px;
                &:hover{
                  color: #5477ff;
                }
              }
              &:hover{
                .enter-detail-field-edit-icon{
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  .full-screen{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 6;
    padding: 8px 12px 12px;
    .full-screen-wrap{
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 6px 20px rgba(0,0,0,0.1);
      .full-screen-header{
        height: 50px;
        width: 100%;
        padding: 0 20px;
        border-bottom: 1px solid #e9eaf2;
        justify-content: space-between;
        .title-wrap{
          min-width: 400px;
        }
        .handle-group{
          min-width: 250px;
          justify-content: flex-end;
          .textIconBtn{
            margin-right: 8px;
          }
          .button{
            width: 80px;
            height: 30px;
            margin: 0 8px;
          }
        }
      }
      .full-screen-markdown-wrap{
        height: 100px;
        width: 100%;
        background-color: #EEF1F5;
        //background-color: #FFF;
        flex: 1;
        &.detail{
          .milkdown{
            //border: none;
          }
        }
        .milkdown{
          width: 80%;
        }
      }
    }
  }
  .footer-switch {
    .footer-switch-item {
      cursor: pointer;
      padding: 4px 12px;
      border-radius: 18px;
      box-sizing: border-box;
      display: flex;
      margin-right: 12px;
      color: #fff;
      background-color: #5477ff;
      transition: background-color 0.4s;

      &:hover {
        background-color: #214eff;
      }

      &.disabled {
        color: #6A6A6A;
        background-color: #E8E8E8;
        cursor: not-allowed;

        &:hover {
          background-color: #E8E8E8;
        }
      }

      >i {
        font-size: 14px;
      }
    }

    .list-num {
      font-weight: bold;
    }
  }
}

.daily-report-type-add-group{
  padding: 4px 8px;
  .daily-report-type-add-item{
    height: 30px;
    width: 100px;
    padding: 12px 8px;
    cursor: pointer;
    &:hover{
      background: #F3F7FF;
      color: #5477ff;
    }
  }
}