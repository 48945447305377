.conference-records-todo-list{
  .todo-del-icon{
    cursor: pointer;
    &:hover{
      color: #5477ff;
    }
  }

  .title-text{
    cursor: pointer;
    color: #5477ff;
  }

  .header-required:before{
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    content: '*';
    color: #F5221B;
  }

  .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    padding: 6px;
  }

  .editable-cell {
    position: relative;
  }
  .editable-cell-value-wrap{
    padding: 5px 12px;
    min-height: 32px;
  }

  .editable-row{
    .editable-cell-value-wrap {
      cursor: pointer;
    }
  }

  .editable-row .editable-cell-value-wrap:hover {
    background-color: #E0E0E0;
  }

  .ellipsis-value{
    overflow: hidden;  //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical;  //规定为垂直方向排列
  }
}
.todo-detail-dialog{
  height: 86vh;
  width: 1000px;
  display: flex;

  >.content.content.content.content {
    padding: 0;
    width: 100px;
    height: 100%;
    display: flex;
    flex: 1;
  }
}