.sales-product-list.sales-product-list.sales-product-list{
  .search-btn-group{
    margin-bottom: 1px;
    .button{
      margin: 6px 8px 0;
    }
    .reset-btn{
      border: 1px solid #7A8199
    }
  }
  .arrow-drop-down-group{
    cursor: pointer;
    .arrow-drop-down{
      font-size: 16px;
      display: inline-block;
      padding:0 12px;
    }
    .tree-text{
      cursor: pointer;
      &:hover{
        color: #3f7dff;
      }
    }
  }
  .editable-table-item{
    height: 100%;
    width: 100%;
    cursor: pointer;
    padding: 0 8px;
    .normal-table-item{
      height: 100%;
      width: 100%;
      line-height: 34px;
      &:hover{
        color: #3f7dff;
      }
    }
    .rootnet-input{
      width: 100%;
    }
    &:hover{
      background-color: rgba(52,143,228,.1);
    }
  }
  .table-item-wrap{
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    .handle-icon-group{
      .remove-icon, .edit-icon{
        font-size: 16px;
        margin-left: 8px;
        &:hover{
          color: #3f7dff;
        }
      }
      .remove-icon{
        margin-right: 8px;
      }
    }
    .table-item-text{
      margin-left: 69px;
      &:hover{
        color: #3f7dff;
      }
    }
  }
  .card-content{
    .header{
      font-size: 14px;
    }
    .cell{
      padding: 0;
      font-size: 14px;
      .remove-icon, .edit-icon{
        visibility: hidden;
      }
      &:hover{
        .remove-icon, .edit-icon{
          visibility: unset;
        }
      }
    }
  }
  div.x-datagrid>div.grid div.row.selected {
    background-color: #EDF1FF;
  }
}