.version-making-add-dialog.version-making-add-dialog.version-making-add-dialog {
  width: 720px;
  height: 75%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content.content.content {
    flex: 1;
    padding: 0;
    overflow: auto;
  }
}