.is-only-log {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .is-only-log-text {
      text-align: center;
      margin-top: 20px;
    }

    .hint {
      font-size: 12px;
      color: #cccccc;
    }
  }
}