.globalDisplay.globalDisplay.globalDisplay {
  position: fixed;
  right: 30px;
  bottom: 70px;
  // width: 50px;
  // height: 50px;
  // background-color: #5477ff;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #5477ff;
  cursor: pointer;
}

.ScheduleManagement.ScheduleManagement.ScheduleManagement.ScheduleManagement.ScheduleManagement {
  position: fixed;
  bottom: 70px;
  width: 500px;
  height: 150px;
  right: 30px;
  z-index: 30;
  border: 1px solid #D0D3E3;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D0D3E3;
    padding: 8px;
    box-sizing: border-box;

    >span:nth-child(2) {
      >i {
        cursor: pointer;
        margin: 0px 8px;
        font-size: 16px;
        color: black;

        &:hover {
          color: #5477ff;
        }
      }
    }
  }

  .content.content.content {
    flex: 1;
    overflow: auto;
    padding: 0 8px;
    box-sizing: border-box;

    >dl {
      margin: 0;
      display: flex;
      padding: 16px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #D0D3E3;

      dd {
        padding: 0 8px;
        box-sizing: border-box;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        >i {
          font-size: 24px;
        }
      }

      dt {
        flex: 1;
        display: flex;
        justify-content: space-between;

        .success {
          color: #33cc8a;
        }

        .loader {
          .text {
            display: none;
          }
        }

        >div:first-child {
          >div:nth-child(1) {
            font-size: 16px;
          }

          >div:nth-child(2) {
            font-size: 12px;
          }
        }

        >div:last-child {
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}