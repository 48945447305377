.requirement-detail-right-panel {
  width: 350px;
  flex: 1 1;
  overflow: auto;
  border: 1px solid #d0d3e3;
  border-radius: 4px;
  margin-left: 12px;
  padding: 4px 0;

  .area-wrap {
    .area-header {
      margin: 4px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .leafProjectIds-tip{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 15px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .workload-num.workload-num.workload-num.workload-num.workload-num {
    margin: 2px 0;

    label {
      font-size: 12px;
    }

    input {
      text-indent: 0;

      &::-webkit-input-placeholder {
        text-indent: 0;
      }
    }
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 2px 8px;
  }

  .release-list-wrap {
    .mock-label {
      align-self: flex-start;
      //margin: 8px 6px 8px 8px;
      margin: 4px 6px 8px 8px;
      width: 120px;
      color: #738299;
      text-align: right;

      &::after {
        content: ":";
        margin: 0 8px 0 2px;
      }
    }
  }

  .join-version-icon,
  .join-project-icon {
    cursor: pointer;
  }

  .release-list-show-wrap {
    width: 180px;
    //border: 1px solid #D0D3E3;
    border-radius: 4px;
    margin-top: 2px;
    //border-radius: 0 0 4px 4px;
    //border-top: 0;
    //margin-top: -8px;
    overflow: hidden;
    margin-bottom: 6px;

    .release-list-item {
      width: 180px;
      //padding: 3px 12px;
      padding: 3px 0;

      .release-list-item-text {
        flex: 1;
        width: 50px;
        white-space: nowrap;
        /*不允许换行*/
        overflow: hidden;
        /*超出隐藏*/
        text-overflow: ellipsis;
        /*文本超出三点代替*/
      }

      .release-list-item-icon {
        font-size: 14px;
        color: #ccc;
        cursor: pointer;
        display: none;
      }

      //&:hover {
      //  background-color: #f8f8f8;
      //  .release-list-item-icon{
      //    display: block;
      //  }
      //}
    }
  }

  .leaf-project-id-form-input {
    min-height: 32px;
  }
}