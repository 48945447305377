.data-auth-add-role{
  .content.content.content.content{
    padding: 16px;
  }
  .long-options{
    .rootnet-popup{
      .container{
        .option{
          min-width: 200px;
        }
      }
    }
  }
}