.imp-project-mgt{
  display: flex;
  flex-direction: column;
  .all-textIconBtn{
    margin-left: 14px;
  }
  .enter-detail-field{
    cursor: pointer;
    color: #5477ff;
    &:hover{
      text-decoration:underline
    }
  }
  .x-progress {
    width: 100%;

    .x-progress-inner {
      min-width: 100px;
    }

    .x-progress-info {
      width: 44px;
      margin-right: 6px;
    }
  }
}

.img-project-mgt-more-item-group{
  padding: 6px;
  .more-item{
    min-width: 130px;
    padding: 6px;
    height: 34px;
    cursor: pointer;
    &:hover{
      color: #5477ff;
      background-color: #E6F7FF;
    }
  }
}