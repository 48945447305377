//设置省略号--ylf
.c-ellipsis{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

//设置options通用样式--ylf
.c-options.c-options {
  display: flex;
  align-items: flex-end;
  margin: 8px 0;
  padding: 4px 0 4px 4px;
  font-size: 14px;
  .rootnet-forminput{
    margin:0 8px;
  }

  .x.button {
    margin-left:56px;
    margin-right:12px;
    height:31px;
    min-width: 74px;
  }
}
