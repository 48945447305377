.requirement-stage-wrap{
  height: 100%;
  flex: 1;

  .relate-document-icon-wrap{
    color: #5477ff;
    cursor: pointer;
  }
  .relate-conference-icon{
    color: #5477ff;
    cursor: pointer;
    margin-left: 18px;
  }
  .handle-header-wrap{
    flex-direction: row-reverse;
    .right-header-wrap{
      .button{
        height: 34px;
      }
    }
    .total-text{
      flex: 1;
      margin-left: 4px;
    }
    .ant-alert{
      flex: 1;
      margin-right: 12px;
      height: 34px;
      .selected-message{
        margin-left: 8px;
        .selected-count{
          color: #1890FF;
          margin: 0 2px;
        }
        .bulk-del-text{
          color: #1890FF;
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
  }

  .ant-table-sticky-scroll{
    display: none;
  }
  .task-name-header{
    overflow: hidden;  //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical;  //规定为垂直方向排列
  }
  .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    padding: 6px;
  }

  .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container{
    height: 100%;
  }
  .ant-table-body{
    height: calc(100% - 35px);
    table{
      height: 100%;
    }
  }
  .ant-table-cell{
    height: 45px;
  }

  .ant-checkbox-wrapper{
    margin: -3px !important;
  }

  tr.ant-table-measure-row{
    // display: none;
    visibility: collapse;
  }

  &.can-edit{
    .ant-table-wrapper{
      height: calc(100% - 34px);
    }
  }
  &.can-not-edit{
    .ant-table-wrapper{
      height: calc(100% - 12px);
    }
  }
  .conference-field{
    .conference-item{
      .conference-text{
        max-width: 100%;
        width: auto;
        color: #5477ff;
        cursor: pointer;
        overflow: hidden;  //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical;  //规定为垂直方向排列
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .work-flow-status-field{
    cursor: pointer;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 5;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-cell {
  position: relative;
}
.requirement-stage-editable-cell-value-wrap{
  padding: 5px 12px;
  height: 32px;
}

.editable-row{
  .requirement-stage-editable-cell-value-wrap {
    cursor: pointer;
  }
}

.editable-row .requirement-stage-editable-cell-value-wrap:hover {
  background-color: #E0E0E0;
}