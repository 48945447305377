.project-cost-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .x-card-singlegrid {
    flex: 1;
  }

  .project-cost-detail-option {
    &.c-options {
      flex-wrap: wrap;
      display: flex;
      align-items: flex-end;
      margin: 0;
      padding: 4px 0 4px 4px;
      font-size: 14px;

      .query-condition {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      .rootnet-forminput {
        margin: 8px;
      }

      .search-btn-group {
        .x.button {
          margin: 6px 8px;
        }

        .reset-btn {
          border: 1px solid #7A8199;
        }
      }
    }
  }
}