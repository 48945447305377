.resource-plan-customize.resource-plan-customize.resource-plan-customize{
  .x-datagrid {
    border-top: none;
    font-size: 12px;
  }
  .add-module-wrap{
    margin: 8px 0;
  }
  .module-col-add{
    margin-left: 4px;
    font-size: 14px;
    cursor: pointer;
    color: #5477ff;
  }
  .delete-icon{
    margin-left: 4px;
    font-size: 14px;
    cursor: pointer;
    color: red;
  }
}