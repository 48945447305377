.dev-project-info-wrap.dev-project-info-wrap {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .dev-project-handle-header {
    width: 100%;
    justify-content: space-between;
    padding: 8px 0 8px 16px;
    border-bottom: 1px solid #F0F0F0;

    .handle-title {
      flex: 1;
      display: flex;

      .button {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .dev-title-wrap {
        width: 100%;

        .dev-type {
          background-color: #5477ff;
          color: #fff;
          padding: 0 8px;
          border-radius: 8px;
          height: 18px;
          font-size: 14px;
        }

        .dev-title {
          margin-left: 4px;
          cursor: pointer;
          font-size: 16px;
          max-width: 90%;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .copy-icon {
          margin-left: 8px;
          margin-top: 2px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .rootnet-forminput {
        flex: 1;
        display: flex;
        margin: 0;

        .form-component {
          flex: 1;
        }
      }
    }

    .handle-group {
      .ant-btn {
        height: 34px;
        margin: 0 6px;
      }
    }
  }

  .dev-project-info {
    display: flex;
    flex: 1;
    overflow: hidden;

    .left-detail {
      flex: 1;
      min-width: 840px;
      overflow: auto;
    }

    .fold-bar {
      width: 22px;
      cursor: pointer;

      &:hover {
        background-color: #EAF4FC;
        color: #5477ff;
      }

      .fold-icon {
        font-size: 18px;
      }
    }

    .dynamic-wrap {
      border-left: 1px solid #F0F0F0;
      padding: 8px;
    }

    .area-wrap {
      margin-bottom: 8px;

      .area-header {
        margin: 4px 0;
        font-size: 14px;
        font-weight: 900;
        padding-right: 8px;

        .stage-help-icon {
          color: #5477ff;
          font-size: 14px;
        }

        .fold-icon {
          cursor: pointer;
          margin-left: 10px;
          margin-right: 18px;
        }

        .button {
          height: 26px;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .text-area-detail-style {
        align-items: baseline;

        .form-component.form-component.form-component {
          height: auto;
          flex: 1;
          word-break: break-all;
          white-space: pre-wrap;
        }
      }

      .area-content-wrap {
        overflow: hidden;
        padding-left: 40px;
        flex-wrap: wrap;

        .input-text-area {
          .form-component {
            white-space: pre-wrap;
            word-break: break-all;
          }
        }

        .text-area-input-edit {
          align-items: flex-start;
        }
      }

      .textarea-field-wrap {
        .rootnet-forminput {
          margin-right: 0;
          height: 32px;
        }

        .textarea-show-value {
          line-height: 32px;
          margin-top: 8px;
          white-space: pre-wrap;
          word-break: break-all;
        }
      }
    }

    .forminput-progress {
      //display: inline-block;
      width: 360px;
      margin: 8px;

      .item-label {
        width: 112px;
        color: #738299;
        text-align: right;
        display: inline-block;

        &::after {
          content: ":";
          margin: 0px 8px 0px 2px;
        }
      }

      .form-component-progress {
        display: inline-block;
      }

    }

    .forminput-progress-num.forminput-progress-num.forminput-progress-num {
      .dev-project-progress-outer {
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        background-color: #A6E8C9;
        height: 22px;
      }
    }

    .dev-project-progress {
      width: 270px;
      display: flex;

      .dev-project-progress-outer {
        flex: 1;
        width: 210px;
        display: flex;
        background-color: #ECF0F1;
        border-radius: 50px;
        font-size: 12px;
        overflow: hidden;
        position: relative;

        .success-progress {
          background-color: #A6E8C9;
          border-radius: 50px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .unfinished-progress {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .inner-text {
          position: absolute;
          width: 100%;
          height: 100%;
          font-weight: bold;
        }

        //.inner-white-text-wrap{
        //  position: absolute;
        //  height: 100%;
        //  font-weight: bold;
        //  color: white;
        //  overflow: hidden;
        //  .inner-white-text{
        //    width: 210px;
        //  }
        //}
      }

      .dev-project-progress-text {
        padding-left: 8px;
        width: 80px;
        font-weight: 700;
      }
    }
  }

  .application-code-skip {
    cursor: pointer;
    color: #5477ff;
  }
}

.dev-project-progress-total-tip {
  .defect-total {
    font-size: 16px;
    font-weight: 700;
  }

  .dev-project-progress-num-item {

    .num-item-label.num-item-label.num-item-label {
      // color: #738299;
      color: white;

      &::after {
        content: ":";
        margin: 0px 8px 0px 2px;
      }
    }

    .num-item-bind {
      color: white;
    }
  }
}

.dev-project-title-copy-popover {
  padding: 6px 12px;

  .button {
    height: 32px;
    margin: 6px;
  }
}

.stage-help-tooltip {

  .stage-help-tooltip-content {
    .stage-help-item {
      font-size: 14px;

      .icon {
        font-size: 14px;
      }

      .left-icon {
        margin-right: 8px;
      }

      // .right-text {}
    }
  }
}