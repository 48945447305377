.workplace-view-common-template{
  height: 100%;
  .view-area-wrap{
    display: none;
  }
  .enter-detail-field-wrap {
    cursor: unset;
    width: 100%;

    .enter-detail-field {
      width: 100px;
      flex: 1;

      .enter-detail-field-text {
        max-width: 100%;
        cursor: pointer;
        color: #5477ff;
        overflow: hidden; //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical; //规定为垂直方向排列
        &:hover {
          text-decoration: underline
        }
      }
    }
  }
  .work-flow-status-field{
    cursor: pointer;
  }
}