//ylf
.operation {
  font-size: 12px;
  color: #5477ff;

  >span {
    cursor: pointer;

    &:not(:last-child) {
      &::after {
        content: '';
        display: inline-block;
        height: 10px;
        border-right: 1px solid #C3C5CC;
        position: relative;
        top: 2px;
        margin: 0 8px;
      }
    }
  }
}

div.all-operate {
  display: inline-flex;
  align-items: center;

  >span {
    &.disabled {
      color: #d6d8e0;
      pointer-events: none;
    }

    color: #5477ff;
    padding: 0 4px;
    cursor: pointer;

    &:not(:last-child)::after {
      content: "";
      display: inline-flex;
      width: 1px;
      height: 10px;
      border-radius: 0.5px;
      background-color: #CFD3E3;
      margin-inline-start: 8px;
    }
  }
}

.x-popup.showMore {
  padding: 0;

  .moreList {
    white-space: nowrap;
    line-height: 1.5em;
    font-size: 14px;
    color: #15171F;
    max-width: 470px;
    overflow: hidden;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #FFFFFF;
    box-shadow: 0 6px 14px 0 rgba(163, 171, 203, 0.40);
    border-radius: 4px;

    >span {
      display: block;
      padding: 6px 12px;

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }

      &.disabled {
        pointer-events: none;
        cursor: default;
        color: #d6d8e0;
      }
    }
  }
}