.import-from-version-dialog.import-from-version-dialog {
  width: 70%;

  .content.content.content {
    padding: 0;
    height: 70vh;
    display: flex;
    flex-direction: column;

    .import-from-version-content {
      flex: 1;
      height: 100px;
      .table-wrap {
        height: 100px;
        width: 100%;
        flex: 1;

        .x-card {
          height: 100px;
          margin-bottom: 4px;
        }
      }
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;

      .text-hint {
        font-size: 12px;
        color: #9A9A9A;
        padding-left: 16px;
      }

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}