.release-info-dialog.release-info-dialog {
  width: 1000px;

  .content.content.content {
    padding: 0;
  }

  .release-info-content {
    position: relative;

    .header {
      height: 220px;
      background-image: url("../../assets/images/versionUpgradeBG.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      padding-left: 60px;

      .header-content {
        width: 600px;
        height: 130px;
        justify-content: space-between;

        .system-name {
          width: 170px;
          height: 30px;
          padding: 4px 16px;
          background: #666699;
          color: #fff;
          border-radius: 15px;
          font-weight: bold;
          font-style: italic;
        }

        .version-name {
          padding: 12px 8px 24px;
          font-size: 26px;
          font-weight: bold;
          color: #FFFFCC;
        }

        .version-info-group {
          color: #FFFFCC;

          .ant-select {
            width: 260px;
          }

          .version-date,
          .version-remind {
            margin-left: 40px;
          }
        }
      }

      .close-icon-wrap {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        background: #ffffffcc;
        border-radius: 50%;

        .close-icon {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .list-group {
      max-height: 50vh;
      flex-wrap: wrap;

      .list-item {
        width: 330px;
        padding: 20px;

        .module {
          height: 30px;
          color: #3f7dff;
          font-size: 18px;
          margin-bottom: 8px;
          justify-content: space-between;

          .module-text {
            flex: 1;
          }

          .ant-tree-select {
            width: 260px;
          }

          .del-icon {
            color: red;
            cursor: pointer;
          }
        }

        .detail {
          flex: 1;
          color: #6A6A6A;

          .detail-text {
            min-height: 22px;

            &.max-height {
              max-height: 132px;
            }
          }
        }
      }

      .add-card {
        width: 280px;
        height: 158px;
        color: #3f7dff;
        border: 1px solid #3f7dff;
        margin: 20px;

        .icon-wrap {
          cursor: pointer;
        }

        .add-icon {
          font-size: 30px;
        }
      }
    }

    .not-show-check {
      position: absolute;
      bottom: -36px;
      margin-left: 20px;
    }
  }

  .footer-switch {
    position: absolute;
    bottom: -38px;
    left: 414px;

    .footer-switch-item {
      cursor: pointer;
      padding: 4px 12px;
      border-radius: 18px;
      box-sizing: border-box;
      display: flex;
      margin-right: 12px;
      color: #fff;
      background-color: #5477ff;
      transition: background-color 0.4s;

      &:hover {
        background-color: #214eff;
      }

      &.disabled {
        color: #6A6A6A;
        background-color: #E8E8E8;
        cursor: not-allowed;

        &:hover {
          background-color: #E8E8E8;
        }
      }

      >i {
        font-size: 14px;
      }


    }

    .list-num {
      font-weight: bold;
    }
  }
}