.dev-project-detail.dev-project-detail.dev-project-detail {
  flex: 1;
  display: flex;
  overflow: hidden;

  .long-options{
    .rootnet-popup{
      .container{
        .option{
          min-width: 520px;
        }
      }
    }
  }

  .dev-project-tabs {
    flex: 1;
    overflow: hidden;
    margin: 8px 12px;
    background-color: white;

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      display: flex;
      flex: 1;
      height: 100px;
      overflow: hidden;
      padding: 6px;
      padding-top: 0;
    }

    .ant-tabs-content,
    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
        // display: flex;
        .tab-content-wrap{
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab.ant-tabs-tab.ant-tabs-tab{
    &.ant-tabs-tab-active{
      .ant-badge{
        color: #1890ff;
      }
      .ant-badge-count{
        background-color: #EFF4FF !important;
        .ant-scroll-number-only{
          color: #6698FF;
        }
      }
    }
  }
}