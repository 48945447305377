.review-minutes-issue-list {
  .issue-list-del-icon {
    cursor: pointer;

    &:hover {
      color: #5477ff;
    }
  }

  .header-required:before {
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    content: '*';
    color: #F5221B;
  }

  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead>tr>th,
  .ant-table.ant-table-small .ant-table-tbody>tr>td,
  .ant-table.ant-table-small tfoot>tr>th,
  .ant-table.ant-table-small tfoot>tr>td {
    padding: 6px;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    min-height: 32px;
  }

  .editable-row {
    .editable-cell-value-wrap {
      cursor: pointer;
    }
  }

  .editable-row .editable-cell-value-wrap:hover {
    background-color: #E0E0E0;
  }
}