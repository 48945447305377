.version-list-change {
  height: 100%;
  display: flex;
  flex-direction: column;

  .search-option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-option {
      flex: 1;
    }

    .action-type {
      width: 280px;
      color: #8A8888;
      margin-right: 10px;
      display: flex;
      justify-content: flex-end;

      >span {
        margin-left: 8px;
      }
    }
  }

  .list-content {
    flex: 1;
    overflow: hidden;

    .x-datagrid {
      height: 100%;
      font-size: 12px;

      .title-hidden {
        width: 100%;
        display: flex;

        .type-color {
          border-radius: 4px;
          margin-right: 4px;
          color: white;
          padding: 0 2px;
          box-sizing: border-box;
          display: inline-block;
          transform: scale(0.7);
        }

        .text {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #5477ff;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}