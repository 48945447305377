.global-const-update-dialog {
  width: 1000px;
  height: 80vh;

  .content.content.content.content {
    padding: 0;
    height: 10px;
    flex: 1;

    .mock-dialog-header {
      display: flex;
      align-items: center;
      color: #15171f;
      padding: 12px 16px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e9eaf2;
      justify-content: space-between;

      .dialog-title {
        font-weight: 700;
      }

      .mock-right-header {
        .header-edit-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }

        .header-handle-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &:hover {
            color: #5477ff;
          }
        }
      }

      .close-area {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 16px;

        .close-icon {
          font-size: 14px;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }

    .global-const-update-content {
      padding: 0 8px;
      height: 10px;
      flex: 1;
      // overflow-y: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .form-wrap {
        flex-wrap: wrap;
      }

      .ant-table-wrapper {
        flex: 1;
        overflow: hidden;
        display: flex;

        .ant-spin-nested-loading {
          flex: 1;
          overflow: hidden;
          display: flex;

          .ant-spin-container {
            flex: 1;
            overflow: hidden;
            display: flex;

            .ant-table {
              flex: 1;
              overflow: hidden;
              display: flex;

              .ant-table-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    .handle-btn-group {
      .handle-btn {
        height: 30px;
      }
    }

    .repeat-tips {
      position: absolute;
      font-size: 12px;
      margin-left: 110px;
      color: red;
      top: -9px;
    }

    .rootnet-forminput {
      //margin-top: 0;
      //margin-bottom: 16px;
    }

    .color-wrap {
      width: 100%;
      height: 30px;
      cursor: pointer;
      padding-left: 12px;

      &:hover {
        background-color: #E0E0E0;
      }
    }

    .display-value {
      margin-left: 12px;
      white-space: nowrap;
      /*不允许换行*/
      overflow: hidden;
      /*超出隐藏*/
      text-overflow: ellipsis;
      /*文本超出三点代替*/
    }

    .dev-project-stage-editable-cell-value-wrap {
      .display-value {
        margin-left: 0;
      }
    }

    .textarea-field-wrap {
      .rootnet-forminput {
        margin-right: 0;
        height: 32px;
      }

      .textarea-show-value {
        line-height: 32px;
        margin-top: 8px;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }
}