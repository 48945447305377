.total-cost-page.total-cost-page.total-cost-page {
  .x-card {
    .card-header {
      .header {
        align-items: center;

        .title {
          display: flex;
          font-weight: bold;

          &::before {
            display: block;
            content: "";
            width: 2px;
            height: 1em;
            background-color: #5477ff;
            box-shadow: 2px 0 3px 0 rgba(24, 144, 255, 0.42);
            margin-right: 12px;
            margin-top: 4px;
          }
        }

        .icon {
          font-size: 14px;
          color: #5477ff;
          margin-left: 8px;
        }

      }

      .right {
        width: 52%;
      }

      .extra-wrap {
        width: 100%;
        justify-content: space-between;

        .expand-text {
          cursor: pointer;
          color: #5477ff;

          .fold-icon {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }
    }

    .card-content {
      display: flex;
      flex-direction: unset;

      .card-content-wrap {
        width: 100%;
      }

      .net-present-value-analysis {
        border-radius: 4px;
        flex: 1;
        margin: 8px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);

        .card-header-text {
          font-size: 18px;
          font-weight: 900;
          text-align: center;
          padding: 8px 0;
        }

        .net-present-value-analysis-content {
          padding-bottom: 8px;
          flex-wrap: wrap;

          .analysis-status {
            margin-left: 12px;
            color: #0285BD;
            background: #DAF2FD;
            font-size: 16px;
            padding: 4px 16px;
            border-radius: 10px;
          }

          .analysis-tips {
            margin-top: 4px;
            margin-left: 8px;

            .analysis-tips-text {
              margin-left: 2px;
              color: #738299;
            }
          }

          .analysis-item {
            margin-left: 60px;
            font-size: 18px;

            .analysis-label {
              color: #13151A;

              .analysis-classify {
                font-weight: 700;
              }

              .analysis-text {
                margin-left: 6px;
                font-size: 12px;
              }
            }

            .analysis-num {
              color: #02A7F0;
              font-weight: 700;
            }
          }

          .edit-btn {
            margin-left: auto;
            margin-right: 8px;
          }
        }
      }

      .total-cost-form-item {
        margin: 8px 8px 8px 16px;
        font-size: 16px;

        .form-item-label.form-item-label.form-item-label {
          color: #738299;
          // color: white;

          &::after {
            content: ":";
            margin: 0px 8px 0px 2px;
          }
        }

        // .form-item-bind {}
      }

      .residue {
        margin: 8px 8px 18px 16px;

        .percentage {
          font-size: 36px;
          font-weight: 700;
        }

        .text {
          font-size: 18px;
          margin: 8px;

        }
      }

      .total-cost-right {
        display: flex;
        flex-direction: column;

        .content {
          flex: 1;

        }
      }

      .total-cost-left,
      .total-cost-right {
        border-radius: 4px;
        flex: 1;
        margin: 8px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);

        .header {
          font-size: 18px;
          font-weight: 900;
          text-align: center;
          padding: 8px 0;
          position: relative;

          .unit {
            position: absolute;
            right: 8px;
            bottom: 9px;
            font-weight: 400;
            font-size: 12px;
          }
        }

        .content {

          .content-left,
          .content-right {
            flex: 1;
          }
        }
      }
    }
  }
}

.analysis-reason-tooltip {
  font-size: 12px;

  .ant-tooltip-content {
    width: 660px;
  }

  .ant-tooltip-inner {
    padding: 0;
    color: black;
  }
}