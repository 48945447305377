.daily-report-comment-record {
  width: 400px;
  height: 400px;

  .daily-report-comment-record-header {
    padding: 8px 16px;
    border-bottom: 1px solid #F8F8F8;
    justify-content: space-between;

    .header-btn {
      .sort-btn {
        cursor: pointer;

        &:hover {
          color: #5477ff;
        }
      }
    }
  }

  .daily-report-comment-record-footer {
    padding: 8px;
  }

  .daily-report-comment-record-content {
    flex: 1;
    overflow: auto;
    padding: 0 16px;

    .record-box {
      display: flex;
      margin-bottom: 16px;

      .comment-all {
        flex: 1;
      }

      .level-two {
        display: flex;
        margin-top: 8px;
      }

      .record-comment {
        flex: 1;
        font-size: 12px;
        display: flex;
        padding-top: 3px;
        box-sizing: border-box;
        justify-content: space-between;
        margin-right: 16px;
        color: #929292;

        >div:nth-child(1) {
          // display: flex;
          flex: 1;

          &:hover .comments-operation {
            display: inline-block;
            color: #5477ff;
          }

          .comments-operation {
            display: none;

            >span {
              cursor: pointer;
            }
          }

          >div:nth-child(2) {
            flex: 1;
            white-space: pre-wrap;
            color: black;
            padding-right: 8px;
            box-sizing: border-box;
            word-break: break-all;
            line-break: anywhere;
          }
        }
      }

      .record-icon {
        width: 35px;

        .head-portrait {
          margin-top: 8px;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          color: white;
          text-align: center;
          line-height: 25px;
          font-size: 12px;
        }

        >i {
          font-size: 16px;
        }
      }

      .record-data {
        flex: 1;
        font-size: 12px;
        overflow: hidden;

        .record-header {
          padding-top: 3px;
          box-sizing: border-box;
          color: #929292;
          display: flex;

          .left {
            display: flex;

            >div {
              margin-right: 8px;
            }
          }

          .right {
            margin-left: 8px;
            margin-right: 16px;
          }
        }

        .record-content {
          display: flex;
          flex-wrap: wrap;
          padding-left: 12px;
          box-sizing: border-box;
          word-break: break-all;

          .record-add {
            border-left: 5px solid #bfbfbf;
            padding-left: 5px;
            box-sizing: border-box;
          }

          .mtext {
            width: 100%;
            margin-right: 16px;
            margin-top: 8px;

            .mtext-header {
              display: flex;
            }

            .change-action {
              margin-right: 8px;
              white-space: nowrap;
              color: #929292;
            }

            .examine {
              cursor: pointer;
              color: #5477ff;
            }

            .mtext-content {
              border-left: 5px solid #8c8c8c;
              padding-left: 5px;
              box-sizing: border-box;
              cursor: pointer;

              .rich-text {
                padding-right: 2px;

                div,
                span,
                applet,
                object,
                iframe,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                blockquote,
                pre,
                a,
                abbr,
                acronym,
                address,
                big,
                cite,
                code,
                del,
                dfn,
                em,
                img,
                ins,
                kbd,
                q,
                s,
                samp,
                small,
                strike,
                strong,
                sub,
                sup,
                tt,
                var,
                b,
                u,
                i,
                center,
                dl,
                dt,
                dd,
                ol,
                ul,
                li,
                fieldset,
                form,
                label,
                legend,
                table,
                caption,
                tbody,
                tfoot,
                thead,
                tr,
                th,
                td,
                article,
                aside,
                canvas,
                details,
                embed,
                figure,
                figcaption,
                footer,
                header,
                hgroup,
                menu,
                nav,
                output,
                ruby,
                section,
                summary,
                time,
                mark,
                audio,
                video {
                  margin: 0;
                  padding: 0;
                  border: 0;
                  font-size: 10px;
                  font: inherit;
                  vertical-align: baseline;
                }

                img {
                  display: none;
                }
              }
            }

            .reverl-all {
              border-left: 5px solid #bfbfbf;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .each {
            display: flex;
            margin-right: 16px;
            margin-top: 8px;

            .change-action {
              margin-right: 8px;
              white-space: nowrap;
              color: #929292;
            }

            .change-data {

              .old {
                background-color: #F6D4D4;
                color: red;
                text-decoration: line-through;
              }

              .new {
                background-color: #D9F0E8;
                color: #1BA376;
                word-wrap: break-word;
              }

              >i {
                margin: 0 8px;
              }
            }
          }
        }
      }
    }
  }
}