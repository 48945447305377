.work-flow-detail{
  .work-flow-detail-header{
    padding: 6px 4px;
    margin: 6px 12px 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 6px 20px rgba(0,0,0,0.1);
  }
  .x-card{
    margin-top: 6px;
    flex: unset;
  }
  .base-info-card{
    margin-bottom: 0;
    .card-content{
      height: 100px;
    }
    .card-extra{
      .button{
        height: 28px;
        margin-left: 12px;
      }
      .icon{
        color: red;
        font-size: 16px;
        margin-top: 2px;
        margin-right: 8px;
      }
    }
  }
  .base-info-wrap{

  }
  .graph-card{
    flex: 1;
  }
}