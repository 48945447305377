.manpower-detail-summary.manpower-detail-summary.manpower-detail-summary {
  overflow: hidden;

  .x-card {
    margin: 0 12px;
    font-size: 12px;

    .card-header {
      .right {
        width: 52%;
      }

      .extra-wrap {
        width: 100%;
        justify-content: space-between;

        .expand-text {
          cursor: pointer;
          color: #5477ff;

          .fold-icon {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }
    }

    .card-content {
      min-height: 100px;
    }
  }
}