.tracking-management-mgt {
  .x-card {
    margin-top: 0;

    .x-datagrid {
      .enter-detail-field-wrap{
        &:hover .concerned-icon{
          display: inline-block !important;
        }
      }
      .enter-detail-field {
        width: 92%;
        cursor: pointer;
        color: #5477ff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .work-flow-status-field{
    cursor: pointer;
    margin-left: 8px;
  }
}