.ant-popover.filter-query {
  .ant-popover-inner-content {
    padding: 10PX;

    .condition {
      .content {
        .ant-form-item {
          margin-bottom: 12px;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 8px;
      box-sizing: border-box;

      >div {
        >button {
          margin-left: 8px;
        }
      }
    }
  }
}