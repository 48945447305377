.sync-to-jira-dialog{
  .content.content.content.content{
    width: 550px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
  }
  .tips-text{
    color: #999999;
    margin: 4px 0 8px 24px;
    font-size: 12px;
  }
}