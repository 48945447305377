.manpower-detail-content {
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #fff;

  .mock-dialog-header {
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;

    .dialog-title {
      font-weight: 700;
    }

    .mock-right-header {

      .header-handle-icon {
        font-size: 18px;
        cursor: pointer;
        margin: 0 20px;

        &:hover {
          color: #5477ff;
        }
      }
    }

    .close-area {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 20px;

      .close-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: #ECECEC;
      }
    }
  }

  .manpower-detail-main-panel {
    flex: 1;
    flex-direction: column;
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    border-top: 1px solid #e9eaf2;

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }
}