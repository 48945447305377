.view-desc.view-desc.view-desc{
  min-width: 1040px;
  flex: none;
  *, *::before, *::after{
    box-sizing: unset;
  }
  .card-content{
    display: block;
  }
  .view-desc-wrap{
    padding: 20px 15px;
    justify-content: space-around;
    box-sizing: border-box;
  }
  .period-item{
    margin: 10px 0;
    width: 260px;
  }
  .schedule{
    width: 270px;
    margin-top: 3px;
    .schedule-item{
      margin: 5px 0;
      .schedule-text{
        margin-right: 10px;
      }
      .x-progress-inner{
        width: 150px;
        background-color: #F0F2F5;
      }
      .x-progress-info{
        display: none;
      }
      .progress-info{
        margin-left: 10px;
        color: #7A8199;
      }
    }
  }
  .progress-wrap{
    width: 165px;
    &.overdue{
      width: 250px;
    }
  }
  .circle-progress-wrap{
    width: 60px;
    height: 60px;
    position: relative;
    .inner-text{
      line-height: 60px;
      text-align: center;
    }
    .wrapper{
      width: 30px;
      height: 60px;
      position: absolute;
      top: 0;
      overflow: hidden;
      &.right{
        right: 0
      }
      &.left{
        left: 0
      }
    }
    .circle-progress{
      width: 54px;
      height: 54px;
      border: 3px solid #F0F2F5;
      border-radius: 50%;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
    }
    .right-circle{
      border-top: 3px solid #5477FF;
      border-right: 3px solid #5477FF;
      right: 0;
    }
    .left-circle{
      border-bottom: 3px solid #5477FF;
      border-left: 3px solid #5477FF;
      left: 0;
    }
  }
  .circle-progress-group{
    height: 68px;
    .circle-progress-group-right{
      margin-left: 15px;
      .progress{
        font-size: 16px;
        margin-bottom: 4px;
      }
      .progress-text{
        color: #7A8199;
      }
    }
  }
  .overdue{
    .right-circle{
      border-top: 3px solid #F5221B;
      border-right: 3px solid #F5221B;
    }
    .left-circle{
      border-bottom: 3px solid #F5221B;
      border-left: 3px solid #F5221B;
    }
    .inner-text{
      color: #F5221B;
      font-size: 16px;
    }
  }
}