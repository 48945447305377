.user-group-personnel.user-group-personnel.user-group-personnel {
  flex: 1;
  margin: 12px;
  margin-left: 0;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 45px;

  .x-card {
    margin: 0;
    height: 100%;

    .role-list-overflow {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}