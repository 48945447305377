.special-component-catalog{
  width: 100%;
  height: 100%;
  .x-card{
    margin: 0;
    box-shadow: none;
  }
  .handle-wrap{
    height: 32px;
    margin-bottom: 4px;
    justify-content: space-between;
    .handle-right-wrap{
      padding-right: 20px;
    }
  }
  .table-wrap{
    flex: 1;
    height: 100px;
    width: 100%;
    font-size: 12px;
  }
}