.resource-schedule-wrap{
  padding: 6px 12px;
  .resource-schedule-left-wrap{
    width: 330px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    border-radius: 4px;
    .dept-search-wrap{
      flex: 1;
      width: 200px;
      .ant-tabs{
        width: 100%;
        height: 100%;
        .ant-tabs-nav{
          padding-left: 20px;
          margin-bottom: 2px;
        }
        .ant-tabs-content{
          height: 100%;
          .ant-tabs-tabpane{
            height: 100%;
          }
        }
      }
    }

    .fold-bar{
      width: 22px;
      cursor: pointer;
      &:hover{
        background-color: #EAF4FC;
        color: #5477ff;
      }
      .fold-icon{
        font-size: 18px;
      }
    }
  }
  .resource-schedule-right-wrap{
    width: 200px;
    flex: 1;
    margin-left: 6px;
    .search-condition-wrap{
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 6px;
    }
    .resource-schedule-right-main-panel{
      flex: 1;
      overflow: auto;
    }
  }

  .containerParent{
    transition: 350ms ease height;
    overflow: hidden;
    display: flex;
    //flex: 1;
    flex-wrap: wrap;
    &::-webkit-scrollbar{
      display: none;
    }
    .container{
      width: 100%;
    }
  }
}