.user-dept-group-item{
  justify-content: space-between;
  height: 26px;

  &:hover {
    background-color: #E9F5FF;
    .clear-icon.clear-icon.clear-icon {
      display: block;
    }
  }

  .clear-icon.clear-icon.clear-icon {
    display: none;
    cursor: pointer;
    font-size: 16px;
    color: #B0B4B8;
    padding-right: 8px;
    &:hover{
      color: #868B8F;
    }
  }
}