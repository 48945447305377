.top-menu-list.top-menu-list.top-menu-list {
  flex: 1;
  height: 100%;
  overflow: hidden;
  margin-left: 30px;

  .ant-menu {
    height: 100%;
    background-color: #0747A6;
    color: white;
    justify-content: center;
  }

  .ant-menu-horizontal {
    border: none;

    .ant-menu-overflow-item:hover {
      background-color: #083376;
    }

    &:not(.ant-menu-dark)>.ant-menu-item-active,
    &:not(.ant-menu-dark)>.ant-menu-item-open,
    &:not(.ant-menu-dark)>.ant-menu-item-selected,
    &:not(.ant-menu-dark)>.ant-menu-item:hover,
    &:not(.ant-menu-dark)>.ant-menu-submenu-active,
    &:not(.ant-menu-dark)>.ant-menu-submenu-open,
    &:not(.ant-menu-dark)>.ant-menu-submenu-selected,
    &:not(.ant-menu-dark)>.ant-menu-submenu:hover {
      color: white;
      background-color: #083376;
    }

    &:not(.ant-menu-dark)>.ant-menu-item-active:after,
    &:not(.ant-menu-dark)>.ant-menu-item-open:after,
    &:not(.ant-menu-dark)>.ant-menu-item-selected:after,
    &:not(.ant-menu-dark)>.ant-menu-item:hover:after,
    &:not(.ant-menu-dark)>.ant-menu-submenu-active:after,
    &:not(.ant-menu-dark)>.ant-menu-submenu-open:after,
    &:not(.ant-menu-dark)>.ant-menu-submenu-selected:after,
    &:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
      border-bottom-color: white;
    }

  }
}