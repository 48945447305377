.contract-options{
  overflow: hidden;
  &.options-show-one-line{
    height: 78px;
  }
  .fold-text{
    width: 40px;
    min-width: 30px;
    height: 31px;
    margin: 28px 5px 0;
    text-align: center;
    line-height: 31px;
    font-weight: 400;
    cursor: pointer;
    color: #5477FF;
  }
  .extra-options{
    margin-left: 4px;
  }
}