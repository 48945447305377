.product-component-list{
  .editable-cell-value-wrap{
    &:hover{
      background-color: #E1E1E1;
    }
  }
  .del-icon.del-icon.del-icon{
    font-size: 16px;
    cursor: pointer;
    &:hover{
      color: red;
    }
  }
}