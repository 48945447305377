$primaryColor: #5477FF;
$borderColor: rgba(0,0,0,0.1);
$activeBg: #F2F6FA;
.work-time-detail-left{
	height: 100%;
	display: flex;
	box-shadow: 0 4px 20px 0 $borderColor;
	.l-menu{
		width: 120px;
		height: 100%;
		background: #fff;
		overflow: auto;
		.menu-item{
			cursor: pointer;
			height: 40px;
			line-height: 40px;
			padding-left: 30px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			word-break: break-all;
			&.active{
				color: $primaryColor;
				background: $activeBg;
				position: relative;
				&::after{
					position: absolute;
					content: '';
					width: 3px;
					height: 100%;
					border-radius: 4px;
					background: $primaryColor;
					right: 0;
					top: 0;
				}				
			}
		}
	}
	&.has{
		.l-menu{
			&:first-child{
				border-right: 1px solid rgba(80,80,80,0.1);
				.menu-item{
					&.active{
						background: none;
						&::after{
							display: none;
						}
					}
				}
			}
			&:last-child{
				.menu-item{
					padding-left: 15px;
					&.active{
						color: $primaryColor;
					}
				}
			}		
		}
	}
}