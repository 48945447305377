.develop-project-dialog{
  .data-grid-wrap{
    flex: 1;
    border: 1px solid #e6e6e6;
    .no-data{
      display: none;
    }
  }
  .remark-input.remark-input.remark-input.remark-input{
    margin-top: 10px;
    height: 60px;
    width: 100%;
  }
  .content.content.content.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 480px;
    justify-content: flex-start;
    .x-datagrid{
      overflow: hidden;
    }
  }
  .options{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    .option-switch{
      margin-right: 10px;
    }
  }
  .search-icon.search-icon.search-icon{
    margin: 0 4px;
    font-size: 16px;
    &.pointer{
      &:hover{
        cursor: pointer;
        color: #5477FF;
      }
    }
  }
  .column{
    .top{
      .x-checkbox{
        display: none;
      }
    }
  }
  //.req-select-item{
  //  width: 400px;
  //  white-space: nowrap;/*不允许换行*/
  //  overflow: hidden;/*超出隐藏*/
  //  text-overflow: ellipsis;/*文本超出三点代替*/
  //}

//  需求下拉框
  .rootnet-popup.rootnet-popup.rootnet-popup{
    .list-panel{
      .container{
        div{
          white-space: nowrap;/*不允许换行*/
          overflow: hidden;/*超出隐藏*/
          text-overflow: ellipsis;/*文本超出三点代替*/
        }
      }
    }
  }
}