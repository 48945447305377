.problemResearch-feedback {
  border: 1px solid #ECECEC;
  padding: 0 0 0 16px;
  box-sizing: border-box;

  .ant-form {
    >div {
      margin: 0;
    }
  }

  .issue-desc {
    height: 74px;
    color: #BDBDBD;
    border-bottom: 1px solid #ECECEC;

    .ant-input {
      height: 100%;
      padding-left: 0;
      font-size: 24px;
    }
  }

  .req-detail {
    padding-top: 16px;
    box-sizing: border-box;

    >textarea {
      min-height: 300px;
      max-height: 500px;
      padding: 0;
    }

  }

  .ant-input {
    border: none;
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    border: none;
    box-shadow: none;
  }
}