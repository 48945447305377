.head-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 16px 0;

  .left,
  .right {
    width: 44%;
    height: 2px;
    background-color: #E6E6E6;
  }

  .text {
    font-size: 14px;
    padding: 6px 12px;
    box-sizing: border-box;
    background-color: #E6E6E6;
    border-radius: 100px;
    white-space: nowrap;
  }
}