.filter-condition-wrap.filter-condition-wrap.filter-condition-wrap.filter-condition-wrap{
  margin-top: 0;
  margin-bottom: 0;
  .filter-condition-card{
    padding:8px;
  }

  .full-screen-icon{
    font-size: 16px;
    cursor: pointer;
    &:hover{
      color: #3f7dff;
    }
  }
  &.full-screen{
    position: absolute;
    margin: 12px;
    width: calc(100% - 24px);
    height: calc(100vh - 62px);
    z-index: 4;
    .relation-card{
      height: 100%;
    }
  }
}