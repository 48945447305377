.export-test-plan-api-dialog {
  width: 932px;
  display: flex;
  flex-direction: column;

  .content.content.content.content.content {
    height: 65vh;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    .left-panel-content {
      flex: 1;
      overflow: auto;

      .x-card {
        margin: 0 8px 8px 8px;

        .card-header {
          .left {
            width: 140px;
          }

          .right {
            flex: 1;
          }
        }
      }
    }

    .right-panel-content {
      width: 180px;
      border-left: 1px solid #e1e1e1;
      display: flex;
      flex-direction: column;

      >div {

        .dragover {
          border-top: 2px solid #5477ff;
        }

        .dragItem {
          margin: 0;
          padding: 8px;
          box-sizing: border-box;
          cursor: move;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          >i {
            cursor: pointer;
            font-size: 14px;

            &:hover {
              color: #5477ff;
            }
          }

          &:hover {
            background-color: #f5f5f5;
          }
        }

        .cancelStyle.cancelStyle.cancelStyle.cancelStyle.cancelStyle.cancelStyle {
          cursor: auto;

          &:hover {
            background-color: #fff;
          }
        }
      }
    }

  }
}