
.modal.modal.modal.modal.create-autorized-dialog{
    .content{
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
        display: grid;
        align-items: flex-end;
        grid-template-columns: 50% 50%;
        grid-template-rows: 32px 48px;
        position: relative;
        .x-forminput.horizontal{
            &.edit{
                div.x.select,div.x.text-input{
                    
                    border: 0px;
                    color: gray;
                    .status{
                        display: none;
                    }
                    >input{
                        cursor: auto;
                    }
                }
            }
            .input-label{
                white-space: nowrap;
            }
            padding-right: 10px;
        }
        .standardTime{
            font-size: 12px;
            color: #738299;
            position: absolute;
            right: 140px;
            bottom:100px;
        }
    }
}
.project-list-auth{
    display: flex;

    .details-left-menu {
        &.x-card {
          flex: 1;
          margin: 12px 0 12px 12px;
          max-width: 300px;
        }
      
        &-item {
          &:hover {
            background-color: #0000000F;
          }
      
          &.active {
            background: #5477FF;
            color: #fff;
          }
      
          height: 40px;
          line-height: 40px;
          padding: 0 16px;
          box-sizing: border-box;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          cursor: pointer;
        }
      }
    .authorized{
        flex: 1;
        .card-content{
            display: flex;
            >.flex.center{
                flex: 1;
            }
            .x-datagrid{
                flex:1;
                .operations {
                    font-size: 12px;
                    color: #5477ff;
                
                    >span {
                        cursor: pointer;
                        &:not(:last-child) {
                            &::after {
                                content: '';
                                display: inline-block;
                                height: 14px;
                                border-right: 1px solid #D8D8D8;
                                position: relative;
                                top: 2px;
                                margin: 0 8px;
                            }
                        }
                    }
                }
    
            }
        }
    }
}
