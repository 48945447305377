.workplace-todo.workplace-todo.workplace-todo{
  flex: 1;
  width: 100px;
  .workplace-todo-wrap{
    height: 100%;
    width: 100%;
  }
  .card-header{
    .ant-tabs-nav.ant-tabs-nav.ant-tabs-nav.ant-tabs-nav{
      margin: 0;
      &:before{
        border-bottom: 0;
      }
      .ant-tabs-tab{
        padding: 9px 0;
      }
    }
  }
  .card-content{
    .ant-tabs{
      height: 100%;
      width: 100%;
      .module-name{
        font-size: 12px;
      }
    }
    .ant-tabs-nav-wrap{
      width: 150px;
    }
    .ant-tabs-tab{
      padding: 8px 20px 8px 8px;
    }
    .ant-tabs-tab-active{
      color: #1890ff;
    }
    .ant-badge{
      color: unset;
    }
    .ant-scroll-number-only{
      transition: none;
      -webkit-transform-style: unset;
      -webkit-backface-visibility: unset;
    }
    .ant-tabs-content, .ant-tabs-tabpane{
      height: 100%;
    }
    .ant-tabs-tabpane{
      padding-left: 0;
    }
  }

  .handle-icon{
    font-size: 18px;
    margin-left: 24px;
  }
  .tab-tag{
    color: #fff;
    width: 40px;
    height: 20px;
    border-radius: 4px;
    margin-right: 4px;
    font-size: 12px;
  }
}