.dev-project-stage-list {
  height: 100%;
  width: 100%;
  padding: 4px;

  .cell-item-text-display.cell-item-text-display {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .stage-parentId {
    background-color: #f7f7f7;

    .ant-table-cell-fix-left {
      background-color: #f7f7f7;
    }

    .ant-table-cell-fix-right {
      background-color: #f7f7f7;
    }
  }

  .stage-style-pad-lef.stage-style-pad-lef.stage-style-pad-lef.stage-style-pad-lef.stage-style-pad-lef {
    &:not(.ant-table-cell-fix-sticky) {
      padding-left: 12px !important;
    }
  }

  .stage-disabled-style {
    color: #B6B6B6 !important;

    .task-name-header,
    .relate-document-icon-wrap,
    .icon {
      color: #B6B6B6 !important;
    }
  }

  .relate-conference-icon {
    color: #5477ff;
    cursor: pointer;
    margin-left: 18px;
  }

  .handle-header-wrap {
    flex-direction: row-reverse;

    .right-header-wrap {
      .button {
        height: 34px;
      }
    }

    .total-text {
      flex: 1;
      margin-left: 4px;
    }

    .total-collect {
      flex: 1;

      .schedule-item {
        margin-right: 12px;

        .schedule-num {
          margin-left: 8px;
          // font-weight: 700;
        }
      }
    }

    .ant-alert {
      // flex: 1;
      margin-right: 12px;
      height: 34px;

      .selected-message {
        margin-left: 8px;

        .selected-count {
          color: #1890FF;
          margin: 0 2px;
        }

        .bulk-del-text {
          color: #1890FF;
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
  }

  .ant-table-sticky-scroll {
    display: none;
  }

  .task-name-header {
    margin-left: 12px;
    overflow: hidden; //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical; //规定为垂直方向排列
  }

  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead>tr>th,
  .ant-table.ant-table-small .ant-table-tbody>tr>td,
  .ant-table.ant-table-small tfoot>tr>th,
  .ant-table.ant-table-small tfoot>tr>td {
    padding: 6px;
  }

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container {
    height: 100%;
  }

  .ant-table-body {
    height: calc(100% - 35px);

    table {
      height: 100%;
    }
  }

  .ant-table-cell {
    height: 40px;
    padding: 0 !important;
  }

  .ant-checkbox-wrapper {
    margin: -3px !important;
  }

  tr.ant-table-measure-row {
    // display: none;
    visibility: collapse;
  }

  .ant-table-wrapper {
    height: calc(100% - 34px);
  }

  .relate-document-icon-wrap {
    color: #5477ff;
    cursor: pointer;
  }

  .conference-field {
    .conference-item {
      .conference-text {
        max-width: 100%;
        width: auto;
        color: #5477ff;
        cursor: pointer;
        overflow: hidden; //隐藏多余行数
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
        -webkit-box-orient: vertical; //规定为垂直方向排列

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 5;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-cell {
  position: relative;
}

.dev-project-stage-editable-cell-value-wrap {
  padding: 5px 12px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: #E0E0E0;
  }
}

.stage-text-display-tooltip {
  .tooltip-text {
    white-space: pre-wrap;
    word-break: break-all;
  }
}