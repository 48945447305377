.view-sort-dialog{
  .content.content.content.content{
    width: 600px;
    height: 70vh;
    .ant-table-wrapper{
      height: calc(100% - 24px);
    }
    .view-sort-wrap{
      height: 100%;
    }
  }
  .ant-table-sticky-scroll{
    display: none;
  }
  .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container{
    height: 100%;
  }

  tr.ant-table-measure-row{
    // display: none;
    visibility: collapse;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 5;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}