.workplace-summary-info.workplace-summary-info.workplace-summary-info{
  .info-content{
    height: 100px;
    width: 100%;
    justify-content: space-evenly;
    .info-item{
      padding: 8px;
      .symbol-icon{
        font-size: 50px;
        margin: 8px;
      }
      .info-detail{
        padding: 8px;
        .title{
          color: #777777;
        }
        .num{
          font-size: 20px;
          font-weight: bold;
        }
        .help-icon{
          margin-top: 4px;
          margin-left: 8px;
        }
      }
    }

    .divider{
      margin: 0 8px;
      height: 70px;
      width: 1px;
      background-color: #e1e1e1;
    }
  }
  .handle-icon{
    font-size: 18px;
    margin-left: 24px;
  }
}