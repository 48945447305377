.tracking-del-dialog.tracking-del-dialog.tracking-del-dialog{
  .reason {
    .rootnet-textarea {
      height: 90px;
    }
  }
  .footer{
    .primary{
      background: #ff4500;
      box-shadow: none;
      &:hover {
        background-color: #ff6a33;
      }
    }
  }
}