.associated-service {
  min-height: 0;
  max-height: 100%;
  overflow: hidden;
  display: flex;

  .x-datagrid {
    font-size: 12px;
  }
  .can-click-title{
    cursor: pointer;
    color: #5477ff;
    &:hover{
      text-decoration: underline;
    }
  }
}