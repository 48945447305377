.code-diff-wrap{
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  .code-diff-path-wrap{
    border-right: 1px solid #CCCCCC;
    width: 400px;
    overflow: auto;
    .code-diff-path-item{
      box-sizing: border-box;
      min-width: 400px;
      width: max-content;
      height: 28px;
      padding: 0 8px;
      cursor: pointer;
      border: 1px solid #fff;
      &:hover{
        background: #E5F3FB;
        border: 1px solid #70C0E7;
      }
      &.active{
        background: #CBE8F6;
        border: 1px solid #26A0DA;
      }
      >.icon{
        margin-right: 6px;
      }
    }
  }
  .code-diff-content{
    height: 100%;
    width: 100px;
    flex: 1;
    overflow: auto;
    >.diff{
      height: fit-content;
    }
  }
  .switch-vew-type{
    height: 20px;
    position: absolute;
    top: 8px;
    right: 12px;
    .icon{
      padding: 2px 6px;
      color: #6E6F73;
      background-color: #fff;
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      cursor: pointer;
      &.active{
        color: #fff;
        background-color: #5E7CE0;
      }
    }
  }
}