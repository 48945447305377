.evaluation-list {
  .x-card-singlegrid {
    .right {
      .button {
        height: 28px;
      }
    }

    .ant-table-wrapper {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table,
      .ant-table-container {
        height: 100%;
      }

      .ant-table-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .ant-table-body {
        flex: 1;
        overflow: auto;
      }
    }

    .select-checked {
      border: 1px solid #cecece;
      border-radius: 4px;
      padding: 4px;
      box-sizing: border-box;
      cursor: text;
      height: 38px;

      .checked-item {
        background-color: #f5f5f5;
        border: 1px solid #f0f0f0;
        padding: 2px 4px;
        box-sizing: border-box;
        margin-right: 8px;
        border-radius: 4px;
        cursor: default;

        .checked-user {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .del-operation {
          margin-left: 6px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            cursor: pointer;
            color: #00000073;
            font-size: 14px;

            &:hover {
              color: #3a3939d3;
            }
          }
        }
      }
    }
  }
}