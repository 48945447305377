div.milestone-mile.x{
    &.x-card{
        .card-content{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 180px;
            flex: initial;
        }
    }    
}