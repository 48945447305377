.manpower-project-maintenance-log {
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 600px;

  .content.content.content.content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .c-options {
      display: flex;
      align-items: flex-end;
      margin: 8px 0;
      padding: 4px 0 4px 4px;
      font-size: 14px;

      .search-btn-group {
        .x.button {
          margin: 6px 8px 0;
        }

        .reset-btn {
          border: 1px solid #7A8199;
        }
      }
    }

    .x-card {
      overflow: hidden;
    }

  }
}