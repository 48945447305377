.version-select-log {
  .select-log {
    margin-left: 80px;

    >div {
      cursor: pointer;
      padding: 4px 6px;

      &:hover {
        background-color: #E4EAFF;
      }

      >i {
        margin-left: 4px;
      }
    }

    .checked {
      background-color: #E4EAFF;
    }

    .agree {
      margin-right: 16px;

      >i {
        color: #25A10D;
        font-size: 14px;
      }
    }

    .reject {
      >i {
        color: #A10D0D;
        font-size: 14px;
      }
    }
  }
}