.menu-info-wrap.menu-info-wrap.menu-info-wrap{
  margin-left: 6px;
  background-color: #fff;
  border-radius: 4px;
  width: 100px;
  height: 100%;
  overflow-y: auto;
  flex: 1;
  padding: 8px 16px;
  .header{
    flex-direction: row-reverse;
  }
  .menu-info{
    width: 100%;
    flex-wrap: wrap;
    .rootnet-radiogroup{
      width: 180px;
    }
  }
  .footer{
    margin-top: 8px;
    margin-left: 124px;
    .button{
      height: 32px;
    }
  }
  .role-name-wrap{
    .mock-label {
      margin-left: 8px;
      margin-top: 6px;
      width: 120px;
      text-align: right;
      color: #738299;
      &::after {
        content: ":";
        margin: 0 8px 0 2px;
      }
    }
    .role-name{
      flex: 1;
      margin-top: 6px;
    }
  }
}