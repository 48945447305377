.cost-center{
  &.table-fill{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    .x-card{
      flex:1;
      overflow: auto;
      .card-content{
        display: flex;
        .x-datagrid{
          flex: 1;
        }
      }
    }
  }
  .cost-center-options{
    .btn.btn.btn.btn{
      width: 75px;
      margin-left: 15px;
      height: 31px;
    }
    .reset-btn.reset-btn.reset-btn{
      color: #3A415C;
      border: 1px solid #7A8199;
    }
  }
  .id-col{
    cursor: pointer;
  }
  .arrow_drop_down{
    margin-right: 5px;
    display: inline-block;
  }
}