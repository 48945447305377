.remark{
  height: 66px;
  line-height: 20px;
  .label{
    margin-top: 4px;
    width: 80px;
    color: #738299;
    text-align: right;
    &::after {
      content: ":";
      margin: 0px 8px 0px 2px;
    }
  }
  .show-text{
    margin-top: 4px;
    width: 290px;
    line-height: 20px;
    overflow: hidden;  //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical;  //规定为垂直方向排列
  }
  &.two-line{
    height: 44px;
    .show-text{
      -webkit-line-clamp: 2
    }
  }
  &.one-line{
    height: 28px;
    .show-text{
      -webkit-line-clamp: 1
    }
  }
}