.user-login-log-wrap.user-login-log-wrap.user-login-log-wrap{
  width: 80%;
  height: 90%;
  .content.content{
    display: flex;
    flex: 1;
    .content-wrap{
      width: 100%;
    }
    .data-grid{
      height: 200px;
    }
  }
  .form-text{
    color: #738299;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .search-btn-group{
    .button.button.button{
      margin: 0 16px 0;
    }
    .reset-btn {
      border: 1px solid #7A8199;
    }
  }
}