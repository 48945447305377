.data-type-drawer {
  width: 620px;

  >.header {
    height: 48px;
  }

  >.content {
    display: flex;
    flex-direction: column;
    padding-left: 12px;

    .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
      margin: 4px 0;
      >label {
        text-align: left;
      }
    }
  }
}