.auto-test-report-list{
  .content.content.content.content{
    width: 1000px;
    height: 70vh;
    .x-datagrid{
      font-size: 12px;
      height: 100px;
      flex: 1;
    }
    .x-pagination {
      flex: none;
      padding-right: 20px;
      justify-content: flex-end;
      border-top: 1px solid #e6e6e6;
    }
  }
}