.attendance-mgt.attendance-mgt.attendance-mgt{
  padding: 12px;
  .up-btn{
    width: 200px;
    margin-bottom: 12px;
  }
  .show-images-area{
    width: 500px;
    height: 500px;
    background: #fff;
    border: 1px solid #ebebeb;
    .no-img-text{
      color: #999999;
    }
  }
  .tips-group{
    margin-left: 24px;
    color: #999999;
    .tips{
      margin-bottom: 4px;
    }
  }
}
.attendance-mgt-confirm-dialog{
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .show-images-area{
    background: #fff;
    height: 400px;
    width: 400px;
  }
}