.copy-production-log.copy-production-log.copy-production-log {
  .content.content.content {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 200px;

    .copy-production-content {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .rootnet-select {
        width: 300px;
      }
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;

      .text-hint {
        font-size: 12px;
        color: #9A9A9A;
        padding-left: 16px;
      }

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}

.copy-production-secondary-confirmation.copy-production-secondary-confirmation {
  .content.content.content {
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
  }
}