.leave-dialog{
  .content.content.content.content{
    align-items: flex-start;
    display: flex;
  }
  .left-menu-item{
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px 12px;
    border-right: 2px solid #E9EAF2;
    justify-content: flex-end;
    &.active{
      border-right: 2px solid #5477FF;
      color: #5477FF;
    }
  }
  .right-content{
    padding: 0 40px;
  }
}