.project-quick-query-dialog{
  >.content.content.content.content{
    padding:8px 2px 0;
  }
  .project-quick-query-wrap{
    width: 1050px;
    height: 600px;
    >.c-options{
      margin-top: 0;
    }
    >.x-card {
      height: 450px;
    }
  }
}