  .concerned-icon.concerned-icon.concerned-icon {
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    color: #303030;
    
    &.concerned-icon-show {
      color: #F59A23;
      transform: scale(1.2, 1.2);
    }
  }