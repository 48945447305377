.customer-voice-mgt{
  .enter-detail-field-wrap{
    width: 100%;
    cursor: unset;
    .enter-detail-field-text{
      max-width: 80%;
    }
    .enter-detail-field-delete-icon {
      visibility: hidden;
      margin-left: 10px;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        color: red;
      }
    }
    &:hover {
      .enter-detail-field-delete-icon {
        visibility: visible;
      }
    }
  }
}