.code-commit-list{
  .x-card{
    margin: 0;
    &.hidden{
      visibility: hidden;
    }
  }
  .commit-detail-wrap{
    .commit-detail-header{
      height: 54px;
      border-bottom: 1px solid #e1e1e1;
      .back-area{
        height: 30px;
        margin-left: 12px;
        margin-right: 24px;
        cursor: pointer;
        &:hover{
          color: #5477ff;
        }
        .back-icon{
          font-size: 14px;
        }
        .back-text{
          margin-left: 12px;
          font-size: 14px;
        }
      }
      .commit-detail-info{
        flex: 1;
        width: 100px;
        .commit-detail-info-header{
          padding-top: 4px;
          max-width: 100%;
          .commit-detail-info-remark{
            font-weight: bold;
            margin-right: 24px;
            flex: 1;
            width: 100px;
          }
        }
        .commit-detail-update-info{
          margin-top: 4px;
          margin-bottom: 4px;
          font-size: 12px;
          color: #A6A4A0;
          .commit-detail-update-info-item{
            margin-right: 20px;
          }
        }
      }
    }
    .commit-code-diff-wrap{
      flex: 1;
      height: 100px;
    }
  }
}