.trace-relate-pipeline-list{
  .run-icon.run-icon.run-icon{
    cursor: pointer;
    color: #5477ff;
    font-size: 18px;
    margin: 0 6px;
    &.stop{
      color: red;
    }
    &.disabled{
      cursor: not-allowed;
      color: #6A6A6A;
    }
  }
}