div.card-List.x{
    flex: 1;
    margin-top: 0;
    overflow: auto;
    .card-List-con{
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        .con{
            display: flex;
            flex-wrap: wrap;
        }
        .row{
            .details{
                width: 100%;
                .details_item{
                    display: flex;
                    justify-content: flex-start;
                    span{
                        display: block;
                    }
                    .details_item_text{
                        flex: 1;
                        max-width: initial;
                    }
                    .tooltip-text{
                        word-break: break-all;
                        white-space: pre-line;
                    }
                }
            }
        }
        .details{
            height: 44px;
            line-height: 44px;
            .details_item_tit{
                width: 100px;
                text-align: right;
            }            
        }
    }
}