.export-delivery-log {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;

  .content.content.content.content.content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}