div.v-task-list {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;

    >.list-option {
        flex: none;
        margin: 12px 12px 0px 12px;
        display: flex;
        align-items: flex-end;

        >.x-forminput {
            margin-right: 12px;

            div.x.text-input {
                background-color: #fff;
            }
        }



        >.x.button {
            margin-left: 12px;
            width: 100px;
        }
    }

    >.list-panel {
        flex: 1;
        display: flex;

        >.x-card {
            flex: 1;
        }

        >.detail-chart {
            flex: none;
            width: 400px;
            display: flex;
            flex-direction: column;

            >.x-card {
                margin-left: 0px;
                margin-top: 0px;
                flex: 1;

                &.milestones {
                    flex: none;
                    margin-top: 12px;
                }
            }
        }
    }

    .x-datagrid {
        position: absolute;
        height: 100%;
        width: 100%;

        .cell>.operations {
            display: flex;
            align-items: center;

            >span {
                position: relative;
                color: #5477FF;
                cursor: pointer;
                width: 45px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:last-child)::after {
                    content: "";
                    display: block;
                    height: 60%;
                    width: 1px;
                    background-color: #d8d8d8;
                    position: absolute;
                    right: 0px;
                }

                &:hover {
                    color: blue;
                }
            }
        }
    }
}



.modal.create-task-dialog {
    >.content {
        display: grid;
        grid-template-columns: repeat(2, 420px);
        grid-template-rows: repeat(6, auto);
        row-gap: 12px;
        justify-items: start;

        .span2 {
            grid-column: span 2;
        }



        .man_day {
            &.x-forminput {
                >div.x.text-input {
                    width: 114px;
                }

                >.input-label {
                    width: 120px;
                    text-align: right;
                }
            }

        }

        >.x-forminput {
            &.w_max {
                grid-column: span 2;

                .x.text-input,
                .x.select {
                    width: 600px;
                }
            }

            >.input-label {
                width: 120px;
                text-align: right;
            }
        }

        p {
            padding-left: 130px;
            margin: 0;
            grid-column: span 2;

        }

        .text_onInput {
            >span {
                display: inline-grid;
                width: 120px;
                margin-right: 8px;
                justify-items: end;
                color: gray;
                font-size: 14px;
            }
        }

    }
}