.relation-card-wrap.relation-card-wrap.relation-card-wrap.relation-card-wrap{
  width: 600px;
  margin-left: 0;
  .relation-card{
    padding: 8px;
    height: 256px;
    flex: unset;
    .relation-item-wrap{
      width: 100%;
    }
    .relation-item{
      height: 50px;
      width: 100%;
      .rootnet-forminput{
        margin-right: 0;
      }
      .tree-add-icon{
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 4px;
        &:hover{
          color: #3f7dff;
        }
      }
      .condition-form-input{
        flex: 1;
        .form-component{
          flex: 1;
        }
      }
      .add-icon, .del-icon{
        font-size: 20px;
        margin-left: 8px;
        cursor: pointer;
      }
      .add-icon:hover{
        color: #3f7dff;
      }
      .del-icon:hover{
        color: red;
      }
    }
  }
  .full-screen-icon{
    font-size: 16px;
    cursor: pointer;
    &:hover{
      color: #3f7dff;
    }
  }
  &.full-screen{
    position: absolute;
    margin: 12px;
    width: calc(100% - 24px);
    height: calc(100vh - 62px);
    z-index: 4;
    .relation-card{
      height: 100%;
    }
  }
}