.workflow-editable-left-panel{
  width: 180px;
  height: 100%;
  padding: 8px;
  background-color: #fff;
  border-right: 1px solid #e1e1e1;
  .add-btn.add-btn.add-btn{
    height: 30px;
    margin: 8px 0;
  }
}