.biz-tree-table {
  .biz-tree-table-expand-col {
    display: flex;
    align-items: center;

    >.expand-icon.expanded {
      margin-top: 0;
    }

  }


  &-expand-col {
    >.expand-icon {


      user-select: none;
      margin: 0 4px;
      cursor: pointer;

      .icon-hover {
        font-size: 14px;

        &:focus,
        &:hover {
          color: #5477FF;
        }
      }

      // transform: rotate(-90deg);
    }
  }
}