.commonHead {
  display: flex;
  background: white;

  >p {
    width: 15%;
    margin: 0;
  }

  >p:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;

    >i {
      font-size: 0.35rem;
    }
  }

  >p:nth-child(2) {
    flex: 1;
    padding: 0.3rem;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 0.24rem;
    text-align: center;
  }
}