.radio-dialog.radio-dialog.radio-dialog{
  .row{
    .x-checkbox{
      input{
        border-radius: 50%;
        &:first-child:checked{
          background-color: #fff;
          &::after{
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            display: block;
            background: #5477ff;
          }
        }
      }
    }
  }
  .top{
    .x-checkbox{
      display: none;
    }
  }
}