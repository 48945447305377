.ModifyMetadata.ModifyMetadata.ModifyMetadata.ModifyMetadata.ModifyMetadata {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  >section {
    flex: 1;
    overflow-y: auto;
    padding: 0.2rem;
    box-sizing: border-box;
    position: relative;

    .loader.loader.loader.loader.loader {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      background-color: white;
      left: 0;
      top: 0;
    }

    .form-error-msg {
      color: red;
    }

    .error.error.error.error.error.error {
      .rootnet-textarea {
        border-color: #F5221B;
        box-shadow: 0px 0px 4px 0px #F5221B;
      }
    }

    .constant-identifier {
      display: flex;
      align-items: center;

      .rootnet-forminput {
        flex: 1;
        margin: 0;
        margin-right: 0.2rem;

        >div {
          width: 100%;
        }
      }

      .switch {
        margin-top: 0.24rem;
      }
    }

    >div {
      background: white;
      padding: 0.2rem 0;
      box-sizing: border-box;
      border-radius: 0.15rem;
      display: flex;
      flex-direction: column;

      >div {
        flex: 1;
        margin: 0;
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;

        >div {
          width: 100%;
        }
      }
    }
  }

  >footer {
    background: white;
    padding: 0.2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .primary {
      width: 90%;
      border-radius: 0.5rem;
      padding: 0.22rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      letter-spacing: 0.1rem
    }
  }
}