.issue-add-dialog {
  width: 1000px;

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 4px 8px;
  }

  >.content.content.content.content {
    padding: 0;

    .content-wrap {
      height: 80vh;
      flex: 1;
      overflow: scroll;
      padding: 8px 12px;
    }

    .search-tracer {
      .prefix {
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: unset;

        i {
          font-size: 14px;
        }
      }

      .suffix {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: unset;

        i {
          display: none;
          cursor: pointer;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.25);

          &:hover {
            color: gray;
          }
        }
      }

      &:hover {
        .suffix>i {
          display: inline-block;
        }
      }

      input {
        text-indent: unset;
      }
    }

    .mock-footer {
      display: flex;
      align-items: center;
      padding: 8px 0;
      height: 48px;
      box-sizing: border-box;
      border-top: 1px solid #E9EAF2;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;

      .continue-add-check {
        margin-left: 20px;
      }

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }

  .area-wrap {
    .area-header {
      margin: 12px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .desc-wrap {
    .rich-text-area {
      margin-left: 40px;
      height: 300px;
    }
  }

  .doc-area {
    margin-left: 40px;
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput {

    .form-component,
    >span {
      margin-left: 6px;
    }
  }

  .rootnet-forminput.horizontal {
    .cascade-select {
      margin-left: 6px;
    }
  }

  .long-options {
    .rootnet-popup {
      .container {
        .option {
          min-width: 300px;
        }
      }
    }
  }

  .effect-grade-tips {
    width: 600px;
    white-space: pre-wrap
  }
}