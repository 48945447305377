.searchBox{
    position: absolute;
    box-sizing: border-box;
    z-index: 10;
    top:50px;
    right:  102px;
    width: 262px;
    height: 116px;
    background: #FFFFFF;
    box-shadow: 0 6px 14px 0 rgba(163,171,203,0.40);
    border-radius: 4px;
    padding: 8px;
    .footer{
        margin-top: 20px;
        height: 48px;
        padding-bottom: 16px;
        display: flex;
        justify-content: flex-end;
        .button{
            width: 60px;
            height: 32px;
        }
        box-sizing: border-box;
    }
    &.nodata{
        height: 168px;
    }
}