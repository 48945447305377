.workflow-editable-panel{
  height: 100%;
  width: 100%;
  border: 1px solid #dfe3e8;
  #graph-container {
    width: calc(100% - 180px);
    height: 100%;
  }
  .x6-widget-stencil  {
    background-color: #fff;
  }
  .x6-widget-stencil-title {
    background-color: #fff;
  }
  .x6-widget-stencil-group-title {
    background-color: #fff !important;
  }
  .x6-widget-transform {
    margin: -1px 0 0 -1px;
    padding: 0;
    border: 1px solid #239edd;
  }
  .x6-widget-transform > div {
    border: 1px solid #239edd;
  }
  .x6-widget-transform > div:hover {
    background-color: #3dafe4;
  }
  .x6-widget-transform-active-handle {
    background-color: #3dafe4;
  }
  .x6-widget-transform-resize {
    border-radius: 0;
  }
  .x6-widget-selection-inner {
    border: 1px solid #239edd;
  }
  .x6-widget-selection-box {
    opacity: 0;
  }
  #graph-container{
    flex: 1;
  }
  .line-setting{
    width: 140px;
    padding: 8px;
    .header-text{
      color: #666;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 12px;
    }
  }
  .x6-edge-label{
    text{
      font-size: 12px;
    }
  }
}