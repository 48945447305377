.customer-detail.customer-detail.customer-detail {
  flex: 1;
  display: flex;
  overflow: hidden;

  .customer-detail-tabs {
    flex: 1;
    overflow: hidden;
    margin: 8px 12px;
    background-color: white;

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      display: flex;
      flex: 1;
      // height: 100px;
      overflow: hidden;
      padding: 6px;
      padding-top: 0;
    }

    .ant-tabs-content,
    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
        display: flex;

        &.ant-tabs-tabpane-hidden {
          display: none;
        }
      }
    }
  }
}