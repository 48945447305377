.dev-project-scope-version.dev-project-scope-version {
  width: 250px;
  height: 100%;
  border-right: 1px solid #F0F0F0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;

  &.is-fold {
    width: 40px;
  }

  .dev-project-version-header {
    height: 46px;
    border-bottom: 1px solid #F0F0F0;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      background-color: #ECECEC;
    }

    .dev-project-version-header-text {
      font-weight: bold;
      padding-left: 4px;
    }

    .fold-icon {
      padding: 0 4px;
      font-size: 16px;
    }
  }

  .dev-project-version-type {
    border-bottom: 1px solid #F0F0F0;

    .version-type-item {
      height: 32px;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background-color: #ECECEC;
      }

      &.active {
        color: #5477ff;
        background: #e6eef9;
      }

      .type-num {
        color: #AFAFAF;
      }
    }
  }

  .dev-project-version-list {
    height: 100px;
    flex: 1;

    .add-version {
      height: 32px;
      padding: 0 8px;
      cursor: pointer;
      border-bottom: 1px solid #F0F0F0;

      &:hover {
        background-color: #ECECEC;
        color: #5477ff;
      }
    }

    .version-item-wrap {
      height: 100px;
      flex: 1;
      overflow-y: auto;
    }

    .version-item {
      padding: 12px 8px;
      cursor: pointer;
      border-bottom: 1px solid #F0F0F0;

      &:hover {
        background-color: #ECECEC;
      }

      &.active {
        background: #e6eef9;
      }

      .version-info {
        .version-title {
          font-size: 15px;
          //font-weight: bold;
          flex: 1;
          width: 10px;
          white-space: nowrap;
          /*不允许换行*/
          overflow: hidden;
          /*超出隐藏*/
          text-overflow: ellipsis;
          /*文本超出三点代替*/
        }

        .version-info-right {
          font-size: 12px;

          .version-status {
            margin-left: 4px;
          }

        }
      }

      .version-progress {
        font-size: 12px;
        margin-top: 6px;

        // padding-right: 8px;
        .version-progress-percent {
          color: #AFAFAF;
        }

        .version-enter {
          padding: 2px 8px;
          border: 1px solid #CFD3E3;
          cursor: pointer;
          border-radius: 4px;
          transition: all 0.3s;
          margin-left: 6px;
          background: #fff;

          &:hover {
            color: #7692FF;
            border: 1px solid #7692FF;
          }
        }
      }
    }
  }
}

.version-info-tooltip-item {
  .ant-tooltip-inner.ant-tooltip-inner.ant-tooltip-inner {
    .text-area-input-detail.text-area-input-detail.text-area-input-detail {
      align-items: flex-start;

      .form-component {
        height: unset;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }

    .rootnet-forminput {
      margin: 0;
    }
  }
}