.version-plan-dashboard-wrap{
  background-color: #fff;
  width: 100%;
  height: 100%;
  .header-count-item{
    margin:0 8px;
  }
  .milestone-icon{
    margin-left: 16px;
    margin-right: 4px;
  }
  .x-card{
    margin: 0;
    height: 100%;
    .cell.cell.cell:not(.tooltip){
      padding: 0;
    }
  }
  .normal-cell{
    padding: 0 12px;
  }
  .inner-cell{
    width: 100%;
    height: 100%;
    padding: 0 12px;
    &.right{
      justify-content: flex-end;
    }
  }
  .extra-wrap{
    .count-wrap{
      color: #8a8888;
      .total-count{
        margin-right: 12px;
      }
    }
    .filter-extra{
      &.active{
        color: #5477ff;
      }
    }
  }
  .title-tag{
    color: #fff;
    font-size: 10px;
    padding: 0 2px;
    border-radius: 4px;
    margin-right: 2px;
    transform: scale(0.7);
  }
}