.dev-list-mgt{
  .grid.grid.grid{
    .cell{
      padding: 0;
      .person-select{
        width: unset !important;
        .ant-select-selection-overflow{
          flex-wrap: unset;
        }
      }
      .rootnet-forminput{
        margin-left: 2px;
      }
      .common-display-field{
        padding: 0 12px;
      }
      .common-edit-field{
        padding: 0 12px;
        cursor: pointer;
        height: 34px;
        &:hover{
          background: #EBF0FB;
        }
      }
      .enter-detail-field-wrap{
        cursor: unset;
        width: 100%;
        .enter-detail-field{
          width: 100px;
          flex: 1;
          .enter-detail-field-text{
            max-width: 100%;
            cursor: pointer;
            color: #5477ff;
            overflow: hidden;  //隐藏多余行数
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
            -webkit-box-orient: vertical;  //规定为垂直方向排列
            &:hover{
              text-decoration:underline
            }
          }
        }
        .enter-detail-field-edit-icon{
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;
          &:hover{
            color: #5477ff;
          }
        }
        &:hover{
          .enter-detail-field-edit-icon{
            display: block;
          }
        }
      }
      .arrow-drop-down-group{
        .common-display-field, .common-edit-field{
          padding-left: 4px;
          padding-right: 12px;
        }
      }
    }
  }
  .work-flow-status-field{
    cursor: pointer;
    margin-left: 8px;
  }
  .space-between{
    justify-content: space-between;
  }
  .more-handle-icon.more-handle-icon.more-handle-icon{
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
    &:hover{
      color: #3f7dff;
    }
  }
  .expand-icon{
    margin-right: 8px;
    margin-top: 2px;
    cursor: pointer;
    &:hover{
      color: #3f7dff;
    }
  }
  .empty-parent{
    width: 20px;
  }
  .empty-child{
    width: 40px;
  }
  .left-enter-field-wrap{
    width: 100px;
    flex: 1;
    .ant-tag{
      padding: 2px 6px;
      font-size: 12px;
      line-height: 12px;
      margin-right: 4px;
    }
  }

  .fist-col{
    width: 100%;
    height: 100%;
    .check-box{
      display: none;
    }
    .rootnet-checkbox{
      height: unset;
    }
    &:hover, &.selected{
      .check-box{
        display: unset;
      }
      .index-num{
        display: none;
      }
    }
  }
}
.dev-handle-popover{
  .dev-handle-item{
    padding: 6px 12px;
    min-width: 150px;
    &.disabled{
      color: #d6d8e0;
      &:hover{
        color: #d6d8e0;
        cursor: not-allowed;
      }
    }
    &:hover{
      cursor: pointer;
      background: #efefef;
      color: #3f7dff;
    }
    &.del-item{
      &:hover{
        color: red;
      }
    }
    .handle-icon.handle-icon{
      font-size: 14px;
      margin-right: 6px;
    }
  }
}
.story-type-add-group{
  padding: 4px 8px;
  .story-type-item{
    height: 30px;
    padding: 12px 8px;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.075);
    }
    .type-tag{
      width: 50px;
      background-color: #5477ff;
      color: #fff;
      border-radius: 8px;
      height: 18px;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}