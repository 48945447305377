.appraise-list-details {
  overflow: hidden;
  .c-options {
    margin-top: 0;

    .btn.btn.btn {
      width: 75px;
      height: 31px;
      margin-left: 15px;
      margin-right: 0;
    }

    .reset-btn {
      color: #3A415C;
      border: 1px solid #7A8199;
    }
  }

  .x-card {
    margin-top: 0;

    .x-datagrid {
      .enter-detail-field {
        cursor: pointer;
        color: #5477ff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}