.person-depart-group-select-user-item{
  width: 100%;
  padding: 6px 4px;
  justify-content: space-between;
  &:hover{
    background-color: #E9F5FF;
    .clear-icon-wrap{
      display: block;
    }
  }
  .user-avatar{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    background-color: #8DC8EA;
    margin-right: 8px;
  }
  .item-selected{
    position: absolute;
    height: 14px;
    width: 14px;
    top: -2px;
    right: -4px;
    border-radius: 50%;
    background-color: #108ee9;
  }
  .user-info{
    font-size: 12px;
    .user-info-header{
      line-height: 20px;
      .user-name{
        margin-right: 4px;
      }
      .user-dept{
        cursor: pointer;
        &:hover{
          color: #5477ff;
        }
      }
      .resigned-user{
        color: #989EA4;
      }
    }
    .user-info-footer{
      line-height: 20px;
      .user-id{
        margin-right: 4px;
      }
      .user-account{
        color: #989EA4;
      }
    }
  }
  .clear-icon-wrap{
    display: none;
    padding-right: 4px;
    .clear-icon{
      cursor: pointer;
      font-size: 16px;
      color: #B0B4B8;
      &:hover{
        color: #868B8F;
      }
    }
  }
}