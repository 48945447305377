.todo-list-detail-content {
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #fff;

  .x-datagrid {
    font-size: 12px;
  }

  .area-wrap {
    .area-header {
      margin: 12px 0;
    }

    .area-content-wrap {
      overflow: hidden;
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 10px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .note-wrap {
    padding-bottom: 10px;
    padding-right: 10px;

    .rich-text-area {
      margin-left: 40px;
    }
  }

  .rich-text-detail-wrap {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 12px;
    white-space: pre-wrap;

    img {
      max-width: 100%;
    }
  }

  .mock-dialog-header {
    display: flex;
    align-items: center;
    color: #15171f;
    padding: 12px 16px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9eaf2;
    justify-content: space-between;

    .dialog-title {
      font-weight: 700;
    }

    .mock-right-header {
      .header-edit-icon {
        font-size: 18px;
        cursor: pointer;
        margin: 0 14px;

        &.edit-active-icon {
          color: #5477ff;
        }

        &:hover {
          color: #5477ff;
        }
      }

      .header-handle-icon {
        font-size: 18px;
        cursor: pointer;
        margin: 0 20px;

        &:hover {
          color: #5477ff;
        }
      }
    }

    .close-area {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;

      .close-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: #ECECEC;
      }
    }
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 8px 4px;
  }

  .text-area-detail.text-area-detail.text-area-detail {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin: 0;
    padding: 0 4px;
    margin: 8px 0;

    >label {
      margin-top: 0;
    }

    .form-component.rootnet-textarea {
      height: 100px;
      margin-right: 8px;
    }

    .form-component {
      flex: 1 !important;
      padding-right: 8px;
      box-sizing: border-box;
      height: auto;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .mock-footer {
    height: 44px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;

    .footer-switch {
      .footer-switch-item {
        cursor: pointer;
        padding: 4px 12px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        margin-right: 12px;
        color: #fff;
        background-color: #5477ff;
        transition: background-color 0.4s;

        &:hover {
          background-color: #214eff;
        }

        &.disabled {
          color: #6A6A6A;
          background-color: #E8E8E8;
          cursor: not-allowed;

          &:hover {
            background-color: #E8E8E8;
          }
        }

        >i {
          font-size: 14px;
        }
      }

      .list-num {
        font-weight: bold;
      }
    }

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

  .todo-list-detail-main-panel {
    height: 100px;
    padding-bottom: 8px;
    margin: 0 12px;
    flex: 1;
    overflow: hidden;
    display: flex;
    border-bottom: 1px solid #e9eaf2;

    .dynamic-wrap {
      overflow: hidden;
    }

    .todo-list-tabs.ant-tabs {
      height: 100%;

      .ant-tabs-nav {
        width: 100%;
        padding-left: 10px;
        margin-bottom: 0;
      }

      >.ant-tabs-content-holder {
        width: 100%;
        display: flex;
        flex: 1;
        height: 100%;
        overflow: hidden;
        padding: 6px;

        >.ant-tabs-content,
        >.ant-tabs-content {
          height: 100%;

          .ant-tabs-tabpane {
            height: 100%;
            display: flex;

            &.ant-tabs-tabpane-hidden {
              display: none;
            }

            .x-datagrid {
              .grid {
                max-height: 392px;

                .enter-detail-field {
                  cursor: pointer;
                  color: #5477ff;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }


      .tab-content {
        height: 100%;
      }
    }

    .add-subtask {
      border: 1px solid #e9eaf2;
      border-radius: 4px;
      padding: 8px;
      margin: 8px 0 0 0;

      >i {
        font-size: 14px;
        margin-right: 6px;
      }

      &:hover {
        cursor: pointer;
        color: #5477ff;
        border-color: #5477ff;
      }
    }

    .todo-list-form {
      flex: 1;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 4px;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: #00000020;
      }

    }

    .todo-list-trag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: col-resize;
      width: 8px;
      border-left: 2px solid transparent;
      box-sizing: border-box;
      background-color: #F0F3F7;

      &:hover {
        border-left-color: #2684FF;
      }

      &:hover>i {
        color: #5477ff;
      }
    }

    .comment-content {
      flex: 1;
      border-top: 1px solid #e9eaf2;
      padding-top: 8px;
      box-sizing: border-box;
    }

    .comment-down {
      position: sticky;
      bottom: 0;
    }
  }

  .todo-list-detail-left-panel {
    width: 100px;
    flex: 1;
    border-top: 1px solid #d0d3e3;
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput {

    .form-component,
    >span {
      margin-left: 6px;
    }
  }

  .source-text-hover {
    cursor: pointer;
    color: #5477ff;

    &:hover {
      text-decoration: underline;
    }
  }

  .ellipsis-line {
    width: 230px;
    white-space: nowrap;
    /*不允许换行*/
    overflow: hidden;
    /*超出隐藏*/
    text-overflow: ellipsis;
    /*文本超出三点代替*/
  }
}