.imp-project-template-list-dialog.x.x.x.x{
  >.content{
    width: 950px;
    height: 550px;
    max-height: 80vh;
    padding-bottom: 10px;
    overflow: scroll;
    .ant-table-wrapper{
      flex: 1;
    }
    .ant-spin-nested-loading{
      height: 100%;
    }
    .ant-spin-container{
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ant-table-pagination.ant-pagination{
     margin: 8px 0 0 0;
    }
  }
}