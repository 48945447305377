.selectUseCaseDialog {
  width: 1066px;

  .customize-options {
    .c-options {

      .rootnet-forminput {

        .switch {
          margin-bottom: 4px;
        }
      }
    }
  }

  .selectUseCaseTree {
    color: gray;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wenjianjiaSize {
    font-size: 14px;
  }

  .content.content.content.content {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding-top: 0;

    .x-card {
      margin: 0;
      flex: 1;
      box-shadow: none;
      overflow: hidden;
    }

    >.flex {
      flex: 1;
      display: flex;
      overflow: hidden;

      // 左侧需求或研发任务
      >div:nth-child(1) {
        // padding: 12px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 4px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      //右侧选中数据
      >div:nth-child(2) {
        margin-left: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        >div {
          width: 240px;
          font-size: 16px;
          padding: 8px 12px;
          border-bottom: 1px solid #ccc;
          box-sizing: border-box;
        }

        ul,
        li {
          padding: 0;
          margin: 0;
          list-style: none
        }

        ul {
          flex: 1;
          overflow: auto;
        }

        li {
          padding: 4px 12px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          >span:nth-child(1) {
            width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          >span:nth-child(2) {
            display: none;
            cursor: pointer;

            i {
              font-size: 14px;
              color: #ccc;
            }
          }

          &:hover>span:nth-child(2) {
            display: inline-block;
          }

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
}