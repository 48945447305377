.product-detail-dialog{
  height: 90%;
  width: 750px;
  max-height: 650px;
  display: flex;
  &.is-add{
    .ant-tabs-nav{
      display: none;
    }
  }
  >.content.content.content.content {
    padding: 0;
    width: 100px;
    height: 100%;
    display: flex;
    flex: 1;
    .product-detail-content{
      padding: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      background-color: #fff;
    }
    .mock-dialog-header {
      display: flex;
      align-items: center;
      color: #15171f;
      padding: 12px 16px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e9eaf2;
      justify-content: space-between;

      .dialog-title {
        font-weight: 700;
      }

      .mock-right-header {
        .header-edit-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }

        .header-handle-icon {
          font-size: 18px;
          cursor: pointer;
          margin: 0 20px;

          &:hover {
            color: #5477ff;
          }
        }
      }

      .close-area {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 16px;

        .close-icon {
          font-size: 14px;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }
    .main-content-wrap{
      width: 100%;
      height: 100px;
      padding: 0 16px;
      flex: 1;
      border-bottom: 1px solid #D0D3E3;
      .ant-tabs, .ant-tabs-content, .ant-tabs-tabpane{
        height: 100%;
      }
      .ant-tabs-tabpane{
        overflow-y: auto;
      }
      .rich-text-form-item{
        .mock-label{
          margin-top: 8px;
          margin-left: 8px;
          width: 130px;
          text-align: right;
          color: #738299;
          &::after {
            content: ":";
            margin: 0 8px 0 2px;
          }
          &.required:before {
            display: inline-block;
            margin-right: 4px;
            line-height: 1;
            content: '*';
            color: #F5221B;
          }
        }

        .rich-text-area{
          height: 250px;
          width: 506px;
        }

        .rich-text-value-show-wrap{
          margin-top: 6px;
          line-height: 28px;
          width: 500px;
          *{
            max-width: 100%;
          }
          p{
            line-height: 25px;
          }
        }
      }

      .area-wrap{
        .area-header{
          margin: 12px 0;
        }
        .area-content-wrap{
          overflow: hidden;
        }
      }
      .fold-icon{
        cursor: pointer;
        //transition: transform 0.3s;
        margin-left: 10px;
        margin-right: 18px;
        &:hover{
          color: #5477ff;
        }
      }
    }
    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;

      .footer-switch {
        .footer-switch-item {
          cursor: pointer;
          padding: 4px 12px;
          border-radius: 18px;
          box-sizing: border-box;
          display: flex;
          margin-right: 12px;
          color: #fff;
          background-color: #5477ff;
          transition: background-color 0.4s;

          &:hover {
            background-color: #214eff;
          }

          &.disabled {
            color: #6A6A6A;
            background-color: #E8E8E8;
            cursor: not-allowed;

            &:hover {
              background-color: #E8E8E8;
            }
          }

          >i {
            font-size: 14px;
          }
        }

        .list-num {
          font-weight: bold;
        }
      }

      .btn-group {
        .button {
          margin-right: 16px;
          height: 32px;
        }
      }
    }
  }
}