.resource-pool-import {
  width: 850px;

  .content {
    height: 65vh;
    display: block;
    overflow: scroll;

    >div {
      >p {
        margin-left: 18px;
      }

      >p:nth-child(1) {
        margin-left: 0;
      }
    }

    .file-input {
      display: none;
    }

    .Download {
      >span {

        i {
          cursor: pointer;
          font-size: 14px;
        }

        span {
          cursor: pointer;
          color: #5477ff;

          &:hover {
            color: #7f93e0;
          }
        }
      }
    }
  }
}