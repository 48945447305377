.apply-join-project-dialog{
  .content.content.content.content{
    width: 600px;
    padding-bottom: 20px;
    .apply-join-under-approbal{
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tips-wrap{
      margin-top: 6px;
      font-size: 12px;
      color: red;
      margin-left: 10px;
      .tips-icon{
        color: red;
        margin-top: 2px;
        margin-right: 6px;
      }
    }
  }
}
.space-between{
  justify-content: space-between;
}
.ant-select-item-option-state{
  display: none;
}