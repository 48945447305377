.resource-summary-info.resource-summary-info.resource-summary-info{
  overflow: hidden;
  .outer-card{
    margin: 0;
    margin-bottom: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  }
  .outer-card-content{
    min-height: 100px;
    .x-datagrid {
      flex: 1;
      border-top: none;
      font-size: 12px;
    }
    .table-wrap{
      width: 100%;
      justify-content: space-between;
      .position-table, .module-table{
        width: 49%;
      }
      .module-table{
        border-left: 1px solid #e6e6e6;
      }
    }
  }
  .card-header{
    .right{
      width: 52%;
    }
    .extra-wrap{
      width: 100%;
      justify-content: space-between;
      .expand-text{
        cursor: pointer;
        color: #5477ff;
        .fold-icon{
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }

  .cell.align-center{
    padding: 0;
    .saturation-item{
      color: #fff;
      font-size: 16px;
      height: 100%;
      width: 100%;
    }
  }
  .module-table .header .column{
    .top.align-center{
      padding: 8px 0;
    }
  }

  .module-header{
    text-align: center;
    white-space: nowrap;/*不允许换行*/
    overflow: hidden;/*超出隐藏*/
    text-overflow: ellipsis;/*文本超出三点代替*/
  }

  .extra-import-btn{
    height: 32px;
  }
}