.defect-content-info {
  height: 100%;
  overflow: auto;
  font-size: 12px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .description-detail {
    p {
      margin-bottom: 0;
    }
    img{
      max-width: 100%;
    }
  }

  .defect-info-add {
    width: 100%;
  }

  .defect-info {
    width: calc(100% - 170px);
  }

  .area-wrap {
    margin-bottom: 8px;

    .area-header {
      margin: 4px 0;
      font-size: 14px;
      font-weight: 900;

      .fold-icon {
        cursor: pointer;
        margin-left: 10px;
        margin-right: 18px;

        &:hover {
          color: #5477ff;
        }
      }
    }

    .area-content-wrap {
      overflow: hidden;
      padding-left: 40px;

      .rootnet-forminput {
        width: 100%;

      }
    }

    .rich-text-area {
      min-height: 680px;

      .tox-tinymce {
        min-height: 680px;
      }
    }

    .area-content-wrap {
      overflow: hidden;

      .rootnet-forminput {
        width: 100%;
        margin: 8px 0;

        .form-component.form-component.form-component {
          margin-left: 0;
          width: 100% !important;
          white-space: pre-wrap;
          height: auto;
        }
      }
    }
  }

  .defect-detail-anchor {
    margin-left: 4px;
    padding-left: 4px;
    height: 100%;
    overflow-y: scroll;

    .ant-affix {
      top: 310px !important;
      z-index: 2 !important;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}