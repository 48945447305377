.work-time-report{
  padding: 12px;
}
.work-time-report-week-pop{
  .ant-picker-content{
    thead{
      tr{
        th:first-child{
          display: none;
        }
      }
    }
    tbody{
      tr{
        td:first-child{
          display: none;
        }
      }
    }
  }
}