.dev-project-stage-info{
  .rich-text-form-item {
    margin: 8px;

    .mock-label {
      margin: 6px 0;
      width: 110px;
      text-align: right;
      color: #738299;

      &::after {
        content: ":";
        margin: 0 8px 0 2px;
      }
    }

    .rich-text-area {
      height: 250px;
      width: 486px;
    }

    .rich-text-value-show-wrap {
      line-height: 22px;
      width: 500px;
      margin-top: 8px;
      * {
        max-width: 100%;
      }
    }
  }
  .textarea-field-wrap{
    .rootnet-forminput{
      margin-right: 0;
      height: 32px;
    }
    .textarea-show-value{
      line-height: 32px;
      margin-top: 8px;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}