.main-panel {
  flex: 1;
  display: flex;
  height: 50%;

  .left-menu-fixed {
    position: fixed;
    z-index: 999;
    height: calc(100% - 48px);
  }

  .left-menu {
    height: 100%;
    background: #fff;
    flex: none;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    border-right: 1px solid rgb(235, 235, 235);

    .menu-logo {
      display: flex;
      background-color: white;
      justify-content: center;
      align-items: center;
      height: 40px;
    }

    .menu-item {
      .header {
        >span {
          flex: 1;
          display: flex;
        }
      }

      &.expanded {
        .sub-menu {
          height: auto;
        }
      }

      .sub-menu {
        height: 0;
      }

      &.common-center {
        position: sticky;
        top: 39px;
        z-index: 999;
        background: #fff;
      }

      &:hover .menu-list-hover>i {
        display: inline-block;
      }
    }

    .menu-list-hover-collected.menu-list-hover-collected.menu-list-hover-collected {
      display: inline-block;
    }

    .menu-list-hover {
      display: flex;
      justify-content: space-between;
      margin-right: 25px;
      flex: 1;

      >i {
        display: none;
      }
    }

    .menu-fixed-fix.menu-fixed-fix {
      >i {
        transform: rotate(0deg);
      }
    }

    .menu-fixed {
      padding-bottom: 8px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;

      >i {
        transform: rotate(45deg);
        font-size: 20px;
        cursor: pointer;
        color: gray;

        &:hover {
          color: #5477ff;
        }
      }
    }

    // &:hover .x-menu-ext {
    //   width: 220px;
    // }

    >div.x.button {
      border-bottom: 1px solid rgb(240, 240, 240);
      border-radius: 0;
      margin: 0;
      height: 40px;
      flex: none;
      background-color: transparent;

      >i.iconfont {
        font-size: 16px;
      }
    }
  }

  .right-main-panel {
    flex: 1;
  }

  .stretch {
    flex: 1;
    position: relative;
    width: 100%;
    overflow: auto;

    >div {
      height: 100%;
    }
  }

  .x-menu-ext.wide {
    width: 220px;
  }

  .menu-tab-link {
    background: #fff;

    .ant-tabs-top {
      flex: 1;

      >.ant-tabs-nav {
        margin: 8px 0 0;
        overflow: hidden;
        height: 32px;

        .ant-tabs-nav-operations {
          line-height: 1;
        }

        .ant-tabs-nav-wrap {
          width: 800px;
          overflow: hidden;

          .ant-tabs-ink-bar-animated {
            visibility: hidden;
          }

          .ant-tabs-tab {
            padding: 0 16px;
            border: 1px solid #e9eaf2;
            background: transparent;

            &.ant-tabs-tab-with-remove{
              padding: 0 0 0 16px;
            }

            &:first-child {
              margin-left: 12px;
              //padding-right:20px;
              overflow: hidden;
            }

            .ant-tabs-tab-btn {
              height: 100%;
              display: flex;
              align-items: center;
              color: #7a8199;

              &:hover {
                color: #5477ff;
              }
            }

            .ant-tabs-tab-remove {
              transform: scale(0);
              margin: 0;
            }

            &:hover {
              .ant-tabs-tab-remove {
                color: #5477ff;
                transform: scale(0.95);
              }

              a {
                color: #5477ff;
                padding-right: 5px;
              }
            }
          }

          .ant-tabs-tab-active {
            background: #f0f3f7;
            border-bottom-color: #f0f3f7;
            // border-radius: 4px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            .ant-tabs-tab-btn {
              color: #5477ff;
            }
          }
        }
      }
    }

    div.close-menu-drop-down {
      display: flex;
      padding: 0 16px 0 0;
      align-items: center;
      border-bottom: 1px solid #e9eaf2;
      cursor: pointer;

      .iconfont {
        color: #000;

        &:hover {
          color: red;
        }
      }
    }

    &.no-tab-close-icon.no-tab-close-icon.no-tab-close-icon.no-tab-close-icon.no-tab-close-icon {
      .ant-tabs-tab-remove {
        visibility: hidden;
      }
    }
  }
}