.view-detail.view-detail.view-detail.view-detail{
  .arrow-drop-down-group{
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .arrow-icon-wrap{
    width: 15px;
  }
  .arrow_drop_down{
    margin-right: 5px;
    display: inline-block;
  }
  .x-card{
    min-width: 1130px;
  }
  .view-detail-card{
    flex: 1;
  }
  .detail-overdue{
    border: 1px solid #000;
    padding: 2px 5px;
    border-radius: 2px;
  }
}
.version-import-dialog.version-import-dialog.version-import-dialog{
  background-color: #F9FAFE;
  .select-version{
    align-self: start;
    .rootnet-forminput{
      margin: 0;
    }
  }
  .main-panel{
    margin:4px 0;
    background-color: #fff;
    width: 800px;
    height: 420px;
    .x-card{
      margin: 0;
    }
  }
  .import-version-box{
    height: 200px;
  }
  >.content.content.content{

    padding-top: 6px;
  }
  >.footer.footer.footer{
    padding-top: 0;
    height: 50px;
  }
}
.log-dialog.log-dialog.log-dialog.log-dialog{
  .short-desc{
    width: 150px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    cursor: default;
  }
  .log-header{
    width: 100%;
    margin: 5px 17px;
    justify-content: space-between;
    align-items: center;
    .log-sum{
      margin-right: 15px;
    }
  }
  .main-panel{
    margin: 17px 0;
    width: 1050px;
    height: 350px;
    .x-card{
      margin: 0;
    }
  }
}