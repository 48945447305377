.inner-version-board-dialog {
  .dialog-headr {
    .head-tip {
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #AFAFAF;
      margin-left: 8px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }
}