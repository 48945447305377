.public-defect {
  .view-area-wrap {
    display: none;
  }

  .x-card {
    margin: 0;

    .grid {
      .cell.cell.cell {
        padding: 0;

        .x-datagrid-tooltip {
          padding: 0;
        }

        .person-select {
          width: unset !important;

          .ant-select-selection-overflow {
            flex-wrap: unset;
          }
        }

        .rootnet-forminput {
          margin-left: 2px;
        }

        .common-display-field {
          padding: 0 12px;
        }

        .common-edit-field {
          padding: 0 12px;
          cursor: pointer;
          height: 34px;

          &:hover {
            background: #EBF0FB;
          }
        }

        .enter-detail-field-wrap {
          cursor: unset;

          .enter-detail-field {
            cursor: pointer;
            color: #5477ff;
          }

          .enter-detail-field-edit-icon {
            display: none;
            margin-left: 10px;
            cursor: pointer;
            font-size: 15px;

            &:hover {
              color: #5477ff;
            }
          }

          &:hover {
            .enter-detail-field-edit-icon {
              display: block;
            }
          }
        }

        .arrow-drop-down-group {

          .common-display-field,
          .common-edit-field {
            padding-left: 4px;
            padding-right: 12px;
          }
        }
      }
    }
  }

  .work-flow-status-field {
    cursor: pointer;
    margin-left: 8px;
  }
}