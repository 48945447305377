.used-product{
  height: 100%;
  width: 100%;
  .x-card{
    margin: 0;
    box-shadow: none;
  }
  .enter-detail-field-wrap{
    cursor: unset;
    .enter-detail-field{
      cursor: pointer;
      color: #5477ff;
    }
    .del-icon{
      display: none;
      margin-left: 10px;
      cursor: pointer;
      font-size: 15px;
      &:hover{
        color: red;
      }
    }
    &:hover{
      .del-icon{
        display: block;
      }
    }
  }
  .cell{
    .x-datagrid-tooltip{
      min-width: 100%;
      .enter-detail-field-wrap{
        min-width: 100%;
      }
    }
  }
  .handle-wrap{
    flex-direction: row-reverse;
    padding-right: 20px;
    height: 32px;
    margin-bottom: 4px;
  }
  .table-wrap{
    flex: 1;
    height: 100px;
    width: 100%;
    font-size: 12px;
  }
  .expand-icon{
    cursor: pointer;
    margin-top: 1px;
    margin-right: 6px;
    &:hover{
      color: #5477ff;
    }
  }
}