.caseTesting {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;

  >.content.content.content.content.content {
    flex: 1;
    display: flex;
    padding: 0;
    box-sizing: border-box;
    flex-direction: column;

    .enter-detail-field {
      cursor: pointer;
      color: #5477ff;

      &:hover {
        text-decoration: underline;
      }
    }

    .view-area-wrap {
      display: none;
    }

    .x-card.x-card-singlegrid {
      margin: 0;
    }

    .mock-dialog-header {
      display: flex;
      align-items: center;
      color: #15171f;
      padding: 12px 16px;
      padding-right: 0;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e9eaf2;
      justify-content: space-between;

      .dialog-title {
        font-weight: 700;
      }

      .mock-right-header {
        .header-doc-icon {
          font-size: 18px;
          cursor: pointer;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }
      }

      .mock-right-header {
        .header-edit-icon {
          font-size: 18px;
          cursor: pointer;

          &.edit-active-icon {
            color: #5477ff;
          }

          &:hover {
            color: #5477ff;
          }
        }
      }

      .close-area {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 20px;

        .close-icon {
          font-size: 14px;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }

    .testCase-footer {
      height: 64px;
      padding: 8px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #ccc;

      .comment-wrap {
        width: 30px;
        height: 30px;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #6A6A6A;

        &:hover {
          color: #1890ff;
          border: 1px solid #1890ff;
        }

        .comment-icon {
          font-size: 18px;
        }
      }

      .arrow-switch {
        display: flex;

        .disabled.disabled.disabled.disabled.disabled {
          color: #6a6a6a;
          background-color: #e8e8e8;
          cursor: not-allowed;
        }

        >div:nth-child(1),
        >div:nth-child(2) {
          cursor: pointer;
          padding: 4px 12px;
          background-color: #5477ff;
          border-radius: 18px;
          box-sizing: border-box;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          >i {
            font-size: 14px;
          }

          &:hover {
            background-color: #214eff;
          }
        }

        >div:nth-child(2) {
          margin: 0 16px;
        }

        >div:nth-child(3) {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
        }

      }
    }

    .left-arrow,
    .right-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      box-sizing: border-box;
      cursor: pointer;
      width: 26px;

      &:hover i {
        color: #5477ff;
      }

      i {
        font-size: 18px;
      }

    }

    .x-tab {
      flex: 1;
      overflow: hidden;

      .tab-container {
        overflow: hidden;
        display: flex;

        .tab-item.active {
          display: flex;
          flex: 1;
          box-sizing: border-box;
          overflow: hidden;

          .executiveHistory {
            flex: 1;
            overflow: hidden;

            .detail-field-text {
              cursor: pointer;
              color: #5477ff;

              &:hover {
                text-decoration: underline;
              }
            }

            .relate-document-icon-wrap {
              color: #5477ff;
              cursor: pointer;
            }
          }
        }
      }
    }

    .continue-add-check {
      position: absolute;
      bottom: -36px;
      z-index: 10;
    }

    .testCase-content {
      flex: 1;
      overflow: hidden;
      display: flex;

      .save-save-status-wrap{
        margin-left: 40px;
        margin-right: 20px;
        transform:rotate(-10deg);
        padding:4px;
        border:1px dashed #00A950;
        .save-save-status{
          padding:2px 6px;
          font-size: 18px;
          font-weight: bold;
          color: #00A950;
          border:1px dashed #00A950;
        }
      }
      .tags-area{
        margin-left: 20px;
      }

      .x-datagrid {
        font-size: 12px;
      }

      .editCondition {
        font-size: 12px;
        border-bottom: none;

        .rootnet-forminput {
          >label {
            font-size: 12px;
          }
        }
      }

      >div:nth-child(1) {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        >.rootnet-forminput {
          >label {
            font-size: 12px;
          }

          margin-top: 12px;
          width: calc(100% - 8px);
          display: flex;

          >div {
            flex: 1;
          }
        }
      }
    }

    .testCase-content-detail {

      .editCondition {
        .text-area-detail.text-area-detail.text-area-detail {
          display: flex;
          align-items: baseline;
          margin-bottom: 2px;

          .form-component {
            flex: 1;
            height: auto;
          }
        }

        .rootnet-forminput {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .x-tab {
        margin-top: 7px;

        .tab-header {
          border-top: 1px solid #e1e1e1;
        }

        .tab-item.active {
          padding-top: 0;
        }

        .displayDetail {
          font-size: 12px;

          >div {
            border: none;

            .rootnet-forminput {
              >label {
                font-size: 12px;
              }

              .form-component {
                padding-left: 4px;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }

    // 右侧
    .editConditionRight.editConditionRight.editConditionRight.editConditionRight {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      border-left: 1px solid #e9eaf2;
    }

    .editCondition {
      // margin-top: 12px;
      padding: 12px;
      box-sizing: border-box;
      width: 325px;
      border-radius: 4px;
      overflow: auto;
      flex: 1;
      .relate-form-input{
        margin-bottom: 0;
        .form-component {
          border-radius: 4px 4px 0 0;
        }
      }

      .searchIconInput {
        margin-bottom: 0;

        .form-component {
          border-radius: 4px 4px 0 0;
        }

        .prefix {
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          background-color: unset;

          i {
            font-size: 14px;
          }
        }

        input {
          text-indent: unset;
        }
      }

      ul,
      li {
        padding: 0;
        margin: 0;
        list-style: none
      }
      .mock-form-item{
        padding: 0 8px;
        .mock-label{
          padding: 6px 0;
          width: 105px;
          text-align: left;
          color: #738299;
          &.right{
            text-align: right;
          }
          &::after {
            content: ":";
            margin: 0 8px 0 2px;
          }
        }
        .mock-form-content{
          .relationList{
            border: none;
            margin-left: 0;
            border-radius: 4px;
          }
        }
      }


      .relationList {
        border: 1px solid #D0D3E3;
        border-top: 0px;
        border-radius: 0 0 4px 4px;
        margin-bottom: 8px;
        margin-left: 113px;
        box-sizing: border-box;
        max-height: 91px;
        width: 180px;
        overflow: auto;

        li {
          height: 30px;
          padding: 4px 12px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          >span:nth-child(1) {
            width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          >span:nth-child(2) {
            display: none;
            cursor: pointer;

            i {
              font-size: 14px;
              color: #ccc;
            }
          }

          &:hover>span:nth-child(2) {
            display: inline-block;
          }

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }
    }

    .displayDetail {
      flex: 1;
      overflow: hidden;
      display: flex;

      // 左侧
      >div:nth-child(1) {
        flex: 1;
        overflow: auto;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 4px;

        .rootnet-forminput {

          .rootnet-input,
          .rootnet-textarea {
            width: 100%;
          }

          .form-component {
            min-height: 50px;
            align-items: stretch;
            white-space: pre-wrap;
            height: auto;
          }

          .rootnet-textarea {
            min-height: 100px;
            height: 100%;

            >textarea {
              position: static;
            }
          }
        }
      }
    }
  }
  .suffix-search-icon{
    width: 20px;
    .icon{
      font-size: 16px;
      &:hover {
        text-decoration: unset;
      }
    }
  }
}

.relationDialog {
  width: 1050px;

  .customize-options {
    .c-options {
      margin-top: 0;
      padding: 0;

      .rootnet-forminput {
        margin-left: 0;

        .switch {
          margin-bottom: 4px;
        }
      }
    }

    .search-btn-group {
      margin-bottom: 8px;
    }
  }

  .content.content.content.content {
    display: flex;
    height: 500px;
    padding-top: 0;

    .query-condition {
      width: 120px;
      border-right: 1px solid #e6e6e6;
      margin-right: 8px;
      box-sizing: border-box;

      >div {
        margin-top: 8px;

        .active {
          color: #5477ff;
        }

        >span {
          cursor: pointer;

          &:hover {
            color: #5477ff;
          }
        }
      }
    }

    .x-card {
      margin: 0;
      flex: 1;
      box-shadow: none;
      overflow: hidden;
    }

    .searchIconInput {

      .prefix {
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: unset;

        i {
          font-size: 14px;
        }
      }

      input {
        text-indent: unset;
      }
    }

    .flex {
      flex: 1;
      display: flex;
      overflow: hidden;

      // 左侧需求或研发任务
      .query-list {
        // padding: 12px;
        box-sizing: border-box;
        // border: 1px solid #ccc;
        border-radius: 4px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      //右侧选中数据
      .selected-list {
        margin-left: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        >div {
          width: 240px;
          font-size: 16px;
          padding: 4px 12px;
          border-bottom: 1px solid #ccc;
          box-sizing: border-box;
        }

        ul,
        li {
          padding: 0;
          margin: 0;
          list-style: none
        }

        ul {
          flex: 1;
          overflow: auto;
        }

        li {
          padding: 4px 12px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          >span:nth-child(1) {
            width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          >span:nth-child(2) {
            display: none;
            cursor: pointer;

            i {
              font-size: 14px;
              color: #ccc;
            }
          }

          &:hover>span:nth-child(2) {
            display: inline-block;
          }

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
}