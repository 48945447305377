.ImpDisplayBoardMgt.ImpDisplayBoardMgt.ImpDisplayBoardMgt.ImpDisplayBoardMgt {

  .x-card.x-card.x-card.x-card {
    margin-top: 8px;

    .card-header {
      .left {
        flex: 1;

        .board-title {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;

          .lef {
            font-weight: bold;
            display: flex;
            align-items: center;
          }

          .content {
            .ant-picker {
              width: 100px;
              border-radius: 50px;
            }
          }

          .rig {
            display: flex;
            font-size: 12px;

            .filter-query-text {
              display: flex;
              align-items: center;
              color: #5477ff;
              position: relative;

              span {
                position: absolute;
                width: 15px;
                height: 15px;
                right: -15px;
                top: -1px;
                color: white;
                background-color: #5477ff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              i {
                font-size: 16px;
                margin-right: 4px;
              }
            }

            .explain-lable {
              margin-left: 24px;
              display: flex;
              align-items: center;

              &::after {
                content: ":";
                margin: 0px 8px 0px 2px;
              }
            }

            .explain-color {
              display: flex;
              flex-direction: column;
              justify-content: center;

              >div:nth-child(1) {
                display: flex;
              }
            }
          }
        }
      }

      .middle {
        display: flex;

        >div {
          padding: 0 8px;
          color: white;
          box-sizing: border-box;
          background-color: #5477ff;
          border-radius: 50px;
          margin: 0 8px;
          cursor: pointer;

          &:hover {
            background-color: #7692FF;
          }
        }
      }
    }

    .ant-table-wrapper {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .ant-table-thead {
        font-size: 12px;

        .ant-table-cell-fix-left.ant-table-cell-fix-left {
          background-color: #fafafa;
          padding: 0 8px;
        }

        .ant-table-cell {
          padding: 0;
          color: black;

          .work-color.month {
            min-width: 80px;
          }

          .toDay-color.toDay-color.toDay-color {
            color: #FF7D02;
          }

          .work-color {
            background-color: #fafafa;
            padding: 0 8px;

          }

          .rest-color {
            color: rgba(0, 0, 0, 0.3);
            // background-color: rgba(0, 0, 0, 0.3);
            padding: 0 8px;
          }
        }
      }

      .ant-table-tbody {
        font-size: 12px;

        .project-names {
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .project-name {
          display: flex;
          align-items: center;

          .color {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 4px;
          }
        }

        .task-show {
          padding: 8px 0;
          box-sizing: border-box;

          >div {
            white-space: nowrap;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 1;
            // word-break: break-all;
            // overflow: hidden;
          }
        }

        .ant-table-cell.ant-table-cell-fix-left {
          padding: 6px 8px;
        }

        .ant-table-cell:not(.ant-table-cell-fix-left) {
          padding: 0;

          .task-color-wrap {
            height: 25px;
            width: 100%;
          }

          .task-color {
            width: 100%;
            height: 25px;
            cursor: pointer;
          }

          .task-color-begin {
            border-radius: 50px 0 0 50px;
          }

          .task-color-end {
            border-radius: 0 50px 50px 0;
          }

          .task-color-begin.task-color-end {
            border-radius: 50px;
          }
        }
      }

      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table,
      .ant-table-container {
        height: 100%;
      }

      .ant-table-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .ant-table-body {
        flex: 1;
        overflow: auto;
      }
    }
  }

  .row-right-border {
    border-right-color: rgba(0, 0, 0, 0.2) !important;
  }
}