.data-switch {
  display: flex;
  justify-content: center;
  align-items: center;

  .textIconBtn {
    margin-left: 16px;
  }

  .disable.disable.disable {
    color: #6A6A6A;
    background-color: #E8E8E8;
    cursor: not-allowed;
  }

  .location {
    margin: 0 8px;
  }

  .last,
  .next {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: #5477ff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    >i {
      color: white;
    }
  }
}