.x-card.macroforecast {
  height: 150px;
  flex: none;
  margin-top: 0;
  margin-bottom: 0;

  .card-content {
    .macroforecast-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .rootnet-forminput {
        width: 30%;
        display: flex;

        .form-component {
          flex: 1;
          width: auto !important;

          .macroforecast-val {
            cursor: pointer;
            color: #5477ff;
            text-decoration: underline;
          }
        }
      }
    }
  }
}