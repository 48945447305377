.requirement-select-dialog.requirement-select-dialog.requirement-select-dialog.requirement-select-dialog{
  width: 900px;
  height: 600px;
  .content.content.content{
    display: flex;
    flex-direction: column;
    flex: 1;
    .x-datagrid{
      height: 200px;
      flex: 1;
    }
  }
  .search-btn-group{
    .button{
      margin-left: 6px;
    }
  }
  .req-list{
    height: 450px;
    flex: 1;
    overflow: hidden;
  }
}