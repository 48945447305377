.defect.defect.defect {
  overflow: hidden;

  .extra-group {
    .textIconBtn {
      margin-left: 14px;
    }
  }

  .x-card {
    &.is-not-table {
      flex: unset;
      height: 40px;
      margin-bottom: 4px;

      .card-content {
        display: none;
      }
    }
  }

  .defect-panel {
    height: 100px;
    margin: 0 12px 12px;
    flex: 1;

    &.defect-panel-full-screen {
      margin: 0;
    }

    .resize {
      cursor: col-resize;
    }

    .left-panel-remove-wrap {
      .line {
        height: 100%;
        width: 6px;
        background: #e9eaf2;
        cursor: col-resize;
      }
    }
  }

  .containerParent {
    transition: 350ms ease height;
    overflow: auto;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .grid {
    .cell {
      padding: 0;

      .person-select {
        width: unset !important;

        .ant-select-selection-overflow {
          flex-wrap: unset;
        }
      }

      .rootnet-forminput {
        margin-left: 2px;
      }

      .common-display-field {
        padding: 0 12px;
      }

      .common-edit-field {
        padding: 0 12px;
        cursor: pointer;
        height: 34px;

        &:hover {
          background: #EBF0FB;
        }
      }

      .enter-detail-field-wrap {
        cursor: unset;

        &:hover .concerned-icon {
          display: inline-block !important;
        }

        .enter-detail-field {
          width: 83%;
          cursor: pointer;
          color: #5477ff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .enter-detail-field-edit-icon {
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;

          &:hover {
            color: #5477ff;
          }
        }

        &:hover {
          .enter-detail-field-edit-icon {
            display: block;
          }
        }
      }

      .arrow-drop-down-group {

        .common-display-field,
        .common-edit-field {
          padding-left: 4px;
          padding-right: 12px;
        }
      }
    }
  }

  .work-flow-status-field {
    cursor: pointer;
    margin-left: 8px;
  }

  .fist-col {
    width: 100%;
    height: 100%;

    .check-box {
      display: none;
    }

    .rootnet-checkbox {
      height: unset;
    }

    &:hover,
    &.selected {
      .check-box {
        display: unset;
      }

      .index-num {
        display: none;
      }
    }
  }
}