i.icon.iconfont {
    font-size: 12px;

    &.rotate {
        &::before {
            display: inline-block;
            transform: rotate(90deg);
        }
    }
}

svg.symbol-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}