.right-mail-detail {
    flex: 1;
    background-color: white;
    border-left: 8px solid #f0f3f7;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100px;

    .customer-download-settings {
        display: flex;
        justify-content: flex-end;
        position: sticky;
        top: 0;
        padding-right: 8px;
        background-color: white;
        padding-top: 8px;
    }

    .rootnet-forminput.rootnet-forminput.rootnet-forminput {
        align-items: baseline;
        width: 100%;
        display: flex;
        margin: 0;
        padding: 8px;

        .form-component.form-component.form-component.form-component {
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-all;
            width: 100px;
            flex: 1;
            height: auto;
        }
    }
}

.customer-download-settings-dialog {
    height: 320px;
    display: flex;
    flex-direction: column;

    .head-hint {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #9A9A9A;
        padding-top: 3px;
    }

    .content {
        flex: 1;
        overflow: auto;

        .visual-range-area.visual-range-area.visual-range-area {
            align-items: flex-start;
        }
    }
}