.release-version-log.release-version-log {
  height: 86vh;
  display: flex;
  flex-direction: column;

  .content.content.content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;

    .edit-content {
      flex: 1;
      padding: 16px 16px 0px 16px;
      overflow: auto;
    }
  }

  .footer {
    padding: 8px 0;
    padding-left: 16px;
    justify-content: space-between;
    border-top: 1px solid #E9EAF2;

    .operation-btn {
      .button {
        height: 32px;
      }
    }

    .btn-group {
      .button {
        margin-right: 16px;
        height: 32px;
      }
    }
  }

  .area-wrap {
    .area-header {
      margin: 8px 0;

      .fold-icon {
        cursor: pointer;
        //transition: transform 0.3s;
        margin-left: 10px;
        margin-right: 18px;

        &:hover {
          color: #5477ff;
        }
      }
    }

    .area-content-wrap {
      overflow: hidden;
      width: 100%;

      &.area-content-wrap-select {
        .rootnet-forminput {
          margin: 8px 0;
          padding-right: 8px;
        }
      }

      .rootnet-forminput {
        width: 100%;
        display: flex;
        overflow: hidden;

        .rootnet-input {
          flex: 1;
        }

        .ant-input.text-area-input {
          width: 100%;
          min-height: 100px;
        }
      }
    }
  }

  .desc-wrap {
    // padding-bottom: 8px;

    .rich-text-area {
      margin-left: 40px;
      height: 400px;
    }
  }


}