.manpower-estimate-detail {
  flex: 1;
  overflow: auto;

  .art-table-body {
    .art-table-cell {
      .empty-cell {
        cursor: pointer;
        width: 100%;
        height: 100%;

        &:hover {
          background: rgba(254, 254, 254, 0.6);
        }
      }

      .edit-cell-operation {
        display: flex;
        width: 100%;

        &:hover {
          .edit-icon {
            display: inline-block;
          }

          .decorate {
            display: none;
          }
        }

        .display-num {
          width: calc(100% - 15px);
          display: inline-block;

          >span {
            margin-left: 15px;
          }
        }

        .decorate {
          display: inline-block;
          width: 15px;
        }

        .edit-icon {

          width: 15px;
          color: #5477ff;
          cursor: pointer;
          display: none;
        }
      }
    }
  }
}