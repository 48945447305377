.imp-project-detail.imp-project-detail.imp-project-detail.imp-project-detail {
  flex: 1;
  background-color: #fff;
  margin: 12px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  .ant-tabs {
    flex: 1;
    height: 200px;

    .ant-tabs-nav-list {
      margin-left: 25%;
    }

    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
        display: flex;

        &.ant-tabs-tabpane-hidden {
          display: none;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    flex: 1;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .dynamic-wrap {
    padding: 10px 20px;
  }

  &::-webkit-scrollbar {
    width: 0 !important
  }

  .ant-tabs-tab:hover {
    color: #5477ff;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #5477ff;
  }
}