.work-time-review-and-search-list {
  .x-card {
    .card-header {
      .header {
        align-items: center;

        .title {
          display: flex;
          font-weight: bold;

          &::before {
            display: block;
            content: "";
            width: 2px;
            height: 1em;
            background-color: #5477ff;
            box-shadow: 2px 0 3px 0 rgba(24, 144, 255, 0.42);
            margin-right: 12px;
            margin-top: 4px;
          }
        }

        .hint {
          margin-left: 16px;
          color: #D4D4D4;
        }
      }
    }

    .card-content {
      .ellipsis-line {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .enter-detail-field {
        cursor: pointer;
        color: #5477ff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}