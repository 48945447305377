.manpower-add-log.manpower-add-log.manpower-add-log {
  width: 800px;

  .content {
    display: flex;
    flex-direction: column;

    .ant-table-wrapper {
      height: 260px;
    }

    .manpower-del-icon {
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }
}