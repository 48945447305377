.MetadataMgtMob.MetadataMgtMob.MetadataMgtMob.MetadataMgtMob.MetadataMgtMob {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.23rem;

  .loader-container {
    background-color: white;
  }

  .tab-header {
    border-bottom: 1px solid transparent;

    .header-list {
      >div {
        flex: 1;
      }
    }
  }

  >header {
    background: white;

    >h1 {
      padding: 0.3rem;
      box-sizing: border-box;
      text-align: center;
      font-weight: 700;
      font-size: 0.24rem;
    }
  }

  >section {
    flex: 1;
    overflow-y: auto;
    padding: 0 0.2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    .loader.loader.loader.loader.loader {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      background-color: white;
      left: 0;
      top: 0;
    }
  }

  >.footer.footer.footer {
    padding: 0.3rem 0;
    background: white;
    box-sizing: border-box;
    display: flex;
    border-top: 1px solid #e7e7e7;

    dl,
    dt,
    dd {
      margin: 0;
    }

    .current.current.current {
      color: black;
    }

    >dl {
      flex: 1;
      display: flex;
      color: #999999;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >dt {
        >i {
          font-size: 0.3rem;
        }
      }
    }
  }

  .initial {
    font-size: 0.24rem;
    border-radius: 0.5rem;
    padding: 0.1rem 0.5rem;
    box-sizing: border-box;
    border: 1px solid #F3F3F3;
    box-shadow: none;

    &:hover {
      background-color: transparent;
    }

    &:active {
      color: #3390FF;
      border-color: #3390FF;
    }
  }

  .labelHeader {
    display: flex;
    justify-content: space-between;
    font-size: 0.3rem;
    font-weight: 700;

    >span {
      margin-top: 0.1rem;
      font-size: 0.24rem;
      font-weight: 400;
    }
  }

  .AuditTemplate {

    //待审核
    >dl {
      display: flex;
      justify-content: space-between;
      background-color: white;
      padding: 0.2rem;
      box-sizing: border-box;
      border-radius: 0.1rem;

      >dt {
        // flex: 1;
        width: 70%;

        >div:first-child {
          font-size: 0.26rem;
          font-weight: 700;
          padding-bottom: 0.2rem;
          box-sizing: border-box;
        }

        >div:last-child {
          display: flex;

          >span:last-child {
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            >span {
              background-color: #FEF8E5;
              font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
              font-size: 0.1rem;
              border: 0.01rem solid #FE9C00;
              color: #FE9C00;
              border-radius: 0.05rem;
              padding: 0.01rem;
              box-sizing: border-box;
              margin-right: 0.1rem;
            }
          }

          >span:first-child {
            width: 50%;
          }
        }
      }

      >dd {
        width: 30%;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .queryData {
    //查询
    padding-top: 0.12rem;
    box-sizing: border-box;
    width: 100%;
    background-color: #f0f3f7;
    position: sticky;
    top: 0;

    .mask {
      position: absolute;
      width: 100%;
      height: 0.65rem;
      top: 0.12rem;
      left: 0;
      z-index: 6;
    }

    .search.search.search.search.search {
      width: 100%;
      border-radius: 0.5rem;
      height: 0.65rem;

      >input {
        padding-left: 0.15rem;
        box-sizing: border-box;
      }
    }
  }

  .search-Box.search-Box.search-Box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: white;
    display: flex;
    flex-direction: column;

    >div:nth-child(1) {
      background-color: white;
      padding: 0.2rem;
      padding-right: 0;
      box-sizing: border-box;
      display: flex;
      box-shadow: 0 6px 7px 0 rgba(16, 19, 24, 0.02);

      >div {
        flex: 1;
      }

      >span {
        padding: 0 0.2rem;
        box-sizing: border-box;
        font-size: 0.28rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #989898;
      }
    }

    .x-tab {
      flex: 1;

      .tab-header {
        position: sticky;
        top: 0;
        left: 0;
      }

      .header {
        padding: 0.2rem;
        box-sizing: border-box;
        font-size: 0.24rem;
      }

      .tab-container {
        display: flex;

        .active {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }

    }

    .type-box {
      flex: 1;
      overflow: auto;
      padding: 0;

      .selection {
        color: black;
      }

      >li {
        color: #737373;
        font-size: 0.25rem;
        list-style: none;
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        border-bottom: 1px solid #F3F3F3;
      }
    }
  }

}


.MyAgent.MyAgent.MyAgent.MyAgent.MyAgent {
  flex: 1;

  >div:nth-child(2) {
    >dl {
      background-color: white;
      padding: 0.2rem;
      box-sizing: border-box;
      border-radius: 0.1rem;
      display: flex;
      flex-direction: column;

      >dd {
        padding-top: 0.2rem;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        margin: 0;
      }

      >dt {
        // border-bottom: 1px solid #F3F3F3;
        padding-bottom: 0.2rem;
        box-sizing: border-box;

        >div {
          >label {
            font-size: 0.24rem;
          }

          >div {
            font-size: 0.24rem;
          }
        }

        >p {
          font-size: 0.26rem;
          font-weight: 700;
          display: flex;
          justify-content: space-between;

          >span {
            font-size: 0.24rem;
            font-weight: 400;
          }
        }

        .horizontal {
          margin: 0;
        }
      }
    }
  }
}