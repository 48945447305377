.cascade-select.cascade-select.cascade-select{
  border-radius: 4px;
  box-shadow: none;
  .ant-select-selector.ant-select-selector.ant-select-selector{
    box-shadow: none;
    &:hover{
      border-color: #5477FF;
    }
  }
  &.error.error.error.error{
    .ant-select-selector{
      border: 1px solid #F5221B;
      &:hover{
        box-shadow: 0 0 4px 0 #F5221B;
      }
    }
  }
  .ant-select-selector{
    height: 32px;
    overflow: auto;
    border-radius: 4px;
    border: 1px solid #CFD3E3;
  }
  &.ant-select-focused{
    .ant-select-selector{
      border-color: #5477FF;
    }
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding-left: 8px;
  }
  .ant-select-selection-placeholder{
    color: #D7D9E0;
  }
  &.ant-select-disabled{
    .ant-select-selection-item-content{
      color: #15171F;
    }
  }
}