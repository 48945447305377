.text-area-input.text-area-input.text-area-input{
  //width: 180px;
  //height: 76px;
  border-radius: 4px;
  padding: 4px 8px;
  &.error.error.error.error{
    border: 1px solid #F5221B;
    &:hover{
      box-shadow: 0 0 4px 0 #F5221B;
    }
  }
  &.disabled.disabled.disabled{
    cursor: not-allowed;
    border-color: #d8dae2;
    background-color: #F5F5F5;
    &.error.error:hover{
      box-shadow: none;
    }
    &:hover{
      box-shadow: none;
    }
  }
  &:hover, &:focus{
    border-color: #3a61ff;
    box-shadow: 0 0 4px 0 #5477ff;
  }
  &::-webkit-input-placeholder{
    color: #D7D9E0;
  }
}