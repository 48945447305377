.daily-report-comment-popover.daily-report-comment-popover.daily-report-comment-popover.daily-report-comment-popover.daily-report-comment-popover.daily-report-comment-popover{
  z-index: 5;
}
.daily-report-comment-icon-btn {
  &.daily-report-comment-badge {
    .ant-badge {
      color: #1890ff;
    }

    .ant-badge-count {
      background-color: #EFF4FF !important;

      .ant-scroll-number-only {
        color: #6698FF;
      }
    }
  }
  .ant-badge {
    color: white;
  }

  .ant-badge-count {
    background-color: rgb(204, 204, 204) !important;

    .ant-scroll-number-only {
      color: white;
    }
  }

}