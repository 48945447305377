.export-csv-dialog {
  display: flex;
  flex-direction: column;
  &.export-api-dialog{
    width: 932px;
  }

  .content.content.content.content.content {
    height: 65vh;
    padding-top: 0;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .x-card {
      flex: 1;
      height: 100px;
      margin: 0;

      .selectedFields {
        width: 164px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
      }

      .card-content.card-content.card-content.card-content.card-content {
        display: flex;

        //height: 350px;
        .left-panel-content {
          font-size: 12px;

          .rootnet-forminput {
            margin: 6px;
          }
        }

        .right-panel-content {
          //height: 450px;
          font-size: 12px;
        }

        >div:nth-child(1) {
          overflow: scroll;

          >div {
            >span {
              margin: 10px 20px;

              >div {
                margin-right: 0;

                >div {
                  margin-right: 5px;
                }
              }
            }

          }
        }

        >div:nth-child(2) {
          min-width: 180px;
          flex: 1;
          border-left: 1px solid #e1e1e1;
          display: flex;
          flex-direction: column;

          >div {

            .dragover {
              border-top: 2px solid #5477ff;
            }

            .dragItem {
              margin: 0;
              padding: 8px;
              box-sizing: border-box;
              cursor: move;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              >i {
                cursor: pointer;
                font-size: 14px;

                &:hover {
                  color: #5477ff;
                }
              }

              &:hover {
                background-color: #f5f5f5;
              }
            }

            .cancelStyle.cancelStyle.cancelStyle.cancelStyle.cancelStyle.cancelStyle {
              cursor: auto;

              &:hover {
                background-color: #fff;
              }
            }
          }
        }
      }
    }

    .footer.footer.footer.footer.footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 10px 0;

      .down.down.down.down {
        background: #5477ff;
        border-radius: 4px;
        color: #fff;

        &:hover {
          background-color: #214eff;
        }
      }
    }
  }
}