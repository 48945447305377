.requirement-business-wrap{
  .fee-type-prefix{
    cursor: pointer;
    color: #5477ff;
  }
  .memo-textarea.memo-textarea.memo-textarea{
    &:hover, &:focus-within{
      border-color: #CFD3E3;
      box-shadow: unset;
    }
    &.memo-textarea-detail{
      margin-top: 2px;
      border: none;
      height: 72px;
    }
    &.error{
      border-color: red;
    }
  }
  .rootnet-radiogroup{
    width: 129px;
  }
}

.fee-type-prefix-pop{
  padding: 0;
  .fee-type-prefix-pop-item{
    height: 32px;
    padding: 2px 10px;
    cursor: pointer;
    &.active{
      background:#D9E3FA;
      font-weight: bold;
    }
    &:hover{
      background:#F2F2F2;
    }
  }
}