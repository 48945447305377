.root-cause-analysis {
  height: 100%;
  font-size: 12px;
  display: flex;

  .x-card {
    .card-content.card-content.card-content {
      display: block;
      overflow: auto;
    }
  }

  .review-btn {
    display: flex;
    justify-content: flex-end;

    .button {
      height: 28px;
      padding: 8px 30px;
    }
  }

  .root-type {
    display: inline-flex;
    align-items: center;

    >label {
      width: 98px;
      white-space: nowrap;
      color: #738299;
      text-align: right;

      &::after {
        content: ":";
        margin: 0px 8px 0px 2px;
      }
    }

    .form-component {
      width: 200px;
      display: flex;
      justify-content: center;

      .ellipsis-line {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-cascader-picker {

        .ant-input:focus,
        .ant-input-focused {
          border-color: #3a61ff;
        }

        .ant-input:hover {
          border-color: #3a61ff;
        }

        .ant-input {
          box-shadow: none;
          border-radius: 4px;
          border: 1px solid #CFD3E3;
          padding-left: 8px;

          &::-webkit-input-placeholder {
            color: #D7D9E0;
            font-size: 12px;
          }
        }
      }
    }
  }

  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput {
    font-size: 12px;
    margin: 8px 8px;

    label {
      font-size: 12px;
    }
  }

  .area-wrap {
    margin-bottom: 8px;

    .area-header {
      margin: 4px 0;
    }

    .area-content-wrap {
      overflow: hidden;

      .rootnet-forminput {
        width: 100%;

        .form-component {
          width: 90% !important;
          white-space: pre-wrap;
          padding-left: 40px;
          box-sizing: border-box;
        }
      }
    }
  }

  .fold-icon {
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 15px;
    margin-right: 18px;

    &:hover {
      color: #5477ff;
    }
  }

  .rich-text-detail-wrap {
    min-height: 100px;
    width: 100%;
    overflow: auto;
    padding: 10px 20px 10px 50px;

    img {
      max-width: 100%;
    }
  }

  .rich-text-area {
    height: 350px;
    width: 100%;
    padding: 10px 20px 10px 50px;
  }
}

.ant-cascader-menus {

  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color: #D9E3FA;
  }
}