.defect-test-case-data-grid.defect-test-case-data-grid.defect-test-case-data-grid.defect-test-case-data-grid.defect-test-case-data-grid.defect-test-case-data-grid {
  .rootnet-forminput.rootnet-forminput.rootnet-forminput {
    margin: 8px;

    .defect-test-case-form {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .search-tracer {
    .prefix {
      width: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: unset;

      i {
        font-size: 14px;
      }
    }

    .suffix {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: unset;

      i {
        display: none;
        cursor: pointer;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.25);

        &:hover {
          color: gray;
        }
      }
    }

    &:hover {
      .suffix>i {
        display: inline-block;
      }
    }

    input {
      text-indent: unset;
    }
  }
}