
.text-area-with-tooltip.text-area-with-tooltip.text-area-with-tooltip{
  &.empty-text-area{
    .rootnet-textarea{
      border: 1px solid #F5221B;
      &:focus-within{
        border-color: #5477FF;
      }
    }
    .error-tooltip{
      display: block;
    }
  }
  .rootnet-textarea{
    height: 72px;
  }
  textarea {
    padding: 5px 8px;
    font-size: 13px;
  }
  .error-tooltip{
    display: none;
    width: 150px;
    font-size: 12px;
  }
}