.resource-dept-search.resource-dept-search.resource-dept-search.resource-dept-search{
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 6px 0 6px 6px;
  .search-wrap{
    width: 100%;
    //border: 1px solid #E6E8EB;
    margin-bottom: 4px;
    .suffix{
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #E6E8EB;
      .search-icon{
        font-size: 16px;
      }
    }
  }
  .dept-tree{
    height: 200px;
    flex: 1;
    overflow-y: auto;
    border: 1px solid #E6E8EB;
    border-radius: 8px;
    padding: 4px 0;
  }
  .ant-tree, .ant-tree-list, .ant-tree-treenode, .ant-tree-node-content-wrapper{
    width: 100%;
  }
}