.basic-information {
  height: 100%;
  display: flex;
  flex-direction: column;

  .version-form-list {
    flex: 1;
    overflow: auto;
  }

  .text-area-detail.text-area-detail.text-area-detail {
    align-items: baseline;
    margin-bottom: 2px;

    .form-component {
      height: auto;
    }
  }

  .footer {
    text-align: center;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 10;
  }
}