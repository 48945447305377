.service-dialog{
  .data-grid-wrap{
    flex: 1;
    border: 1px solid #e6e6e6;
    max-height: 400px;
    .no-data{
      display: none;
    }
  }
  .content.content.content.content{
    display: flex;
    align-items: flex-start;
    height: 450px;
    .x-datagrid{
      overflow: hidden;
    }
  }
  .left-menu-item{
    width: 155px;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px 5px;
    border-right: 2px solid #E9EAF2;
    justify-content: flex-end;
    &.active{
      border-right: 2px solid #5477FF;
      color: #5477FF;
    }
    .ut{
      align-self: flex-start;
      margin-top: 2px;
      margin-left: 2px;
      width: 22px;
      height: 16px;
      background: #33CC8A;
      text-align: center;
      line-height: 16px;
      border-radius: 6px;
      color: #fff;
      font-size: 12px;
      letter-spacing: 1px;
      transform: scale(0.8)
    }
  }
  .right-content{
    height: 430px;
    margin-left: 10px;
    flex: 1;
    .option-switch{
      margin-right: 10px;
    }
    .content{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    }
  }
  .no-data-wrap{
    width: 500px;
    padding:70px 50px;
  }

  .remark-input.remark-input.remark-input.remark-input{
    margin-top: 10px;
    height: 60px;
    width: 100%;
  }

  .column{
    .top{
      .x-checkbox{
        display: none;
      }
    }
  }
  .options{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }
  .search-icon.search-icon.search-icon{
    margin: 0 4px;
    font-size: 16px;
    &.pointer{
      &:hover{
        cursor: pointer;
        color: #5477FF;
      }
    }
  }
}