.app-bar-quick-create{
  margin-right: 12px;
  .textIconBtn {
    color: white;
    margin: 0;

    &:hover {
      color: bisque;
    }
  }
}
.app-bar-quick-create-pop{
  .ant-popover-arrow{
    display: none;
  }
}