.system-relation-dialog{
  .content{
    height: 700px;
    width: 1000px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .content-wrap{
      height: 650px;
      width: 900px;
      flex-grow: 1;
    }
    .graph-wrap{
      height: 10000px;
      width: 900px;
      flex:1;
      display: flex;
      overflow: hidden;
    }
    .remark{
      margin-left: 20px;
      height: 30px;
      margin-top: 10px;
      align-self: flex-start;
      .blue-block{
        width: 14px;
        height: 14px;
        background: #3F7DFF;
        border-radius: 4px;
        margin: 0 5px
      }
    }
  }
}