.role-menu.role-menu {
  flex: 1;
  margin: 12px;
  margin-left: 0;
  background-color: white;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 45px;

  .tree-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #7A8199;
  }

  .ant-tabs {
    height: 100%;

    .ant-tabs-nav {
      padding: 0 12px;
    }

    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane-active {
        height: 100%;
        overflow: scroll;

        // .ant-tree {
        //   height: 100%;

        //   .ant-tree-list {
        //     height: 100%;
        //   }
        // }
      }
    }
  }
}