.my-center.my-center.my-center.my-center{
  .row-tab{
    background: #fff;
    width: 180px;
    border-right: 1px solid #ebebeb;
    .row-tab-item{
      box-sizing: border-box;
      cursor: pointer;
      padding-left: 8px;
      width: 180px;
      height: 40px;
      line-height: 40px;
      &:hover{
        background: #0000000F;
      }
      &.active{
        background-color: #5477ff;
        color: white;
      }
    }
  }
}