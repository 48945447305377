.plan-version-mgt {
  >.x-card {
    margin-top: 0;

    .x-datagrid {
      .enter-detail-field {
        cursor: pointer;
        color: #5477ff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}