.imp-project-plan{
  width: 100%;
  .receive-department-header:before{
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    content: '*';
    color: #F5221B;
  }
  .handle-header{
    //height: 34px;
    flex-direction: row-reverse;
    //position: absolute;
    //top: 0;
    //right: 0;
    .ant-alert{
      flex: 1;
      margin:0 12px;
      height: 34px;
      .selected-message{
        margin-left: 8px;
        .selected-count{
          color: #1890FF;
          margin: 0 2px;
        }
        .bulk-del-text{
          color: #1890FF;
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
    .handle-group{
      .ant-btn{
        margin: 0 6px;
        height: 34px;
      }
      .ant-btn-primary{
        background-color: #5477ff;
        border: none;
        &:hover{
          background-color: #7692ff;
        }
      }
    }
  }
  .editable-row{
    .table-index-col{
      .add-next-row-icon{
        display: none;
        cursor: pointer;
      }
    }
    &:hover{
      .table-index-col{
        .index{
          display: none;
        }
        .add-next-row-icon{
          display: block;
          color: #5477ff;
        }
      }
    }
  }
  .ant-table-sticky-scroll{
    display: none;
  }
  .task-name-header, .receive-department-value{
    overflow: hidden;  //隐藏多余行数
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
    -webkit-box-orient: vertical;  //规定为垂直方向排列
  }
  .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    padding: 6px;
  }
}

.img-project-plan-more-item-group{
  padding: 6px;
  .more-item{
    min-width: 130px;
    padding: 6px;
    height: 34px;
    cursor: pointer;
    &:hover{
      color: #1890ff;
      background-color: #E6F7FF;
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 5;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-cell {
  position: relative;
}
.imp-plan-editable-cell-value-wrap{
  padding: 5px 12px;
  height: 32px;
}

.editable-row{
  .imp-plan-editable-cell-value-wrap {
    cursor: pointer;
  }
}

.editable-row .imp-plan-editable-cell-value-wrap:hover {
  background-color: #E0E0E0;
}

.common-del-message-dialog.common-del-message-dialog{
  .header{
    border-bottom: 1px solid rgba(0,0,0,0.07);
  }
  .footer{
    .primary{
      background: #ff4500;
      box-shadow: none;
      &:hover {
        background-color: #ff6a33;
      }
    }
  }
}