.project-manpower-summary-detail-info.project-manpower-summary-detail-info {
  flex: 1;
  overflow: hidden;

  .containerParent {
    transition: 350ms ease height;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      width: 100%;
    }
  }
}