.MetadataDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  >section {
    flex: 1;
    overflow-y: auto;
    padding: 0.2rem;
    padding-top: 0;
    box-sizing: border-box;
    position: relative;

    .loader {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      background-color: white;
      left: 0;
      top: 0;
    }

    >h1 {
      font-size: 0.28rem;
    }

    .noData.noData.noData {
      text-align: center;
      font-size: 0.26rem;
      margin: 0;
    }

    .information {
      margin-bottom: 0.2rem;
      padding: 0.2rem;
      box-sizing: border-box;
      background-color: white;
      border-radius: 0.15rem;
      font-size: 0.24rem;


      >p:nth-child(1) {
        display: flex;
        justify-content: space-between;

        >span:first-child {
          display: flex;
          align-items: center;
          font-size: 0.3rem;

          // font-weight: 700;
          >span {
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            margin: 0 0.1rem;
            box-sizing: border-box;
          }

          >i {
            font-size: 0.55rem;
            margin-right: 0.1rem;
            color: #FE9C00;
          }
        }

        >span:last-child {
          display: flex;
          align-items: center;
        }
      }

      >.label {

        >span {
          color: #3390FF;
          background-color: rgba(51, 144, 255, 0.1);
          border: 1px solid #3390FF;
          padding: 0.05rem 0.2rem;
          box-sizing: border-box;
          border-radius: 0.5rem;
          font-size: 0.24rem;
        }
      }


      >.perData.perData.perData.perData.perData {
        padding: 0.15rem;
        box-sizing: border-box;
        display: flex;

        >span:first-child {
          color: #737373;
          margin-right: 0.3rem;
        }


        >span:last-child {
          flex: 1;

          >i {
            padding: 0 0.1rem;
            box-sizing: border-box;
            font-weight: 700;
          }

          >span:first-child {
            background-color: rgb(253, 230, 230);
            text-decoration: line-through;
            color: red;
          }

          >span:last-child {
            color: #24B47E;
            background-color: #E9F7F2;
          }
        }
      }
    }
  }

  >footer {
    background: white;
    padding: 0.2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .primary {
      width: 90%;
      border-radius: 0.5rem;
      padding: 0.22rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      letter-spacing: 0.1rem
    }
  }
}