.requirement-stage-info {
  .text-area-memo.text-area-memo.text-area-memo {
    align-items: flex-start;

    .rootnet-textarea.rootnet-textarea {
      height: 100px;
    }

    .form-component {
      height: unset;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .delivery-list-add-item {
    text-align: center;

    >span {
      cursor: pointer;
      color: #5477ff;
    }
  }

  .delivery-list-item {
    .icon-close {
      cursor: pointer;
      font-size: 14px;
      display: none;

      &:hover {
        color: #5477ff;
      }

    }

    &:hover .icon-close {
      display: inline-block;
    }
  }

  .rich-text-form-item {
    margin: 8px;

    .mock-label {
      margin-top: 6px;
      width: 110px;
      text-align: right;
      color: #738299;

      &::after {
        content: ":";
        margin: 0 8px 0 2px;
      }
    }

    .rich-text-area {
      height: 250px;
      width: 486px;
    }

    .rich-text-value-show-wrap {
      margin-top: 6px;
      line-height: 32px;
      width: 500px;

      * {
        max-width: 100%;
      }
    }
  }
}