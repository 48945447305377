.dev-project-risk-follow-up-and-todo-list {
  height: 100%;
  display: flex;

  .ant-tabs {
    flex: 1;
    overflow: hidden;

    .ant-tabs-content-holder {
      padding: 0 !important;
    }

    .ant-tabs-content {
      overflow: hidden;

      .x-datagrid {
        font-size: 12px;
      }
    }
  }
}