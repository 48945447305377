.dev-project-mgt {
  >.fill{
    .x-card {
      margin-top: 0;
  
      .x-datagrid {
        .enter-detail-field {
          cursor: pointer;
          color: #5477ff;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
  }
  }

  .work-flow-status-field {
    cursor: pointer;
    margin-left: 8px;
  }
}

.dev-project-type-add-group {
  padding: 4px 8px;

  .dev-project-type-item {
    height: 30px;
    padding: 12px 8px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }

    .type-tag {
      width: 50px;
      background-color: #5477ff;
      color: #fff;
      border-radius: 8px;
      height: 18px;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}