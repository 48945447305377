.costRatePending {
  .convertPrice {
    cursor: pointer;

    >i {
      margin-left: 4px;
      color: #5477ff;
    }
  }

  .edit-Box-input.edit-Box-input.edit-Box-input {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    >.rootnet-forminput {
      width: 100px;
      height: 24px;
      margin: 0;

      >.form-component {
        width: 100%;

        >input {
          text-align: right;
          text-indent: 8;
          padding-right: 8px;
          box-sizing: border-box;
        }
      }
    }
  }
}

.convert-price-edit {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}