.evaluation-details {
  overflow: auto;

  .x.loader {
    width: 100%;
    height: 100%;
  }

  .content {
    width: 1280px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 12px;

    .title {
      text-align: center;
      background-color: #19299C;
      color: #fff;
      border-radius: 4px 4px 0 0;
      font-size: 24px;
      font-weight: bold;
      padding: 6px 0;
      box-sizing: border-box;
    }

    .instruction {
      padding: 0 12px;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;

      .identification {
        padding: 8px 0;
        border-bottom: 1px solid #ccc;
        margin-bottom: 12px;
      }
    }

    .evaluation-form {
      padding: 0 12px;
      box-sizing: border-box;

      .appraiser {
        padding: 8px 0;
        margin-bottom: 12px;
      }

      .form-detail {

        .form-content {
          .form-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 12px;
          }
        }

        .rootnet-forminput {
          margin-bottom: 24px;

          >label {
            font-size: 14px;
            color: black;
            font-weight: 900;
          }
        }
      }

      .radio-row {
        .rootnet-radiogroup {
          // flex-direction: column;
          align-items: start;
        }
      }

      .rootnet-forminput.reason {
        &.error {
          &:hover {
            >.rootnet-textarea {
              box-shadow: 0 0 4px 0 #F5221B;
            }
          }

          &:focus-within {
            >.rootnet-textarea {
              border: 1px solid #5477FF;
              box-shadow: 0 0 4px 0 #5477FF;
            }
          }

          >.rootnet-textarea {
            border: 1px solid #F5221B;
          }
        }
      }
    }

    .operating-btn {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;

      .button {
        width: 80px;
        height: 32px;
      }
    }
  }

}