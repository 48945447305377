.view-update-dialog.view-update-dialog.view-update-dialog{
  min-width: 1000px;
  .content.content{
    height: 70vh;
    max-height: 640px;
    overflow: scroll;
    .hidden-component{
      >.rootnet-forminput{
        margin: 0 6px;
        height: 38px;
        >div{
          display: none;
        }
      }
    }
  }
  .title-repeat{
    margin-left: 128px;
    color: red;
  }
  .title-form-item{
    .form-component.form-component {
      flex: 1;
    }
  }
  .view-field-transfer{
    .view-field-transfer-header{
      height: 38px;
      .header-item{
        color: #8091a5;
        font-weight: bold;
        .red{
          color: red;
        }
      }
      .second-header-item{
        margin-left: 210px;
      }
    }
    .ant-transfer-list{
     width: 273px;
    }

    .transfer{
      .container{
        .items.items.items.items{
          height: 300px;
          flex: unset;
        }
      }
    }
  }
  .check-wrap{
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 124px;
  }
}