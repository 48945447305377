.dev-project-major-risk.dev-project-major-risk.dev-project-major-risk {
  flex: 1;
  overflow: hidden;

  .ant-table-wrapper.ant-table-wrapper.ant-table-wrapper {
    .ant-table-cell {
      font-size: 12px;
    }
  }

  .risk-title-style {
    &:not(th) {
      cursor: pointer;
      color: #5477ff;
    }
  }

  .risk-remarks {
    margin: 16px 0;
    padding-right: 8px;

    .header {
      margin: 4px 0;
      font-size: 14px;
      padding-right: 8px;

      .title {
        color: #738299;
      }

      .icon-btn {
        font-weight: 900;
      }

      .button {
        height: 26px;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .rootnet-forminput {
      width: 100%;
      max-height: 110px;
      overflow: auto;

      .form-component {
        height: auto;
        white-space: pre-wrap;
        word-break: break-all;
        line-break: anywhere;
        font-size: 12px;
      }
    }
  }
}