.system-mgt-common.system-mgt-common.system-mgt-common.system-mgt-common{
  .card-extra{
    .all-textIconBtn{
      margin-left: 15px;
      .icon{
        margin-top: 2px;
      }
    }
  }
  .card-group{
    padding: 0 6px 6px;
    flex-wrap: wrap;
    .empty-item{
      height: 0;
      margin: 6px;
      flex: 1;
      min-width: 380px;
    }
    .card-item{
      flex: 1;
      min-width: 380px;
      height: 210px;
      background: #fff;
      margin: 0 6px 12px;
      border-radius: 4px;
      box-shadow: 0 7px 22px 0 rgba(163,171,186,0.19);
      &:hover{
        box-shadow: 0 7px 22px 0 #A3ABBA80;
      }
      &.add-card{
        cursor: pointer;
        color: #5477FF;
        .add-icon{
          font-size: 30px;
        }
        .add-text{
          margin-top: 4px;
          width: 368px;
          text-align: center;
        }
      }
      &.card-detail{
        justify-content: space-between;
        font-size: 12px;
        color: #15171F;
        .card-header{
          margin-bottom: 5px;
          height: 36px;
          border-bottom: 1px solid #E9EAF2;
          padding: 0 10px;
          justify-content: space-between;
          .card-title{
            font-weight: bold;
          }
          .del-icon{
            font-size: 14px;
            cursor: pointer;
            &:hover{
            color: #5477FF;
            }
          }
        }
        .display-form{
          flex-wrap: wrap;
          .display-form-item{
            height: 30px;
            line-height: 30px;
            .label{
              width: 80px;
              color: #738299;
              text-align: right;
              &::after {
                content: ":";
                margin: 0px 8px 0px 2px;
              }
            }
            .show-text{
              width: 100px;
              line-height: 30px;
              white-space: nowrap;/*不允许换行*/
              overflow: hidden;/*超出隐藏*/
              text-overflow: ellipsis;/*文本超出三点代替*/
            }
          }
        }
        .bottom-text{
          color: #3E4046;
          font-size: 14px;
          cursor: pointer;
          margin-top: 6px;
          height: 36px;
          border-top: 1px solid #E9EAF2;
          &:hover{
            color: #5477FF;
          }
        }
      }
    }
  }
  .c-options{
    .btn.btn.btn {
      width: 75px;
      height: 31px;
      margin-left: 15px;
      margin-right: 0;
    }
    .reset-btn {
      color: #3A415C;
      border: 1px solid #7A8199;
    }
  }
}