.contact-person {

  .contact-person-head {
    display: flex;

    .title {
      display: flex;
      font-weight: 700;
      align-items: center;

      &::before {
        background-color: #5477ff;
        box-shadow: 2px 0 3px 0 #1890ff6b;
        content: "";
        display: block;
        height: 1em;
        margin-right: 12px;
        width: 2px;
      }
    }

    .hint {
      margin-left: 16px;
      color: #9A9A9A;
    }
  }

  .x-card {
    margin: 0;
    box-shadow: none;
  }
}