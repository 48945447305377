.metadata-log-drawer.metadata-log-drawer.metadata-log-drawer{
  width: 750px;
  *, *::before, *::after {
    box-sizing: unset;
  }
  >.footer{
    display: none;
  }
  .metadata-log-drawer-content{
    overflow: auto;
    overflow-x: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 50px 50px 20px;
    .timeline-item{
      &:not(:last-child){
        .line-group>.line{
          margin-top: 5px;
          width: 0;
          flex: 1;
          border-right: 1px dotted #CFD3E5;
        }
        .log-detail{
          margin-bottom: 40px;
        }
      }
      .time{
        width: 145px;
      }
      .line-group{
        .circle{
          margin: 5px 6px 0;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          border: 2px solid #B2B5BF;
        }
      }
      .audit-flag{
        width: 56px;
        height: 18px;
        font-size: 12px;
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: 3px;
        text-align: center;
        line-height: 18px;
      }
      .fold-icon{
        margin:0 6px;
        color: #B2B5BF;
        cursor: pointer;
      }
      .log-detail{
        flex: 1;
        overflow: hidden;
        .log-line{
          display: flex;
          height: 27px;
          margin-bottom: 6px;
        }
        .log-header{
          margin:10px 10px 6px 0;
        }
        .log-line-group{
          width: 600px;
        }
      }
      .log-item{
        margin:0 10px 8px 0;
        min-height: 22px;
        .text-area{
          flex-wrap: wrap;
          flex: 1;
          .to-icon{
            margin: 2px;
          }
        }
        .label{
          color: #7A8199;
          margin: 2px 5px 2px 0;
        }
        .text{
          max-width: 288px;
          margin:2px 6px;
          white-space: nowrap;/*不允许换行*/
          overflow: hidden;/*超出隐藏*/
          text-overflow: ellipsis;/*文本超出三点代替*/
        }
      }
    }
  }
}