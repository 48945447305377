.version-associated {
  height: 100%;
  display: flex;
  flex-direction: column;

  .version-associated-form {
    flex: 1;
    overflow: hidden;

    .version-associated-form-data {
      max-height: calc(100% - 90px);
      display: flex;
    }

    .x-datagrid {
      flex: 1;
      font-size: 12px;

      .current-version-background {
        background-color: #edf3f9 !important;
      }

      .clear-produch {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          >i {
            display: inline-block;
          }
        }

        >i {
          display: none;
          cursor: pointer;
          font-size: 14px;

          &:hover {
            color: #5477ff;
          }
        }
      }

      .operation-icon {
        display: inline-block;

        .edit-icon {
          margin-left: 6px;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            color: #5477ff;
          }
        }
      }
    }

    .add-version-item {
      .action-icon {
        display: inline-block;

        .add-color {
          color: #5477ff;
        }

        .del-color {
          color: red;
        }

        >div {
          display: inline-block;
          cursor: pointer;
          font-size: 14px;
          margin-left: 8px;
        }
      }
    }

    .add-data-item {
      margin-top: 4px;
      margin-bottom: 4px;
      height: 32px;
      border: 1px dashed #f0f0f0;
      text-align: center;
      line-height: 32px;
      cursor: pointer;

      &:hover {
        color: #5477ff;
        border-color: #5477ff;
      }

      .icon {
        font-weight: 900;
      }
    }
  }

  .footer {
    text-align: center;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 10;
  }
}