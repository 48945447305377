.content-template-mgt {
  .x-card {
    .grid {

      .cell.cell.cell {
        .ant-switch {
          transform: scale(0.9, 0.9);
        }

        .common-display-field {
          //padding: 0 12px;
        }

        .enter-detail-field-wrap {
          cursor: unset;

          .enter-detail-field {
            cursor: pointer;
            color: #5477ff;
          }

          .enter-detail-field-edit-icon,
          .enter-detail-field-delete-icon {
            display: none;
            margin-left: 10px;
            cursor: pointer;
            font-size: 15px;

            &:hover {
              color: #5477ff;
            }
          }

          &:hover {

            .enter-detail-field-edit-icon,
            .enter-detail-field-delete-icon {
              display: block;
            }
          }
        }
      }
    }
  }

  .extra-group {
    .icon-return2fanhuiyou {
      font-weight: 600;
    }

    .button-disabled.button-disabled.button-disabled.button-disabled.button-disabled {
      &.ant-btn {
        background-color: #D8DAE2;
        border-color: #d9d9d9;

        &:hover {
          border-color: #d9d9d9;
          background-color: #D8DAE2;
        }
      }
    }

    .content-template-button.content-template-button.content-template-button {
      margin-left: 24px;
      border-radius: 4px;

      &.ant-btn {
        background: #5477ff;
        border-color: #5477ff;
        color: white;

        &:hover {
          background: #7692FF;
          border-color: #7692FF;
        }
      }
    }
  }
}