.select-detail-dialog {
  display: flex;
  flex-direction: column;
  width: 800px;

  .content.content.content.content {
    padding: 0;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .select-detail-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ant-pagination {
        margin: 8px;
      }

      .ant-input-group-wrapper.ant-input-search {
        margin: 8px;

        .ant-btn {
          border-radius: 0 4px 4px 0;
        }

        .ant-input-affix-wrapper {
          border-radius: 4px 0 0 4px;
        }
      }

      .ant-table-wrapper {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .ant-spin-nested-loading,
        .ant-spin-container,
        .ant-table,
        .ant-table-container {
          height: 100%;
        }

        .ant-table.ant-table-small {
          height: 430px;
        }

        .ant-table-container {
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }

        .ant-table-body {
          flex: 1;
          overflow: auto;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    border-top: 1px solid #E9EAF2;

    .button {
      height: 32px;
      margin-right: 16px;
    }
  }
}