.work-time-report-handle-row{
  width: 100%;
  .week-switch-wrap{
    height: 32px;
    width: 16px;
    margin: 0 6px;
    cursor: pointer;
    transition: border .3s;
    &.previous-week{
      margin-left: 12px;
    }
    &:hover{
      color: #5477ff;
    }
    &.disabled{
      color: #909090;
      cursor: not-allowed;
    }
  }
  .this-week-btn{
    padding: 0 12px;
    height: 32px;
    margin: 0 6px;
    cursor: pointer;
    transition: border .3s;
    color: #5477ff;
    &:hover{
      text-decoration: underline;
    }
  }
  .workday-total{
    margin: 0 12px;
    color: #909090;
  }
  .refresh-icon{
    margin: 0 12px;
    cursor: pointer;
    color: #666A6D;
    &:hover{
      color: #5477ff;
    }
  }
  .deadline-date{
    margin-left: 12px;
    color: #909090;
  }
  .date-selected-right-wrap{
    .ant-btn{
      margin-left: 12px;
    }
    .others-report-btn{
      .others-report-cancel{
        color: #909090;
        &:hover{
          color: #40a9ff;
        }
      }
    }
  }
}