.testCaseDetail {
  height: 100%;
  display: flex;
  flex-direction: column;

  >.header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin: 4px 8px;
    padding-right: 16px;
    box-sizing: border-box;
    border-radius: 4px;

    .lef {
      .arrow-crumbs {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    .rig {
      display: flex;
      align-items: center;
    }

    .all-go-back.all-go-back.all-go-back {
      height: 50px;
      line-height: 50px;
    }
  }

  >.resize {
    cursor: col-resize;
  }

  .content {
    flex: 1;
    display: flex;
    overflow: hidden;

    .leftList {
      margin-left: 8px;
      margin-bottom: 8px;
      background-color: white;
      display: flex;
      border-radius: 4px;

      >.title {
        height: 40px;
        border-bottom: 1px solid #e9eaf2;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >span:last-child {
          >i {
            margin-left: 8px;
            cursor: pointer;
            font-size: 16px;

            &:hover {
              color: #5477ff;
            }
          }
        }
      }

      >div:last-child {
        width: 300px;
        padding-top: 16px;
        box-sizing: border-box;
        flex: 1;
        overflow: hidden;

        .ant-tree-list-holder-inner {

          .ant-tree-treenode:nth-child(1) {
            .ant-tree-switcher {
              display: none;
            }
          }
        }
      }

      >div {
        padding: 0 16px;
        box-sizing: border-box;
      }

      .tree-list-title {
        display: flex;
        justify-content: space-between;

        .hover-suffix {
          display: none;

          >i {
            display: inline-block;
            transform: rotate(-90deg);
            font-weight: 900;
            font-size: 15px;

            &:hover {
              color: #5477ff;
            }
          }
        }

        &:hover {
          .hover-suffix {
            display: inline-block;
          }
        }
      }

      .ant-tree {
        .ant-tree-list-holder {
          overflow-x: hidden;
        }

        flex: 1;
      }
    }

    .left-drag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: col-resize;
      width: 8px;
      border-left: 2px solid transparent;
      box-sizing: border-box;

      &:hover {
        border-left-color: #2684FF;
      }

      &:hover>i {
        color: #5477ff;
      }
    }

  }
}

.popoverMore {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.ant-tree-switcher_close {
  .transform-rotate {
    transform: rotate(90deg);
    font-weight: 900;
  }
}

.ant-tree-switcher_open {
  .transform-rotate {
    transform: rotate(180deg);
    font-weight: 900;
  }
}

.treeDrag.treeDrag.treeDrag.treeDrag {
  min-width: 480px;

  .content {
    height: 380px;
    display: flex;
    flex-direction: column;

    >span {
      display: block;
      padding-bottom: 16px;
      box-sizing: border-box;
    }

    >div {
      flex: 1;
      overflow: hidden;
    }

    .ant-tree {
      font-size: 12px;

      .ant-tree-list-holder {
        padding: 2px;
        box-sizing: border-box;
      }
    }
  }
}

.DelMessage-lz {
  >span {
    font-size: 12px;
    color: #BBBBBB;
  }
}

.allConditions-popover {
  .all-textIconBtn.all-textIconBtn.all-textIconBtn.all-textIconBtn {
    margin-left: 0 !important;

    >i {
      display: none;
    }

    >span {
      margin-left: 0;
    }
  }

  >div {
    padding: 2px 16px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
      color: #5477ff;
    }

  }
}

.associatedTestPlan {

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none
  }

  .choosePlan {
    height: 300px;
    overflow: auto;
    padding-left: 8px;
    box-sizing: border-box;

    li {
      padding: 4px 8px 4px 0px;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
      display: flex;
      cursor: pointer;

      &:hover {
        background-color: #f8f8f8;
      }

      .separateStyle {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    li:last-child {
      border-bottom: 1px solid transparent;
    }
  }
}

.case-batch-add-tags-dialog {
  .content.content.content {
    height: 300px;
  }

  .tags-popover.tags-popover {
    width: 300px;
  }
}