div.v-workbench {
    >.statistics {
        flex: none;
        display: flex;

        >div {
            position: relative;
            height: 100px;
            background-color: white;
            box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin: 12px 12px 0px 12px;
            display: flex;
            flex: 1 0 100px;

            &:last-child {
                flex: 5 0 200px;
                margin-left: 0px;

                >.iconfont {
                    position: absolute;
                    top: 6px;
                    right: 12px;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }

        .summary {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;

            &:not(:last-of-type)::after {
                content: "";
                display: block;
                height: 50%;
                width: 1px;
                background-color: #e4e4e4;
                position: absolute;
                right: 0px;
            }

            >i.icon {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #54FFB5;
                font-size: 18px;
                background-color: #54FFB520;

                &.alert {
                    color: #FB7A25;
                    background-color: #FB7A2520;
                }
            }

            >.data {
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                >span:first-child {
                    font-size: 24px;
                    font-weight: bold;
                }

                >span:last-child {
                    font-size: 12px;
                    color: #738299;
                }
            }
        }
    }

    .x-card.list {
        flex: 1;

        .x-datagrid {
            height: 100%;

            .row.task-expired {
                color: #EF1D1D;
            }

            .workbenchToTask {
                flex: 1;
                cursor: pointer;
            }
        }

        .no_across_line.x-datagrid {
            .header {
                .container {
                    div.column {
                        .top.x-fill {
                            border-right: none;
                        }

                        // &.fixed-left {
                        //     .top.x-fill {
                        //         border-right: 1px solid #e6e6e6;
                        //     }
                        // }
                    }
                }
            }

            .grid>div {
                .row {
                    .cell {
                        border-right: none;

                        // &.fixed-left {
                        //     border-right: 1px solid #e6e6e6;
                        // }
                    }
                }
            }
        }

    }

    .periodPicker {
        position: absolute;
        top: 38px;
        right: 14px;
        font-size: 14px;
        width: 264px;
        z-index: 10;
        background: #FFFFFF;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.10);
        border-radius: 4px;
        border-radius: 4px;

        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;
            margin-bottom: 0;
            margin-top: 14px;

            li.item {
                flex: 1;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 20px;
                cursor: pointer;

                .item-icon {
                    padding: 0 7px;
                    width: 16px;
                    height: 16px;
                }

                span.item-text {
                    display: inline-block;
                    flex: 1;
                    text-align: left;
                    // font-family: PingFangSC-Medium;
                    font-size: 14px;
                    font-weight: 800;
                    color: #13151A;
                }
            }
        }
    }
}