.work-flow-edit-dialog{
  .content.content.content.content{
    width: 1010px;
    height: 70vh;
  }
  .work-flow-edit-wrap{
    width: 100%;
    height: 100%;
    .work-flow-info-wrap{
      padding: 4px 12px;

      .work-flow-name{
        margin-right: 40px;
      }

      .work-flow-node-wrap{
        margin-right: 20px;
        .previous-node, .next-node{
          border: 1px solid #262626;
          padding: 2px 8px;
          border-radius: 4px;
        }
        .ant-tag{
          margin: 0 8px;
        }
        .next-icon{
          margin: 0 8px;
          transform: rotate(180deg);
        }
      }
    }
  }
  .ant-tabs{
    height: 100px;
    flex: 1;
    .ant-tabs-nav{
      padding-left: 10px;
      margin-bottom: 0;
    }
    .ant-tabs-content-holder{
      display: flex;
      flex: 1;
      height: 100px;
      overflow: hidden;
      padding: 6px;
    }
    .ant-tabs-content, .ant-tabs-content{
      flex: 1;
      overflow: auto;
    }
    .tab-content{
      height: 100%;
    }
  }
}