.imp-task-info-dialog.imp-task-info-dialog.imp-task-info-dialog {
  >.content.content.content.content {
    width: 750px;
    padding: 0;

    .imp-task-info-content {
      .mock-dialog-header {
        display: flex;
        align-items: center;
        color: #15171f;
        padding: 12px 16px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #e9eaf2;
        justify-content: space-between;

        .dialog-title {
          font-weight: 700;
        }

        .mock-right-header {
          .header-edit-icon {
            font-size: 18px;
            cursor: pointer;
            margin: 0 20px;

            &.edit-active-icon {
              color: #5477ff;
            }

            &:hover {
              color: #5477ff;
            }
          }

          .header-handle-icon {
            font-size: 18px;
            cursor: pointer;
            margin: 0 20px;

            &:hover {
              color: #5477ff;
            }
          }
        }

        .close-area {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          cursor: pointer;

          .close-icon {
            font-size: 14px;
          }

          &:hover {
            background-color: #ECECEC;
          }
        }
      }
    }
  }

  .imp-task-info-main-panel {
    height: 550px;
    max-height: 80vh;
    padding: 2px 8px;
    overflow: auto;

    &.detail-mode {
      .rootnet-forminput {
        align-items: flex-start;

        label {
          height: 32px;
          line-height: 32px;
        }
      }

      .form-component {
        height: unset;
        min-height: 32px;
        line-height: 32px;
      }
    }
  }

  .rich-text-form-item {
    margin: 8px;

    .mock-label {
      margin-top: 6px;
      width: 120px;
      text-align: right;
      color: #738299;

      &::after {
        content: ":";
        margin: 0 8px 0 2px;
      }
    }

    .rich-text-area {
      height: 250px;
      width: 536px;
    }
  }

  .imp-task-info-footer {
    display: flex;
    padding: 8px 0;
    height: 48px;
    box-sizing: border-box;
    border-top: 1px solid #E9EAF2;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;

    .empty-footer-area {
      width: 260px;
    }

    .btn-handle-group {
      width: 260px;
      justify-content: flex-end;
    }

    .imp-task-info-footer-btn {
      margin: 0 16px 0 0;
      height: 32px;
    }
  }

  .footer-switch {
    left: -158px;

    .footer-switch-item {
      cursor: pointer;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      box-sizing: border-box;
      display: flex;
      margin: 0 12px;
      color: #fff;
      background-color: #5477ff;
      transition: background-color 0.4s;

      &:hover {
        background-color: #214eff;
      }

      &.disabled {
        color: #6A6A6A;
        background-color: #E8E8E8;
        cursor: not-allowed;

        &:hover {
          background-color: #E8E8E8;
        }
      }

      >i {
        font-size: 14px;
      }


    }

    .list-num {
      font-weight: bold;
    }
  }

  .rich-text-value-show-wrap {
    max-width: 536px;
    line-height: 32px;
  }
}