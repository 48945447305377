.drawer-template-authority {
  .x-card {
    .card-content {
      .x-datagrid {
        .cell {
          padding: 0 4px;
          display: flex;

          >span {
            width: 95%;

            .user-select {
              width: 100%;
            }
          }
        }

        .x-datagrid-tooltip {
          width: 100%;
        }

        .convert-edit-field-style {
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .edit-icon {
            position: absolute;
            right: 2px;
            top: 9px;
            display: none;
            color: #5477FF;
          }

          &:hover .edit-icon {
            display: inline-block;
          }
        }

        .rootnet-input,
        .rootnet-select {
          width: 95%;
        }
      }
    }
  }
}