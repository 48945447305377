div.milepost-left.x {
    height: 100%;
    margin: 0 12px;
    width: 300px;
    .item{
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        cursor: pointer;
    }
    .active{
        background: #5477FF;
        color: #fff;
    }
}