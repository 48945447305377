$primaryColor: #5477FF;
div.work-time-detail-opt{
    display: flex;
    justify-content: space-between;
    margin: 14px 12px;
    .button{
        height: 32px;
    }
    .btn{
        width: 74px;
        height: 32px;
        background: #fbfcfe;
        border: 1px solid #7A8199;
        border-radius: 4px;
        line-height: 32px;
        color: #555;
        &.active{
            color: $primaryColor;
            border-color: $primaryColor;
        }
        &:hover{
            background: #fff;
        }
    }
    .optionLeft{
        .rootnet-forminput{
            margin: 0;
            margin-right: 20px;
        }
        .prefix{
            background-color: #fff;
            border: none;
            margin-left: 4px;
        }
    }
    .optionRight{
        display: flex;
        i.icon.iconfont{
            font-size: 14px;
            margin: 3px 6px 0 0
        }
    }
}