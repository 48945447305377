.user-info-card{
  width: 400px;
  min-height: 205px;
  padding: 8px;
  .user-avatar{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    background-color: #8DC8EA;
    margin-right: 12px;
  }
  .user-header-right{
    .user-name{
      margin-bottom: 4px;
    }
  }
  .user-content{
    .user-content-item{
      margin-bottom: 6px;
      .user-skill-group{
        flex: 1;
        flex-wrap: wrap;
        .ant-tag{
          margin-bottom: 4px;
        }
      }
    }
  }
}