div.all-go-back.all-go-back.all-go-back {
    height: 56px;
    line-height: 56px;
    margin-right: auto;
    i{
        font-size: 18px;
        margin: 0 25px;
        color: #5477ff;
        cursor: pointer;
    }
    .all-back-title{
        font-size: 14px;
        font-weight: 900;
    }
}