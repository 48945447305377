.all-textIconBtn{
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	.textBox {
		display: none;
		position: absolute;
		top: 24px;
		white-space: nowrap;
		padding: 6px;
		line-height: 1.5em;
		z-index: 8;
		background: rgba(0, 0, 0, 0.7);
		color: rgba(255, 255, 255, 0.8);
		box-shadow: 3px 3px 7px rgba(124, 124, 124, 0.7);
		border-radius: 3px;
		max-width: 470px;
		overflow: hidden;
		backdrop-filter: blur(5px);
	}
	.textWrite{
		font-size: 14px;
		margin-left: 4px;
	}
	&:hover {
		.textBox {
			display: block;
		}

		color: #5477ff;
	}

	&.tib-right {
		.textBox {
			right: 12px;
		}
	}

	&.tib-left {
		.textBox {
			left: 12px;
		}
	}

	&.disabled {
		background-color: white;
		color: #d6d8e0;
		pointer-events: none;
	}

	&.label{
		&:hover{
			color:#5477ff;
		}
	}
}
