.copyMove-lz {
  min-width: 480px;

  .content.content.content.content.content {
    min-height: 300px;

    .noTreelist {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: grey;
      padding-right: 16px;
      box-sizing: border-box;
    }

    >div:first-child {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px;
      border-left: 3px solid #2688E3;
      box-sizing: border-box;

      i {
        font-size: 16px;
        color: #2688E3;
      }

    }

    .rootnet-forminput {
      margin: 12px 0;

      label {
        font-size: 14px;
        color: black;
      }
    }

    >div:nth-child(3) {
      >div:first-child {
        margin-bottom: 4px;
      }

      >div:last-child {
        width: 100%;
        height: 300px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding-left: 16px;
        box-sizing: border-box;
      }
    }
  }
}