.version-req-stage-task {
  .rich-ellipsis-line {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

}

.version-req-stage-task-rich-text-value {
  max-height: 650px;
  overflow: auto;
}