.issue-analysis{
  font-size: 12px;
  .new-version{
    .rootnet-radiogroup{
      width: 186px;
    }
  }
  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
    font-size: 12px;
    label{
      font-size: 12px;
    }
  }
  .area-wrap{
    .area-header{
      margin: 4px 0;
    }
    .area-content-wrap{
      overflow: hidden;
    }
  }
  .fold-icon{
    cursor: pointer;
    //transition: transform 0.3s;
    margin-left: 15px;
    margin-right: 18px;
    &:hover{
      color: #5477ff;
    }
  }
  .rootnet-forminput.rootnet-forminput.rootnet-forminput.rootnet-forminput{
    margin: 2px 8px;
  }
  .long-options{
    .rootnet-popup{
      .container{
        .option{
          min-width: 300px;
        }
      }
    }
  }

  .rich-text-detail-wrap{
    min-height: 200px;
    width: 100%;
    overflow: auto;
    padding: 10px 20px 10px 50px;
    img{
      max-width: 100%;
    }
  }
  .rich-text-area{
    //height: 350px;
    width: 100%;
    padding: 10px 20px 10px 50px;
  }
  .ellipsis-line{
    width: 180px;
    white-space: nowrap;/*不允许换行*/
    overflow: hidden;/*超出隐藏*/
    text-overflow: ellipsis;/*文本超出三点代替*/
  }

  .test-suggest-textarea.test-suggest-textarea.test-suggest-textarea.test-suggest-textarea{
    width: 100%;
  }



  .release-list-wrap{
    .mock-label{
      margin: 8px 6px 8px 8px;
      width: 120px;
      color: #738299;
      text-align: right;
      &::after {
        content: ":";
        margin: 0 8px 0 2px;
      }
    }
  }

  .join-version-icon{
    cursor: pointer;
  }
  .release-list-show-wrap{
    width: 502px;
    //border: 1px solid #D0D3E3;
    border-radius: 4px;
    margin-top: 2px;
    overflow: hidden;
    margin-bottom: 6px;
    flex-wrap: wrap;
    .release-list-item{
      padding: 3px 12px;
      background-color: #f8f8f8;
      margin: 2px;
      border-radius: 4px;
      // .release-list-item-text{

      // }
      .release-list-item-icon{
        font-size: 14px;
        color: #ccc;
        cursor: pointer;
        display: none;
      }
    }
  }

  .search-tracer {
    .prefix {
      width: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: unset;

      i {
        font-size: 14px;
      }
    }

    .suffix {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: unset;

      i {
        display: none;
        cursor: pointer;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.25);

        &:hover {
          color: gray;
        }
      }
    }

    &:hover {
      .suffix>i {
        display: inline-block;
      }
    }

    input {
      text-indent: unset;
    }
  }
}