.project-manpower-summary-list.project-manpower-summary-list {
  flex: 1;
  display: flex;
  overflow: hidden;

  .x-card {
    flex: 1;
    margin: 8px 12px;

    .title {
      display: flex;
      font-weight: bold;

      &::before {
        display: block;
        content: "";
        width: 2px;
        height: 1em;
        background-color: #5477ff;
        box-shadow: 2px 0 3px 0 rgba(24, 144, 255, 0.42);
        margin-right: 12px;
        margin-top: 4px;
      }
    }

    .hint {
      margin-left: 16px;
      color: #9A9A9A;
    }

    .card-content.card-content {
      overflow: auto;
    }
  }
}