.project-drawer {
  width: 700px;

  >div.header.header.header {
    height: 39px;
    font-weight: 600;
    padding-left: 24px;
  }

  >div.content {
    display: block;
    overflow: hidden;

    .project-history-record.project-history-record.project-history-record {
      height: 50%;
      padding: 0;
      width: 95%;
      margin: 0;
      // margin-top: 50px;
      padding-top: 50px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      >li {
        position: relative;
        list-style: none;
        padding-bottom: 50px;
        display: list-item;
        box-sizing: border-box;

        >div:nth-child(1) {
          >span {
            margin: 0;
            display: block;
            box-sizing: border-box;
          }

          >span:nth-child(1) {
            font-size: 16px;
          }

          >span:nth-child(2) {
            padding-top: 5px;
            font-size: 14px;
          }

          position: absolute;
          top: -18px;
          width: calc(50% - 29%);
          text-align: right;
        }

        >div:nth-child(2) {
          position: absolute;
          left: 24%;
          top: 10px;
          height: calc(100% - 10px);
          border-left: 2px solid #f0f0f0;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        >div:nth-child(3) {
          margin-left: -6.5px;
          position: absolute;
          left: 24%;
          color: #1890ff;
          width: 15px;
          height: 15px;
          background-color: #fff;
          border: 2px solid #1890ff;
          border-radius: 100px;
          box-sizing: border-box;
        }

        >div:nth-child(4) {
          font-size: 16px;
          position: relative;
          top: -5px;
          left: 23%;
          width: 75%;
          text-align: left;
          margin-left: 26px;
          padding: 0;
          word-break: break-word;
          box-sizing: border-box;

          >span {
            margin: 0;
            display: block;
            box-sizing: border-box;
          }

          >span:nth-child(1) {
            font-size: 16px;
            width: 100%;
          }

          >span:nth-child(2) {
            padding-top: 5px;
            font-size: 14px;
          }
        }

        >.reason.reason.reason {
          top: -18px;
        }

        >.reasonColor.reasonColor.reasonColor {
          border: 2px solid red
        }
      }

      >li:last-child {
        >div:nth-child(2) {
          display: none;
        }
      }

    }

    .project-record {
      height: 100%;
      padding-left: 16px;
      padding-right: 8px;
    }
  }

  .footer.footer.footer {
    border-top: none;
  }
}