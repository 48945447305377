.version-detail {
  height: 100%;
  display: flex;

  .version-tabs {
    flex: 1;
    overflow: hidden;
    margin: 8px 12px;
    background-color: white;

    >.ant-tabs-nav {
      .ant-tabs-nav-list {
        .ant-tabs-tab:nth-child(11) {
          position: relative;

          &::before {
            position: absolute;
            content: '';
            width: 1px;
            height: 28px;
            left: -12px;
            background-color: #CFD3E3;
          }
        }
      }
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      height: 100%;
      padding: 6px;
      padding-top: 0;
    }

    .ant-tabs-content,
    .ant-tabs-content {
      height: 100%;


      .ant-tabs-tabpane {
        height: 100%;

        &.ant-tabs-tabpane-hidden {
          display: none;
        }
      }
    }

    .tab-content {
      height: 100%;
    }
  }
}