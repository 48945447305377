.tracking-detail-info {
  font-size: 12px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .tracking-info {
    width: calc(100% - 120px);
  }

  .area-wrap {
    .rich-text-area {
      min-height: 300px;

      .tox-tinymce {
        min-height: 300px;
      }
    }

    .area-content-wrap {
      overflow: hidden;

      .database-tabs {
        .english-num-styley {
          word-break: break-all;
        }

        .ant-tabs-content-holder.ant-tabs-content-holder {
          display: flex;
          flex: 1;
          padding: 0;
          border: none;
          border-bottom-color: transparent;

          .ant-tabs-content,
          .ant-tabs-content {
            flex: 1;
          }

          .rootnet-forminput {
            // width: calc(100% - 20px);
            width: 100%;


            .form-component {
              font-size: 12px;
              // width: calc(90% + 18px) !important;
              width: 90%;
            }
          }
        }
      }

      .rootnet-forminput {
        width: 100%;
        margin: 8px 0;

        .autoTextarea {
          display: flex;

          >textarea {
            position: static;
          }
        }

        .form-component.form-component.form-component {
          margin-left: 0;
          width: 100% !important;
          white-space: pre-wrap;
          height: auto;
        }
      }
    }
  }

  .tracking-detail-anchor {
    position: absolute;
    top: 20%;
    right: 0;

    .tracking-anchor {
      position: relative;
      font-size: 14px;
      color: black;
      padding-left: 2px;
      box-sizing: border-box;

      .tracking-anchor-ink {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        .tracking-anchor-ink-ball.visible {
          display: inline-block;
        }

        .tracking-anchor-ink-ball {
          position: absolute;
          left: 50%;
          display: none;
          width: 8px;
          height: 8px;
          background-color: #fff;
          border: 2px solid #1890ff;
          border-radius: 8px;
          transform: translate(-50%);
          transition: top .3s ease-in-out;
        }

        &::before {
          position: relative;
          display: block;
          width: 2px;
          height: 100%;
          margin: 0 auto;
          background-color: #0000000f;
          content: " ";
        }
      }

      .tracking-anchor-link {
        padding: 7px 0 7px 16px;
        box-sizing: border-box;

        .tracking-anchor-link-title.tracking-anchor-link-title-active {
          color: #1890ff;
        }

        .tracking-anchor-link-title {
          position: relative;
          display: block;
          margin-bottom: 6px;
          overflow: hidden;
          color: black;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: all .3s;
          cursor: pointer;

          &:hover {
            color: #1890ff;
          }

          &:only-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}