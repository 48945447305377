.check-list-export-log {
  width: 470px;
  height: 75vh;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: auto;

    .ant-select-selector {
      overflow: auto;
      height: 32px;
    }

    .title-bold {
      font-weight: bold;
    }

    .rootnet-forminput {
      margin: 4px;
    }

    .export-customers {
      height: 32px;
      margin: 4px;
      display: flex;
      align-items: center;

      .ant-checkbox-wrapper {
        >span:nth-child(2) {
          padding-right: 6px;
        }
      }

      .ant-select-selector {
        border-radius: 4px;
        width: 240px;
        border-color: #CFD3E3;
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #5477FF;
        box-shadow: none;
      }
    }

    .button {
      height: 22px;
      margin-left: 33px;
      padding: 0 4px;
      margin-bottom: 4px;
    }
  }
}

.modify-file-export-log {
  .content.content.content.content {
    padding: 0;
    width: 400px;

    .label-text {
      white-space: nowrap;
      color: #738299;
    }

    .content-panel {
      padding: 12px;
    }

    .rootnet-radiogroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;

      .btn-group {
        .button {
          margin-right: 8px;
          height: 32px;
        }
      }
    }
  }
}

.inventory-export-log {
  width: 570px;

  .text-hint {
    font-size: 12px;
    color: rgb(191, 191, 191);
    padding-left: 16px;
    font-weight: 400;
  }

  .title-wrap {
    .title-text {
      margin-right: 4px;
    }
  }

  .content.content.content.content.content.content {
    display: flex;
    flex-direction: column;
    padding: 0;

    .ant-select {
      .ant-select-selector {
        height: 32px;
        overflow: auto;
      }
    }

    .checkbox-group-style {
      display: inline-flex;
      align-items: center;
      margin: 8px;
      box-sizing: border-box;

      >label {
        flex: none;
        text-align: right;
        margin-bottom: 0px;
        font-size: 14px;
        white-space: nowrap;
        color: #738299;

        &::after {
          content: ":";
          margin: 0px 8px 0px 2px;
        }
      }
    }

    .content-panel {
      padding: 16px;
    }

    .mock-footer {
      height: 44px;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      border-radius: 0 0 4px 4px;
      justify-content: space-between;
      border-top: 1px solid #e9eaf2;
      padding: 0 16px 0 16px;

      .btn-group {
        .button {
          margin-right: 8px;
          height: 32px;
        }
      }
    }
  }
}

.script-export-log {
  .form-select-client {
    margin: 8px;

    .label-client {
      margin-bottom: 0px;
      font-size: 14px;
      white-space: nowrap;
      color: #738299;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &::after {
        content: ":";
        margin: 0px 8px 0px 2px;
      }
    }

    .ant-select {
      width: 180px;
    }
  }
}