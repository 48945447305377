.task-list-making {

  .left-list {
    background-color: white;
    display: flex;
    border-radius: 4px;

    >.title {
      height: 40px;
      border-bottom: 1px solid #e9eaf2;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      >span:last-child {
        >i {
          margin-left: 8px;
          cursor: pointer;
          font-size: 16px;

          &:hover {
            color: #5477ff;
          }
        }
      }
    }

    .expendIconWrap {
      padding-left: 0;
      padding-right: 12px;
    }

    .left-list-tree-data {
      padding-top: 16px;
      box-sizing: border-box;
      flex: 1;
      overflow: hidden;
      padding-left: 0;
    }

    >div {
      padding: 0 16px;
      box-sizing: border-box;
      margin-left: 0 !important;
    }

    .tree-list-title {
      display: flex;
      justify-content: space-between;

      .hover-suffix {
        display: none;

        >i {
          display: inline-block;
          transform: rotate(-90deg);
          font-weight: 900;
          font-size: 15px;

          &:hover {
            color: #5477ff;
          }
        }
      }

      &:hover {
        .hover-suffix {
          display: inline-block;
        }
      }
    }

    .ant-tree {
      .ant-tree-list-holder {
        overflow-x: hidden;
      }

      flex: 1;
    }
  }
}