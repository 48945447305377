.requirement-copy-dialog{
  .content.content.content.content{
    width: 500px;
    .rootnet-checkbox{
      margin-left: 36px;
      >span{
        margin-left: 10px;
      }
    }
  }
}