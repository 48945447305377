.module-information {
  .ant-drawer-content-wrapper {
    width: 70% !important;

    .headr-title {
      align-items: center;

      .switch-type {
        margin-left: 18px;
      }
    }

    div.x-datagrid>div.grid div.row>div.cell.row-span {
      background-color: inherit;
    }

    .convert-edit-field-style {
      width: 100%;
      cursor: pointer;
      text-align: center;
      position: relative;

      .edit-icon {
        position: absolute;
        top: 3px;
        right: 4px;
        display: none;
        color: #5477FF;
      }

      &:hover .edit-icon {
        display: inline-block;
      }
    }

    .x-datagrid-tooltip {
      width: 100%;

      .rootnet-input {
        width: 95%;
      }
    }
  }
}