.personnel-gap.personnel-gap.personnel-gap {
  .art-table-wrapper {
    // border-top: none;
    font-size: 12px;

    .convert-edit-field-style {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .distinguish-month-fill {
        width: 100%;
        height: 100%;
        line-height: 34px;
      }

      .edit-icon {
        position: absolute;
        right: 16px;
        top: 1px;
        display: none;
        color: #5477FF;
      }

      &:hover .edit-icon {
        display: inline-block;
      }
    }

    .rootnet-input {
      width: 95%;
    }

    .x.loader {
      width: 566px;
      height: 280px;
    }

    .art-table-row:hover .distinguish-month {
      background-color: #F5F5F5;
    }

    .art-table-cell {
      padding: 0;

      .distinguish-month {
        width: 100%;
        height: 100%;
        background-color: #eeeeee;
        line-height: 34px;
      }

      .saturation-item {
        color: #fff;
        font-size: 16px;
        height: 100%;
        width: 100%;
      }

      .date-item {
        height: 100%;
        width: 100%;
      }

      .cell-full-item {
        height: 100%;
        width: 100%;
      }

      .rest-day {
        background-color: #F5F5F5;
      }

      .has-task-item {
        background: rgba(255, 125, 0, 0.2);
      }

      .task-item {
        &:hover {
          cursor: pointer;
          background-color: #8DC8EA55;
        }
      }
    }

  }

  .dfjeIr th {
    padding: 4px 8px;
    box-sizing: border-box;
  }

  .art-table-cell.lock-left.first:nth-child(1) {
    padding-left: 12px;
  }

  .summary-bold {
    font-weight: 900;
  }

  .total-row {
    text-align: center;
    font-weight: 900;
  }
}

.personnel-gap-details {
  padding: 8px;
  min-height: 280px;
  max-height: 500px;

  .x-card {
    margin: 8px 0;
    max-height: 240px;
  }
}