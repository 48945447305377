.version-making {
  height: 100%;
  display: flex;

  .ant-tabs {
    flex: 1;
    overflow: hidden;

    .ant-tabs-content {
      overflow: hidden;

      .x-datagrid {
        font-size: 12px;
      }
    }
  }
}