div.r.details {
    margin-top: 32px;
    margin-left: 50px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-row-gap: 24px;
}


div.r.tail {
    .text {
        display: inline-grid;
        justify-content: end;
        color: #738299;
        width: 120px;
        text-align: right;
    }
}

div.r.backLink {
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 16px;

}