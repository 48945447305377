.existing-personnel {
  .ant-drawer-content-wrapper {
    width: 80% !important;

    .ant-drawer-header {
      .headr-title {
        justify-content: space-between;
        .switch-type{
          margin-right: 28px;
          font-size: 12px;
        }
      }
    }

    .art-table-wrapper {
      font-size: 12px;

      .art-table-cell {
        padding: 0;
      }

      .art-table-cell.first:nth-child(1) {
        padding-left: 12px;
      }
    }

    .convert-edit-field-style {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .edit-icon {
        position: absolute;
        right: 2px;
        top: 9px;
        display: none;
        color: #5477FF;
      }

      &:hover .edit-icon {
        display: inline-block;
      }
    }

    .rootnet-input,
    .rootnet-select,
    .rootnet-datepicker {
      width: 95%;
    }

    .rootnet-datepicker {
      .rootnet-popup {
        max-width: 240px;

        table {
          padding: 0;
        }

        thead>th>tr {
          margin: 0;
          padding: 0;
        }

        tbody>tr {
          padding: 0;
          margin: 0;

          td {
            padding: 0;
            margin: 0;

            div {
              padding: 0;
              margin: 0;
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }

  }
}