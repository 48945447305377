.resource-forecast-detail {
  flex: 1;
  margin-left: 8px;
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .import-annual-forecast-workload {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2EEEE;

    .textIconBtn {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-tabs {
    flex: 1;
    padding: 8px;
    box-sizing: border-box;
    overflow: hidden;

    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content-holder {
      display: flex;
      flex: 1;
      overflow: hidden;
    }

    .ant-tabs-content,
    .ant-tabs-content {
      flex: 1;
      overflow: auto;
    }

    .tab-content {
      height: 100%;
    }
  }
}

.antd-default-style {
  .ant-drawer-header {
    padding: 8.5px 24px;

    .ant-drawer-close {
      padding: 12px;
    }
  }

  .ant-drawer-body {
    padding: 0;

    .x-datagrid {
      height: 100%;
    }
  }
}