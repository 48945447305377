.editable-email-list{
  margin-top: 12px;
  flex: 1;
  width: 1000px;
  flex-wrap: wrap;
  .tag-plus {
    background: #fff;
    border-style: dashed;
    cursor: pointer;
  }
  .edit-tag {
    user-select: none;
  }
  .tag-input {
    width: 160px;
    //font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
    height: 22px;
  }
  .ant-tag{
    margin-bottom: 4px;
    height: 24px;
    //margin: 2px;
    color: rgba(0,0,0,.85);
    .ant-tag-close-icon{
      color: rgba(0,0,0,.65);
    }
  }
}