.requirement-mgt-list {
  .grid.grid.grid {
    .rich-ellipsis-line {
      padding: 0 12px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cell {
      padding: 0;

      .person-select {
        width: unset !important;

        .ant-select-selection-overflow {
          flex-wrap: unset;
        }
      }

      .rootnet-forminput {
        margin-left: 2px;
      }

      .common-display-field {
        padding: 0 12px;
      }

      .common-edit-field {
        padding: 0 12px;
        cursor: pointer;
        height: 34px;

        &:hover {
          background: #EBF0FB;
        }
      }

      .enter-detail-field-wrap {
        cursor: unset;
        width: 100%;

        &:hover .concerned-icon {
          display: inline-block !important;
        }

        .enter-detail-field {
          width: 100px;
          flex: 1;

          .enter-detail-field-text {
            max-width: 100%;
            cursor: pointer;
            color: #5477ff;
            overflow: hidden; //隐藏多余行数
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; //规定显示几行，其余行数用小数点代替
            -webkit-box-orient: vertical; //规定为垂直方向排列

            &:hover {
              text-decoration: underline
            }
          }
        }

        .enter-detail-field-edit-icon {
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;

          &:hover {
            color: #5477ff;
          }
        }

        &:hover {
          .enter-detail-field-edit-icon {
            display: block;
          }
        }
      }

      .arrow-drop-down-group {

        .common-display-field,
        .common-edit-field {
          padding-left: 4px;
          padding-right: 12px;
        }
      }
    }
  }

  .work-flow-status-field {
    cursor: pointer;
    margin-left: 8px;
  }

  .space-between {
    justify-content: space-between;
  }

  .left-enter-field-wrap {
    width: 100px;
    flex: 1;

    .ant-tag {
      padding: 2px 6px;
      font-size: 12px;
      line-height: 12px;
      margin-right: 4px;
    }
  }
}