.dev-project-issue.dev-project-issue.dev-project-issue {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .x-card-singlegrid {
    //margin: 0;
  }

  .x-card {
    margin-top: 0;

    .x-datagrid {
      .enter-detail-field-wrap {
        cursor: unset;

        .enter-detail-field {
          cursor: pointer;
          color: #5477ff;
          white-space: nowrap;
          /*不允许换行*/
          overflow: hidden;
          /*超出隐藏*/
          text-overflow: ellipsis;

          /*文本超出三点代替*/
          &:hover {
            text-decoration: underline
          }
        }

        .enter-detail-field-edit-icon {
          display: none;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;

          &:hover {
            color: #5477ff;
          }
        }

        &:hover {
          .enter-detail-field-edit-icon {
            display: block;
          }
        }
      }

      .empty-parent {
        width: 20px;
      }

      .empty-child {
        width: 40px;
      }

      .expand-icon {
        margin-right: 8px;
        margin-top: 2px;
        cursor: pointer;

        &:hover {
          color: #3f7dff;
        }
      }

      .enter-detail-field {
        cursor: pointer;
        color: #5477ff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}