.version-form-list {

  .version-copy-icon,
  .version-del-icon {
    color: #5477ff;
    cursor: pointer;
  }

  .ant-table {

    .ant-empty.ant-empty-normal {
      display: none;
    }
  }

  .add-data-item {
    margin-top: 4px;
    height: 32px;
    border: 1px dashed #f0f0f0;
    text-align: center;
    line-height: 32px;
    cursor: pointer;

    &:hover {
      color: #5477ff;
      border-color: #5477ff;
    }

    .icon {
      font-weight: 900;
    }
  }

  .rootnet-checkbox.rootnet-checkbox.rootnet-checkbox {
    margin: 0;
  }

  .mulcust-select-style.required {
    >label {
      &::before {
        display: inline-block;
        margin-right: 4px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
        color: #F5221B;
      }
    }
  }

  .mulcust-select-style {
    display: inline-flex;
    align-items: center;
    margin: 8px;
    box-sizing: border-box;

    >label {
      flex: none;
      text-align: right;
      margin-bottom: 0px;
      font-size: 14px;
      white-space: nowrap;
      color: #738299;

      &::after {
        content: ":";
        margin: 0px 8px 0px 2px;
      }
    }

    .mulcustid-style-color.mulcustid-style-color {
      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
      }

      .ant-select-selector {
        border-color: red;
      }
    }

    .ant-select {
      border-radius: 4px;

      .ant-select-selector {
        border-radius: 4px;
        height: 32px;
        overflow: auto;
      }
    }
  }

  .checkbox-group-style {
    display: inline-flex;
    align-items: center;
    margin: 8px;
    box-sizing: border-box;

    >label {
      flex: none;
      text-align: right;
      margin-bottom: 0px;
      font-size: 14px;
      white-space: nowrap;
      color: #738299;

      &::after {
        content: ":";
        margin: 0px 8px 0px 2px;
      }
    }

    .ant-checkbox-group {
      width: 230px;
    }
  }
}